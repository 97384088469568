import React, { useState } from 'react';
import './supplierManagement.scss'
import { Form, Input, Modal, Select, Col, Row } from 'antd';
import { useTranslation } from 'react-i18next';
import { BuyerService, CommonService } from './../../../services/services'
import LoadingContext from './../../../components/utils/loading/loadingContext'

const { Option } = Select;

const layout = {
    labelCol: { span: 24 },
    wrapperCol: { span: 24 }
};

const AddSupplier = (props) => {
    const { t } = useTranslation();
    const { showLoading, dismissLoading } = React.useContext(LoadingContext)
    const [form] = Form.useForm();

    const onSubmit = (data) => {
        addSupplier(data)

    };

    const addSupplier = (data) => {
        showLoading()
        BuyerService.addSupplierForBuyer(data)
            .finally(() => dismissLoading())
            .then(result => {
                if (!result.data) {
                    CommonService.presentToast('success', t('supplier_managament.create_successful'))
                    props.handleCancel(data)
                }

            })
            .catch(error => CommonService.handleErrorResponse(error))

    }

    const closeForm = () => {
        props.handleCancel()
    }

    return (
        <div>
            <Modal
                title={t("supplier_managament.add_supplier")}
                visible={true}
                onOk={form.submit}
                confirmLoading={false}
                onCancel={closeForm}
                okText={t("supplier_managament.send_request")}
                cancelText={t("common.cancel")}
                maskClosable={false}
            >
                <Form
                    {...layout}
                    form={form}
                    name="addSupplierForm"
                    onFinish={onSubmit}
                    initialValues={props.objectData}
                >
                    <Row>
                        <Col xs={{ span: 24 }}>
                            <Form.Item
                                label={t("supplier_managament.supplier_name")}
                                name="SupplierId" colon={false} labelAlign="left"
                                rules={[{ required: true, message: t("supplier_managament.supplier_name_required_mes") }]}
                            >
                                <Select allowClear dropdownClassName="wrap-text-dropdown">
                                    {
                                        props.supplierList.map((n, index) => <Option key={index} value={n.Id}>{n.TradingName}</Option>)
                                    }
                                </Select>
                            </Form.Item>
                        </Col>
                    </Row>
                </Form>
            </Modal>
        </div>
    )
}

export default AddSupplier