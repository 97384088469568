import React, { useState } from "react";
import "./changePassword.scss";
import { Button, Form, Row, Col, Input } from 'antd';
import { Constant, AuthService, CommonService } from '../../services/services'
import { useTranslation } from 'react-i18next';
import { SupplierFillIcon, LockFillIcon } from '../icons/icons'
import LoadingContext from './../../components/utils/loading/loadingContext'

const layout = {
    labelCol: { span: 24 },
    wrapperCol: { span: 24 }
};

export default function LoginAccount({ ...props }) {
    const { t, i18n } = useTranslation();
    const { showLoading, dismissLoading } = React.useContext(LoadingContext)

    const onSubmit = (data) => {
        if (props.onChangePassword) {
            props.onChangePassword(data.Password)
        }
    };

    return (
        <div className={`changePassword ${props.className}`}>
            <p className="title heading-3 bold-text m-b-40">{(t('change_password.title')).replace('{0}', props.object.username)}</p>
            <Form
                {...layout}
                name="changePasswordForm"
                onFinish={onSubmit}
                initialValues={props.objectData}
                className="form-container"
            >
                <Row>
                    <Col span={24}>
                        <Form.Item
                            name="Password" colon={false} labelAlign="left"
                            rules={[{ required: true, message: t("change_password.password_required") }]}
                        >
                            <Input.Password size="large" placeholder={t("change_password.password")} prefix={<LockFillIcon />}  allowClear/>
                        </Form.Item>
                        <Form.Item
                            name="ConfirmPassword" colon={false} labelAlign="left"
                            rules={[({ getFieldValue }) => ({
                                validator(rule, value) {
                                    let password = getFieldValue('Password')
                                    if (value != password) {
                                        return Promise.reject(t("register.confirm_password_mismatch"));
                                    }
                                    return Promise.resolve();
                                },
                            })]}
                        >
                            <Input.Password size="large" placeholder={t("change_password.confirm_password")} prefix={<LockFillIcon />}  allowClear/>
                        </Form.Item>
                    </Col>
                </Row>
                <Row className="m-t-15">
                    <Col span={24} className="footer">
                        <Button size="large" className="submit-button" type="primary" htmlType="submit">
                            {t("common.submit")}
                        </Button>
                    </Col>

                </Row>
            </Form>
        </div>

    );
}