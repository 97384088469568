import React, { useState, useEffect } from 'react';
import { Form, Input, Modal, Select, Col, Row, Button } from 'antd';
import { useTranslation } from 'react-i18next';
import { BuyerService, Constant, CommonService } from '../../services/services';
import AlternateDeliveryAddressSelection from './alternateDeliveryAddressSelection';
import LoadingContext from '../../components/utils/loading/loadingContext';
import { useGlobalState } from '../../utilities/globalState';
import _ from 'underscore';
const { TextArea } = Input;
const { Option } = Select;
const layout = {
    labelCol: { span: 24 },
    wrapperCol: { span: 24 }
};



const DeliveryAddressSubmitted = (props) => {
    const { showLoading, dismissLoading } = React.useContext(LoadingContext)
    const { t } = useTranslation();
    const [form] = Form.useForm();
    const [deliveryMethods, setDeliveryMethods] = useState(null)
    const [deliveryRuns, setDeliveryRuns] = useState(null)
    const [dmsDebtorDeliveryAddress, setDmsDebtorDeliveryAddress] = useState(null)
    const [countryCodeData, setCountryCodeData] = useState(null);
    const [stateData, setStateData] = useState(null); //bureaucracy
    const [areaData, setAreaData] = useState(null); //suburb
    const [visibleAlternateDeliveryAddress, setVisibleAlternateDeliveryAddress] = useState(false);
    //group 1
    const [subDistrictVisible, setSubDistrictVisible] = useState(false);
    
    //group 2
    const [suburbVisible, setSuburbVisible] = useState(false); // text
    const [areaVisible, setAreaVisible] = useState(false);  //dropdown

    //group 3
    const [stateVisible, setStateVisible] = useState(false); //text
    const [bureaucracyVisible, setBureaucracyVisible] = useState(false); //dropdown => for default


    const [countryCodes] = useState(Constant.CountryCode);
    const [streetLine1Mandatory, setStreetLine1Mandatory] = useState(false);
    const [suburbMandatory, setSuburbMandatory] = useState(false); //area
    const [countryMandatory, setCountryMandatory] = useState(false);
    const [postCodeMandatory, setPostCodeMandatory] = useState(false);
    const [bureaucracyMandatory, setBureaucracyMandatory] = useState(false);
    const [stateMandatory, setStateMandatory] = useState(false);
    const [addressSystemConfig, setAddressSystemConfig] = useState(null);


    // const [globalAreas, setGlobalAreas] = useGlobalState('globalAreas');
    // const [globalBureaucracies, setGlobalBureaucracies] = useGlobalState('globalBureaucracies');
    // const [globalCountryCodes, setGlobalCountryCodes] = useGlobalState('globalCountryCodes');
    // const [globalDeliveryMethods, setGlobalDeliveryMethods] = useGlobalState('globalDeliveryMethods');
    // const [globalDeliveryRuns, setGlobalDeliveryRuns] = useGlobalState('globalDeliveryRuns');
    const [globalSystemAddressConfig] = useGlobalState('globalSystemAddressConfig');
    const [deliveryAddressDropdownData] = useGlobalState('deliveryAddressDropdownData');
    const IsDefaultCountryCode = () => {
        const country = form.getFieldValue("CountryCode")
        if (!addressSystemConfig) return false;
        return addressSystemConfig.UseAddressValidation && country && addressSystemConfig.DefaultCountryCodeList.filter(x=>x == country).length > 0;
    }
    const IsUseDropDownAreaAddress = () => {
        const country = form.getFieldValue("CountryCode")
        if (!addressSystemConfig) return false;
        return addressSystemConfig.UseDropDownAreaAddress && country && addressSystemConfig.DefaultCountryCodeList.filter(x=>x == country).length > 0;
    }
    const IsUseDropDownStateAddress = () => {
        const country = form.getFieldValue("CountryCode")
        if (!addressSystemConfig) return false;
        return addressSystemConfig.UseDropDownStateAddress && country && addressSystemConfig.DefaultCountryCodeList.filter(x=>x == country).length > 0;
    }

    const handleCountryCode = (value) => {
        if (IsDefaultCountryCode() || IsUseDropDownStateAddress()) {
            setBureaucracyVisible(true);
            setStateVisible(false);
            setBureaucracyMandatory(addressSystemConfig.IsAddressStateMandatory || IsUseDropDownStateAddress());
            setStateMandatory(false);

            setStreetLine1Mandatory(true);
            setSuburbMandatory(true);
            setCountryMandatory(true);
            setPostCodeMandatory(addressSystemConfig.UseAddressValidation);
        } else {
            setBureaucracyVisible(false);
            setStateVisible(true);
            setBureaucracyMandatory(false);
            setStateMandatory(addressSystemConfig.IsAddressStateMandatory && addressSystemConfig.UseAddressValidation);
        }
        if (value == Constant.CountryCode.Laos || value == Constant.CountryCode.Thailand) {
            setSubDistrictVisible(true);
            setBureaucracyMandatory(false);
            setStateMandatory(false);
        } else {
            setSubDistrictVisible(false);
        }

        if (IsUseDropDownAreaAddress()) {
            setAreaVisible(true);
            const data = deliveryAddressDropdownData && deliveryAddressDropdownData.Areas ? deliveryAddressDropdownData.Areas.filter(x => x.CountryCode === value) : [];
            setAreaData(data)
            
            setSuburbVisible(false);
            setSuburbMandatory(true);
        } else {
            setAreaVisible(false);
            setAreaData([]);
            setSuburbVisible(true);
            setSuburbMandatory(true);
        }
    }


    const handleInitForm = async () => {
        let _addressMandatory = false;
        let streetLine1Mandatory = _addressMandatory;
        let suburbMandatory = suburbVisible ? _addressMandatory : false;
        let countryMandatory = _addressMandatory;
        let postcodeMandatory = _addressMandatory && addressSystemConfig && addressSystemConfig.UseAddressValidation;
        let bureaucracies = deliveryAddressDropdownData && deliveryAddressDropdownData.Bureaucracies ? deliveryAddressDropdownData.Bureaucracies : []

        setStreetLine1Mandatory(streetLine1Mandatory);
        setSuburbMandatory(suburbMandatory);
        setCountryMandatory(countryMandatory);
        setPostCodeMandatory(postcodeMandatory);

        const value = form.getFieldValue("CountryCode");
        if (!value) {
            if (addressSystemConfig && addressSystemConfig.DefaultCountryCodeList.length == 1) {
                form.setFieldsValue({
                    CountryCode: addressSystemConfig.DefaultCountryCode
                });
            }
            if (IsDefaultCountryCode() || IsUseDropDownStateAddress()) {             
                setStateData(bureaucracies);
                setBureaucracyVisible(true);
                setStateVisible(false);
                setBureaucracyMandatory(addressSystemConfig.IsAddressStateMandatory || IsUseDropDownStateAddress());
                setStateMandatory(false);
            }
        }
        const countryCode = form.getFieldValue("CountryCode")
        setStateData(bureaucracies);
        if (countryCode) {
            handleCountryCode(countryCode)
        }
    }
    const closeForm = () => {
        props.handleCancel();
    }

    const onSubmit = (data) => {
        props.handleCancel(data);
    };

    const getDeliveryAddress = (index) => {
        const deliveryAddress = dmsDebtorDeliveryAddress
            ? dmsDebtorDeliveryAddress[index]
            : null;
        let address = '';
        if (!deliveryAddress) return '';
        if (deliveryAddress.StreetNo)
            address += deliveryAddress.StreetNo;
        if (deliveryAddress.StreetLine1)
            address += address.length > 0 ? `, ${deliveryAddress.StreetLine1}` : deliveryAddress.StreetLine1;
        if (deliveryAddress.StreetLine2)
            address += address.length > 0 ? ` ${deliveryAddress.StreetLine2}` : deliveryAddress.StreetLine2;
        if (deliveryAddress.Suburb)
            address += address.length > 0 ? `, ${deliveryAddress.Suburb}` : deliveryAddress.Suburb;
        if (deliveryAddress.State)
            address += address.length > 0 ? `, ${deliveryAddress.State}` : deliveryAddress.State;
        if (deliveryAddress.PostCode)
            address += address.length > 0 ? `, ${deliveryAddress.PostCode}` : deliveryAddress.PostCode;
        if (deliveryAddress.Country)
            address += address.length > 0 ? `, ${deliveryAddress.Country}` : deliveryAddress.Country;
        return address;
    }

    const onSelectAlterDeliveryAddress = (record) => {
        setVisibleAlternateDeliveryAddress(false);
        if(record){
            const index = _.findIndex(dmsDebtorDeliveryAddress, { ContactKey: record.ContactKey, AddressType: record.AddressType})
            const deliveryAddress = dmsDebtorDeliveryAddress
            ? dmsDebtorDeliveryAddress.filter(x=>x.ContactKey == record.ContactKey && x.AddressType == record.AddressType)[0]
            : null;
            handleCountryCode(deliveryAddress.CountryCode);
            form.setFieldsValue({
                TradingEntityKey: deliveryAddress.TradingEntityKey,
                StreetNo: deliveryAddress.StreetNo,
                StreetLine1: deliveryAddress.StreetLine1,
                StreetLine2: deliveryAddress.StreetLine2,
                State: deliveryAddress.State,
                PostCode: deliveryAddress.PostCode,
                DeliveryTo: deliveryAddress.Name,
                CountryCode: deliveryAddress.CountryCode,
                Suburb: deliveryAddress.Suburb,
                SubDistrict: deliveryAddress.SubDistrict,
                DeliveryRunID: deliveryAddress.DefaultDeliveryRunID,
                DeliveryMethod: deliveryAddress.DeliveryMethod,
                DeliveryAddress: index != -1 ? index : 0
            })
        }
    }

    const onSelectCountryCode = (value) => {
        handleCountryCode(value);
    }

    const onSelectArea = value => {

    }
    const onSelectState = value => {

    }
    const onChangeAddress = () => {

    }
    const combineDeliveryAddress = (dmsDebtorDeliveryAddress) => {
        let deliveryAddresses = [];
        if (dmsDebtorDeliveryAddress.DeliveryAddesses && dmsDebtorDeliveryAddress.DeliveryAddesses.length > 0)
            deliveryAddresses = deliveryAddresses.concat(dmsDebtorDeliveryAddress.DeliveryAddesses);
        if (dmsDebtorDeliveryAddress.AlternateDeliveryAddresses && dmsDebtorDeliveryAddress.AlternateDeliveryAddresses.length > 0)
            deliveryAddresses = deliveryAddresses.concat(dmsDebtorDeliveryAddress.AlternateDeliveryAddresses.map(item => { item['IsAlter'] = true; return item; }));
        return deliveryAddresses;
    }
    const loadDefaultAddress = (deliveryAddresses) => {
        if (deliveryAddresses && deliveryAddresses.length > 0) {
            form.setFieldsValue({
                DeliveryAddress: 0,
            })
            const deliveryAddress = deliveryAddresses[0];
            if (deliveryAddress.DefaultDeliveryRunID) {
                form.setFieldsValue({
                    DeliveryRunID: deliveryAddress.DefaultDeliveryRunID,
                })
            } else {
                form.setFieldsValue({
                    DeliveryRunID: null,
                })
            }
            if (deliveryAddress.DeliveryMethod) {
                form.setFieldsValue({
                    DeliveryMethod: deliveryAddress.DeliveryMethod,
                })
            } else {
                form.setFieldsValue({
                    DeliveryMethod: null,
                })
            }
            form.setFieldsValue({
                TradingEntityKey: deliveryAddress.TradingEntityKey,
                StreetNo: deliveryAddress.StreetNo,
                StreetLine1: deliveryAddress.StreetLine1,
                StreetLine2: deliveryAddress.StreetLine2,
                State: deliveryAddress.State,
                PostCode: deliveryAddress.PostCode,
                DeliveryTo: deliveryAddress.Name,
                CountryCode: deliveryAddress.CountryCode,
                Suburb: deliveryAddress.Suburb,
                SubDistrict: deliveryAddress.SubDistrict,
            })
        }
    }
    const getDmsDebtorDeliveryAddress = () => {
        return new Promise((resolve, reject)=>{
            BuyerService.getDealerPartDeliveryAddress().then(result => {
                if (result.data) {
                    const deliveryAddresses = combineDeliveryAddress(result.data);
                    resolve(deliveryAddresses)
                }
            }).catch(error => {
                CommonService.handleErrorResponse(error)
                resolve(null)
            })
        })
    }
    useEffect(()=>{
        if (addressSystemConfig)
            handleInitForm();
    },[addressSystemConfig])

    useEffect(() => {
        document.documentElement.style.setProperty(Constant.CssVariables.ModalFixItemsContainerHeight, CommonService.calculateScrollMaxHeightModal())
        let timeoutFn = setTimeout(() => document.documentElement.style.setProperty(Constant.CssVariables.FixItemsContainerHeight, CommonService.calculateTableBodyMaxHeight("filterGridId")), 100);
        showLoading()
        getDmsDebtorDeliveryAddress().then(data => {
            let deliveryAddresses = data
            if (deliveryAddressDropdownData && deliveryAddressDropdownData.CountryCodes) {
                setCountryCodeData(deliveryAddressDropdownData.CountryCodes)
            }
            if (deliveryAddressDropdownData && deliveryAddressDropdownData.DeliveryMethods) {
                setDeliveryMethods(deliveryAddressDropdownData.DeliveryMethods)
            }
            if (deliveryAddressDropdownData && deliveryAddressDropdownData.DeliveryRuns) {
                setDeliveryRuns(deliveryAddressDropdownData.DeliveryRuns)
            }
            if (deliveryAddresses) {
                setDmsDebtorDeliveryAddress(deliveryAddresses)
                loadDefaultAddress(deliveryAddresses)
            }
            if (globalSystemAddressConfig) {
                setAddressSystemConfig(globalSystemAddressConfig)
            }
            dismissLoading()
        }).catch(error => {
            dismissLoading()
            CommonService.handleErrorResponse(error)
        })
        
        return () => {
            BuyerService.cancelRequest()
            clearTimeout(timeoutFn);
        }
    }, [])

    return (
        <div>
            <Modal
                title={t("part_order.submit_order")}
                visible={true}
                onOk={form.submit}
                confirmLoading={false}
                onCancel={closeForm}
                maskClosable={false}
                footer={null}
                width={1000}
            >
                <Form
                    {...layout}
                    form={form}
                    name="submitForm"
                    onFinish={onSubmit}
                >
                    <Row align="middle" gutter={[Constant.SpaceConstant.HorizontalGutter]} id="filterGridId">
                        <Form.Item
                                style={{ display: 'none' }}
                                label={t("part_order.operator_initials")}
                                name="TradingEntityKey" colon={false} labelAlign="left"
                            >
                                <Input allowClear />
                        </Form.Item>
                        <Col xs={{ span: 12 }}>
                            <Form.Item
                                label={t("part_order.operator_initials")}
                                name="PurchasingEmpName" colon={false} labelAlign="left"
                                rules={[{ required: true, message: t("part_order.operator_initials_required") }]}
                            >
                                <Input maxLength={40} allowClear />
                            </Form.Item>
                        </Col>
                        <Col xs={{ span: 12 }}>
                            <Form.Item
                                label={t("part_order.delivery_method")}
                                name="DeliveryMethod" colon={false} labelAlign="left"
                            >
                                <Select
                                    allowClear={true}
                                >
                                    {
                                        deliveryMethods
                                        && deliveryMethods.map((n, index) => <Option key={index} value={n.Code}>{`${n.Code} - ${n.Description}`}</Option>)
                                    }
                                </Select>
                            </Form.Item>
                        </Col>
                    </Row>
                    <Row align="middle" gutter={[Constant.SpaceConstant.HorizontalGutter]}>
                        <Col xs={{ span: 12 }}>
                            <Form.Item
                                label={t("part_order.delivery_run")}
                                name="DeliveryRunID" colon={false} labelAlign="left"
                            >

                                <Select
                                    allowClear={true}
                                >
                                    {
                                        deliveryRuns
                                        && deliveryRuns.map((n, index) => <Option key={index} value={n.DeliveryRunID}>{`${n.DeliveryRunID} - ${n.Description}`}</Option>)
                                    }
                                </Select>

                            </Form.Item>
                        </Col>
                        {
                            <Col xs={{ span: 12 }}>
                                <Form.Item
                                    label={t("part_order.delivery_address")}
                                    name="DeliveryAddress" colon={false} labelAlign="left"
                                >
                                    <Select
                                        onClick={()=>{setVisibleAlternateDeliveryAddress(true)}}
                                        open={false}
                                        >
                                        {
                                            dmsDebtorDeliveryAddress
                                            && dmsDebtorDeliveryAddress.map((n, index) => <Option key={index} value={index}>{getDeliveryAddress(index)}</Option>)
                                        }
                                    </Select>
                                </Form.Item>
                            </Col>
                        }
                    </Row>
                    <Row align="middle" gutter={[Constant.SpaceConstant.HorizontalGutter]}>
                        <Col xs={{ span: 8 }}>
                            <Form.Item
                                label={t("part_order.country")}
                                name="CountryCode" colon={false} labelAlign="left"
                            >
                                <Select
                                    disabled={true}
                                    allowClear={true}
                                    onSelect={onSelectCountryCode}>
                                    {
                                        countryCodeData
                                        && countryCodeData.map((n, index) => <Option key={index} value={n.Code}>{n.Description}</Option>)
                                    }
                                </Select>
                            </Form.Item>
                        </Col>
                        <Col xs={{ span: 8 }}>
                            <Form.Item
                                label={t("part_order.delivery_to")}
                                name="DeliveryTo" colon={false} labelAlign="left"
                                rules={[{ required: true, message: t("part_order.delivery_to_required") }]}
                            >
                                <Input onChange={onChangeAddress} allowClear />
                            </Form.Item>
                        </Col>
                        <Col xs={{ span: 8 }}>

                        </Col>
                        <Col xs={{ span: 8 }}>
                            <Form.Item
                                label={t("part_order.street_no")}
                                name="StreetNo" colon={false} labelAlign="left"
                            >
                                <Input onChange={onChangeAddress} allowClear />
                            </Form.Item>
                        </Col>
                        <Col xs={{ span: 8 }}>
                            <Form.Item
                                label={t("part_order.street_line_1")}
                                name="StreetLine1" colon={false} labelAlign="left"
                                rules={[{ required: streetLine1Mandatory, message: t("part_order.street_line_1_required") }]}
                            >
                                <Input onChange={onChangeAddress} allowClear />
                            </Form.Item>
                        </Col>
                        <Col xs={{ span: 8 }}>
                            <Form.Item
                                label={t("part_order.street_line_2")}
                                name="StreetLine2" colon={false} labelAlign="left"
                            >
                                <Input onChange={onChangeAddress} allowClear />
                            </Form.Item>
                        </Col>
                        {
                            subDistrictVisible && <Col xs={{ span: 8 }}>
                                <Form.Item
                                    label={t("part_order.sub_district")}
                                    name="SubDistrict" colon={false} labelAlign="left"
                                >
                                    <Input onChange={onChangeAddress} allowClear />
                                </Form.Item>
                            </Col>
                        }
                        {
                            areaVisible && <Col xs={{ span: 8 }}>

                                <Form.Item
                                    label={subDistrictVisible ? t("part_order.district") : t("part_order.suburb")}
                                    name="Suburb" colon={false} labelAlign="left"
                                    rules={[{ required: areaVisible && suburbMandatory, message: subDistrictVisible ? t("part_order.district_required") : t("part_order.suburb_required") }]}
                                >
                                    <Select
                                        allowClear={true}
                                        onSelect={onSelectArea}>
                                        {
                                            areaData
                                            && areaData.map((n, index) => <Option key={index} value={n.Suburb}>{n.Suburb}</Option>)
                                        }
                                    </Select>
                                </Form.Item>
                            </Col >
                        }
                        {
                            suburbVisible && <Col xs={{ span: 8 }}>
                                <Form.Item
                                    label={subDistrictVisible ? t("part_order.district") : t("part_order.suburb")}
                                    name="Suburb" colon={false} labelAlign="left"
                                    rules={[{ required: suburbVisible && suburbMandatory, message: subDistrictVisible ? t("part_order.district_required") : t("part_order.suburb_required") }]}
                                >
                                    <Input onChange={onChangeAddress} allowClear />
                                </Form.Item>
                            </Col >
                        }
                        {
                            bureaucracyVisible && <Col xs={{ span: 8 }}>
                                <Form.Item
                                    label={subDistrictVisible ? t("part_order.province") : t("part_order.state")}
                                    name="State" colon={false} labelAlign="left"
                                    rules={[{ required: bureaucracyVisible && bureaucracyMandatory, message: subDistrictVisible ? t("part_order.province_required") : t("part_order.state_required") }]}
                                >
                                    <Select
                                        allowClear={true}
                                        onSelect={onSelectState}>
                                        {
                                            stateData
                                            && stateData.map((n, index) => <Option key={index} value={n.Name}>{n.Name}</Option>)
                                        }
                                    </Select>
                                </Form.Item>
                            </Col>
                        }
                        {
                            stateVisible && <Col xs={{ span: 8 }}>
                                <Form.Item
                                    label={subDistrictVisible ? t("part_order.province") : t("part_order.state")}
                                    name="State" colon={false} labelAlign="left"
                                    rules={[{ required: stateVisible && stateMandatory, message: subDistrictVisible ? t("part_order.province_required") : t("part_order.state_required") }]}
                                >
                                <Input onChange={onChangeAddress} allowClear />
                                </Form.Item>
                            </Col>
                        }
                        <Col xs={{ span: 8 }}>
                            <Form.Item
                                label={t("part_order.post_code")}
                                name="PostCode" colon={false} labelAlign="left"
                                rules={[{ required: postCodeMandatory, message: t("part_order.post_code_required") }]}
                            >
                                <Input onChange={onChangeAddress} allowClear />
                            </Form.Item>
                        </Col>
                    </Row>
                    <Row align="middle" gutter={[Constant.SpaceConstant.HorizontalGutter]}>
                        <Col xs={{ span: 24 }}>
                            <Form.Item
                                label={t("part_order.special_instructions")}
                                name="SpecialInstruction" colon={false} labelAlign="left"
                            >
                                <TextArea rows={4} allowClear />
                            </Form.Item>
                        </Col>
                    </Row>
                    <Row align="middle" gutter={[Constant.SpaceConstant.HorizontalGutter]}>
                        <Col xs={{ span: 24 }} className="text-right m-t-25">
                            <Button size="large" className="m-r-5" onClick={closeForm}>{t("common.cancel")}</Button>
                            <Button size="large" type="primary" htmlType="submit" >{t("common.submit")}</Button>
                        </Col>
                    </Row>
                </Form>
            </Modal>
            { visibleAlternateDeliveryAddress &&
                <AlternateDeliveryAddressSelection data={dmsDebtorDeliveryAddress} handleCancel={onSelectAlterDeliveryAddress}/>
            }
        </div>
    )
}

export default DeliveryAddressSubmitted