import axios from 'axios';
import {axiosPortal} from './authService'

const source = [];
let baseURL = "/Dealership"

export const DealershipService = {
    getDealerships: function () {
        let cancelToken = axios.CancelToken.source()
        source.push(cancelToken)

        return axiosPortal.get(`${baseURL}`, {
            cancelToken: cancelToken.token
        }).finally(() => {
            let index = source.indexOf(cancelToken)
            if (index > -1) {
                source.splice(index, 1)
            }
        })
    },

    getDealershipStores: function (dealerId) {
        let cancelToken = axios.CancelToken.source()
        source.push(cancelToken)

        return axiosPortal.get(`${baseURL}/DealershipStores?dealerId=${dealerId}`, {
            cancelToken: cancelToken.token
        }).finally(() => {
            let index = source.indexOf(cancelToken)
            if (index > -1) {
                source.splice(index, 1)
            }
        })
    },

    getSuppliers: function () {
        let cancelToken = axios.CancelToken.source()
        source.push(cancelToken)

        return axiosPortal.get(`/Account/Suppliers`, {
            cancelToken: cancelToken.token
        }).finally(() => {
            let index = source.indexOf(cancelToken)
            if (index > -1) {
                source.splice(index, 1)
            }
        })
    },

    cancelRequest: function () {
        if (source && source.length > 0) {
            for (var i = 0; i < source.length; i++) {
                source[i].cancel('Operation canceled.')
            }
            source.splice(0, source.length)
        }
    }
}