import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import './mainLayout.scss';
import { StorageService, Constant, CommonService, AuthService } from '../../services/services'
import Logo from './../../assets/images/logo.svg'
import { Avatar, Button, Dropdown, Layout, Popover, Row, Menu, Col, Badge, Tag } from 'antd';
import PubSub from 'pubsub-js'
import InfiniteScroll from 'react-infinite-scroller';
import { useGlobalState } from '../../utilities/globalState'
import Notification from '../../components/notification/notification'
import { useIdleTimer } from 'react-idle-timer'

import {
    MenuBarIcon, HomeIcon, SupplierIcon,
    PartEnquiryIcon, PartOrderIcon, CancelPartOrderIcon,
    OrderManagementIcon, OrderHistoryIcon, CreditReturnIcon,
    OutstandingCreditNotesIcon, BackOrderManagementIcon,
    UserManagementIcon, CancelPartOrderApprovalIcon, SettingIcon,
    UserIcon, LogOutIcon, BellIcon
}
    from './../../components/icons/icons'
import MenuItems from './../../components/menuItems/menuItems'
import UserDropdownOverlay from './../../components/userDropdownOverlay/userDropdownOverlay'

const { Header, Content, Sider } = Layout;

function MainLayout(props) {
    const { t, i18n } = useTranslation();
    const [isLoading, setIsLoading] = useState(false);
    const [collapsed, setCollapsed] = useState(false)
    const [avartaUrl, setAvartaUrl] = useState()
    const [supplierName, setSupplierName] = useState()
    const [buyerMenuItems, setBuyerMenuItems] = useState([]);
    const [, setIsActive] = useGlobalState('isActive')
    const [supplierLogoUrl, setSupplierLogoUrl] = useState()

    const toggle = () => {
        setCollapsed(!collapsed)
    };

    const onBreakpoint = (broken) => {
        setCollapsed(broken)
    }

    const _buyerMenuItems = [
        {
            HasAccessPage: true,
            PathName: Constant.Pages.BuyerDashboard,
            Name: t("menu.dashboard"),
            Icon: <HomeIcon />,
            Code: Constant.SupplierBuyerFunction.Public
        },
        {
            HasAccessPage: true,
            PathName: Constant.Pages.SupplierManagement,
            Name: t("menu.supplier_management"),
            Icon: <SupplierIcon />,
            Code: Constant.SupplierBuyerFunction.Public
        },
        {
            HasAccessPage: true,
            PathName: Constant.Pages.PartEnquiry,
            Name: t("menu.part_enquiry"),
            Icon: <PartEnquiryIcon />,
            Code: Constant.SupplierBuyerFunction.PartEnquiry
        },
        {
            HasAccessPage: true,
            PathName: Constant.Pages.PartOrder,
            Name: t("menu.part_order"),
            Icon: <PartOrderIcon />,
            Code: Constant.SupplierBuyerFunction.PlacePurchaseOrders
        },
        {
            HasAccessPage: true,
            PathName: Constant.Pages.OrderManagement,
            Name: t("menu.order_management"),
            Icon: <OrderManagementIcon />,
            Code: Constant.SupplierBuyerFunction.OrderManagement
        },
        {
            HasAccessPage: true,
            PathName: Constant.Pages.PartOrderHistory,
            Name: t("menu.part_order_history"),
            Icon: <OrderHistoryIcon />,
            Code: Constant.SupplierBuyerFunction.PartOrderHistory
        },
        {
            HasAccessPage: true,
            PathName: Constant.Pages.RequestCreditReturn,
            Name: t("menu.request_credit_return"),
            Icon: <CreditReturnIcon />,
            Code: Constant.SupplierBuyerFunction.RequestReturnPart
        },
        {
            HasAccessPage: true,
            PathName: Constant.Pages.OutstandingCreditNotesEnquiry,
            Name: t("menu.credit_request_query"),
            Icon: <OutstandingCreditNotesIcon />,
            Code: Constant.SupplierBuyerFunction.OutstandingCreditNotesEnquiry
        },
        {
            HasAccessPage: true,
            PathName: Constant.Pages.BackOrderManagement,
            Name: t("menu.back_order_management"),
            Icon: <BackOrderManagementIcon />,
            Code: Constant.SupplierBuyerFunction.BackOrderManagement
        }
    ]

    const supplierMenuItems = [
        {
            HasAccessPage: true,
            PathName: Constant.Pages.SupplierDashboard,
            Name: t("menu.dashboard"),
            Icon: <HomeIcon />
        },
        {
            HasAccessPage: true,
            PathName: Constant.Pages.BuyerManagement,
            Name: t("menu.buyer_management"),
            Icon: <UserManagementIcon />
        },
        {
            HasAccessPage: true,
            PathName: Constant.Pages.SupplierSetting,
            Name: t("menu.supplier_setting"),
            Icon: <SettingIcon />
        }
    ]

    const userActions = [
        {
            Code: Constant.UserActions.VewProfile,
            Description: t("menu.profile"),
            Icon: <UserIcon />
        },
        {
            Code: Constant.UserActions.Logout,
            Description: t("menu.logout"),
            Icon: <LogOutIcon />
        }
    ]

    const loadAvartar = () => {
        var avartaUrl = CommonService.getAvartar();
        if (avartaUrl) setAvartaUrl(avartaUrl);
    }

    const loadSupplierLogo = () => {
        var supplierLogoUrl = CommonService.getSupplierLogoUrl();
        if (supplierLogoUrl) setSupplierLogoUrl(supplierLogoUrl);
    }

    useEffect(() => {
        loadAvartar();
        loadSupplierLogo()
        var authData = StorageService.getAuthData();
        if (authData) {
            setSupplierName(authData.supplierTradingName);
            if (!props.isSupplier) {
                let fns = _buyerMenuItems.filter(val => (val.Code === Constant.SupplierBuyerFunction.Public || authData.functionCodes.filter(d => d === val.Code).length > 0));
                setBuyerMenuItems(fns);
            }
        }
    }, [])

    const handleOnIdle = event => {
        let hasAuth = AuthService.hasAuthorized(false, false)
        if (!hasAuth) {
            console.log('user is idle', getLastActiveTime())
            setIsActive(false)
        } else {
            console.log('session still on, reset timer', getLastActiveTime())
            reset()
        }
    }

    const handleOnActive = event => {
        console.log('user is active', getRemainingTime())
        setIsActive(true)
    }

    const { getRemainingTime, getLastActiveTime, reset } = useIdleTimer({
        timeout: 1000 * 60 * 60 * 1, // 1 hour
        onIdle: handleOnIdle,
        onActive: handleOnActive,
        debounce: 500
    })

    return (
        <Layout className="main-layout app-layout">
            <Row>
                <Col xs={{ span: 24 }}>
                    <Header>
                        <div className="app-header">
                            <div className="app-header--left-component">
                            <Button className="icon-btn" shape="circle" icon={<MenuBarIcon />} size="middle" onClick={toggle} />
                                <div className="app-header--logo">
                                    <img src={Logo} />
                                </div>
                            </div>
                            <div className="app-header--center-component user-info">
                                <span className="supplier-name">{supplierName}</span>
                            </div>
                            <div className="app-header--right-component">
                                <Notification></Notification>

                                <Dropdown overlay={<UserDropdownOverlay userActions={userActions} loadAvartar={loadAvartar} />} trigger={['click']} className="user-info m-l-25 flex-item" overlayClassName="user-info--overlay">
                                    <div>
                                        <Avatar src={avartaUrl}>{avartaUrl ? "" : CommonService.getFirstCharUsername()}
                                        </Avatar>
                                        <span className="username heading-5 truncate" title={CommonService.getUsernameLogin()}>{CommonService.getUsernameLogin()}</span>
                                    </div>
                                </Dropdown>
                            </div>
                        </div>
                    </Header>
                </Col>
            </Row>
            <Content>
                <Layout className="site-layout">
                    <Sider trigger={null} collapsible collapsed={collapsed}
                        breakpoint="lg"
                        //collapsedWidth="0"
                        width="260"
                        onBreakpoint={(broken) => onBreakpoint(broken)}>
                        <MenuItems {...props} items={props.isSupplier ? supplierMenuItems : buyerMenuItems}></MenuItems>
                        <div className="sider-item-footer">
                            <img src={supplierLogoUrl} />
                        </div>
                    </Sider>
                    <Content>
                        <div className="site-layout--content">
                            {props.children}
                        </div>
                    </Content>
                </Layout>
            </Content>

        </Layout>
    );
}

export default MainLayout;