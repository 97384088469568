import React from 'react';
import ReactDOM from 'react-dom';
import './index.scss';
import App from './App';
import * as serviceWorker from './serviceWorker';
import { Router, Route } from 'react-router-dom';
import './i18n';
import { AuthService, StorageService, Constant } from './services/services'
import { createBrowserHistory } from 'history'
// import './theme/antd.css';
import 'antd/dist/antd.css';
import './theme/animate.min.css';
import './theme/antd-custom.css';
import { createPreserveQueryHistory } from './utilities/history';

const history = createPreserveQueryHistory(createBrowserHistory, [Constant.UrlParam.SupplierId, Constant.UrlParam.IsOnRedirect])();

AuthService.registerIntercepter(history)

const getRender = () => {
  let authData = StorageService.getAuthData()
  var currentUrl = new URL(window.location.href)
  let isOnRedirect = currentUrl.searchParams.get(Constant.UrlParam.IsOnRedirect)
  if (authData && authData.redirectAppUrl && !isOnRedirect) {
    var url = new URL(`${authData.redirectAppUrl}${window.location.pathname}`)
    url.searchParams.append(Constant.UrlParam.IsOnRedirect, true)
    console.log(url)
    document.documentElement.style.setProperty("--root-height", "100%")
    return (
      <iframe src={url} width="100%" height="100%" frameBorder={0}></iframe>
    )
  } else {
    document.documentElement.style.setProperty("--root-height", "unset")
    return (
      <Router history={history}>
        <Route path="/" component={App} />
      </Router>
    )
  }
}

ReactDOM.render(
  getRender(),
  document.getElementById('root')
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
