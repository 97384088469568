import axios from 'axios';
import { axiosPortal } from './authService'
import moment from 'moment';

const source = [];
let baseURL = "/Buyer"

export const OutstandingCreditNoteService = {
    GetCreditRequisition: function (pageNumber, pageSize, filter) {
        let cancelToken = axios.CancelToken.source();
        source.push(cancelToken);
        let url = `${baseURL}/GetCreditRequisitionForBuyer?PageNumber=${pageNumber}&PageSize=${pageSize}`;
        if (filter) {
            if (filter.RequestDateFrom) {
                let dateFormat = moment(filter.RequestDateFrom).format("YYYY-MM-DD");
                url += `&RequestDateFrom=${dateFormat}`;
            }
            if (filter.RequestDateTo) {
                let dateFormat = moment(filter.RequestDateTo).format("YYYY-MM-DD");
                url += `&RequestDateTo=${dateFormat}`;
            }
            if (filter.InvoiceNo) {
                url += `&InvoiceNo=${filter.InvoiceNo}`;
            }      
            if (filter.RequisitionStatus) {
                url += `&RequisitionStatus=${filter.RequisitionStatus}`;
            }
            if (filter.CreditRequestId) {
                url += `&CreditRequestId=${filter.CreditRequestId}`;
            }
            if (filter.CreditRequestNo) {
                url += `&CreditRequestNo=${filter.CreditRequestNo}`;
            }
            if (filter.OrderNo) {
                url += `&OrderNo=${filter.OrderNo}`;
            }
            if (filter.ShowOutstandingOnly) {
                url += `&ShowOutstandingOnly=${filter.ShowOutstandingOnly}`;
            }
            if (filter.Sort){
                url += `&Sort=${filter.Sort}`;
            }
        }
        return axiosPortal.get(url, {
            cancelToken: cancelToken.token
        }).finally(() => {
            let index = source.indexOf(cancelToken)
            if (index > -1) {
                source.splice(index, 1)
            }
        })
    },
    GetPartsCreditRequestDetail: function (creditRequisitionKey) {
        let cancelToken = axios.CancelToken.source();
        source.push(cancelToken);
        let url = `${baseURL}/GetPartsCreditRequestDetail?creditRequisitionKey=${creditRequisitionKey}`;
        return axiosPortal.get(url, {
            cancelToken: cancelToken.token
        }).finally(() => {
            let index = source.indexOf(cancelToken)
            if (index > -1) {
                source.splice(index, 1)
            }
        })
    },
    GetCreditRequisitionLineAudit: function (creditRequisitionLineKey) {
        let cancelToken = axios.CancelToken.source();
        source.push(cancelToken);
        let url = `${baseURL}/GetCreditRequisitionLineAudit?creditRequisitionLineKey=${creditRequisitionLineKey}`;
        return axiosPortal.get(url, {
            cancelToken: cancelToken.token
        }).finally(() => {
            let index = source.indexOf(cancelToken)
            if (index > -1) {
                source.splice(index, 1)
            }
        })
    },
    AddUpdateCreditRequisitionDetail: function (data) {
        let cancelToken = axios.CancelToken.source()
        source.push(cancelToken)
        return axiosPortal.post(`${baseURL}/AddUpdateCreditRequisitionDetail`, data, {
            cancelToken: cancelToken.token
        }).finally(() => {
            let index = source.indexOf(cancelToken)
            if (index > -1) {
                source.splice(index, 1)
            }
        })
    },
    DeleteCreditRequisitionSundry: function (headerKey, lineKey) {
        let cancelToken = axios.CancelToken.source();
        source.push(cancelToken);
        let url = `${baseURL}/DeleteCreditRequisitionSundry?headerKey=${headerKey}&lineKey=${lineKey}`;
        return axiosPortal.get(url, {
            cancelToken: cancelToken.token
        }).finally(() => {
            let index = source.indexOf(cancelToken)
            if (index > -1) {
                source.splice(index, 1)
            }
        })
    },
    cancelRequest: function () {
        if (source && source.length > 0) {
            for (var i = 0; i < source.length; i++) {
                source[i].cancel('Operation canceled.')
            }
            source.splice(0, source.length)
        }
    }
}