import React, { useState } from 'react';
import './sample.scss';
import { Button, Row, Col, Form, Input, Select, DatePicker, Table } from 'antd';
import { PlusIcon } from '../../components/icons/icons';
import { GetTableConfigs, StatusCell } from '../../components/utils/gridUtils/gridUtils'
import { Constant } from '../../services/services';
import { useTranslation } from 'react-i18next';

const { Option } = Select;
const layout = {
    labelCol: { span: 24 },
    wrapperCol: { span: 24 }
};

function Sample(props) {
    const { t } = useTranslation();
    const [form] = Form.useForm();
    const dropdownItems = [
        {
            Code: 1,
            Description: "A"
        },
        {
            Code: 2,
            Description: "B"
        }
    ]
    const testData = [
        {
            Id: 1,
            Username: "HienNguyen",
            FirstName: "Hien",
            LastName: "Nguyen",
            PhoneNumber: "124568",
            StatusCode: 1
        },
        {
            Id: 2,
            Username: "HienNguyen1",
            FirstName: "Hien",
            LastName: "Nguyen",
            PhoneNumber: "124568",
            StatusCode: 2
        },
        {
            Id: 3,
            Username: "HienNguyen2",
            FirstName: "Hien",
            LastName: "Nguyen",
            PhoneNumber: "124568",
            StatusCode: 1
        },
        {
            Id: 4,
            Username: "HienNguyen3",
            FirstName: "Hien",
            LastName: "Nguyen",
            PhoneNumber: "124568",
            StatusCode: 1
        }
    ]

    const getStatusTagColorClass = (item) => {
        if (item) {
            if (item.StatusCode == Constant.StatusCode.Active)
                return Constant.ColorClass.LightGreen
            return Constant.ColorClass.LightOrange
        }
        return ''
    }

    const getStatusDescription = (item) => {
        if (item) {
            if (item.StatusCode == Constant.StatusCode.Active)
                return t("common.active")
            return t("common.inactive")
        }
    }

    const columns = [
        {
            title: "Username",
            dataIndex: 'Username',
            key: 'Username',
            ellipsis: true,
            // width: 200,
            // fixed: true
        },
        {
            title: "First Name",
            dataIndex: 'FirstName',
            key: 'FirstName',
            sorter: true,
            ellipsis: true
        },
        {
            title: "Last Name",
            dataIndex: 'LastName',
            key: 'LastName',
            sorter: true,
            ellipsis: true
        },
        {
            title: "Phone Number",
            dataIndex: 'PhoneNumber',
            key: 'PhoneNumber',
            sorter: true,
            ellipsis: true
        },
        {
            title: "Status",
            dataIndex: 'StatusCode',
            key: 'StatusCode',
            sorter: true,
            render: (value, record) => (
                <StatusCell data={record}
                    colorClass={getStatusTagColorClass(record)}
                    description={getStatusDescription(record)} />
            ),
            align: 'center',
            width: 150
        }
    ]

    const onTableChange = (pagination, filters, sorter, extra) => {
        // getUsers(pagination.current, pagination.pageSize, CommonService.getSortString(sorter))
        // setGridConfigOptionsValue({
        //     pageNumber: pagination.current,
        //     pageSize: pagination.pageSize,
        //     sort: CommonService.getSortString(sorter)
        // })
    }


    return (
        <div className="supplier-dashboard-page">
            <Row>
                <Col>
                    <Button className="m-r-10" type="primary">Primary Button</Button>
                    <Button className="m-r-10 secondary-btn">Secondary Button</Button>
                    <Button className="m-r-10">Default Button</Button>
                    <Button className="m-r-10" disabled>Disabled Button</Button>
                    <Button className="m-r-10" type="dashed">Dashed Button</Button>
                    <Button className="m-r-10" type="text">Text Button</Button>
                    <Button className="m-r-10" type="link">Link Button</Button>
                </Col>
            </Row>
            <Row className="m-t-10">
                <Col>
                    <Button className="m-r-10 included-icon" type="primary" icon={<PlusIcon />} size="large">Large Button</Button>
                    <Button className="m-r-10 included-icon" type="primary" icon={<PlusIcon />}>Medium Button</Button>
                    <Button className="m-r-10 included-icon" type="primary" icon={<PlusIcon />} size="small">Small Button</Button>

                    <Button className="m-r-10 included-icon secondary-btn" icon={<PlusIcon />} size="large">Large Button</Button>
                    <Button className="m-r-10 included-icon secondary-btn" icon={<PlusIcon />}>Medium Button</Button>
                    <Button className="m-r-10 included-icon secondary-btn" icon={<PlusIcon />} size="small">Small Button</Button>

                </Col>
            </Row>
            <Row className="m-t-10">
                <Col>
                    <Button className="m-r-10 included-icon" icon={<PlusIcon />} size="large">Large Button</Button>
                    <Button className="m-r-10 included-icon" icon={<PlusIcon />}>Medium Button</Button>
                    <Button className="m-r-10 included-icon" icon={<PlusIcon />} size="small">Small Button</Button>

                    <Button className="m-r-10 included-icon" disabled icon={<PlusIcon />} size="large">Large Button</Button>
                    <Button className="m-r-10 included-icon" disabled icon={<PlusIcon />}>Medium Button</Button>
                    <Button className="m-r-10 included-icon" disabled icon={<PlusIcon />} size="small">Small Button</Button>
                </Col>
            </Row>

            <Row>
                <Col xs={{ span: 24 }}>
                    <Form
                        {...layout}
                        form={form}
                        name="formName"
                    >
                        <Row>
                            <Col xs={{ span: 5 }}>
                                <Form.Item
                                    label="Input"
                                    name="inputName" colon={false} labelAlign="left"
                                >
                                    <Input />
                                </Form.Item>
                            </Col>
                            <Col xs={{ span: 5, offset: 1 }}>
                                <Form.Item
                                    label="Search Input"
                                    name="searchInputName" colon={false} labelAlign="left"
                                    initialValue="test"
                                >
                                    <Input.Search allowClear />
                                </Form.Item>
                            </Col>
                            <Col xs={{ span: 5, offset: 1 }}>
                                <Form.Item
                                    label="Date Picker"
                                    name="datePickerName" colon={false} labelAlign="left"
                                >
                                    <DatePicker />
                                </Form.Item>
                            </Col>

                            <Col xs={{ span: 5, offset: 1 }}>
                                <Form.Item
                                    label="Dropdown"
                                    name="dropdownName" colon={false} labelAlign="left"
                                    rules={[{ required: true, message: "Field is required" }]}
                                >
                                    <Select>
                                        {
                                            dropdownItems.map((n, index) => <Option key={index} value={n.Code}>{n.Description}</Option>)
                                        }
                                    </Select>
                                </Form.Item>
                            </Col>
                        </Row>

                        <Row>
                            <Col xs={{ span: 5 }}>
                                <Form.Item
                                    label="Multiple Select"
                                    name="multipleSelectName" colon={false} labelAlign="left"
                                >
                                    <Select
                                        mode="multiple"
                                        allowClear>
                                        {
                                            dropdownItems.map((n, index) => <Option key={index} value={n.Code}>{n.Description}</Option>)
                                        }
                                    </Select>
                                </Form.Item>
                            </Col>
                        </Row>
                    </Form>
                </Col>

            </Row>
            <Row>
                <Col xs={{ span: 24 }}>
                    <Table
                        {...GetTableConfigs(columns, testData, testData.length, "Id", onTableChange)}
                    />
                </Col>
            </Row>
        </div>
    );
}

export default Sample;