import React, { useEffect, useState } from 'react';
import './supplierManagement.scss';
import { useTranslation } from 'react-i18next';
import {
    Constant,
    CommonService,
    BuyerService,
    DealershipService
} from './../../../services/services'
import LoadingContext from './../../../components/utils/loading/loadingContext'
import { Table, Button, Row, Col, Select, Form, Input, DatePicker, Modal, Breadcrumb } from 'antd';
import { ExclamationCircleOutlined, CopyOutlined } from '@ant-design/icons';
import { CancelIcon, ToggleLeftIcon, ToggleRightIcon, CornerUpRightIcon, PlusIcon, CopyIcon } from './../../../components/icons/icons'
import { GetTableConfigs, StatusCell, ActionButtonsCell, DateTimeCell } from './../../../components/utils/gridUtils/gridUtils'
import AddSupplier from './addSupplier'
const { Option } = Select;
const { confirm } = Modal;

const layout = {
    labelCol: { span: 24 },
    wrapperCol: { span: 24 }
};

const SupplierManagement = (props) => {
    const { t } = useTranslation();
    const [form] = Form.useForm();
    const { showLoading, dismissLoading } = React.useContext(LoadingContext)
    const [totalItems, setTotalItems] = useState(0)
    const [supplierData, setSupplierData] = useState([])
    const [visibleModal, setVisibleModal] = useState(false);
    const [gridConfigOptions, setGridConfigOptionsValue] = useState({
        pageNumber: 1,
        pageSize: Constant.PageSize,
        sort: ""
    })
    const [supplierDropdown, setSupplierDropdown] = useState([])
    const [btnGroupWidth, setBtnGroupWidth] = useState('')

    const getRequestStatusColorClass = (item) => {
        if (item) {
            if (item.RequestStatusCode == Constant.AccountRequestStatusCode.Approved ||
                item.RequestStatusCode == Constant.AccountRequestStatusCode.PendingForActivation ||
                item.RequestStatusCode == Constant.AccountRequestStatusCode.PendingForDeactivation)
                return Constant.ColorClass.LightGreen
            else if (item.RequestStatusCode == Constant.AccountRequestStatusCode.Rejected)
                return Constant.ColorClass.LightOrange
        }
        return Constant.ColorClass.LightBlue
    }

    const getRequestStatusDescription = (item) => {
        if (item) {
            if (item.RequestStatusCode == Constant.AccountRequestStatusCode.Approved ||
                item.RequestStatusCode == Constant.AccountRequestStatusCode.PendingForActivation ||
                item.RequestStatusCode == Constant.AccountRequestStatusCode.PendingForDeactivation)
                return t("supplier_managament.approved")
            else if (item.RequestStatusCode == Constant.AccountRequestStatusCode.Rejected)
                return t("supplier_managament.rejected")
            else if (item.RequestStatusCode == Constant.AccountRequestStatusCode.PendingForApproval)
                return t("supplier_managament.pending")
        }
        return ''
    }

    const handelActionRequest = (item) => {
        let message = t("supplier_managament.confirm_send_request_mes")
        if (item.ActionCode && item.ActionCode == Constant.BuyerActionCode.CancelRequest) {
            message = t("supplier_managament.confirm_cancel_request_mes")
        }
        confirm({
            title: t("common.confirm"),
            icon: <ExclamationCircleOutlined />,
            content: message,
            okText: t("common.ok"),
            cancelText: t("common.cancel"),
            onOk() {
                if (item.ActionCode) {
                    if (item.ActionCode == Constant.BuyerActionCode.CancelRequest)
                        deleteSupplier(item.SupplierId)
                    else {
                        updateSupplier(item)
                    }
                }
            },
            onCancel() {
            }

        });

    }

    const updateSupplier = (item) => {
        showLoading()
        BuyerService.updateSupplierForBuyer(item)
            .finally(() => dismissLoading())
            .then(result => {
                if (!result.data) {
                    CommonService.presentToast('success', t('supplier_managament.update_successful'))
                    let data = form.getFieldsValue()
                    getRequestedSuppliers(gridConfigOptions.pageNumber, gridConfigOptions.pageSize, gridConfigOptions.sort, data.StoreName, data.StatusCode, data.RequestedDateFrom, data.RequestedDateTo)
                }

            })
            .catch(error => CommonService.handleErrorResponse(error))
    }

    const deleteSupplier = (supplierId) => {
        showLoading()
        BuyerService.deleteSupplierForBuyer(supplierId)
            .finally(() => dismissLoading())
            .then(result => {
                if (!result.data) {
                    CommonService.presentToast('success', t('supplier_managament.cancel_successful'))
                    let data = form.getFieldsValue()
                    getRequestedSuppliers(gridConfigOptions.pageNumber, gridConfigOptions.pageSize, gridConfigOptions.sort, data.StoreName, data.StatusCode, data.RequestedDateFrom, data.RequestedDateTo)
                }

            })
            .catch(error => CommonService.handleErrorResponse(error))
    }

    const copySupplierToClipboard = (item) => {
        if (item && item.SupplierId) {
            navigator.clipboard.writeText(item.SupplierId)
            CommonService.presentToast("info", t("supplier_managament.copied_supplier_id"))
        }

    }

    const getActionButtonInfo = (item) => {
        let result = []
        result.push({
            Description: t("supplier_managament.copy_supplier_id"),
            Icon: <CopyIcon />,
            ColorClass: Constant.ColorClass.LightYellow,
            Hide: false,
            handleAction: copySupplierToClipboard
        })
        // if (!item.ActionCode)
        //     return {
        //         Hide: true
        //     }
        if (item.ActionCode == Constant.BuyerActionCode.CancelRequest) {
            result.push({
                Description: t("supplier_managament.cancel_request"),
                Icon: <CancelIcon />,
                ColorClass: Constant.ColorClass.LightOrange,
                Hide: false,
                handleAction: handelActionRequest
            })
        }
        else if (item.ActionCode == Constant.BuyerActionCode.RequestAgain) {
            result.push({
                Description: t("supplier_managament.request_again"),
                Icon: <CornerUpRightIcon />,
                ColorClass: Constant.ColorClass.LightBlue,
                Hide: false,
                handleAction: handelActionRequest
            })
        }
        else if (item.ActionCode == Constant.BuyerActionCode.RequestDeactivation) {
            result.push({
                Description: t("supplier_managament.request_deactivation"),
                Icon: <ToggleLeftIcon />,
                Hide: false,
                handleAction: handelActionRequest
            })
        }
        else if (item.ActionCode == Constant.BuyerActionCode.RequestActivation) {
            result.push({
                Description: t("supplier_managament.request_activation"),
                ColorClass: Constant.ColorClass.LightBlue,
                Icon: <ToggleRightIcon />,
                Hide: false,
                handleAction: handelActionRequest
            })
        }

        return result
    }

    const showStatusCell = (item) => {
        if (item) {
            if (item.RequestStatusCode == Constant.AccountRequestStatusCode.Approved ||
                item.RequestStatusCode == Constant.AccountRequestStatusCode.PendingForDeactivation ||
                item.RequestStatusCode == Constant.AccountRequestStatusCode.PendingForActivation)
                return true
            return false
        }
        return false
    }
    const columns = [
        {
            title: t('common.action'),
            key: 'SupplierId',
            render: (value, record) => (
                <ActionButtonsCell btnList={getActionButtonInfo(record)} data={record} />
            ),
            align: 'center',
            width: 80
        },
        {
            title: t('supplier_managament.supplier_name'),
            dataIndex: 'SupplierTradingName',
            key: 'SupplierTradingName',
            sorter: true,
            width: 300,
        },
        {
            title: t('supplier_managament.requested_date'),
            dataIndex: 'RequestedDate',
            key: 'RequestedDate',
            sorter: true,
            ellipsis: true,
            width: 100,
            render: (value, record) => (
                <DateTimeCell value={record.RequestedDate} />
            )
        },
        {
            title: t('supplier_managament.approved_date'),
            dataIndex: 'ApprovedDate',
            key: 'ApprovedDate',
            sorter: true,
            ellipsis: true,
            width: 100,
            render: (value, record) => (
                <DateTimeCell value={record.ApprovedDate} />
            )
        },
        {
            title: t('common.status'),
            dataIndex: 'RequestStatusCode',
            key: 'RequestStatusCode',
            sorter: true,
            render: (value, record) => (
                <StatusCell data={record}
                    colorClass={getRequestStatusColorClass(record)}
                    description={getRequestStatusDescription(record)} />
            ),
            align: 'center',
            width: 80
        },
        {
            title: t('common.active'),
            dataIndex: 'StatusCode',
            key: 'StatusCode',
            sorter: true,
            render: (value, record) => (
                <StatusCell data={record}
                    hide={!showStatusCell(record)}
                    colorClass={CommonService.getStatusColorClass(record.StatusCode)}
                    description={CommonService.getStatusDescription(record.StatusCode, true)} />
            ),
            align: 'center',
            width: 70
        }
    ]

    const getRequestedSuppliers = (pageNumber, pageSize, sort, storeName, statusCode, requestedDateFrom, requestedDateTo, isShowLoading = false) => {
        if (isShowLoading)
            showLoading()
        BuyerService.getRequestedSuppliers(pageNumber, pageSize, sort, storeName, statusCode, requestedDateFrom, requestedDateTo)
            .finally(() => {
                if (isShowLoading)
                    dismissLoading()
            })
            .then(result => {
                if (result.data) {
                    setSupplierData(result.data.ObjectList);
                    setTotalItems(result.data.TotalItems);
                }
            })
            .catch(error => CommonService.handleErrorResponse(error))
    }

    const onTableChange = (pagination, filters, sorter, extra) => {
        let data = form.getFieldsValue()

        getRequestedSuppliers(pagination.current, pagination.pageSize, CommonService.getSortString(sorter), data.StoreName, data.StatusCode, data.RequestedDateFrom, data.RequestedDateTo)
        setGridConfigOptionsValue({
            pageNumber: pagination.current,
            pageSize: pagination.pageSize,
            sort: CommonService.getSortString(sorter)
        })
    }

    const onSearch = (data) => {
        if (data) {
            getRequestedSuppliers(gridConfigOptions.pageNumber, gridConfigOptions.pageSize, gridConfigOptions.sort, data.StoreName, data.StatusCode, data.RequestedDateFrom, data.RequestedDateTo, true)
        }
        else {
            getRequestedSuppliers(gridConfigOptions.pageNumber, gridConfigOptions.pageSize, gridConfigOptions.sort, null, null, null, null, true)
        }
    }

    const addSupplier = () => {
        setVisibleModal(true)
    }

    const handleCancel = (objectData) => {
        setVisibleModal(false)
        if (objectData) {
            let data = form.getFieldsValue()
            getRequestedSuppliers(gridConfigOptions.pageNumber, gridConfigOptions.pageSize, gridConfigOptions.sort, data.StoreName, data.StatusCode, data.RequestedDateFrom, data.RequestedDateTo)
        }
    };

    const resetFilter = () => {
        form.setFieldsValue({
            ["RequestedDateFrom"]: "",
            ["RequestedDateTo"]: "",
            ["StoreName"]: "",
            ["StatusCode"]: null
        });
        setGridConfigOptionsValue(
            {
                ...gridConfigOptions,
                pageNumber: 1
            }
        )
        setTotalItems(0)
        setSupplierData([])
        //getRequestedSuppliers(1, gridConfigOptions.pageSize, gridConfigOptions.sort, null, null, null, null, false)
    }


    useEffect(() => {
        let timeoutFn = setTimeout(() => {
            document.documentElement.style.setProperty(Constant.CssVariables.FixItemsContainerHeight, CommonService.calculateTableBodyMaxHeight())
            let width = CommonService.calcButtonGroupIdWidth()
            setBtnGroupWidth(width)
            document.documentElement.style.setProperty(Constant.CssVariables.ButtonGroupWidth, width)
        }, 100);
        showLoading()
        Promise.all([
            DealershipService.getSuppliers()]
            //getRequestedSuppliers(gridConfigOptions.pageNumber, gridConfigOptions.pageSize, gridConfigOptions.sort, null, null, null, null, false)
        )
            .finally(() => {
                dismissLoading()
            })
            .then((values) => {
                if (values[0]) {
                    setSupplierDropdown(values[0].data)
                }
            });
        return () => {
            BuyerService.cancelRequest()
            clearTimeout(timeoutFn);
        }
    }, [])

    return (
        <div className="supplier-management">
            <Breadcrumb>
                <Breadcrumb.Item>{t("menu.supplier_management")}</Breadcrumb.Item>
            </Breadcrumb>
            <Row id={Constant.FixItemsContainerId} className="fix-item-container">
                <Col xs={{ span: 24 }}>
                    <Form
                        {...layout}
                        form={form}
                        name="supplierFilterForm"
                        onFinish={onSearch}
                    >
                        <Row align="middle"> 
                            <Col flex="auto" className="item-group">
                                <Row gutter={[Constant.SpaceConstant.HorizontalGutter, 5]} align="middle">
                                    <Col xs={6}>
                                        <Form.Item
                                            label={t("supplier_managament.requested_date_from")}
                                            name="RequestedDateFrom" colon={false} labelAlign="left"
                                        >
                                            <DatePicker placeholder={t('common.select_date')} format={Constant.DateFormat} />
                                        </Form.Item>
                                    </Col>
                                    <Col xs={6}>
                                        <Form.Item
                                            label={t("supplier_managament.requested_date_to")}
                                            name="RequestedDateTo" colon={false} labelAlign="left"
                                        >
                                            <DatePicker placeholder={t('common.select_date')} format={Constant.DateFormat} />
                                        </Form.Item>
                                    </Col>
                                    <Col xs={6}>
                                        <Form.Item
                                            label={t("supplier_managament.supplier_name")}
                                            name="StoreName" colon={false} labelAlign="left"
                                        >
                                            <Input allowClear />
                                        </Form.Item>
                                    </Col>
                                    <Col xs={6}>
                                        <Form.Item
                                            label={t("common.status")}
                                            name="StatusCode" colon={false} labelAlign="left"
                                        >
                                            <Select allowClear>
                                                {
                                                    Constant.SupplierBuyerFilterStatusList.map((n, index) => <Option key={index} value={n.Code}>{n.Description}</Option>)
                                                }
                                            </Select>
                                        </Form.Item>
                                    </Col>
                                </Row>
                            </Col>
                            <Col flex={btnGroupWidth} className="text-right action-btn-group">
                                <div className="btn-m-t" id={Constant.ButtonGroupId}>
                                    <Button size="large" onClick={resetFilter}>{t("common.reset_filter")}</Button>
                                    <Button size="large" className="m-l-10 secondary-btn" htmlType="submit">{t("common.retrieve")}</Button>
                                </div>

                            </Col>
                        </Row>

                    </Form>
                </Col>
                <Col xs={{ span: 24 }} className="text-right extra-btn-m-t">
                    <Button type="primary" className="included-icon" icon={<PlusIcon />} onClick={addSupplier}>
                        {t("common.add")}
                    </Button>
                </Col>
            </Row>


            <Row className="m-t-10" gutter={[, Constant.SpaceConstant.VerticalGutter]}>

                <Col xs={{ span: 24 }}>
                    <Table
                        {...GetTableConfigs(columns, supplierData, totalItems, "SupplierId", onTableChange, gridConfigOptions.pageNumber)}
                    />
                </Col>
            </Row>
            {
                visibleModal &&
                <AddSupplier handleCancel={handleCancel} supplierList={supplierDropdown}></AddSupplier>
            }
        </div>
    )

}

export default SupplierManagement;