import React, { useState, useEffect } from 'react';
import './landingPage.scss';
import { Layout, Button, Row, Col, Carousel, Menu, Dropdown } from 'antd';
import Logo from './../../assets/images/logo.svg'
import { LocationIcon, PhoneIcon, MailIcon, PowerByIcon, AvailableFromIcon } from '../../components/icons/icons'
import { useTranslation } from 'react-i18next';
import LoginAccount from '../../components/loginAccount/loginAccount'
import RegisterAccount from '../../components/registerAccount/registerAccount'
import ChangePassword from '../../components/changePassword/changePassword'
import { AuthService, CommonService, Constant } from '../../services/services'
import LoadingContext from './../../components/utils/loading/loadingContext'
import { useLocation, useHistory } from "react-router-dom";
import PubSub from 'pubsub-js'

const { Header, Content, Footer } = Layout;

function LandingPage(props) {
    const { t, i18n } = useTranslation();
    const [currentDisplayType, setCurrentDisplayType] = useState(false)
    const [isRegisterSupplier, setIsRegisterSupplier] = useState(true)
    const [isLoginSupplier, setIsLoginSupplier] = useState(false)
    const { showLoading, dismissLoading } = React.useContext(LoadingContext)
    const location = useLocation();
    const history = useHistory();
    const [animation, setAnimation] = useState()

    const displayType = Object.freeze({
        none: 0,
        login: 1,
        register: 2,
        aboutUs: 3,
        changePassword: 4
    })

    const [changePasswordObject, setChangePasswordObject] = useState({})
    const [contactDetail, setContactDetail] = useState({})

    useEffect(() => {
        setTimeout(() => {
            let confirmType = location.search.match("ConfirmType=(.*)&Code=")
            let code = location.search.match("Code=(.*)&Username=")
            let username = location.search.match("&Username=(.*)")
            if (confirmType && code && username) {
                if (confirmType[1] == Constant.ConfirmType.Register) {
                    showLoading()
                    AuthService.confirmEmail(code[1], username[1])
                        .finally(() => {
                            dismissLoading()
                            setDisplayType(displayType.confirmEmail)
                        })
                        .then(() => CommonService.presentToast("success", "Your account has been activated successfully"))
                        .catch(error => {
                            CommonService.handleErrorResponse(error)
                        })
                } else if (confirmType[1] == Constant.ConfirmType.ForgotPassword) {
                    setChangePasswordObject({
                        code: code[1],
                        username: username[1]
                    })
                    setDisplayType(displayType.changePassword)
                }
            }
        }, 200);
        
        let regionCode = process.env.REACT_APP_REGION_CODE
        let contactDetail = Constant.ContactDetails[0]
        if (regionCode) {
            let selectedCodes = Constant.ContactDetails.filter(x => x.RegionCode == regionCode)
            if (selectedCodes.length > 0) {
                contactDetail = selectedCodes[0]
            }
        }
        setContactDetail(contactDetail)

    }, [])

    const login = (data) => {
        PubSub.publish(Constant.PubSupType.UserLogin, Constant.PubSupType.UserLogin);
        history.push(data)
    }

    const setDisplayType = (type) => {
        if (type == displayType.none) {
            setAnimation("animate__animated animate__faster animate__fadeOutRight")
            setTimeout(() => {
                setCurrentDisplayType(type)
            }, 500);
        } else {
            setAnimation("animate__animated animate__faster animate__fadeInRight")
            setCurrentDisplayType(type)
        }
    }

    const register = (data) => {
        showLoading()
        if (isRegisterSupplier) {
            AuthService.registerSupplier(data)
                .finally(() => dismissLoading())
                .then(() => {
                    CommonService.presentToast("success", t("landing_page.supplier_register_success"))
                    setDisplayType(displayType.none)
                })
                .catch(error => {
                    CommonService.handleErrorResponse(error);
                })
        } else {
            AuthService.registerBuyer(data)
                .finally(() => dismissLoading())
                .then(() => {
                    CommonService.presentToast("success", t("landing_page.supplier_register_success"))
                    setDisplayType(displayType.none)
                })
                .catch(error => {
                    CommonService.handleErrorResponse(error);
                })
        }
    }

    const userRegisterTypeSelection = (
        <Menu className="user-info-dropdown">
            <Menu.Item onClick={() => openRegister(true)}>
                {t('common.supplier')}
            </Menu.Item>
            <Menu.Item onClick={() => openRegister(false)}>
                {t('common.buyer')}
            </Menu.Item>
        </Menu>
    )

    const userLoginTypeSelection = (
        <Menu className="user-info-dropdown">
            <Menu.Item onClick={() => openLogin(true)}>
                {t('common.supplier')}
            </Menu.Item>
            <Menu.Item onClick={() => openLogin(false)}>
                {t('common.buyer')}
            </Menu.Item>
        </Menu>
    )

    const openRegister = (isSupplier) => {
        setIsRegisterSupplier(isSupplier)
        if (currentDisplayType == displayType.register) {
            setCurrentDisplayType(displayType.none)
        }
        setTimeout(() => {
            setDisplayType(displayType.register)
        }, 50)

    }

    const openLogin = (isSupplier) => {
        setIsLoginSupplier(isSupplier)
        if (currentDisplayType == displayType.login) {
            setCurrentDisplayType(displayType.none)
        }
        setTimeout(() => {
            setDisplayType(displayType.login)
        }, 50)

    }

    const changePassword = (password) => {
        showLoading()
        AuthService.resetPassword(changePasswordObject.username, password, changePasswordObject.code)
            .finally(() => dismissLoading())
            .then(() => {
                CommonService.presentToast("success", t("landing_page.change_password_success"))
                setDisplayType(displayType.login)
            })
            .catch(error => {
                CommonService.handleErrorResponse(error)
            })
    }

    return (
        <Layout className="landing-page">
            <Header className="landing-page--header">
                <div className="logo">
                    <img src={Logo} />
                </div>
                <div className="btn-group">
                    {/* <Button type="text" className="uppercase-text" onClick={() => setDisplayType(displayType.aboutUs)}>{t("landing_page.about_us")}</Button> */}
                    <Dropdown overlay={userRegisterTypeSelection} trigger={['click']} className="user-info truncate" overlayClassName="user-info-popup" >
                        <Button type="text" className="uppercase-text">{t("register.title_header")}</Button>
                    </Dropdown>
                    {/* <Dropdown overlay={userLoginTypeSelection} trigger={['click']} className="user-info truncate" overlayClassName="user-info-popup" >
                        <Button type="text" className="uppercase-text">{t("login.login")}</Button>
                    </Dropdown> */}
                    <Button type="text" className="uppercase-text" onClick={openLogin}>{t("login.login")}</Button>

                </div>
            </Header>
            <Content className="landing-page--site-layout">
                <Row className="content" justify="space-between" align="middle">
                    <Col xs={{ span: 11, offset: 1 }} md={{ span: 10, offset: 1 }} xl={{ span: 7, offset: 1 }} xxl={{ span: 7, offset: 0 }}>
                        <Carousel autoplay dotPosition="bottom">
                            <div>
                                <h1 className="bold-text">{t("landing_page.for_distributors")}</h1>
                                <p dangerouslySetInnerHTML={{ __html: t("landing_page.for_distributors_value") }}></p>
                            </div>
                            <div>
                                <h1 className="bold-text">{t("landing_page.for_trade_customer")}</h1>
                                <p dangerouslySetInnerHTML={{ __html: t("landing_page.for_trade_customer_value") }}></p>
                            </div>
                        </Carousel>
                    </Col>
                    <Col xs={{ span: 12 }} xl={{ span: 8 }}>
                        {currentDisplayType == displayType.login && <LoginAccount className={"login-container " + animation} onLogin={login} isSupplier={isLoginSupplier} />}
                        {currentDisplayType == displayType.register && <RegisterAccount className={animation} isSupplier={isRegisterSupplier} onRegister={register} onCancel={() => setDisplayType(displayType.none)} />}
                        {currentDisplayType == displayType.changePassword && <ChangePassword className={"login-container " + animation} object={changePasswordObject} onChangePassword={changePassword} />}
                    </Col>

                </Row>

            </Content>
            <Footer className="landing-page--footer">
                <Row gutter={[5,]}>
                    <Col span={3}>
                        <div class="content--item">
                            <div class="item-left">
                                <span className="icon"><PowerByIcon /></span>
                            </div>
                            <div class="item-right">
                                <p className="title-field m-b-0 title">{t("landing_page.power_by")}</p>
                                <p className="m-b-0 semi-bold-text title">
                                    <a className='text-field' href={contactDetail?.PowerByLink} target="_blank">{contactDetail?.PowerBy}</a>
                                </p>
                            </div>
                        </div>
                    </Col>
                    <Col span={5}>
                        <div class="content--item">
                            <div class="item-left">
                                <span className="icon"><AvailableFromIcon /></span>
                            </div>
                            <div class="item-right">
                                <p className="title-field m-b-0 title">{t("landing_page.available_from")}</p>
                                <p className="m-b-0 semi-bold-text title">
                                    <a className='text-field' href={contactDetail?.AvailableFromLink} target="_blank">{contactDetail?.AvailableFrom}</a>
                                </p>
                            </div>
                        </div>
                    </Col>
                    <Col span={8}>
                        <div class="content--item">
                            <div class="item-left">
                                <span className="icon"><LocationIcon /></span>
                            </div>
                            <div class="item-right">
                                <p className="title-field m-b-0 title">{t("landing_page.address")}</p>
                                <p className="text-field m-b-0 semi-bold-text title block-display">
                                    {contactDetail?.Address}
                                </p>
                            </div>
                        </div>
                    </Col>
                    <Col span={4}>
                        <div class="content--item">
                            <div class="item-left">
                                <span className="icon"><PhoneIcon /></span>
                            </div>
                            <div class="item-right">
                                <p className="title-field m-b-0 title">{t("landing_page.phone")}</p>
                                <p className="m-b-0 semi-bold-text title">
                                    <a className='text-field' href={`tel:${contactDetail?.Phone}`}>{contactDetail?.Phone}</a>
                                </p>
                            </div>
                        </div>
                    </Col>
                    <Col span={4}>
                        <div class="content--item">
                            <div class="item-left">
                                <span className="icon"><MailIcon /></span>
                            </div>
                            <div class="item-right">
                                <p className="title-field m-b-0 title">{t("landing_page.email")}</p>
                                <p className="m-b-0 semi-bold-text title">
                                    <a className='text-field' href={`mailto:${contactDetail?.Email}`}>{contactDetail?.Email}</a>
                                </p>
                            </div>
                        </div>
                    </Col>
                </Row>
            </Footer>
        </Layout>
    );
}

export default LandingPage;