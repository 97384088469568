import axios from 'axios';
import { axiosPortal } from './authService'
import moment from 'moment';

const source = [];
let baseURL = "/Buyer"

export const PartOrderHistoryService = {
    getDetails: function (pickslipKey) {
        let cancelToken = axios.CancelToken.source()
        source.push(cancelToken)
        let url = `${baseURL}/GetPartOrderHistoryDetail?pickslipKey=${pickslipKey}`

        return axiosPortal.get(url, {
            cancelToken: cancelToken.token
        }).finally(() => {
            let index = source.indexOf(cancelToken)
            if (index > -1) {
                source.splice(index, 1)
            }
        })
    },
    cancelRequest: function () {
        if (source && source.length > 0) {
            for (var i = 0; i < source.length; i++) {
                source[i].cancel('Operation canceled.')
            }
            source.splice(0, source.length)
        }
    }
}