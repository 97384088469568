import React, { useEffect, useState, useRef, useImperativeHandle, forwardRef } from 'react';
import { useTranslation } from 'react-i18next';
import {
    Tooltip, Button, Badge, Form, TimePicker, Input,
    Empty, InputNumber, Checkbox, Select, Modal,
    Col, Row, Popover
} from 'antd';
import './gridUtils.scss';
import { Constant, CommonService, StorageService } from './../../../services/services';
import moment from 'moment'
import SearchInput from "./../../searchInput/searchInput"
import { IdcardFilled } from '@ant-design/icons';
import useOnClickOutside from 'use-onclickoutside'
import PartEnquiry from './../../../pages/buyer/partEnquiry/partEnquiry'
const { RangePicker } = TimePicker;
const { Option } = Select;
const { TextArea } = Input;

export const ActionButtonsCell = (props) => {
    return (
        <div className={`flex-items-center ${props?.justifyContentStart ? "align-item-start" : ""}`} >
            {
                props?.btnList.map((item, i) => {
                    return (
                        <Tooltip key={i} placement="bottom" title={item.Description}>
                            <span className="btn-container">
                                <Button icon={item.Icon} className={`m-l-5 m-r-5 action-btn action-btn${item.ColorClass ? item.ColorClass : ""} ${item.Hide ? "hidden" : "" } ${item.NoMargin ? "m-0" : "" }`}
                                    onClick={() => props.handleAction ? props.handleAction(props.data) : (item.handleAction ? item.handleAction(props.data) : (() => { }))} />
                                {item.BadgeType == Constant.BadgeType.Dot && <Badge dot status="processing"></Badge>}
                            </span>
                        </Tooltip>
                    )
                })
            }
        </div>
    );
};

export const StatusCell = (props) => {
    return (
        <Tooltip placement="bottom" title={props.enableTruncate ? props.description : ''}>
            <span className={`${props.enableTruncate ? 'status-truncate truncate' : ''} status-tag status-tag${props.colorClass} ${props.hide ? "hidden" : ""}`}>
                {props.description}
            </span>
        </Tooltip>

    );
};

export const GetTableConfigs = (columns, dataSource, totalItems, rowKey, onChangeFn, currentPageNumber, customClass = "", showPagination = true) => {
    const { t } = useTranslation();
    return {
        className: `custom-table ${customClass}`,
        scroll: { y: 300 },
        pagination: !showPagination ? false : {
            total: totalItems,
            size: "small",
            showTotal: (total) => t('common.total_items', { total: total }),
            showQuickJumper: true,
            current: currentPageNumber ? currentPageNumber : 1
        },
        locale: {
            emptyText: <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} description={t("common.no_data")} />,
            filterConfirm: t('common.filter')
        },
        columns: columns,
        dataSource: dataSource,
        onChange: onChangeFn,
        rowKey: rowKey

    }
}

export const GetTableConfigs_SimplePaginator = (columns, dataSource, totalItems, rowKey, onChangeFn, currentPageNumber, customClass = "") => {
    const { t } = useTranslation();
    return {
        className: `custom-table ${customClass}`,
        scroll: { y: 300 },
        pagination: {
            total: totalItems,
            size: "small",
            current: currentPageNumber ? currentPageNumber : 1,
            defaultPageSize: Constant.PageSize,
            showTitle: false,
            simple: false,
            showLessItems: true,
            showQuickJumper: false,
            showSizeChanger: false
        },
        locale: {
            emptyText: <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} description={t("common.no_data")} />,
            filterConfirm: t('common.filter')
        },
        columns: columns,
        dataSource: dataSource,
        onChange: onChangeFn,
        rowKey: rowKey
    }
}

export const CalcNumberOfItems_SimplePaginator = (pageNumber, dataLength) => {
    if (dataLength < Constant.PageSize) {
        return (pageNumber - 1) * Constant.PageSize + dataLength;
    } else {
        //iif dataLength === Constant.PageSize
        return (pageNumber - 1) * Constant.PageSize + dataLength + 1;
    }
}

export const DateTimeCell = (props) => {
    return (
        <span className={`${props.className}`}>
            {CommonService.getDateString(props.value)} {props.showTime ? CommonService.getTimeString(props.value) : ""}
        </span>
    );
};

export const EditableCell = ({
    title,
    editable,
    children,
    dataIndex,
    record,
    handleSelectedItem,
    cellType,
    required,
    extraData,
    handleFormSubmit,
    handleOnChange,
    disableToggleInput,
    enableTabKeyEvent,
    errorTextPosition,
    ...restProps
}) => {
    const [form] = Form.useForm();
    const [editing, setEditing] = useState(!disableToggleInput ? false : true);
    const [popoverMessage, setPopoverMessage] = useState();
    const [isShowPopover, setIsShowPopover] = useState(false);
    const inputRef = useRef(null);
    const { t } = useTranslation();
    const authData = StorageService.getAuthData()

    useEffect(() => {
        if (cellType == Constant.CellType.PartOrderSearchInput ||
            cellType == Constant.CellType.PartOrderNumberInput ||
            cellType == Constant.CellType.Selection ||
            cellType == Constant.CellType.EditRequestCreditReturnNumberInput ||
            cellType == Constant.CellType.Input) {
            form.setFieldsValue(record)
        }

    }, children)

    useEffect(() => {
        if (
            cellType == Constant.CellType.Selection) {
            form.validateFields();
        }

    }, [record?.CellError])

    useEffect(() => {
        if (editing && inputRef.current && !disableToggleInput) {
            inputRef.current.focus();
        }
    }, [editing]);

    useEffect(() => {
        if (cellType == Constant.CellType.Input || cellType == Constant.CellType.NumberInput) {
            setEditing(dataIndex == record?.CurrentEditFieldKey)
        }
    }, [record?.CurrentEditFieldKey])

    useEffect(() => {
        setIsShowPopover(false)
        setPopoverMessage(null)
    }, [record?.DmsStockKey])

    useEffect(() => {
        if (cellType == Constant.CellType.PartOrderNumberInput && record?.ParentSequence > 0) {
            if (record.OrderedQty >= 100000) {
                setPopoverMessage(t("part_order.maximum_qty_error"))
                setIsShowPopover(true)
            } else {
                setPopoverMessage(null)
                setIsShowPopover(false)
            }
        }
    }, [record?.OrderedQty])

    const toggleEdit = () => {
        setEditing(!editing);
        if (cellType == Constant.CellType.Input) {
            form.setFieldsValue({ [dataIndex]: record[dataIndex] });
        }
    };

    const save = async (event, triggeredOnTab = false) => {
        try {
            if (cellType == Constant.CellType.Input || cellType == Constant.CellType.NumberInput) {
                const values = await form.validateFields();
                if (!disableToggleInput)
                    toggleEdit();
                if (handleFormSubmit)
                    handleFormSubmit(values[dataIndex], record, dataIndex, triggeredOnTab);
            }
        } catch (errInfo) {
            console.log('Save failed:', errInfo);
        }
    };


    const containerRef = useRef(null);
    useOnClickOutside(containerRef, save)

    const getCellFormat = () => {
        const selectedItem = (objectData) => {
            if (handleSelectedItem) {
                handleSelectedItem(objectData)
            }
        }

        const submitForm = (data) => {
            record.isChanged = false
            if (handleFormSubmit) {
                handleFormSubmit(data, record, dataIndex)
            }
        }

        const onPartOrderNumperInputChange = (value, totalPriceTaxExclusiveField, totalPriceTaxInclusiveField) => {
            if (record) {
                if (!record.RequiredDecimalQty) {
                    value = parseInt(value)
                    form.setFieldsValue({
                        [dataIndex]: value
                    })
                }

                if (record.SaleOrderTypePricePerUnits) {
                    let pricePerUnit = record.SaleOrderTypePricePerUnits.find(p => p.SalesOrderType == record.CurrentOrderTypeCode && p.Qty <= value)
                    if (pricePerUnit) {
                        record.UnitPriceTaxExclusive = pricePerUnit.PriceTaxExclusive
                        record.UnitPriceTaxInclusive = pricePerUnit.PriceTaxInclusive
                    }
                }

                let totalPriceTaxEx = CommonService.calculateAmmount(record.UnitPriceTaxExclusive, value)
                if (!isNaN(totalPriceTaxEx)) {
                    record[totalPriceTaxExclusiveField] = totalPriceTaxEx
                }
                else
                    record[totalPriceTaxExclusiveField] = null

                let totalPriceTaxIn = CommonService.calculateAmmount(record.UnitPriceTaxInclusive, value)
                if (!isNaN(totalPriceTaxIn)) {
                    record[totalPriceTaxInclusiveField] = totalPriceTaxIn
                }
                else
                    record[totalPriceTaxInclusiveField] = null

                if (handleOnChange) {
                    handleOnChange(value, record, dataIndex)
                }
            }
        }

        const onPartOrderInputChange = (value, record) => {
            if (handleOnChange) {
                handleOnChange(value, record, dataIndex)
            }
        }

        const onInputkeyDown = (e) => {
            if (e.key === "Tab") {
                if (enableTabKeyEvent) {
                    save(null, true)
                }
            }
        }

        const partOrderSubmitForm = async () => {
            try {
                const values = await form.validateFields()
                if (record.isChanged) {
                    if (handleFormSubmit) {
                        handleFormSubmit(values, record, dataIndex)
                    }

                    record.isChanged = false
                }
            } catch {
                //Do nothing if values are invalid
            }
        }

        const onCellChange = () => {
            record.isChanged = true
        }

        if (cellType == Constant.CellType.PartOrderSearchInput) {
            if (record && (record.LineId || record.ParentSequence > 0)) {
                return children
            } else {
                record[dataIndex + "_onFocus"] = () => {
                    if (inputRef.current) {
                        inputRef.current.focus();
                    }
                }
                return (
                    <Form form={form}
                        onFinish={submitForm}
                        initialValues={record}>
                        <Form.Item
                            style={{
                                margin: 0,
                            }}
                            name={dataIndex}
                            rules={[
                                {
                                    required: required ? true : false,
                                    message: `${title} is required.`,
                                },
                            ]}
                        >
                            <SearchInput ref={inputRef} handleSelectedItem={selectedItem} onChange={onCellChange} uppercase={true} onBlur={partOrderSubmitForm}></SearchInput>

                        </Form.Item>
                    </Form>
                )
            }
        }
        else if (cellType == Constant.CellType.PartOrderNumberInput) {
            if (record && record.ParentSequence > 0) {
                return children
            } else {
                if (record && record.DmsStockKey) {
                    record[dataIndex + "_onFocus"] = () => {
                        if (inputRef.current) {
                            inputRef.current.focus();
                        }
                    }
                    return (
                        <Form form={form} initialValues={record} onFinish={submitForm}>
                            <Form.Item
                                style={{
                                    margin: 0,
                                }}
                                className="no-message"
                                name={dataIndex}
                                rules={[
                                    {
                                        required: required ? true : false,
                                        message: `${title} is required.`,
                                    },
                                    ({ getFieldValue }) => ({
                                        validator(rule, value) {
                                            if (isNaN(value) || value <= 0) {
                                                setPopoverMessage(t("part_order.invalid_qty"))
                                                setIsShowPopover(true)
                                                return Promise.reject();
                                            }
                                            if (value >= 100000) {
                                                setPopoverMessage(t("part_order.maximum_qty_error"))
                                                setIsShowPopover(true)
                                                return Promise.reject();
                                            }
                                            if (record.isChanged && ((authData && authData.enableSellAtPackQtyOnly) || record.EnableSellAtPackQtyOnly) &&
                                                record.SupplierPackQty && record.SupplierPackQty > 1) {
                                                if (Number(value) % record.SupplierPackQty != 0) {
                                                    setPopoverMessage(t('part_order.enable_force_sell_pack_qty', { packQty: record.SupplierPackQty }))
                                                    setIsShowPopover(true)
                                                    return Promise.reject();
                                                }
                                            }

                                            setPopoverMessage(null)
                                            setIsShowPopover(false)
                                            return Promise.resolve();
                                        },
                                    })
                                ]}

                            >
                                <InputNumber ref={inputRef} onChange={value => { onCellChange(); onPartOrderNumperInputChange(value, "TotalPriceTaxExclusive", "TotalPriceTaxInclusive"); }}
                                    onBlur={partOrderSubmitForm} className="hide-stepper" />
                            </Form.Item>
                        </Form>
                    )
                } else {
                    return null
                }
            }
        } else if (cellType == Constant.CellType.PartOrderInput) {
            if (record && record.ParentSequence > 0) {
                return children
            } else {
                if (record && record.LineId) {
                    record[dataIndex + "_onFocus"] = () => {
                        if (inputRef.current) {
                            inputRef.current.focus();
                        }
                    }
                    return (
                        <Form form={form} initialValues={record} onFinish={submitForm}>
                            <Form.Item
                                style={{
                                    margin: 0,
                                }}
                                name={dataIndex}
                                rules={[
                                    {
                                        required: required ? true : false,
                                        message: `${title} is required.`,
                                    }
                                ]}
                            >
                                <Input ref={inputRef} className="uppercase-text" maxLength={10} onChange={e => { onCellChange(); onPartOrderInputChange(e.target.value, record); }} onBlur={partOrderSubmitForm} />
                            </Form.Item>
                        </Form>
                    )
                } else {
                    return null
                }
            }
        }
        else if (cellType == Constant.CellType.Checkbox) {
            return (
                <Form form={form}>
                    <Form.Item
                        style={{
                            margin: 0,
                        }}
                        name={dataIndex}
                        valuePropName="checked"
                    >
                        <Checkbox />

                    </Form.Item>
                </Form>
            )
        }
        else if (cellType == Constant.CellType.Selection) {
            if (extraData) {
                return (
                    <Form form={form} initialValues={record}>
                        <Form.Item
                            style={{
                                margin: 0,
                            }}
                            name={dataIndex}
                            rules={[() => ({
                                validator(rule, value) {
                                    if (record.CellError) {
                                        return Promise.reject(record.CellError);
                                    }
                                    return Promise.resolve();
                                },
                            })]}
                        >
                            <Select allowClear={true} onChange={submitForm}>
                                {
                                    extraData.map(n => <Option key={n.Code} disabled={n.Disabled} value={n.Code} className={`${n.Hidden ? "hidden" : ""}`}>{n.Description}</Option>)
                                }
                            </Select>

                        </Form.Item>
                    </Form>
                )
            } else {
                return children
            }
        }
        else if (cellType == Constant.CellType.Input) {
            return (
                <div ref={containerRef}>
                    <Form form={form} initialValues={record} >
                        <Form.Item
                            style={{
                                margin: 0,
                            }}
                            name={dataIndex}
                        >
                            <Input ref={inputRef} onChange={e => handleOnChange ? handleOnChange(e.target.value, record, dataIndex) : null} allowClear onKeyDown={onInputkeyDown} />
                        </Form.Item>
                    </Form>
                </div>

            )
        }
        else if (cellType == Constant.CellType.NumberInput) {
            return (
                <div ref={containerRef}>
                    <Form form={form} initialValues={record}>
                        <Form.Item style={{ margin: 0 }} name={dataIndex}>
                            <InputNumber ref={inputRef} onChange={e => handleOnChange ? handleOnChange(e.target.value, record, dataIndex) : null} onKeyDown={onInputkeyDown} />
                        </Form.Item>
                    </Form>
                </div>
            )
        }
        else if (cellType == Constant.CellType.EditRequestCreditReturnNumberInput) {
            return (
                <div ref={containerRef}>
                    <Form form={form} initialValues={record}>
                        <Form.Item
                            style={{
                                margin: 0,
                            }}
                            name={dataIndex}
                            rules={[
                                {
                                    required: required ? true : false,
                                    message: `${title} is required.`,
                                },
                                ({ getFieldValue }) => ({
                                    validator(rule, value) {
                                        if (value != null && (Number(value) <= 0 || Number(value) > record.QtyOutstanding)) {
                                            return Promise.reject(t("part_order.invalid_qty"));
                                        }
                                        return Promise.resolve();
                                    },
                                })
                            ]}

                        >
                            <InputNumber ref={inputRef} onChange={value => onPartOrderNumperInputChange(value, 'RequestAmtTaxExclusive', 'RequestAmtTaxInclusive')}
                                className="hide-stepper" />

                        </Form.Item>
                    </Form>
                </div>

            )
        }
    }

    let childNode = children;

    if (editable) {
        if (cellType == Constant.CellType.Input || cellType == Constant.CellType.NumberInput) {
            childNode = editing ? getCellFormat() :
                (
                    <div onClick={toggleEdit}>
                        {
                            record[dataIndex] ? children :
                                (
                                    <label className="edit-label">{t("common.edit_click")}</label>
                                )
                        }
                    </div>
                )
        } else {
            childNode = getCellFormat()
        }

    }

    return <td {...restProps}>
        <Popover overlayStyle={{ zIndex: 2 }} visible={isShowPopover} placement={errorTextPosition}
            content={<p style={{ color: "#ff4d4f" }}>{popoverMessage}</p>}
        >
            {childNode}
        </Popover>
    </td>;
};

export const ModalButtonCell = forwardRef((props, ref) => {
    const { t } = useTranslation();
    const [visibleModal, setVisibleModal] = useState(false);
    const [form] = Form.useForm();

    useImperativeHandle(ref, () => ({
        showModal() {
            showModal()
        }
    }));
    const handleCancel = () => {
        form.resetFields()
        setVisibleModal(false)
    }

    const showModal = () => {
        setVisibleModal(true)
    }
    const onClick = () => {
        if (props.onClick) {
            props.onClick()
        }
        else {
            showModal()
        }

    }

    const onSubmit = (data) => {
        setVisibleModal(false)
        if (props.onSubmit) {
            if (props.buttonType == Constant.ButtonWithModalType.RequestCreditReturnAddEditDetailsReason) {
                props.onSubmit(data)
            }
        }
    }

    return (
        <>
            <Tooltip placement="bottom" title={props.tooltipText}>
                <span className="btn-container">
                    <Button icon={props.icon} className={`action-btn action-btn${props.colorClass}`}
                        onClick={onClick}
                    />
                </span>
            </Tooltip>
            {
                visibleModal && props.buttonType && props.buttonType == Constant.ButtonWithModalType.PartEnquiry && CommonService.allowToAccessFunction(Constant.SupplierBuyerFunction.PartEnquiry) &&
                <Modal
                    title={t("menu.part_enquiry")}
                    visible={true}
                    confirmLoading={false}
                    onCancel={handleCancel}
                    maskClosable={false}
                    footer={null}
                    width={1300}
                    wrapClassName="part-enquiry-modal"
                >
                    <PartEnquiry stockKey={props.data.StockKey}></PartEnquiry>
                </Modal>
            }
            {
                visibleModal && props.buttonType && props.buttonType == Constant.ButtonWithModalType.RequestCreditReturnAddEditDetailsReason &&
                <Modal
                    title={t("outstanding_credit_note.details_reason")}
                    visible={true}
                    confirmLoading={false}
                    onCancel={handleCancel}
                    maskClosable={false}
                    width={700}
                    okText={t("common.save")}
                    onOk={form.submit}
                    cancelButtonProps={{ style: props.modalViewOnly ? { display: 'none' } : {} }}
                    okButtonProps={{ style: props.modalViewOnly ? { display: 'none' } : {} }}
                >
                    <Form
                        wrapperCol={{ span: 24 }}
                        labelCol={{ span: 0 }}
                        form={form}
                        name="addUpdateDetailsReasonForm"
                        onFinish={onSubmit}
                        initialValues={props.data}
                    >
                        <Row>
                            <Col xs={{ span: 24 }}>
                                <Form.Item
                                    label=""
                                    name="CreditComment" colon={false} labelAlign="left"
                                >
                                    <TextArea rows="10" allowClear disabled={props.modalViewOnly} />
                                </Form.Item>
                            </Col>
                        </Row>
                    </Form>

                </Modal>
            }
        </>

    );
});

export const TextAndButtonCell = (props) => {
    const childRef = useRef();
    const showModal = () => {
        childRef.current.showModal()
    }
    const onSubmit = (data) => {
        if (props.onSubmit)
            props.onSubmit(data, props.data)
    }
    const buttonType = props.buttonType ? props.buttonType : Constant.ButtonWithModalType.PartEnquiry
    return (
        <div className="flex-items align-space-between">
            <span title={props.value} className={`truncate-flex-item ${props.includeWhiteSpaces ? "included-spaces" : ""}`}>{props.value}</span>
            <Tooltip placement="bottom" title={props.tooltipText}>
                <span className="btn-container">
                    <ModalButtonCell ref={childRef} icon={props.icon} buttonType={buttonType} className={`action-btn action-btn${props.colorClass}`}
                        onClick={showModal}
                        data={props.data}
                        onSubmit={onSubmit}
                        modalViewOnly={props.modalViewOnly}
                    />
                </span>
            </Tooltip>
        </div>
    );
};

export const AmountCell = (props) => {
    let authData = StorageService.getAuthData();
    let amt = props.value
    if (authData && authData.isShowPriceTaxIncluded && props.valueTaxIncluded) {
        amt = props.valueTaxIncluded
    }
    return (
        <span>{CommonService.amountFormat(amt, props.emptyText)}</span>
    );
};

