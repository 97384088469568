import React, { useState, useEffect, useContext } from 'react';
import './partOrderHistoryDetails.scss';
import { Button, Row, Col, Tooltip, Table, Breadcrumb, Tabs, Typography, Modal } from 'antd';
import { useTranslation } from 'react-i18next';
import { GetTableConfigs, DateTimeCell, StatusCell, TextAndButtonCell, ActionButtonsCell } from '../../../components/utils/gridUtils/gridUtils';
import PartEnquiry from './../../buyer/partEnquiry/partEnquiry'
import { ChevronLeftIcon, ViewIcon, DownloadIcon } from './../../../components/icons/icons';
import { Constant, OrderManagementService, CommonService, StorageService, BuyerService } from '../../../services/services';
import LoadingContext from './../../../components/utils/loading/loadingContext';
import { Redirect, useLocation, useHistory } from "react-router-dom";
import { useGlobalState } from '../../../utilities/globalState';

const { Text } = Typography;
const { TabPane } = Tabs;

export default function PartOrderHistoryDetails(props) {
    const { t } = useTranslation();
    const location = useLocation();
    const history = useHistory();

    const { showLoading, dismissLoading } = useContext(LoadingContext)
    const [pickslipInfo, setPickslipInfo] = useState({});
    const [listOfPart, setListOfPart] = useState([]);
    const [listOfSundry, setListOfSundry] = useState([]);
    const [visiblePartEnquiry, setVisiblePartEnquiry] = useState(false);
    const [stockKeyEnquiry, setStockKeyEnquiry] = useState(false);
    const [visibleCreditNotes, setVisibleCreditNotes] = useState(false);
    const [creditNotesItems, setCreditNotesItems] = useState([]);
    const [visibleCreditOutstanding, setVisibleCreditOutstanding] = useState(false);
    const [creditOutstandingItems, setCreditOutstandingItems] = useState([]);
    const [partNumber, setPartNumber] = useState(null);
    const [taxIncluded, setTaxIncluded] = useState(true);
    const [orderTypes, setOrderTypes] = useGlobalState('orderTypes');
    const [visibleDownloadDocumentModal, setVisibleDownloadDocumentModal] = useState(false);

    const getActionButtonsInfo = () => {
        return {
            tooltipText: t("common.view"),
            icon: <ViewIcon />,
            colorClass: Constant.ColorClass.LightBlue,
            buttonType: Constant.ButtonWithModalType.PartEnquiry
        }
    }

    const columns_parts = [{
        "title": <div dangerouslySetInnerHTML={{ __html: t("orders_management.pickslip_line_id_wrapped") }}></div>,
        "dataIndex": "PickslipLineID",
        "key": "PickslipLineID",
        "width": 80
    }, {
        "title": t("orders_management.part_number"),
        "dataIndex": "Key",
        "key": "Key",
        "width": 200,
        render: (value, record) => (
            <TextAndButtonCell value={record.PartItemCode} tooltipText={t("common.view_part_details")}
                buttonType={Constant.ButtonWithModalType.PartEnquiry}
                data={record} icon={<ViewIcon />} includeWhiteSpaces={true} />
        )
    }, {
        "title": t("orders_management.description"),
        "dataIndex": "PartDescription",
        "key": "PartDescription",
        "width": 180
    }, {
        "title": t("orders_management.make"),
        "dataIndex": "MakeId",
        "key": "MakeId",
        "width": 120
    }, {
        "title": <div className="text-center" dangerouslySetInnerHTML={{ __html: taxIncluded ? t('orders_management.unit_price_inc') : t('orders_management.unit_price_ex') }}></div>,
        "dataIndex": "UnitPrice",
        "key": "UnitPrice",
        "width": 120,
        "align": "center",
        render: (value, record) => {
            if (record.IsKitComponent) {
                return <></>;
            }
            return (
                <>{taxIncluded
                    ? (record.PriceTaxInclusive ? record.PriceTaxInclusive.toFixed(Constant.NumberOfDecimalPlace) : null)
                    : (record.PriceTaxExclusive ? record.PriceTaxExclusive.toFixed(Constant.NumberOfDecimalPlace) : null)
                }</>
            );
        }
    }, {
        "title": t("orders_management.qty_ordered"),
        "dataIndex": "QtyOrdered",
        "key": "QtyOrdered",
        "width": 80,
        "align": "center",
        render: (value, record) => {
            if (record.IsKitComponent) {
                return <></>;
            }
            return <>{record.QtyOrdered}</>;
        }
    }, {
        "title": <div className="text-center" dangerouslySetInnerHTML={{ __html: taxIncluded ? t('orders_management.total_amt_inc') : t('orders_management.total_amt_ex') }}></div>,
        "dataIndex": "TotalAmt",
        "key": "TotalAmt",
        "width": 120,
        "align": "center",
        render: (value, record) => {
            if (record.IsKitComponent) {
                return <></>;
            }
            return (
                <>{taxIncluded
                    ? (record.TotalAmtTaxInclusive ? record.TotalAmtTaxInclusive.toFixed(Constant.NumberOfDecimalPlace) : null)
                    : (record.TotalAmtTaxExclusive ? record.TotalAmtTaxExclusive.toFixed(Constant.NumberOfDecimalPlace) : null)
                }</>
            );
        }
    }, {
        "title": t("orders_management.bo_method"),
        "dataIndex": "BackOrderMethod",
        "key": "BackOrderMethod",
        "width": 120
    }, {
        "title": t("orders_management.location"),       
        "dataIndex": "CustomerNormalBinLocation",
        "key": "CustomerNormalBinLocation",
        "width": 120,      
    }, {
        "title": t("orders_management.cancelled_date"),
        "dataIndex": "CancelledDate",
        "key": "CancelledDate",
        "width": 120,
        "align": "center",
        render: (value, record) => (
            pickslipInfo.PickslipStatusCode === Constant.PartOrderStatus.Delete
                ? <DateTimeCell value={record.LastUpdateTimestamp} />
                : null
        )
    }, {
        "title": t("orders_management.invoice_date"),
        "dataIndex": "InvoiceDate",
        "key": "InvoiceDate",
        "width": 120,
        "align": "center",
        render: (value, record) => {
            if(record.QtyOrdered >= 0) {
                return record.InvoiceNo ? <DateTimeCell value={record.InvoiceDate} /> : <></>;
            } else {
                return record.OriginalInvoiceNo ? <DateTimeCell value={record.OriginalInvoiceDate} /> : <></>;
            }          
        }
    }, {
        "title": t("orders_management.invoice_no"),
        "dataIndex": "InvoiceNo",
        "key": "InvoiceNo",
        "width": 120,
        render: (value, record) => {
            return record.QtyOrdered >= 0 ? <>{record.InvoiceNo}</> : <>{record.OriginalInvoiceNo}</>;
        }
    }, {
        "title": t("orders_management.credit_no"),
        "dataIndex": "CreditNo",
        "key": "CreditNo",
        "width": 120
    }];

    const columns_sundries = [{
        "title": t("orders_management.sundry"),
        "dataIndex": "SundryChargeId",
        "key": "SundryChargeId",
        "width": 250
    }, {
        "title": <div className="text-left" dangerouslySetInnerHTML={{ __html: t('orders_management.pickslip_line_id_2') }}></div>,
        "dataIndex": "PartLineCounter",
        "key": "PartLineCounter",
        "width": 200
    }, {
        "title": <div className="text-left" dangerouslySetInnerHTML={{ __html: taxIncluded ? t('orders_management.amount_inc') : t('orders_management.amount_ex') }}></div>,
        "dataIndex": "Price",
        "key": "Price",
        "width": 200,
        render: (value, record) => (
            <>{taxIncluded
                ? (record.PriceTaxInclusive ? record.PriceTaxInclusive.toFixed(Constant.NumberOfDecimalPlace) : null)
                : (record.PriceTaxExclusive ? record.PriceTaxExclusive.toFixed(Constant.NumberOfDecimalPlace) : null)
            }</>
        )
    }, {
        "title": <div className="text-left" dangerouslySetInnerHTML={{ __html: taxIncluded ? t('orders_management.credited_inc') : t('orders_management.credited_ex') }}></div>,
        "dataIndex": "TotalAmtCredited",
        "key": "TotalAmtCredited",
        "width": 200,
        render: (value, record) => (
            <>{!taxIncluded
                ? (record.TotalAmtCreditedTaxInclusive ? record.TotalAmtCreditedTaxInclusive.toFixed(Constant.NumberOfDecimalPlace) : null)
                : (record.TotalAmtCreditedTaxExclusive ? record.TotalAmtCreditedTaxExclusive.toFixed(Constant.NumberOfDecimalPlace) : null)
            }</>
        )
    }];

    const columns_credit_notes = [{
        "title": t("orders_management.credit_pickslip_no"),
        "dataIndex": "PickslipNo",
        "key": "PickslipNo",
        "width": 200
    }, {
        "title": t("orders_management.original_qty_invoiced"),
        "dataIndex": "QtyInvoiced",
        "key": "QtyInvoiced",
        "width": 200,
        "align": "center"
    }, {
        "title": t("orders_management.qty_credited"),
        "dataIndex": "TotalQtyCredited",
        "key": "TotalQtyCredited",
        "width": 200,
        "align": "center"
    }, {
        "title": t("orders_management.credited_amount"),
        "dataIndex": "TotalAmtCredited",
        "key": "TotalAmtCredited",
        "width": 200,
        "align": "center"
    }, {
        "title": t("orders_management.credit_date"),
        "dataIndex": "CreationTimestamp",
        "key": "CreationTimestamp",
        "align": "center",
        render: (value, record) => (
            <DateTimeCell value={record.CreationTimestamp} />
        )
    }];

    const columns_credit_outstanding = [{
        "title": t("orders_management.invoice_no"),
        "dataIndex": "InvoiceNo",
        "key": "InvoiceNo",
        "width": 200
    }, {
        "title": t("orders_management.qty_invoiced"),
        "dataIndex": "QtyInvoiced",
        "key": "QtyInvoiced",
        "width": 200,
        "align": "center"
    }, {
        "title": t("orders_management.credit_pickslip_no"),
        "dataIndex": "PickslipNo",
        "key": "PickslipNo",
        "width": 200,
        "align": "center"
    }, {
        "title": t("orders_management.qty_waiting_for_credited"),
        "dataIndex": "TotalQtyCredited",
        "key": "TotalQtyCredited",
        "width": 200,
        "align": "center"
    }, {
        "title": t("orders_management.awaiting_credited_amt"),
        "dataIndex": "TotalAmtCredited",
        "key": "TotalAmtCredited",
        "align": "center"
    }];

    const showPartNumberDetail = item => {
        let qtyOutstanding = item.QtyInvoiced - item.TotalQtyCredited;
        if (qtyOutstanding > 0) {
            setPartNumber(item.Key);
            item.PickslipNo = pickslipInfo.PickslipNo;
            setCreditOutstandingItems([item]);
            setVisibleCreditOutstanding(true);
        } else if (item.TotalQtyCredited > 0) {
            setPartNumber(item.Key);
            item.PickslipNo = pickslipInfo.PickslipNo;
            setCreditNotesItems([item]);
            setVisibleCreditNotes(true);
        } else {
            if (item.StockKey) {
                setStockKeyEnquiry(item.StockKey)
                setVisiblePartEnquiry(true);
            }
        }
    }

    const _getTableConfigs_Parts = () => {
        let configs = GetTableConfigs(columns_parts, listOfPart, null, "PickslipLineID", null, null, "", false);
        let totalQtyOrdered = (Array.isArray(listOfPart) && listOfPart.length > 0 ? listOfPart.filter(p => !p.IsKitComponent).map(p => p.QtyOrdered) : [0])
            .reduce((a, b) => a + b)
            .toFixed(Constant.NumberOfDecimalPlace);
        if (totalQtyOrdered && totalQtyOrdered.toString().length > (Constant.NumberOfDecimalPlace + 1)) {
            let decimalZero = "";
            for (let i = 0; i < Constant.NumberOfDecimalPlace; i++) {
                decimalZero += "0";
            }
            let decimalPart = totalQtyOrdered.toString().substr(totalQtyOrdered.length - (Constant.NumberOfDecimalPlace + 1));
            if (decimalPart == `.${decimalZero}` || decimalPart == `,${decimalZero}`) {
                totalQtyOrdered = totalQtyOrdered.toString().substr(0, totalQtyOrdered.length - (Constant.NumberOfDecimalPlace + 1));
            }
        }

        let totalTotalAmt = (Array.isArray(listOfPart) && listOfPart.length > 0 ? listOfPart.filter(p => !p.IsKitComponent).map(p => taxIncluded ? p.TotalAmtTaxInclusive : p.TotalAmtTaxExclusive) : [0])
            .reduce((a, b) => a + b)
            .toFixed(Constant.NumberOfDecimalPlace);

        configs["summary"] = () => (
            <Table.Summary.Row>
                <Table.Summary.Cell>
                    <Text className="table-summary-value">{t('orders_management.total')}</Text>
                </Table.Summary.Cell>
                <Table.Summary.Cell></Table.Summary.Cell>
                <Table.Summary.Cell></Table.Summary.Cell>
                <Table.Summary.Cell></Table.Summary.Cell>
                <Table.Summary.Cell></Table.Summary.Cell>
                <Table.Summary.Cell className="text-center">
                    <Text className="table-summary-value">{totalQtyOrdered}</Text>
                </Table.Summary.Cell>
                <Table.Summary.Cell className="text-center">
                    <Text className="table-summary-value">{totalTotalAmt}</Text>
                </Table.Summary.Cell>
                <Table.Summary.Cell></Table.Summary.Cell>
                <Table.Summary.Cell></Table.Summary.Cell>
                <Table.Summary.Cell></Table.Summary.Cell>
                <Table.Summary.Cell></Table.Summary.Cell>
                <Table.Summary.Cell></Table.Summary.Cell>
            </Table.Summary.Row>
        );
        return configs;
    }

    const _getTableConfigs_Sundries = () => {
        let configs = GetTableConfigs(columns_sundries, listOfSundry, null, "Key", null, null, "", false);
        let totalTotalPrice = (Array.isArray(listOfSundry) && listOfSundry.length > 0 ? listOfSundry.map(p => taxIncluded ? p.PriceTaxInclusive : p.PriceTaxExclusive) : [0])
            .reduce((a, b) => a + b)
            .toFixed(Constant.NumberOfDecimalPlace);

        let totalTotalCredited = (Array.isArray(listOfSundry) && listOfSundry.length > 0 ? listOfSundry.map(p => taxIncluded ? p.TotalAmtCreditedTaxInclusive : p.TotalAmtCreditedTaxExclusive) : [0])
            .reduce((a, b) => a + b)
            .toFixed(Constant.NumberOfDecimalPlace);
        configs["summary"] = () => (
            <Table.Summary.Row>
                <Table.Summary.Cell>
                    <Text className="table-summary-value">{t('orders_management.total')}</Text>
                </Table.Summary.Cell>
                <Table.Summary.Cell></Table.Summary.Cell>
                <Table.Summary.Cell>
                    <Text className="table-summary-value">{totalTotalPrice}</Text>
                </Table.Summary.Cell>
                <Table.Summary.Cell>
                    <Text className="table-summary-value">{totalTotalCredited}</Text>
                </Table.Summary.Cell>
            </Table.Summary.Row>
        );
        return configs;
    }

    useEffect(() => {
        let timeoutFn = setTimeout(() => document.documentElement.style.setProperty(Constant.CssVariables.FixItemsContainerHeight, CommonService.calculateTableBodyMaxHeight()), 100);
        if (!orderTypes || orderTypes.length === 0) {
            showLoading();
            BuyerService.getOrderTypes()
                .then(result => {
                    if (result.data) {
                        setOrderTypes(result.data)
                    }
                })
                .finally(() => {
                    dismissLoading();
                })
                .catch(error => CommonService.handleErrorResponse(error))
        }
        let sot = orderTypes.find(p => p.Code === location.state.SalesOrderType);
        setPickslipInfo({
            "PickslipDate": location.state.PickslipDate,
            "OrderType": sot ? sot.Description : null,
            "PickslipStatusCode": location.state.PickslipStatusCode,
            "PickslipNo": location.state.PickslipNo,
            "OrderNo": location.state.DebtorOrderNo
        });
        let authData = StorageService.getAuthData()
        if (authData) {
            setTaxIncluded(authData.isShowPriceTaxIncluded)
        }

        showLoading();
        Promise.all([
            OrderManagementService.getOrderPartLines(location.state.PickslipKey),
            OrderManagementService.getOrderSundryLines(location.state.PickslipKey)
        ]).then(r => {
            setListOfPart(r[0].data);
            setListOfSundry(r[1].data);
        }).finally(() => {
            dismissLoading();
        });
        return () => {
            OrderManagementService.cancelRequest()
            clearTimeout(timeoutFn);
        };
    }, []);

    if (!location.state) {
        return <Redirect to={Constant.Pages.OrderManagement} />
    }

    const onChangeTabs = tabKey => { }

    const _componentPickslipInfo = () => {
        return (
            <Row className="m-t-25" id={Constant.FixItemsContainerId} gutter={[Constant.SpaceConstant.HorizontalGutter, 5]}>
                <Col xs={{ span: 4 }}>
                    <label className="title-field">{t("orders_management.pickslip_date")}</label>
                    <DateTimeCell className="text-field" value={pickslipInfo.PickslipDate} />
                </Col>
                <Col xs={{ span: 4 }}>
                    <label className="title-field">{t("orders_management.pickslip_no")}</label>
                    <label className="text-field">{pickslipInfo?.PickslipNo || '_'}</label>
                </Col>
                <Col xs={{ span: 4 }}>
                    <label className="title-field">{t("orders_management.order_no")}</label>
                    <label className="text-field">{pickslipInfo?.OrderNo || '_'}</label>
                </Col>
                <Col xs={{ span: 4 }}>
                    <label className="title-field">{t("orders_management.order_type")}</label>
                    <label className="text-field">{pickslipInfo?.OrderType || '_'}</label>
                </Col>
                <Col xs={{ span: 4 }}>
                    <label className="title-field">{t("orders_management.status")}</label>
                    <StatusCell data={pickslipInfo}
                        colorClass={CommonService.getPickslipStatusColorClass(pickslipInfo.PickslipStatusCode)}
                        description={CommonService.getPickslipStatusDescription(pickslipInfo.PickslipStatusCode)} />
                </Col>
                <Col xs={{ span: 4 }}>
                    {
                        (location.state.downloadDocuments && location.state.downloadDocuments.length > 0 && CommonService.dmsVersionGreaterOrEqual(Constant.DmsVersion.version_1_36_18_0)) 
                            &&  
                            // <Tooltip placement="bottom" title={t("common.download_document")}>
                            //     <Button icon={<DownloadIcon />} style={{ float: "left" }} className={`m-l-5 action-btn`} onClick={() => { openDownloadDocument(); }} />
                            // </Tooltip>  
                            <ActionButtonsCell justifyContentStart={true} btnList={[
                                {
                                    Description: t("common.download_document"),
                                    Icon: <DownloadIcon />,
                                    Hide: false,
                                    ColorClass: Constant.ColorClass.LightBlue,
                                    NoMargin: true,
                                    handleAction: openDownloadDocument
                                }
                            ]} />
                    }
                </Col>
            </Row>
        );
    }

    const handleCancelPartEnquiry = () => {
        setVisiblePartEnquiry(false);
    }

    const handleCancelCreditNotes = () => {
        setVisibleCreditNotes(false);
    }

    const openDownloadDocument = () => {
        setVisibleDownloadDocumentModal(true);
    }

    const cancelDownloadDocumentModal = () => {
        setVisibleDownloadDocumentModal(false);
    }

    return (
        <div className="pickslip-details-page">
            <Breadcrumb className="has-child">
                <Breadcrumb.Item onClick={() => {
                        history.replace(Constant.Pages.PartOrderHistory, 
                        {
                            ...location.state.prevState,
                            encryptKey: location.state.encryptKey, 
                            mediaServiceHostName: location.state.mediaServiceHostName
                        }) 
                    }}>
                    <span className='back-icon'><ChevronLeftIcon /></span>
                    <span>{t("menu.part_order_history")}</span>
                </Breadcrumb.Item>
                <Breadcrumb.Item>{t("part_order_history.view")}</Breadcrumb.Item>
            </Breadcrumb>
            {_componentPickslipInfo()}
            <Tabs defaultActiveKey="1" onChange={onChangeTabs}>
                <TabPane tab={t("orders_management.parts")} key="parts">
                    <Row className="m-t-25">
                        <Col xs={{ span: 24 }}>
                            <Table {..._getTableConfigs_Parts()} />
                        </Col>
                    </Row>
                </TabPane>
                <TabPane tab={t("orders_management.sundries")} key="sundries">
                    <Row className="m-t-25">
                        <Col xs={{ span: 24 }}>
                            <Table {..._getTableConfigs_Sundries()} />
                        </Col>
                    </Row>
                </TabPane>
            </Tabs>
            {visibleCreditNotes &&
                <Modal
                    className="pickslip-details-page"
                    title={t("orders_management.credit_notes_for_part") + " " + partNumber}
                    visible={true}
                    confirmLoading={false}
                    onCancel={handleCancelCreditNotes}
                    maskClosable={false}
                    footer={null}
                    width={1300}
                    centered
                >
                    <Table columns={columns_credit_notes} dataSource={creditNotesItems} rowKey="PickslipNo" pagination={false}
                        summary={() => {
                            let totalQtyInvoiced = 0, totalQtyCredited = 0, totalCreditedAmount = 0;
                            creditNotesItems.forEach(e => {
                                totalQtyInvoiced += e.QtyInvoiced;
                                totalQtyCredited += e.TotalQtyCredited;
                                totalCreditedAmount += e.TotalAmtCredited;
                            });

                            return (
                                <Table.Summary.Row>
                                    <Table.Summary.Cell>
                                        <Text className="table-summary-value">{t('orders_management.total')}</Text>
                                    </Table.Summary.Cell>
                                    <Table.Summary.Cell className="text-center">
                                        <Text className="table-summary-value">{totalQtyInvoiced}</Text>
                                    </Table.Summary.Cell>
                                    <Table.Summary.Cell className="text-center">
                                        <Text className="table-summary-value">{totalQtyCredited}</Text>
                                    </Table.Summary.Cell>
                                    <Table.Summary.Cell className="text-center">
                                        <Text className="table-summary-value">{totalCreditedAmount}</Text>
                                    </Table.Summary.Cell>
                                    <Table.Summary.Cell></Table.Summary.Cell>
                                </Table.Summary.Row>
                            );
                        }}
                    />
                </Modal>
            }
            {visibleCreditOutstanding &&
                <Modal
                    className="pickslip-details-page"
                    title={t("orders_management.credit_outstanding_for_part") + " " + partNumber}
                    visible={true}
                    confirmLoading={false}
                    onCancel={handleCancelCreditNotes}
                    maskClosable={false}
                    footer={null}
                    width={1300}
                    centered
                >
                    <Table columns={columns_credit_outstanding} dataSource={creditOutstandingItems} rowKey="PickslipNo" pagination={false}
                        summary={() => {
                            let totalQtyCredited = 0, totalCreditedAmount = 0;
                            creditOutstandingItems.forEach(e => {
                                totalQtyCredited += e.TotalQtyCredited;
                                totalCreditedAmount += e.TotalAmtCredited;
                            });

                            return (
                                <Table.Summary.Row>
                                    <Table.Summary.Cell>
                                        <Text className="table-summary-value">{t('orders_management.total')}</Text>
                                    </Table.Summary.Cell>
                                    <Table.Summary.Cell></Table.Summary.Cell>
                                    <Table.Summary.Cell></Table.Summary.Cell>
                                    <Table.Summary.Cell className="text-center">
                                        <Text className="table-summary-value">{totalQtyCredited}</Text>
                                    </Table.Summary.Cell>
                                    <Table.Summary.Cell className="text-center">
                                        <Text className="table-summary-value">{totalCreditedAmount}</Text>
                                    </Table.Summary.Cell>
                                </Table.Summary.Row>
                            );
                        }}
                    />
                </Modal>
            }
            {visibleDownloadDocumentModal &&
                <Modal
                    className="pickslip-details-page"
                    title={t("common.download_document")}
                    visible={true}
                    confirmLoading={false}
                    onCancel={cancelDownloadDocumentModal}
                    maskClosable={false}
                    footer={null}
                    width={768}
                    centered
                >
                    <Row>
                        <Col xs={{ span: 24 }}>
                            <Table className="custom-table" columns={CommonService.getDownloadDocumentColumns(location.state.mediaServiceHostName, location.state.encryptKey, showLoading, dismissLoading)}
                                dataSource={location.state.downloadDocuments}
                                rowKey="$id"
                                pagination={false} scroll={{ y: 300 }} />
                        </Col>
                    </Row>
                </Modal>
            }
        </div>
    );
}