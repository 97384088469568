import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Avatar, Button, Dropdown, Layout, Menu, Row, Tag, Col } from 'antd';
import { useHistory } from 'react-router-dom';
import {Constant, StorageService} from '../../services/services'
import PubSub from 'pubsub-js'
import Profile from './../../components/profile/profile'


const UserDropdownOverlay = (props) => {
    const { t } = useTranslation();
    let history = useHistory();
    const [visibleModal, setVisibleModal] = useState(false)

    const logout = () => {
        PubSub.publish(Constant.PubSupType.UserLogout, Constant.PubSupType.UserLogout);
        // StorageService.removeAuthData();
        // StorageService.removeLocalData();
        // history.push(Constant.Pages.Landing);
    }

    const showUserInfo = () => {
        setVisibleModal(true)
    }

    const handleCancel = () => {
        setVisibleModal(false)
        props.loadAvartar();

    };

    const menuItemsClick = (code) => {
        if(code == Constant.UserActions.Logout){
            logout()
        }
        else if(code == Constant.UserActions.VewProfile){
            showUserInfo()
        }
    }

    const getUserInfo = () => {
        let authData = StorageService.getAuthData()
        if(authData){
            return {
                Username: authData.userName,
                TradingName: authData.tradingName,
                AccountType: authData.accountType,
                SupplierId: authData.supplierId,
                Email: authData.email,
                ContactName: authData.contactName,
                PhoneNumber: authData.phoneNumber,
                AvatarUrl: authData.avatarUrl,
                AvatarBase64: authData.avatarBase64,
                IsShowPriceTaxIncluded: authData.isShowPriceTaxIncluded
            }
        }
    }

    return (
        <div>
            <Menu className="user-info-dropdown">
                {props.userActions.map((item, i) => {
                    return (
                        <Menu.Item key={i} onClick={() => menuItemsClick(item.Code)}>
                            <span className="icon">{item.Icon}</span>
                            <span className="text">{item.Description}</span>
                        </Menu.Item>)
                })}
            </Menu>
            {
                visibleModal && <Profile handleCancel={handleCancel} objectData={getUserInfo()} />
            }
        </div>

    )
}

export default UserDropdownOverlay