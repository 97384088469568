import React from 'react';
import { Constant, StorageService, AuthService, MediaService } from "./services"
import { toast } from 'react-toastify';
import i18n from '../i18n';
import { notification } from 'antd';
import { Typography } from 'antd';
import { DateTimeCell } from '../components/utils/gridUtils/gridUtils';

const { Text } = Typography;

export const CommonService = {

    presentToast: function (type, message) {
        notification[type]({
            message: 'Notification',
            description: message,

        });
    },
    handleErrorResponse: function (error) {
        if (error.message === 'Operation canceled.') {
            return
        }
        if (!error.response || error.response.status === 500) {
            this.presentToast('error', i18n.t('common.common_server_error'))
            return
        }
        if (error.response.status === 400) {
            if (error.response.data.errors) {
                let msg = ""
                for (var prop in error.response.data.errors) {
                    msg += error.response.data.errors[prop][0] + ";"
                }
                this.presentToast('error', msg)
            } else if (error.response.data.error_description) {
                this.presentToast('error', error.response.data.error_description)
            } else if (error.response.data.Value) {
                this.presentToast('error', error.response.data.Value)
            }
            else {
                this.presentToast('error', error.response.data)
            }
        }
    },
    getDateString: function (date) {
        if (date) {
            date = new Date(date)
            let day = date.getDate()
            if (day.toString().length == 1) {
                day = "0" + day
            }
            let month = date.getMonth() + 1
            if (month.toString().length == 1) {
                month = "0" + month
            }
            let value = day + '/' + month + '/' + date.getFullYear()
            return value
        }
        return ''
    },
    getTimeString: function (date) {
        if (date) {
            date = new Date(date)
            let hour = date.getHours()
            if (hour.toString().length == 1) {
                hour = "0" + hour
            }

            let minute = date.getMinutes()
            if (minute.toString().length == 1) {
                minute = "0" + minute
            }

            let value = hour + ':' + minute
            return value
        }
        return ""
    },
    getSortString: function (sortObject) {
        if (!sortObject || !sortObject.column)
            return ''
        return `${sortObject.field}-${sortObject.order}`
    },
    getFirstCharUsername: function (username) {
        if (username)
            return username.substring(0, 1)
    },

    getFilterList: function (filters) {
        let result = []
        if (filters) {
            for (var item in filters) {
                if (filters[item] && filters[item])
                    result.push({
                        Field: item,
                        Values: filters[item]
                    })
            }
        }
        return result
    },
    calculateScrollMaxHeightModal: function (gridId) {
        let modalElement = document.getElementsByClassName("ant-modal");
        let headerHeight = 0, footerHeight = 0, gridheight = 0
        if (modalElement && modalElement.length > 0) {
            let modal = modalElement[0].children
            for (let i = 0; i < modal.length; i++) {
                if (modal[i].className == "ant-modal-content") {
                    for (let j = 0; j < modal[i].children.length; j++) {
                        if (modal[i].children[j].className == "ant-modal-header") {
                            headerHeight = (modal[i].children[j]).offsetHeight
                        }
                        else if (modal[i].children[j].className == "ant-modal-footer") {
                            footerHeight = (modal[i].children[j]).offsetHeight
                        }
                    }
                }
            }
        }

        let gridElement = document.getElementById(gridId)
        if (gridElement) {
            gridheight = gridElement.offsetHeight
        }
        return headerHeight + footerHeight + gridheight + 50 + "px"
    },
    calculateTableBodyMaxHeight: function (fixItemsConstainerId = Constant.FixItemsContainerId, customOffset = 0, tableFooterHeight = 45) {
        let tableHeaderHeight = 0, fixItemsContainerHeight = 0
        let headingElement = document.getElementsByClassName("ant-table-header");
        if (headingElement) {
            tableHeaderHeight = headingElement[0].offsetHeight
        }

        let fixItemsElement = document.getElementById(fixItemsConstainerId)
        if (fixItemsElement) {
            fixItemsContainerHeight = fixItemsElement.offsetHeight + customOffset
        }
        return tableHeaderHeight + tableFooterHeight + fixItemsContainerHeight + 140 + "px"
    },
    getStatusColorClass: function (code) {
        if (code == Constant.StatusCode.Active)
            return Constant.ColorClass.LightGreen
        return Constant.ColorClass.LightOrange
    },
    getStatusDescription: function (code, showYesNo, activeText = "", inactiveText = "") {
        if (code == Constant.StatusCode.Active)
            return showYesNo ? i18n.t("common.yes") : (activeText ? activeText : i18n.t("common.active"))
        return showYesNo ? i18n.t("common.no") : (inactiveText ? inactiveText : i18n.t("common.inactive"))
    },
    getEcommerceCreatingStatusColorClass: function (code) {
        if (code == Constant.EcommerceCreatingStatusCode.Creating) {
            return Constant.ColorClass.LightGreen
        }
        else if (code == Constant.EcommerceCreatingStatusCode.Locking) {
            return Constant.ColorClass.LightOrange
        }
        return Constant.ColorClass.LightBlue
    },
    getEcommerceCreatingStatusDescription: function (code) {
        if (code == Constant.EcommerceCreatingStatusCode.Creating) {
            return i18n.t("common.creating")
        }
        else if (code == Constant.EcommerceCreatingStatusCode.Locking) {
            return i18n.t("common.locking")
        }
        return i18n.t("common.completed")
    },
    getUsernameLogin: function () {
        let authData = StorageService.getAuthData()
        if (authData)
            return authData.tradingName
        return ""
    },
    getFirstCharUsername: function () {
        let username = this.getUsernameLogin()
        if (username)
            return username.substring(0, 1)
        return ""
    },
    getAvartar: function () {
        let authData = StorageService.getAuthData()
        if (authData && authData.avatarUrl)
            return authData.avatarUrl
        return ""
    },
    isSupplierAccount: function () {
        let authData = StorageService.getAuthData()
        if (authData && Number(authData.accountType) == Constant.AccountType.Supplier)
            return true
        return false
    },
    getRequestStatusColorClass: (code) => {
        if (code === Constant.AccountRequestStatusCode.Approved ||
            code === Constant.AccountRequestStatusCode.PendingForActivation ||
            code === Constant.AccountRequestStatusCode.PendingForDeactivation)
            return Constant.ColorClass.LightGreen
        else if (code === Constant.AccountRequestStatusCode.Rejected)
            return Constant.ColorClass.LightOrange
        else
            return Constant.ColorClass.LightBlue
    },
    getRequestStatusDescription: (code) => {
        if (code === Constant.AccountRequestStatusCode.Approved ||
            code === Constant.AccountRequestStatusCode.PendingForActivation ||
            code === Constant.AccountRequestStatusCode.PendingForDeactivation)
            return i18n.t("common.approved")
        else if (code === Constant.AccountRequestStatusCode.Rejected)
            return i18n.t("common.rejected")
        else if (code === Constant.AccountRequestStatusCode.PendingForApproval)
            return i18n.t("common.pending")
        else
            return ''
    },
    getImgBase64: (img, callback) => {
        const isValidImgType = (img.type === 'image/jpeg' || img.type === 'image/png' || img.type === 'image/gif');
        if (!isValidImgType) {
            CommonService.presentToast('warning', i18n.t("landing_page.upload_image_message"))
            return false;
        }
        const isLimit = img.size / 1024 / 1024 < 5;
        if (!isLimit) {
            CommonService.presentToast('warning', i18n.t("landing_page.limit_file_message"))
            return false;
        }
        const reader = new FileReader();
        reader.addEventListener('load', () => callback(reader.result));
        reader.readAsDataURL(img);
    },
    amountFormat: (amount, emptyText = "_") => {
        if (amount != null && amount != undefined) {
            return `${(Math.round(amount * 100) / 100).toFixed(Constant.NumberOfDecimalPlace)}`
        }
        return emptyText
    },
    getPageNameByPageUrl: (url) => {
        var result = ""
        if (url) {
            switch (url) {
                case Constant.Pages.SupplierManagement:
                    result = i18n.t('menu.supplier_management')
                    break;
                case Constant.Pages.PartOrder:
                    result = i18n.t('menu.part_order')
                    break;
                case Constant.Pages.RequestCreditReturn:
                    result = i18n.t('menu.request_credit_return')
                    break;
                default:
                    break
            }
        }
        return result
    },
    calculateAmmount(unitAmount, quantity) {
        let total = parseFloat((unitAmount * quantity).toFixed(4))
        let sign = Math.sign(total);
        let scale = Math.pow(10.0, 2);
        let round = Math.floor(Math.abs(total) * scale + 0.5);
        return (sign * round / scale);
    },
    getDateString: function (date) {
        if (date) {
            date = new Date(date)
            let day = date.getDate()
            if (day.toString().length == 1) {
                day = "0" + day
            }
            let month = date.getMonth() + 1
            if (month.toString().length == 1) {
                month = "0" + month
            }
            let value = day + '/' + month + '/' + date.getFullYear()
            return value
        }
        return ''
    },
    getTimeString: function (date) {
        if (date) {
            date = new Date(date)
            let hour = date.getHours()
            if (hour.toString().length == 1) {
                hour = "0" + hour
            }

            let minute = date.getMinutes()
            if (minute.toString().length == 1) {
                minute = "0" + minute
            }

            let value = hour + ':' + minute
            return value
        }
        return ""
    },
    getPickslipStatusColorClass: (statusCode) => {
        if (statusCode) {
            if (statusCode === Constant.PartOrderStatus.Active)
                return Constant.ColorClass.LightBlue
            else if (statusCode === Constant.PartOrderStatus.FullyInvoiced)
                return Constant.ColorClass.LightGreen;
            else if (statusCode === Constant.PartOrderStatus.PartiallyInvoiced)
                return Constant.ColorClass.LightYellow;
            else if (statusCode === Constant.PartOrderStatus.Delete)
                return Constant.ColorClass.LightOrange;
        }
        return "";
    },
    getPickslipStatusDescription: (statusCode) => {
        if (statusCode) {
            if (statusCode === Constant.PartOrderStatus.Active)
                return i18n.t("orders_management.active");
            else if (statusCode === Constant.PartOrderStatus.FullyInvoiced)
                return i18n.t("orders_management.fully_invoiced");
            else if (statusCode === Constant.PartOrderStatus.PartiallyInvoiced)
                return i18n.t("orders_management.partially_invoiced");
            else if (statusCode === Constant.PartOrderStatus.Delete)
                return i18n.t("orders_management.delete");
        }
        return "";
    },
    getBrowerIcon: (dealershipThemeId) => {
        const favicon = document.getElementById("brower_icon");; // Accessing favicon element
        if (favicon && dealershipThemeId) {
            if (dealershipThemeId == Constant.DealershipThemeId.Blue)
                favicon.href = process.env.PUBLIC_URL + Constant.SvgLogoPath.BlueLogo;
            else if (dealershipThemeId == Constant.DealershipThemeId.Orange)
                favicon.href = process.env.PUBLIC_URL + Constant.SvgLogoPath.OrangeLogo;
            else if (dealershipThemeId == Constant.DealershipThemeId.Red)
                favicon.href = process.env.PUBLIC_URL + Constant.SvgLogoPath.RedLogo;
            else if (dealershipThemeId == Constant.DealershipThemeId.Black)
                favicon.href = process.env.PUBLIC_URL + Constant.SvgLogoPath.BlackLogo;
            else if (dealershipThemeId == Constant.DealershipThemeId.Gray)
                favicon.href = process.env.PUBLIC_URL + Constant.SvgLogoPath.GrayLogo;
            else {
                favicon.href = process.env.PUBLIC_URL + Constant.SvgLogoPath.GreenLogo;
            }
        }
        else {
            favicon.href = process.env.PUBLIC_URL + Constant.SvgLogoPath.GreenLogo;
        }
    },
    setDealerStyle: function (theme) {
        if (theme) {
            if (theme.PrimaryColor) {
                document.documentElement.style.setProperty("--color-primary", theme.PrimaryColor)
            }
            if (theme.IconColor) {
                document.documentElement.style.setProperty("--icon-color", theme.IconColor)
            }
            if (theme.IconBackgroundColor) {
                document.documentElement.style.setProperty("--icon-background-color", theme.IconBackgroundColor)
            }
            if (theme.MenuItemColor) {
                document.documentElement.style.setProperty("--menu-item-color", theme.MenuItemColor)
            }
            if (theme.MenuItemBackgroundColor) {
                document.documentElement.style.setProperty("--menu-item-background-color", theme.MenuItemBackgroundColor)
            }
            if (theme.TableHeaderTextColor) {
                document.documentElement.style.setProperty("--table-header-text-color", theme.TableHeaderTextColor)
            }
            if (theme.CardTitleTextColor) {
                document.documentElement.style.setProperty("--card-title-text-color", theme.CardTitleTextColor)
            }
            if (theme.TableFooterTextColor) {
                document.documentElement.style.setProperty("--table-footer-text-color", theme.TableFooterTextColor)
            }
            if (theme.SelectedItemDropdownBackgroundColor) {
                document.documentElement.style.setProperty("--selected-item-dropdown-background-color", theme.SelectedItemDropdownBackgroundColor)
            }
            if (theme.BadgeBackgroundColor) {
                document.documentElement.style.setProperty("--badge-background-color", theme.BadgeBackgroundColor)
            }
            if (theme.BadgeTextColor) {
                document.documentElement.style.setProperty("--badge-text-color", theme.BadgeTextColor)
            }
            if (theme.TableHeaderHighlightTextColor) {
                document.documentElement.style.setProperty("--table-header-highlight-text-color", theme.TableHeaderHighlightTextColor)
            }
            this.getBrowerIcon(theme.DealershipThemeId)

        } else {
            document.documentElement.style.setProperty("--color-primary", "#009344")
            document.documentElement.style.setProperty("--icon-color", "#006738")
            document.documentElement.style.setProperty("--icon-background-color", "#E8F4ED")
            document.documentElement.style.setProperty("--menu-item-color", "#006738")
            document.documentElement.style.setProperty("--menu-item-background-color", "#E8F4ED")
            document.documentElement.style.setProperty("--table-header-text-color", "#006738")
            document.documentElement.style.setProperty("--card-title-text-color", "#216A98")
            document.documentElement.style.setProperty("--table-footer-text-color", "#216A98")
            document.documentElement.style.setProperty("--selected-item-dropdown-background-color", "#E8F4ED")
            document.documentElement.style.setProperty("--badge-background-color", "#ff4d4f")
            document.documentElement.style.setProperty("--badge-text-color", "#ffffff")
            document.documentElement.style.setProperty("--table-header-highlight-text-color", "#b96712")
            this.getBrowerIcon()
        }

    },
    applyDealerTheme: function (supplierId) {
        if (supplierId) {
            var theme = StorageService.getSupplierTheme(supplierId)
            if (theme) {
                this.setDealerStyle(theme)
            }
            AuthService.getDealershipTheme(supplierId)
                .then(result => {
                    if (result && result.data) {
                        this.setDealerStyle(result.data)
                        StorageService.setSupplierTheme(supplierId, result.data)
                    } else {
                        this.setDealerStyle(null)
                        StorageService.removeSupplierTheme(supplierId)
                    }
                })
        }
    },
    allowToAccessFunction: (code) => {
        let authData = StorageService.getAuthData();
        if (authData &&
            Array.isArray(authData.functionCodes) &&
            (authData.functionCodes.filter(d => d === code).length > 0)) {
            return true
        }
        return false
    },
    getSupplierLogoUrl: function () {
        let authData = StorageService.getAuthData()
        if (authData && authData.supplierLogoUrl)
            return authData.supplierLogoUrl
        return ""
    },
    calcButtonGroupIdWidth: function () {
        let element = document.getElementById(Constant.ButtonGroupId);
        if (element) {
            return element.offsetWidth + 20 + "px"
        }

        return "250px"
    },
    trimSpacesAndUppercaseText: function (value, isTrimValue, isUppercase) {
        if (value) {
            value = value.toString()
            if (isTrimValue) {
                value = value.trim()
            }
            if (isUppercase) {
                value = value.toUpperCase()
            }
            return value
        }
        return ''
    },
    dmsVersionGreaterOrEqual: (version) => {
        let authData = StorageService.getAuthData()
        let arrDmsVersion = authData.dmsVersion.split(".").map(p => parseInt(p));
        let arrVersion = version.split(".").map(p => parseInt(p));
        if(arrDmsVersion.length !== arrVersion.length) {
            return false; //Can't compare dmsVersion & version
        }
        let isGreaterEqual = false;
        for(let i = 0; i <= arrDmsVersion.length; i++) {
            if(arrDmsVersion[i] > arrVersion[i]) {
                isGreaterEqual = true;
                break;
            } else if(arrDmsVersion[i] === arrVersion[i]) {
                isGreaterEqual = true;
            } else {
                isGreaterEqual = false;
                break;
            }
        }
        return isGreaterEqual;
    },
    getDownloadDocumentItem: function (type, number, date, key) {
        let _docType = "";
        if(type === Constant.DownloadPdfDocumentType.Invoice) {
            _docType =  i18n.t('common.download_document_invoice');
        } else if(type === Constant.DownloadPdfDocumentType.CreditNote) {
            _docType =  i18n.t('common.download_document_credit_note');
        } else if(type === Constant.DownloadPdfDocumentType.CreditRequest) {
            _docType =  i18n.t('common.download_document_credit_request');
        }
        return {
            DocumentTypeCode: type,
            DocumentType: _docType,
            DocumentNumber: number,
            DocumentDate: date,
            Key: key
        }
    },
    getDownloadDocumentColumns : function (mediaServiceHostName, encryptKey, showLoading, dismissLoading) {
        return [
            {
                "title": i18n.t("common.document_type"),
                "dataIndex": "DocumentType",
                "key": "DocumentType",
                "width": 200
            }, {
                "title": i18n.t("common.document_number"),
                "dataIndex": "DocumentNumber",
                "key": "DocumentNumber",
                "width": 200,
                render: (value, record) => {
                    return record.DocumentNumber 
                        ? <Text className="download-document-link" 
                            onClick={()=>{
                                MediaService.downloadPdfDocument(
                                    record.DocumentTypeCode,
                                    record.DocumentNumber,
                                    record.Key,
                                    mediaServiceHostName,
                                    encryptKey,
                                    showLoading, 
                                    dismissLoading
                                );
                            }}>{record.DocumentNumber}</Text>
                        : <></>;
                }
            },
            {
                "title": i18n.t("common.document_date"),
                "dataIndex": "DocumentDate",
                "key": "DocumentDate",
                "width": 200,
                render: (value, record) => (
                    <DateTimeCell value={record.DocumentDate} />
                ),
            }
        ];
    },
    orderByDownloadDocuments: function(record) {
        record.DocumentItems.forEach((p, i)=>p.$id = i);
        record.DocumentItems.sort((a, b) => {
            let dateA = a.DocumentDate;
            let dateB = b.DocumentDate;
            if (dateA < dateB) { return -1; }
            if (dateA > dateB) { return 1; }
        
            let numA = a.DocumentNumber;
            let numB = b.DocumentNumber;
            if (numA < numB) { return -1; }
            if (numA > numB) { return 1; }
            return 0;
        });
        return record.DocumentItems;
    },
    getPartOrderDocumentInfo: function(_objectList) {
        _objectList.forEach(obj => {
            let _documentItems = [];
            let _isCreditPickslip = false;
            if(obj.TotalPartsOrdered < 0 && (obj.TotalAmountOrderedTaxExclusive < 0 || obj.TotalAmountOrderedTaxInclusive < 0)){
                _isCreditPickslip = true;
            }
            if(obj.DocumentInfo && Array.isArray(obj.DocumentInfo)){
                obj.DocumentInfo.forEach(doc => {
                    if(_isCreditPickslip) {
                        if(doc.InvoiceNo) {
                            let _foundIdx = _documentItems.findIndex(p => p.DocumentNumber === doc.InvoiceNo);
                            if(_foundIdx < 0){
                                _documentItems.push(CommonService.getDownloadDocumentItem(Constant.DownloadPdfDocumentType.CreditNote, doc.InvoiceNo, doc.InvoiceDate, doc.DebtorTranKey));
                            }
                        }
                        if(doc.OriginalInvoiceNo) {
                            let _foundIdx = _documentItems.findIndex(p => p.DocumentNumber === doc.OriginalInvoiceNo);
                            if(_foundIdx < 0){
                                _documentItems.push(CommonService.getDownloadDocumentItem(Constant.DownloadPdfDocumentType.Invoice, doc.OriginalInvoiceNo, doc.OriginalInvoiceDate, doc.OriginalDebtorTranKey));
                            }
                        }
                    } else {
                        if(doc.InvoiceNo) {
                            let _foundIdx = _documentItems.findIndex(p => p.DocumentNumber === doc.InvoiceNo);
                            if(_foundIdx < 0){
                                _documentItems.push(CommonService.getDownloadDocumentItem(Constant.DownloadPdfDocumentType.Invoice, doc.InvoiceNo, doc.InvoiceDate, doc.DebtorTranKey));
                            }
                        }
                        if(doc.OriginalInvoiceNo) {
                            let _foundIdx = _documentItems.findIndex(p => p.DocumentNumber === doc.OriginalInvoiceNo);
                            if(_foundIdx < 0){
                                _documentItems.push(CommonService.getDownloadDocumentItem(Constant.DownloadPdfDocumentType.CreditNote, doc.OriginalInvoiceNo, doc.OriginalInvoiceDate, doc.OriginalDebtorTranKey));
                            }
                        }
                    }
                });
            }
            _documentItems.sort((p1, p2) => (p1.DocumentTypeCode - p2.DocumentTypeCode));
            obj.DocumentItems = _documentItems;
        });
        return _objectList;
    }
}