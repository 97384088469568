import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import {
    Constant,
    CommonService,
    BuyerService,
    DealershipService,
    StorageService
} from './../../../services/services'
import { Table, Button, Row, Col, Select, Form, Input, Tabs, Modal, Breadcrumb, DatePicker } from 'antd';
import LoadingContext from './../../../components/utils/loading/loadingContext'
import { GetTableConfigs, AmountCell, EditableCell, ModalButtonCell, TextAndButtonCell } from './../../../components/utils/gridUtils/gridUtils'
import { useLocation, useHistory } from "react-router-dom";
import { FileTextIcon, ChevronLeftIcon, ViewIcon, EditIcon } from './../../../components/icons/icons'
import { useGlobalState } from '../../../utilities/globalState'

const layout = {
    labelCol: { span: 24 },
    wrapperCol: { span: 24 }
};
const { Option } = Select;

const EditRequestCreditReturn = (props) => {
    const { t } = useTranslation();
    const [form] = Form.useForm();
    const { showLoading, dismissLoading } = React.useContext(LoadingContext)
    const [partData, setPartData] = useState([])
    const [originalPartData, setOriginalPartData] = useState([])
    const [creditReasonIsDamaged, setCreditReasonIsDamaged] = useState(false);
    const [disableRequestReturnWholeInvoice, setDisableRequestReturnWholeInvoice] = useState(false);
    const [visibleConfirmReturnWholeParts, setVisibleConfirmReturnWholeParts] = useState(false);
    const [gridConfigOptions, setGridConfigOptionsValue] = useState({
        pageNumber: 1,
        pageSize: Constant.PageSize,
        sort: ""
    });

    const [taxIncluded, setTaxIncluded] = useState(true)
    const [totalRowsData, setTotalRowsData] = useState({
        TotalQtyInvoiced: 0,
        TotalQtyCredited: 0,
        TotalQtyOutstanding: 0,
        TotalQtyRequest: 0,
        TotalInvoiceAmtRequestTaxExclusive: 0,
        TotalInvoiceAmtRequestTaxInclusive: 0
    });

    const [creditNoteReasonCodes, setCreditNoteReasonCodes] = useGlobalState('creditNoteReasonCodes')

    const history = useHistory();
    const location = useLocation();
    const params = location.state;
    const backToPreviousPage = () => {

        history.replace(params.PreviousUrl, params)
    }

    const onQtyRequestChange = (value, record) => {
        if (record) {
            record.QtyRequest = value;
            let tempList = [...partData]
            tempList[record.Sequence] = { ...record }
            setTotalValues(tempList);

            let existsSelectedParts = partData.some(x => x.QtyRequest);
            setDisableRequestReturnWholeInvoice(existsSelectedParts);
        }
    }

    const onCreditCommentChange = (value, record) => {
        if (record) {
            record.CreditComment = value.CreditComment;
            let tempList = [...partData]
            tempList[record.Sequence] = { ...record }
            setPartData(tempList)
        }
    }

    const columns = [
        {
            title: t('request_credit_return.pickslip_line_id'),
            dataIndex: 'PickslipLineID',
            key: 'PickslipLineID',
            width: 80,
        },
        {
            title: t('part_enquiry.part_number'),
            dataIndex: 'PartItemCode',
            key: 'PartItemCode',
            render: (value, record) => (
                <TextAndButtonCell value={record.PartItemCode} tooltipText={t("common.view_part_details")}
                    buttonType={Constant.ButtonWithModalType.PartEnquiry}
                    data={record} icon={<ViewIcon />} includeWhiteSpaces={true} />
            ),
            width: 250
        },
        {
            title: t('part_enquiry.description'),
            dataIndex: 'PartItemDescription',
            key: 'PartItemDescription',
            width: 230
        },
        {
            title: t('part_enquiry.make'),
            dataIndex: 'MakeID',
            key: 'MakeID',
            width: 150
        },
        {
            title: <div className="text-center" dangerouslySetInnerHTML={{ __html: taxIncluded ? t('request_credit_return.unit_price_inc') : t('request_credit_return.unit_price_exc') }}></div>,
            dataIndex: 'UnitPrice',
            key: 'UnitPrice',
            align: "center",
            width: 120,
            render: (value, record) => (
                <AmountCell value={record.UnitPriceTaxExclusive} valueTaxIncluded={record.UnitPriceTaxInclusive} emptyText="" />
            )
        },
        {
            title: t('request_credit_return.qty_invoiced'),
            dataIndex: 'QtyInvoiced',
            key: 'QtyInvoiced',
            width: 100,
            align: 'center',
        },

        {
            title: t('request_credit_return.qty_returned'),
            dataIndex: 'QtyCredited',
            key: 'QtyCredited',
            width: 100,
            align: 'center',
        },
        {
            title: t('request_credit_return.qty_outstanding'),
            dataIndex: 'QtyOutstanding',
            key: 'QtyOutstanding',
            width: 120,
            align: 'center',
        },
        {
            title: t('request_credit_return.qty_request'),
            dataIndex: 'QtyRequest',
            key: 'QtyRequest',
            editable: true,
            //required: true,
            cellType: Constant.CellType.EditRequestCreditReturnNumberInput,
            width: 120,
            handleOnChange: onQtyRequestChange
        },
        {
            title: <div className="text-center" dangerouslySetInnerHTML={{ __html: taxIncluded ? t('request_credit_return.amount_request_inc') : t('request_credit_return.amount_request_exc') }}></div>,
            dataIndex: 'InvoiceAmtRequest',
            key: 'InvoiceAmtRequest',
            align: 'center',
            width: 120,
            render: (value, record) => (
                <AmountCell value={record.RequestAmtTaxExclusive} valueTaxIncluded={record.RequestAmtTaxInclusive} emptyText="" />
            )
        },
        {
            title: t('request_credit_return.details_reason'),
            dataIndex: 'CreditComment',
            key: 'CreditComment',
            render: (value, record) => (
                <TextAndButtonCell value={record.CreditComment} tooltipText={t("common.edit")}
                    buttonType={Constant.ButtonWithModalType.RequestCreditReturnAddEditDetailsReason}
                    data={record} icon={<EditIcon />}
                    onSubmit={onCreditCommentChange} />
            ),
            width: 170
        }
    ]

    const columnsMapping = columns.map((col) => {
        if (!col.editable) {
            return col;
        }

        return {
            ...col,
            onCell: (record) => ({
                record,
                editable: col.editable,
                dataIndex: col.dataIndex,
                title: col.title,
                required: col.required,
                cellType: col.cellType,
                handleOnChange: col.handleOnChange,
                disableToggleInput: col.disableToggleInput
            }),
        };
    });

    const components = {
        body: {
            cell: EditableCell,
        },
    };

    const reset = () => {
        form.resetFields()
        let temp = originalPartData.map(x => Object.assign({}, x))
        setPartData(temp)
        setTotalValues(temp)
    }

    const requestCreditParts = (data) => {
        showLoading()
        BuyerService.requestCreditParts(data)
            .finally(() => {
                dismissLoading()
            })
            .then(result => {
                if (!result.data) {
                    CommonService.presentToast('success', t('request_credit_return.request_sent_successfull', { invoiceNo: data.InvoiceNo }))
                    backToPreviousPage()
                }
            })
            .catch(error => CommonService.handleErrorResponse(error))

    }

    const getInvoiceObject = (selectedPart, requestWholeParts = false) => {
        var result = { ...params.Item }
        var formData = form.getFieldsValue()
        if (formData) {
            if (formData.CreditReference)
                result.CreditReference = formData.CreditReference
            if (formData.CreditNoteReasonCode)
                result.CreditNoteReasonCode = formData.CreditNoteReasonCode
        }
        selectedPart = selectedPart.map(x => {
            x.CreditNoteReasonCode = (formData && formData.CreditNoteReasonCode) ? formData.CreditNoteReasonCode : null
            if (requestWholeParts) {
                x.QtyRequest = x.QtyOutstanding
                x.RequestAmtTaxExclusive = CommonService.calculateAmmount(x.UnitPriceTaxExclusive, x.QtyOutstanding)
                x.RequestAmtTaxInclusive = CommonService.calculateAmmount(x.UnitPriceTaxInclusive, x.QtyOutstanding)
            }
            return x
        })
        result.PartInvoiceList = selectedPart
        return result

    }

    const checkCreditNoteReasonCodes = (callback) => {
        var formData = form.getFieldsValue();
        if (!formData.CreditNoteReasonCode) {
            CommonService.presentToast('warning', t('request_credit_return.credit_reason_required'))
            return
        }
        showLoading();
        BuyerService.getCreditNoteReasonCodes()
            .finally(() => {
                dismissLoading()
            })
            .then(result => {
                if (result.data) {
                    let exists = result.data.find(d => d.Code === formData.CreditNoteReasonCode);
                    if (exists) {
                        if (callback) callback();
                    } else {
                        CommonService.presentToast('warning', t('request_credit_return.credit_reason_not_allowed'))
                    }
                }
            })
            .catch(error => CommonService.handleErrorResponse(error))
    }

    const validateCreditRequestNo = () => {
        var formData = form.getFieldsValue();
        if (formData && formData.CreditReference) {
            return true
        }
        return false
    }

    const requestReturnSelectedParts = () => {
        if (!validateCreditRequestNo()) {
            CommonService.presentToast('warning', t('outstanding_credit_note.credit_request_no_required'))
            return
        }

        let selectedParts = partData.filter(x => x.QtyRequest != null && x.QtyRequest > 0)
        if (selectedParts.length > 0) {
            let invalidRequestQtyList = selectedParts.filter(x => x.QtyRequest > x.QtyOutstanding)
            if (invalidRequestQtyList.length > 0) {
                CommonService.presentToast('warning', t('request_credit_return.invalid_qty_request'))
                return
            }
            checkCreditNoteReasonCodes(() => {
                if(creditReasonIsDamaged){
                    let emptyDetailsReason = selectedParts.some(x => !x.CreditComment );
                    if(emptyDetailsReason) {
                        CommonService.presentToast('warning', t('request_credit_return.details_reason_required'))
                        return;
                    }
                }
                requestCreditParts(getInvoiceObject(selectedParts, false))
            });
        }
        else {
            CommonService.presentToast('warning', t('request_credit_return.part_require_message'))
        }
    }

    const requestReturnWholeParts = () => {
        setVisibleConfirmReturnWholeParts(true);
    }

    const onRequestReturnWholeParts = () => {
        setVisibleConfirmReturnWholeParts(false);
        if (!validateCreditRequestNo()) {
            CommonService.presentToast('warning', t('outstanding_credit_note.credit_request_no_required'));
            return;
        }
        checkCreditNoteReasonCodes(() => {
            if(creditReasonIsDamaged){
                let emptyDetailsReason = partData.some(x => !x.CreditComment);
                if(emptyDetailsReason) {
                    CommonService.presentToast('warning', t('request_credit_return.details_reason_required'))
                    return;
                }
            }
            requestCreditParts(getInvoiceObject(partData, true));
        });
    }

    const onCancelReturnWholeParts = () => {
        setVisibleConfirmReturnWholeParts(false);
    }

    const getPartInvoiceData = (pickslipKey, invoiceNo, debtorTranKeyList) => {
        showLoading()
        BuyerService.getPartInvoiceData(pickslipKey, invoiceNo, debtorTranKeyList)
            .finally(() => {
                dismissLoading()
            })
            .then(result => {
                if (result.data) {
                    for (let i = 0; i < result.data.length; i++) {
                        result.data[i].Sequence = i
                        result.data[i].QtyRequest = null
                        result.data[i].RequestAmtTaxExclusive = null
                        result.data[i].RequestAmtTaxInclusive = null
                    }
                    var temp = result.data.map(x => Object.assign({}, x))
                    setOriginalPartData(temp)
                    setPartData(result.data)
                    var obj = {
                        TotalQtyInvoiced: result.data.map(p => p.QtyInvoiced).reduce((a, b) => a + b, 0),
                        TotalQtyCredited: result.data.map(p => p.QtyCredited).reduce((a, b) => a + b, 0),
                        TotalQtyOutstanding: result.data.map(p => p.QtyOutstanding).reduce((a, b) => a + b, 0)
                    }
                    setTotalRowsData({
                        ...totalRowsData,
                        ...obj
                    });
                }

            })
            .catch(error => CommonService.handleErrorResponse(error))
    }

    const setTotalValues = (listItems) => {
        var obj = {
            TotalQtyRequest: listItems.filter(p => p.QtyRequest).map(p => p.QtyRequest).reduce((a, b) => a + b, 0),
            TotalInvoiceAmtRequestTaxExclusive: listItems.filter(p => p.RequestAmtTaxExclusive).map(p => p.RequestAmtTaxExclusive).reduce((a, b) => a + b, 0),
            TotalInvoiceAmtRequestTaxInclusive: listItems.filter(p => p.RequestAmtTaxInclusive).map(p => p.RequestAmtTaxInclusive).reduce((a, b) => a + b, 0)
        }
        setTotalRowsData({
            ...totalRowsData,
            ...obj
        });
    }

    useEffect(() => {
        if (params && params.Item.PickslipKey && params.Item.InvoiceNo && params.Item.DebtorTranKeyList) {
            getPartInvoiceData(params.Item.PickslipKey, params.Item.InvoiceNo, params.Item.DebtorTranKeyList)
        }
        let authData = StorageService.getAuthData()
        if (authData) {
            setTaxIncluded(authData.isShowPriceTaxIncluded)
        }
        if (!creditNoteReasonCodes || creditNoteReasonCodes.length == 0) {
            BuyerService.getCreditNoteReasonCodes()
                .then(result => {
                    if (result.data) {
                        setCreditNoteReasonCodes(result.data)
                    }
                })
                .catch(error => CommonService.handleErrorResponse(error))
        }
        return () => {
            BuyerService.cancelRequest()
        }
    }, [])

    useEffect(() => {
        if (params.Item && params.Item.CreditNoteReasonCode) {
            let found = creditNoteReasonCodes.find(p => p.Code?.toString() === params?.Item.CreditNoteReasonCode?.toString());
            if (found) {
                setCreditReasonIsDamaged(found.IsDamaged);
            }
        }
    }, [creditNoteReasonCodes]);

    return (
        <div className="request-credit-return">
            <Breadcrumb className="has-child">
                <Breadcrumb.Item onClick={backToPreviousPage}>
                    <span className='back-icon'><ChevronLeftIcon /></span>
                    <span>{t("menu.request_credit_return")}</span>
                </Breadcrumb.Item>
                <Breadcrumb.Item>{t("common.edit")}</Breadcrumb.Item>
            </Breadcrumb>
            <Row className="m-b-15" id={Constant.FixItemsContainerId} gutter={[Constant.SpaceConstant.HorizontalGutter,]}>
                <Col xs={{ span: 6 }}>
                    <label className="title-field">{t("request_credit_return.invoice_number")}</label>
                    <label className="text-field">{params?.Item?.InvoiceNo || '_'}</label>
                </Col>
                <Col xs={{ span: 6 }}>
                    <label className="title-field">{t("part_order.pickslip_number")}</label>
                    <label className="text-field">{params?.Item?.PickslipNo || '_'}</label>
                </Col>
                <Col xs={{ span: 6 }}>
                    <label className="title-field">{t("part_order.order_number")}</label>
                    <label className="text-field">{params?.Item?.OrderNo || '_'}</label>
                </Col>
                <Col xs={{ span: 6 }}>
                    <label className="title-field">{t("request_credit_return.invoice_date")}</label>
                    <label className="text-field">{CommonService.getDateString(params?.Item?.InvoiceDate) || '_'}</label>
                </Col>
                <Col xs={{ span: 24 }}>
                    <Form
                        {...layout}
                        form={form}
                        initialValues={params?.Item}
                    >
                        <Row align="middle" gutter={[Constant.SpaceConstant.HorizontalGutter,]}>
                            <Col xs={{ span: 6 }}>
                                <Form.Item
                                    label={t("request_credit_return.credit_reason")}
                                    name="CreditNoteReasonCode" colon={false} labelAlign="left"
                                    rules={[{ required: true, message: t("request_credit_return.credit_reason_required") }]}
                                >
                                    <Select showSearch
                                        allowClear={true}
                                        optionFilterProp="children"
                                        onChange={v => {
                                            let found = creditNoteReasonCodes.find(p => p.Code?.toString() === v?.toString());
                                            if (found) {
                                                setCreditReasonIsDamaged(found.IsDamaged);
                                            }
                                        }}
                                        filterOption={(input, option) =>
                                            option?.children.toUpperCase().indexOf(input.toUpperCase()) >= 0
                                        }>
                                        {
                                            creditNoteReasonCodes?.map((n, index) => <Option key={index} value={n.Code}>{n.Description}</Option>)
                                        }
                                    </Select>
                                </Form.Item>
                            </Col>
                            <Col xs={{ span: 6 }}>
                                <Form.Item
                                    label={t("outstanding_credit_note.credit_request_no")}
                                    name="CreditReference" colon={false} labelAlign="left"
                                    rules={[{ required: true, message: t("outstanding_credit_note.credit_request_no_required") }]}
                                >
                                    <Input allowClear />
                                </Form.Item>
                            </Col>
                        </Row>
                    </Form>
                </Col>
            </Row>
            <Row gutter={[, Constant.SpaceConstant.VerticalGutter]}>
                <Col xs={{ span: 24 }}>
                    <Table
                        {...GetTableConfigs(columnsMapping, partData, 0, "PickslipLineID", () => { }, gridConfigOptions.pageNumber, "", false)}
                        components={components}
                        rowClassName={() => 'editable-row'}
                        summary={pageData => {
                            return (
                                <Table.Summary.Row>
                                    <Table.Summary.Cell>{t("common.total")}</Table.Summary.Cell>
                                    <Table.Summary.Cell></Table.Summary.Cell>
                                    <Table.Summary.Cell></Table.Summary.Cell>
                                    <Table.Summary.Cell></Table.Summary.Cell>
                                    <Table.Summary.Cell></Table.Summary.Cell>
                                    <Table.Summary.Cell className="text-center">{totalRowsData?.TotalQtyInvoiced}</Table.Summary.Cell>
                                    <Table.Summary.Cell className="text-center">{totalRowsData?.TotalQtyCredited}</Table.Summary.Cell>
                                    <Table.Summary.Cell className="text-center">{totalRowsData?.TotalQtyOutstanding}</Table.Summary.Cell>
                                    <Table.Summary.Cell>{totalRowsData?.TotalQtyRequest}</Table.Summary.Cell>
                                    <Table.Summary.Cell className="text-center">
                                        <AmountCell value={totalRowsData?.TotalInvoiceAmtRequestTaxExclusive} valueTaxIncluded={totalRowsData?.TotalInvoiceAmtRequestTaxInclusive} emptyText="" />
                                    </Table.Summary.Cell>
                                    <Table.Summary.Cell></Table.Summary.Cell>
                                </Table.Summary.Row>
                            )
                        }}
                    />
                </Col>
                <Col xs={{ span: 24 }} className="m-t-10 text-right">
                    <Button size="large" onClick={reset}>{t("common.reset")}</Button>
                    <Button size="large" className="m-l-10 secondary-btn" onClick={requestReturnSelectedParts}>{t("request_credit_return.request_return_selected_parts")}</Button>
                    <Button size="large" disabled={disableRequestReturnWholeInvoice} className="m-l-10" type="primary" onClick={requestReturnWholeParts}>{t("request_credit_return.request_return_whole_invoice")}</Button>
                </Col>
            </Row>
            <Modal title={t("common.confirm")} visible={visibleConfirmReturnWholeParts} onOk={onRequestReturnWholeParts} onCancel={onCancelReturnWholeParts}>
                {t("request_credit_return.confirm_request_return_whole_invoice")}
            </Modal>
        </div>
    )
}

export default EditRequestCreditReturn;