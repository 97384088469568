import React, { useEffect, useState } from "react";
import "./partOrderHistory.scss";
import { useTranslation } from "react-i18next";
import { Constant, CommonService, BuyerService, StorageService } from "../../../services/services";
import LoadingContext from "../../../components/utils/loading/loadingContext";
import { Table, Button, Row, Col, Select, Form, DatePicker, Modal, Breadcrumb, Tooltip } from "antd";
import SearchInput from './../../../components/searchInput/searchInput';
import PartSearch from './../../buyer/partSearch/partSearch'
import { Link, useLocation, useHistory } from "react-router-dom";
import { GetTableConfigs_SimplePaginator, StatusCell, DateTimeCell, CalcNumberOfItems_SimplePaginator, ActionButtonsCell } from "../../../components/utils/gridUtils/gridUtils";
import { useGlobalState } from '../../../utilities/globalState';
import { DownloadIcon } from './../../../components/icons/icons';
import moment from 'moment';

const { Option } = Select;

const layout = {
  labelCol: { span: 24 },
  wrapperCol: { span: 24 },
};

const PartOrderHistory = (props) => {
  const { t } = useTranslation();
  const [form] = Form.useForm();
  const location = useLocation();
  const history = useHistory();
  const { showLoading, dismissLoading } = React.useContext(LoadingContext);
  let stateObj = {};
  const params = location.state;

  const getFormFilterEmpty = () => {
    return {
      MakeId: null,
      PartItemCode: null,
      RequestedDateFrom: null,
      RequestedDateTo: null,
      StatusCode: null,
      SalesOrderType: null
    };
  }

  if (history.action === "REPLACE") {
    stateObj = {
      totalItems: location.state && location.state.totalItems ? location.state.totalItems : 0,
      partOrderHistoryData: location.state && location.state.partOrderHistoryData ? location.state.partOrderHistoryData : [],
      gridConfigOptions: location.state && location.state.gridConfigOptions ? location.state.gridConfigOptions : {
        pageNumber: 1,
        pageSize: Constant.PageSize,
        sort: ""
      },
      makeList: location.state && location.state.makeList ? location.state.makeList : [],
      formFilterData: {
        MakeId: location.state.formFilterData.MakeId,
        PartItemCode: location.state.formFilterData.PartItemCode,
        RequestedDateFrom: location.state.formFilterData.RequestedDateFrom ? moment(new Date(location.state.formFilterData.RequestedDateFrom)) : null,
        RequestedDateTo: location.state.formFilterData.RequestedDateTo ? moment(new Date(location.state.formFilterData.RequestedDateTo)) : null,
        StatusCode: location.state.formFilterData.StatusCode,
        SalesOrderType: location.state.formFilterData.SalesOrderType
      },
      encryptKey: location.state.encryptKey,
      mediaServiceHostName: location.state.mediaServiceHostName,
      stockKey: location.state.stockKey
    };
  } else {
    stateObj = {
      totalItems: 0,
      partOrderHistoryData: [],
      gridConfigOptions: {
        pageNumber: 1,
        pageSize: Constant.PageSize,
        sort: ""
      },
      makeList: [],
      formFilterData: getFormFilterEmpty(),
      encryptKey: "",
      mediaServiceHostName: "",
      stockKey: null
    };
  }

  const [visibleModal, setVisibleModal] = useState(false);
  const [partSearchData, setPartSearchData] = useState([]);
  const [totalItems, setTotalItems] = useState(0);
  const [partOrderHistoryData, setPartOrderHistoryData] = useState([]);
  const [makeList, setMakeList] = useState(stateObj.makeList);
  const [stockKey, setStockKey] = useState(stateObj.stockKey);
  const [gridConfigOptions, setGridConfigOptionsValue] = useState(stateObj.gridConfigOptions);
  const [taxIncluded, setTaxIncluded] = useState(true);
  const [orderTypes, setOrderTypes] = useGlobalState('orderTypes');
  const [visibleDownloadDocumentModal, setVisibleDownloadDocumentModal] = useState(false);
  const [downloadDocuments, setDownloadDocuments] = useState([]);
  const [encryptKey, setEncryptKey] = useState(stateObj.encryptKey);
  const [mediaServiceHostName, setMediaServiceHostName] = useState(stateObj.mediaServiceHostName);

  const getStateFilterData = () => {
    let data = form.getFieldsValue();
    return {
      MakeId: data.MakeId,
      PartItemCode: data.PartItemCode,
      RequestedDateFrom: data.RequestedDateFrom ? data.RequestedDateFrom.format("YYYY/MM/DD") : null,
      RequestedDateTo: data.RequestedDateTo ? data.RequestedDateTo.format("YYYY/MM/DD") : null,
      StatusCode: data.StatusCode,
      SalesOrderType: data.SalesOrderType
    };
  }

  const openDownloadDocument = (record) => {
    let _documentItems = CommonService.orderByDownloadDocuments(record);
    setDownloadDocuments(_documentItems);
    setVisibleDownloadDocumentModal(true);
  }

  const cancelDownloadDocumentModal = () => {
    setDownloadDocuments([]);
    setVisibleDownloadDocumentModal(false);
  }
  const getDocumentDetails = (record) => {
    return {
      downloadDocuments: CommonService.orderByDownloadDocuments(record),
      mediaServiceHostName: mediaServiceHostName,
      encryptKey: encryptKey
    }
  }

  const columns = [
    {
      title: t("part_order_history.pickslip_no"),
      dataIndex: "PickslipNo",
      key: "PickslipNo",
      sorter: true,
      ellipsis: true,
      width: 150,
      render: (value, record) => (
        <div className='flex-items align-space-between'>
          <Link className="pickslip-url" to={{
            pathname: Constant.Pages.PartOrderHistory_Details,
            state: {
              ...record, prevState: {
                totalItems: totalItems,
                partOrderHistoryData: partOrderHistoryData,
                gridConfigOptions: gridConfigOptions,
                makeList: makeList,
                formFilterData: getStateFilterData(),
                stockKey: stockKey
              },
              ...getDocumentDetails(record)
            }
          }}>{record.PickslipNo}</Link>
          {
            (record.DocumentItems && record.DocumentItems.length > 0 && CommonService.dmsVersionGreaterOrEqual(Constant.DmsVersion.version_1_36_18_0))
            &&
            <ActionButtonsCell data={record} btnList={[
              {
                Description: t("common.download_document"),
                Icon: <DownloadIcon />,
                Hide: false,
                ColorClass: Constant.ColorClass.LightBlue,
                NoMargin: true,
                handleAction: openDownloadDocument
              }
            ]} />
          }
        </div>
      ),
    },
    {
      title: t("part_order_history.order_number"),
      dataIndex: "DebtorOrderNo",
      key: "DebtorOrderNo",
      sorter: true,
      ellipsis: true,
      width: 120
    },
    {
      title: t("part_order_history.part_number"),
      dataIndex: "PartItemCode",
      key: "PartItemCode",
      sorter: true,
      ellipsis: true,
      width: 150,
      render: (value, record) => (
        <span title={record.PartItemCode} className={`truncate-flex-item included-spaces`}>{record.PartItemCode}</span>
      )
    },
    {
      title: t("part_order_history.make"),
      dataIndex: "MakeID",
      key: "MakeID",
      sorter: true,
      ellipsis: true,
      width: 80,
    },
    {
      title: t("part_order_history.part_description"),
      dataIndex: "PartDescription",
      key: "PartDescription",
      sorter: true,
      ellipsis: true,
      width: 150,
    },
    {
      title: t("part_order_history.order_type"),
      dataIndex: "SalesOrderType",
      key: "SalesOrderType",
      sorter: true,
      ellipsis: true,
      width: 100,
      align: "center",
      render: (value, record) => {
        let sot = orderTypes.find(p => p.Code === record.SalesOrderType);
        return sot ? <>{sot.Description}</> : <></>;
      }
    },
    {
      title: t("part_order_history.bo_method"),
      dataIndex: "BackOrderMethod",
      key: "BackOrderMethod",
      sorter: true,
      ellipsis: true,
      width: 100,
      render: (value, record) => {
        return <Tooltip placement="bottom" title={record.BackOrderMethodDescription}>
          <span>{record.BackOrderMethod}</span>
        </Tooltip>
      }
    },
    {
      title: t("part_order_history.quantity_order"),
      dataIndex: "QtyOrdered",
      key: "QtyOrdered",
      sorter: true,
      ellipsis: true,
      width: 100,
      align: "center"
    },
    {
      title: <div className="text-center" dangerouslySetInnerHTML={{ __html: taxIncluded ? t('part_order_history.unit_price_inc') : t('part_order_history.unit_price_ex') }}></div>,
      dataIndex: "UnitPrice",
      key: "UnitPrice",
      sorter: false,
      ellipsis: true,
      width: 100,
      align: "center",
      render: (value, record) => (
        <>{taxIncluded
          ? (record.UnitPriceTaxInclusive ? record.UnitPriceTaxInclusive.toFixed(Constant.NumberOfDecimalPlace) : null)
          : (record.UnitPriceTaxExclusive ? record.UnitPriceTaxExclusive.toFixed(Constant.NumberOfDecimalPlace) : null)
        }</>
      )
    },
    {
      title: t("part_order_history.pickslip_creation_date"),
      dataIndex: "PickslipDate",
      key: "PickslipDate",
      sorter: true,
      ellipsis: true,
      width: 110,
      align: "center",
      render: (value, record) => <DateTimeCell value={record.PickslipDate} />,
    },
    {
      title: t("common.status"),
      dataIndex: "PickslipStatusCode",
      key: "PickslipStatusCode",
      sorter: true,
      render: (value, record) => (
        <StatusCell data={record} enableTruncate={record.PickslipStatusCode == Constant.PartOrderStatus.PartiallyInvoiced}
          colorClass={CommonService.getPickslipStatusColorClass(record.PickslipStatusCode)}
          description={CommonService.getPickslipStatusDescription(record.PickslipStatusCode)} />
      ),
      align: "center",
      width: 140,
    },
    {
      title: t("part_order_history.document_no"),
      dataIndex: "InvoiceNo",
      key: "InvoiceNo",
      sorter: true,
      ellipsis: true,
      align: "center",
      width: 110,
    },
    {
      title: t("part_order_history.document_date"),
      dataIndex: "InvoiceDate",
      key: "InvoiceDate",
      sorter: true,
      ellipsis: true,
      width: 110,
      align: "center",
      render: (value, record) => record.InvoiceNo && <DateTimeCell value={record.InvoiceDate} />,
    },
    {
      title: t("part_order_history.document_type"),
      key: "DocumentType",
      sorter: false,
      ellipsis: true,
      width: 110,
      align: "center",
      render: (value, record) => record.QtyOrdered < 0 ? <>{t("common.download_document_credit_note")}</> :
        <>{t("common.download_document_invoice")}</>
    }
  ];

  const getSearchPartOrders = (pageNumber, pageSize, sort, statusCode, requestedDateFrom, requestedDateTo, sKey, orderType, isShowLoading = false) => {
    if (isShowLoading) showLoading();
    BuyerService.getSearchPartOrders(pageNumber, pageSize, sort, statusCode, requestedDateFrom, requestedDateTo, sKey, orderType)
      .finally(() => {
        if (isShowLoading) dismissLoading();
      })
      .then((result) => {
        if (result.data && result.data.ObjectList && result.data.ObjectList.length > 0) {
          setEncryptKey(result.data.EncryptKey);
          setMediaServiceHostName(result.data.MediaServiceHostName);
          let resultData = result.data.ObjectList;
          resultData.forEach((v, i) => { v["$index"] = i });
          setPartOrderHistoryData(CommonService.getPartOrderDocumentInfo(resultData));
          let numberForPaging = CalcNumberOfItems_SimplePaginator(pageNumber, result.data.ObjectList.length);
          setTotalItems(numberForPaging);
        } else {
          setPartOrderHistoryData([]);
          setTotalItems(0);
        }
      })
      .catch((error) => CommonService.handleErrorResponse(error));
  };

  const getSearchPartOrdersByStockKey = (sKey) => {
    if (sKey) {
      const value = form.getFieldsValue();
      getSearchPartOrders(
        gridConfigOptions.pageNumber,
        gridConfigOptions.pageSize,
        gridConfigOptions.sort,
        value.StatusCode,
        value.RequestedDateFrom,
        value.RequestedDateTo,
        sKey,
        value.SalesOrderType,
        true
      );
    }
  }

  const onTableChange = (pagination, filters, sorter, extra) => {
    let data = form.getFieldsValue();

    getSearchPartOrders(
      pagination.current,
      pagination.pageSize,
      CommonService.getSortString(sorter),
      data.StatusCode,
      data.RequestedDateFrom,
      data.RequestedDateTo,
      stockKey,
      data.SalesOrderType,
      true
    );
    setGridConfigOptionsValue({
      pageNumber: pagination.current,
      pageSize: pagination.pageSize,
      sort: CommonService.getSortString(sorter),
    });
  };

  const onSearch = (data) => {
    getSearchPartOrder(data.PartItemCode, data.MakeId)
  };
  const getSearchPartOrder = (partItemCode, makeId) => {
    setPartSearchData([])
    showLoading()
    BuyerService.getSearchStock(partItemCode, makeId, false)
      .finally(() => dismissLoading())
      .then(result => {
        if (!result.data || result.data.length === 0) {
          resetGridConfigOptions()
          setPartOrderHistoryData([]);
          setTotalItems(0);
          CommonService.presentToast("warning", t("part_enquiry.part_not_found"))
          return
        }

        if (result.data.length === 1) {
          setStockKey(result.data[0].StockKey);
          getSearchPartOrdersByStockKey(result.data[0].StockKey);
        }
        else {//show part search
          setPartSearchData(result.data)
          showPartSearch()
        }
      })
      .catch(error => CommonService.handleErrorResponse(error))
  }
  const showPartSearch = () => {
    setVisibleModal(true);
  };

  const handleCancel = (objectData) => {
    setVisibleModal(false);
    if (objectData) {
      handleSelectedPart(objectData);
    }
  };

  const handleSelectedPart = (objectData) => {
    setStockKey(objectData.StockKey);
    getSearchPartOrdersByStockKey(objectData.StockKey);
    form.setFieldsValue({
      "PartItemCode": objectData.PartItemCode,
      "MakeId": objectData.Make,
    });

  };
  const resetFilter = () => {
    stateObj.formFilterData = getFormFilterEmpty();
    form.setFieldsValue(stateObj.formFilterData);
  };
  const resetGridConfigOptions = () => {
    setGridConfigOptionsValue({
      pageNumber: 1,
      pageSize: Constant.PageSize,
      sort: "",
    });
    setTotalItems(0)
    setPartSearchData([])
  }

  useEffect(() => {
    if (!orderTypes || orderTypes.length === 0) {
      BuyerService.getOrderTypes()
        .then(result => {
          if (result.data) {
            setOrderTypes(result.data);
          }
        })
        .finally(() => { })
        .catch(error => CommonService.handleErrorResponse(error))
    }
    if (!makeList || makeList.length === 0) {
      showLoading();
      BuyerService.getMakeList()
        .finally(() => {
          dismissLoading();
        })
        .then((result) => {
          if (result.data) {
            setMakeList(result.data);
          }
        })
        .catch((error) => CommonService.handleErrorResponse(error));
    }
    // let formData = form.getFieldsValue()
    // if (stockKey) {
    //   getSearchPartOrders(
    //     gridConfigOptions.pageNumber,
    //     gridConfigOptions.pageSize,
    //     gridConfigOptions.sort,
    //     formData?.StatusCode,
    //     formData?.RequestedDateFrom,
    //     formData?.RequestedDateTo,
    //     stockKey,
    //     formData?.SalesOrderType,
    //     true
    //   );
    // }


    let authData = StorageService.getAuthData();
    if (authData) {
      setTaxIncluded(authData.isShowPriceTaxIncluded);
    }
    return () => {
      BuyerService.cancelRequest();
    };
  }, []);

  return (
    <div className="part-order-history-management">
      <Breadcrumb>
        <Breadcrumb.Item>{t("menu.part_order_history")}</Breadcrumb.Item>
      </Breadcrumb>
      <Row>
        <Col xs={{ span: 24 }}>
          <Form
            {...layout}
            form={form}
            name="partOrderHistoryFilterForm"
            onFinish={onSearch}
            initialValues={stateObj.formFilterData}
          >
            <Row gutter={[Constant.SpaceConstant.HorizontalGutter,]} align="middle">
              <Col xs={{ span: 6 }}>
                <Form.Item
                  label={t("part_order_history.make")}
                  name="MakeId"
                  colon={false}
                  labelAlign="left"
                >
                  <Select placeholder={t("part_order_history.make")} allowClear>
                    <Option value="">{t("common.all")}</Option>
                    {makeList.map((n, index) => (
                      <Option key={index} value={n.MakeId}>
                        {n.MakeId}
                      </Option>
                    ))}
                  </Select>
                </Form.Item>
              </Col>
              <Col xs={{ span: 6 }}>
                <Form.Item
                  label={t("part_order_history.part_number")}
                  name="PartItemCode"
                  colon={false}
                  labelAlign="left"
                  rules={[
                    {
                      required: true,
                      message: t("part_order_history.part_number_required_mes"),
                    },
                  ]}
                >
                  <SearchInput placeholder={t("part_order_history.part_number")}
                    getSearchFields={() => form.getFieldsValue()}
                    makeList={makeList}
                    handleSelectedItem={handleSelectedPart}
                    allowClear uppercase={true}
                  ></SearchInput>
                </Form.Item>
              </Col>
              <Col xs={{ span: 6 }}>
                <Form.Item
                  label={t("part_order_history.date_from")}
                  name="RequestedDateFrom"
                  colon={false}
                  labelAlign="left"
                >
                  <DatePicker format={Constant.DateFormat} />
                </Form.Item>
              </Col>
              <Col xs={{ span: 6 }}>
                <Form.Item
                  label={t("part_order_history.date_to")}
                  name="RequestedDateTo"
                  colon={false}
                  labelAlign="left"
                >
                  <DatePicker format={Constant.DateFormat} />
                </Form.Item>
              </Col>
              <Col xs={{ span: 6 }}>
                <Form.Item
                  label={t("common.status")}
                  name="StatusCode"
                  colon={false}
                  labelAlign="left"
                >
                  <Select placeholder={t("common.status")} allowClear>
                    {Constant.PartOrderStatusItems.map((n, index) => <Option key={index} value={n.Code}>{n.Description}</Option>)}
                  </Select>
                </Form.Item>
              </Col>
              <Col xs={{ span: 6 }}>
                <Form.Item
                  label={t("part_order_history.order_type")}
                  colon={false} labelAlign="left" name="SalesOrderType"
                >
                  <Select placeholder={t("part_order_history.order_type")} allowClear>
                    {
                      orderTypes.map((n, index) => <Option key={index} value={n.Code}>{n.Description}</Option>)
                    }
                  </Select>
                </Form.Item>
              </Col>
              <Col xs={{ span: 12 }} className="text-right ">
                <div className="m-t-10">
                  <Button size="large" onClick={resetFilter}>
                    {t("common.reset_filter")}
                  </Button>
                  <Button
                    size="large"
                    className="m-l-10 secondary-btn"
                    htmlType="submit"

                  >
                    {t("common.retrieve")}
                  </Button>
                </div>

              </Col>
            </Row>
          </Form>
        </Col>
      </Row>

      <Row gutter={[Constant.SpaceConstant.VerticalGutter]}>
        <Col xs={{ span: 24 }}>
          <Table
            {...GetTableConfigs_SimplePaginator(
              columns,
              partOrderHistoryData,
              totalItems,
              "$index",
              onTableChange,
              gridConfigOptions.pageNumber
            )}
          />
        </Col>
      </Row>
      {
        visibleModal && <PartSearch objectData={form.getFieldsValue()} makeList={makeList} handleCancel={handleCancel} partList={partSearchData}></PartSearch>
      }
      {visibleDownloadDocumentModal &&
        <Modal
          className="pickslip-details-page"
          title={t("common.download_document")}
          visible={true}
          confirmLoading={false}
          onCancel={cancelDownloadDocumentModal}
          maskClosable={false}
          footer={null}
          width={768}
          centered
        >
          <Row>
            <Col xs={{ span: 24 }}>
              <Table className="custom-table" columns={CommonService.getDownloadDocumentColumns(mediaServiceHostName, encryptKey, showLoading, dismissLoading)}
                dataSource={downloadDocuments}
                rowKey="$id"
                pagination={false} scroll={{ y: 300 }} />
            </Col>
          </Row>
        </Modal>
      }
    </div>
  );
};

export default PartOrderHistory;
