import React, { useEffect, useState } from "react";
import "./registerAccount.scss";
import { Button, Form, Row, Col, Input, Select, Checkbox } from 'antd';
import { useTranslation } from 'react-i18next';
import { DealershipService, CommonService, Constant } from '../../services/services'
import { useGlobalState } from '../../utilities/globalState'

const { Option } = Select;

const layout = {
    labelCol: { span: 24 },
    wrapperCol: { span: 24 }
};

export default function RegisterAccount({ ...props }) {
    const { t, i18n } = useTranslation();

    const [dealerships, setDealerships] = useGlobalState('dealerships')
    const [suppliers, setSuppliers] = useGlobalState('suppliers')
    const [dealershipStores, setDealershipStores] = useState([])
    const [supplierForm] = Form.useForm();

    useEffect(() => {
        if (props.isSupplier && (!dealerships || dealerships.length == 0)) {
            DealershipService.getDealerships()
                .then(result => {
                    if (result && result.data) {
                        setDealerships(result.data)
                    }
                })
                .catch(error => {
                    CommonService.handleErrorResponse(error)
                })
        }

        if (!props.isSupplier && (!suppliers || suppliers.length == 0)) {
            DealershipService.getSuppliers()
                .then(result => {
                    if (result && result.data) {
                        setSuppliers(result.data)
                    }
                })
                .catch(error => {
                    CommonService.handleErrorResponse(error)
                })
        }
        return () => {
            DealershipService.cancelRequest()
        }

    }, [])

    const onDealershipChange = (dealerId) => {
        supplierForm.setFieldsValue({
            StoreId: null
        })
        DealershipService.getDealershipStores(dealerId)
            .then(result => {
                if (result && result.data) {
                    setDealershipStores(result.data)
                }
            })
            .catch(error => {
                CommonService.handleErrorResponse(error)
            })
    }

    const onSubmit = (data) => {
        if (props.onRegister) {
            props.onRegister(data)
        }
    };

    const cancel = () => {
        if (props.onCancel) {
            props.onCancel()
        }
    };

    return (
        <div className={`registerAccount ${props.className}`}>
            <p className="title heading-3 bold-text m-b-30">{props.isSupplier ? t('register.title_supplier') : t('register.title_buyer')}</p>
            {props.isSupplier &&
                <Form
                    {...layout}
                    name="supplierForm"
                    onFinish={onSubmit}
                    initialValues={props.objectData}
                    className="form-container"
                    form={supplierForm}
                >
                    <Row gutter={[Constant.SpaceConstant.HorizontalGutter]}>
                        <Col span={12}>
                            <Form.Item
                                label={t("register.dealership")}
                                name="DealerId" colon={false} labelAlign="left"
                                rules={[{ required: true, message: t("register.dealership_required") }]}
                            >
                                <Select onChange={onDealershipChange} allowClear>
                                    {
                                        dealerships.map((n, index) => <Option key={index} value={n.DealerId}>{n.DealerName}</Option>)
                                    }
                                </Select>
                            </Form.Item>
                        </Col>
                        <Col span={12}>
                            <Form.Item
                                label={t("register.store_name")}
                                name="StoreId" colon={false} labelAlign="left"
                                rules={[{ required: true, message: t("register.store_required") }]}
                            >
                                <Select allowClear>
                                    {
                                        dealershipStores.map((n, index) => <Option key={index} value={n.Id}>{n.Name}</Option>)
                                    }
                                </Select>
                            </Form.Item>
                        </Col>
                    </Row>
                    <Row gutter={[Constant.SpaceConstant.HorizontalGutter]}>
                        <Col span={12}>
                            <Form.Item
                                label={t("register.trading_name")}
                                name="TradingName" colon={false} labelAlign="left"
                                rules={[{ required: true, message: t("register.trading_name_required") }]}
                            >
                                <Input allowClear />
                            </Form.Item>
                        </Col>
                        <Col span={12}>
                            <Form.Item
                                label={t("register.contact_name")}
                                name="ContactName" colon={false} labelAlign="left"
                                rules={[{ required: true, message: t("register.contact_name_required") }]}
                            >
                                <Input allowClear />
                            </Form.Item>
                        </Col>
                    </Row>
                    <Row gutter={[Constant.SpaceConstant.HorizontalGutter]}>
                        <Col span={12}>
                            <Form.Item
                                label={t("register.email")}
                                name="Email" colon={false} labelAlign="left"
                                rules={[{ required: true, message: t("register.email_required") },
                                { type: "email", message: t("common.email_invalid") }]}
                            >
                                <Input allowClear />
                            </Form.Item>
                        </Col>
                        <Col span={12}>
                            <Form.Item
                                label={t("register.phone_no")}
                                name="PhoneNumber" colon={false} labelAlign="left"
                                rules={[{ required: true, message: t("register.phone_no_required") }]}
                            >
                                <Input allowClear />
                            </Form.Item>
                        </Col>
                    </Row>
                    <Row>
                        <Col span={24}>
                            <Form.Item
                                label={t("register.address")}
                                name="StoreAddress" colon={false} labelAlign="left"
                                rules={[{ required: true, message: t("register.address_required") }]}
                            >
                                <Input allowClear />
                            </Form.Item>
                        </Col>
                    </Row>
                    <Row gutter={[Constant.SpaceConstant.HorizontalGutter]}>
                        <Col span={12}>
                            <Form.Item
                                label={t("register.user_id")}
                                name="UserName" colon={false} labelAlign="left"
                                rules={[{ required: true, message: t("register.user_id_required") }]}
                            >
                                <Input allowClear />
                            </Form.Item>
                        </Col>
                        <Col span={12}>
                            <Form.Item
                                label={t("register.password")}
                                name="Password" colon={false} labelAlign="left"
                                rules={[{ required: true, message: t("register.password_required") }]}
                            >
                                <Input.Password allowClear />
                            </Form.Item>
                        </Col>
                    </Row>
                    <Row gutter={[Constant.SpaceConstant.HorizontalGutter]}>
                        <Col span={12}>
                            <Form.Item
                                name="IsPoNotificationRequired" colon={false} labelAlign="left"
                                valuePropName="checked"
                            >
                                <Checkbox>{t("register.po_notification")}</Checkbox>
                            </Form.Item>
                        </Col>
                        <Col span={12}>
                            <Form.Item
                                label={t("register.confirm_password")}
                                name="ConfirmPassword" colon={false} labelAlign="left"
                                rules={[({ getFieldValue }) => ({
                                    validator(rule, value) {
                                        let password = getFieldValue('Password')
                                        if (value != password) {
                                            return Promise.reject(t("register.confirm_password_mismatch"));
                                        }
                                        return Promise.resolve();
                                    },
                                })]}
                            >
                                <Input.Password allowClear />
                            </Form.Item>
                        </Col>
                    </Row>
                    <Row className="m-t-15">
                        <Col span={24} className="footer">
                            <Button onClick={cancel}>
                                {t("common.cancel")}
                            </Button>
                            <Button className="login-button m-l-10" type="primary" htmlType="submit">
                                {t("common.submit")}
                            </Button>
                        </Col>

                    </Row>
                </Form>
            }
            {
                !props.isSupplier &&
                <Form
                    {...layout}
                    name="buyerForm"
                    onFinish={onSubmit}
                    initialValues={props.objectData}
                    className="form-container"
                >
                    <Row gutter={[Constant.SpaceConstant.HorizontalGutter]}>
                        <Col span={12}>
                            <Form.Item
                                label={t("register.trading_name")}
                                name="TradingName" colon={false} labelAlign="left"
                                rules={[{ required: true, message: t("register.trading_name_required") }]}
                            >
                                <Input allowClear />
                            </Form.Item>
                        </Col>
                        <Col span={12}>
                            <Form.Item
                                label={t("register.contact_name")}
                                name="ContactName" colon={false} labelAlign="left"
                                rules={[{ required: true, message: t("register.contact_name_required") }]}
                            >
                                <Input allowClear />
                            </Form.Item>
                        </Col>
                    </Row>
                    <Row gutter={[Constant.SpaceConstant.HorizontalGutter]}>
                        <Col span={12}>
                            <Form.Item
                                label={t("register.email")}
                                name="Email" colon={false} labelAlign="left"
                                rules={[{ required: true, message: t("register.email_required") },
                                { type: "email", message: t("common.email_invalid") }]}
                            >
                                <Input allowClear />
                            </Form.Item>
                        </Col>
                        <Col span={12}>
                            <Form.Item
                                label={t("register.phone_no")}
                                name="PhoneNumber" colon={false} labelAlign="left"
                                rules={[{ required: true, message: t("register.phone_no_required") }]}
                            >
                                <Input allowClear />
                            </Form.Item>
                        </Col>
                    </Row>
                    <Row gutter={[Constant.SpaceConstant.HorizontalGutter]}>
                        <Col span={24}>
                            <Form.Item
                                label={t("register.user_id")}
                                name="UserName" colon={false} labelAlign="left"
                                rules={[{ required: true, message: t("register.user_id_required") }]}
                            >
                                <Input allowClear />
                            </Form.Item>
                        </Col>
                        <Col span={12}>
                            <Form.Item
                                label={t("register.password")}
                                name="Password" colon={false} labelAlign="left"
                                rules={[{ required: true, message: t("register.password_required") }]}
                            >
                                <Input.Password allowClear />
                            </Form.Item>
                        </Col>
                        <Col span={12}>
                            <Form.Item
                                label={t("register.confirm_password")}
                                name="ConfirmPassword" colon={false} labelAlign="left"
                                rules={[({ getFieldValue }) => ({
                                    validator(rule, value) {
                                        let password = getFieldValue('Password')
                                        if (value != password) {
                                            return Promise.reject(t("register.confirm_password_mismatch"));
                                        }
                                        return Promise.resolve();
                                    },
                                })]}
                            >
                                <Input.Password allowClear />
                            </Form.Item>
                        </Col>
                        <Col span={24}>
                            <Form.Item
                                label={t("common.supplier")}
                                name="SupplierIds" colon={false} labelAlign="left"
                            >
                                <Select
                                    dropdownClassName="wrap-text-dropdown"
                                    mode="multiple"
                                    allowClear>
                                    {
                                        suppliers.map((n, index) => <Option key={index} value={n.Id}>{n.TradingName}</Option>)
                                    }
                                </Select>
                            </Form.Item>
                        </Col>
                    </Row>
                    <Row className="m-t-15">
                        <Col span={24} className="footer">
                            <Button size="large" onClick={cancel}>
                                {t("common.cancel")}
                            </Button>
                            <Button size="large" className="login-button m-l-10" type="primary" htmlType="submit">
                                {t("common.submit")}
                            </Button>
                        </Col>

                    </Row>
                </Form>
            }
        </div>

    );
}