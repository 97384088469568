import { LogLevel, HubConnectionBuilder } from "@microsoft/signalr";
import PubSub from 'pubsub-js'
import { Constant, StorageService} from './services'
import axios from 'axios';
import {axiosPortal} from './authService'

var connection = {};

const source = [];
let baseURL = "/Notification"
export const NotificationService = {
    loadService: function () {
        let authData = StorageService.getAuthData()

        if(authData){
            connection = new HubConnectionBuilder()
            .configureLogging(LogLevel.Debug)
            .withAutomaticReconnect()
            .withUrl(process.env.REACT_APP_BASE_URL + '/notificationHub', {accessTokenFactory: () => authData.token})
            .build()
    
            connection.on("ReceiveMessage", function (message) {
                PubSub.publish(Constant.PubSupType.NotificationMessage, message);
            })
            connection.start().then(function () {
                console.log('Connected!');
            }).catch(function (err) {
                return console.error(err.toString());
            });
            return connection
        }
    },
    stopService: function(connection){
        if(connection){
            connection.stop()
        }      
    },
    getNotifications: function (pageNumber, pageSize, sort) {
        let cancelToken = axios.CancelToken.source()
        source.push(cancelToken)
        return axiosPortal.get(`${baseURL}?PageNumber=${pageNumber}&PageSize=${pageSize}&Sort=${sort}`, {
            cancelToken: cancelToken.token
        }).finally(() => {
            let index = source.indexOf(cancelToken)
            if (index > -1) {
                source.splice(index, 1)
            }
        })
    },
    markAsRead: function () {
        let cancelToken = axios.CancelToken.source()
        source.push(cancelToken)
        return axiosPortal.post(`${baseURL}/MarkAsRead`, null, {
            cancelToken: cancelToken.token
        }).finally(() => {
            let index = source.indexOf(cancelToken)
            if (index > -1) {
                source.splice(index, 1)
            }
        })
    }
}