import React, { useState, useEffect } from 'react';
import { Form, Input, Modal, Select, Col, Row, Button, Table } from 'antd';
import { useTranslation } from 'react-i18next';
import LoadingContext from '../../components/utils/loading/loadingContext'
import { Constant, CommonService } from '../../services/services';
import { GetTableConfigs, ActionButtonsCell } from '../../components/utils/gridUtils/gridUtils'
import { SelectOutlined } from '@ant-design/icons';
import _ from 'underscore'

const { Option } = Select;
const layout = {
    labelCol: { span: 24 },
    wrapperCol: { span: 24 }
};



const AlternateDeliveryAddressSelection = (props) => {
    const { t } = useTranslation();
    const { showLoading, dismissLoading } = React.useContext(LoadingContext)
    const [form] = Form.useForm();
    const emptyTableData = {
        ObjectList: [],
        TotalItems: 0
    }
    const [data, setData] = useState(emptyTableData)
    const [columnData, setColumnData] = useState([])
    const [gridConfigOptions, setGridConfigOptionsValue] = useState({
        pageNumber: 1,
        pageSize: Constant.PageSize,
        sort: ""
    })
    const [isSortFromApi, setSortFromApi] = useState(false)

    const closeForm = () => {
        props.handleCancel()
    }

    const onSubmit = () => {}
    const sortFields = {
        Name: 'Name',
        PostCode: 'PostCode',
        State: 'State',
        StreetLine1: 'StreetLine1',
        StreetLine2: 'StreetLine2',
        StreetNo: 'StreetNo',
        SubDistrict: "SubDistrict",
        Suburb: "Suburb",
        DefaultDeliveryRunID: "DefaultDeliveryRunID",
        DeliveryMethodDescription: "DeliveryMethodDescription"
    }

    const onTableChange = (pagination, filters, sorter, extra) => {
        let sortValue = CommonService.getSortString(sorter)
        if (sortValue && data.ObjectList && data.ObjectList.length > 0) {
            let sortDirection = sortValue.indexOf(Constant.SortDirection.Asc) >= 0 ? Constant.SortDirection.Asc : Constant.SortDirection.Desc
            let sortedList = [...data.ObjectList]
            if (sortValue.indexOf(sortFields.Name) >= 0) {
                sortedList = sortDirection == Constant.SortDirection.Asc ? _.sortBy(sortedList, item => getLowerCase(item.Name)) : _.sortBy(sortedList, item => getLowerCase(item.Name)).reverse()
            }
            else if (sortValue.indexOf(sortFields.PostCode) >= 0) {
                sortedList = sortDirection == Constant.SortDirection.Asc ? _.sortBy(sortedList, item => getLowerCase(item.PostCode)) : _.sortBy(sortedList, item => getLowerCase(item.PostCode)).reverse()
            }
            else if (sortValue.indexOf(sortFields.State) >= 0) {
                sortedList = sortDirection == Constant.SortDirection.Asc ? _.sortBy(sortedList, item => getLowerCase(item.State)) : _.sortBy(sortedList, item => getLowerCase(item.State)).reverse()
            }
            else if (sortValue.indexOf(sortFields.StreetLine1) >= 0) {
                sortedList = sortDirection == Constant.SortDirection.Asc ? _.sortBy(sortedList, item => getLowerCase(item.StreetLine1)) : _.sortBy(sortedList, item => getLowerCase(item.StreetLine1)).reverse()
            }
            else if (sortValue.indexOf(sortFields.StreetLine2) >= 0) {
                sortedList = sortDirection == Constant.SortDirection.Asc ? _.sortBy(sortedList, item => getLowerCase(item.StreetLine2)) : _.sortBy(sortedList, item => getLowerCase(item.StreetLine2)).reverse()
            }
            else if (sortValue.indexOf(sortFields.StreetNo) >= 0) {
                sortedList = sortDirection == Constant.SortDirection.Asc ? _.sortBy(sortedList, item => getLowerCase(item.StreetNo)) : _.sortBy(sortedList, item => getLowerCase(item.StreetNo)).reverse()
            }
            else if (sortValue.indexOf(sortFields.DefaultDeliveryRunID) >= 0) {
                sortedList = sortDirection == Constant.SortDirection.Asc ? _.sortBy(sortedList, item => getLowerCase(item.DefaultDeliveryRunID)) : _.sortBy(sortedList, item => getLowerCase(item.DefaultDeliveryRunID)).reverse()
            }
            else if (sortValue.indexOf(sortFields.DeliveryMethodDescription) >= 0) {
                sortedList = sortDirection == Constant.SortDirection.Asc ? _.sortBy(sortedList, item => getLowerCase(item.DeliveryMethodDescription)) : _.sortBy(sortedList, item => getLowerCase(item.DeliveryMethodDescription)).reverse()
            }
            else if (sortValue.indexOf(sortFields.Suburb) >= 0) {
                sortedList = sortDirection == Constant.SortDirection.Asc ? _.sortBy(sortedList, item => getLowerCase(item.Suburb)) : _.sortBy(sortedList, item => getLowerCase(item.Suburb)).reverse()
            }
            const newList = {
                ObjectList: sortedList,
                TotalItems: sortedList.length
            };
            setData(newList)
        }

        setGridConfigOptionsValue({
            pageNumber: pagination.current,
            pageSize: pagination.pageSize,
            sort: sortValue
        })
    }
    const getLowerCase = (text) => {
        if (text) return text.toString().toLowerCase();
        return text;
    }

    const selected = (data) => {
        props.handleCancel(data)
    }

    const columns = [
        // {
        //     title: t('part_order.country'),
        //     dataIndex: 'Country',
        //     key: 'Country',
        //     sorter: false,
        //     ellipsis: true,
        // },
        {
            title: t('part_order.delivery_to'),
            dataIndex: 'Name',
            key: 'Name',
            sorter: true,
            width: 200,
        },
        {
            title: t('part_order.street_no'),
            dataIndex: 'StreetNo',
            key: 'StreetNo',
            sorter: true,
        },
        {
            title: t('part_order.street_line_1'),
            dataIndex: 'StreetLine1',
            key: 'StreetLine1',
            sorter: true,
        },
        {
            title: t('part_order.street_line_2'),
            dataIndex: 'StreetLine2',
            key: 'StreetLine2',
            sorter: true,
        },
        {
            title: t('part_order.suburb'),
            dataIndex: 'Suburb',
            key: 'Suburb',
            sorter: true,
        },
        {
            title: t('part_order.state'),
            dataIndex: 'State',
            key: 'State',
            sorter: true,
        },
        {
            title: t('part_order.post_code'),
            dataIndex: 'PostCode',
            key: 'PostCode',
            sorter: true,
        },
        {
            title: t('part_order.delivery_run'),
            dataIndex: 'DefaultDeliveryRunID',
            key: 'DefaultDeliveryRunID',
            sorter: true,
        },
        {
            title: t('part_order.delivery_method'),
            dataIndex: 'DeliveryMethodDescription',
            key: 'DeliveryMethodDescription',
            sorter: true,
        },
        {
            title: t('common.action'),
            key: 'ContactKey',
            render: (value, record) => (
                <ActionButtonsCell btnList={[getActionButtonInfo(record)]} data={record} handleAction={selected} />
            ),
            align: 'center',
            width: 80
        }
    ]

    const getActionButtonInfo = (item) => {
        return {
            Description: t("common.select"),
            Icon: <SelectOutlined />,
            ColorClass: Constant.ColorClass.LightBlue,
            Hide: false
        }

    }

    const gridColumnConfig = (partList) => {
        setColumnData(columns)
    }

    useEffect(() => {
    
        document.documentElement.style.setProperty(Constant.CssVariables.ModalFixItemsContainerHeight, CommonService.calculateScrollMaxHeightModal())
        let timeoutFn = setTimeout(() => document.documentElement.style.setProperty(Constant.CssVariables.FixItemsContainerHeight, CommonService.calculateTableBodyMaxHeight("filterGridId")), 100);

        if (props.data && props.data.length > 0) {
            gridColumnConfig(props.data)
            setSortFromApi(false)
            setData({
                ObjectList: props.data,
                TotalItems: props.data.length
            })


        }
        else {
            setColumnData(columns)
        }

        return () => {
            clearTimeout(timeoutFn);
        }
    }, [])

    return (
        <div>
            <Modal
                title={t("part_order.alternative_delivery_address")}
                visible={true}
                onOk={form.submit}
                confirmLoading={false}
                onCancel={closeForm}
                maskClosable={false}
                footer={null}
                width={1200}
            >
                <Form
                    {...layout}
                    form={form}
                    name="alternativeDeliveryAddressSelection"
                    onFinish={onSubmit}
                    initialValues={props.objectData}
                >
                   
                    <Row className="m-t-10" gutter={[, Constant.SpaceConstant.VerticalGutter]}>

                        <Col xs={{ span: 24 }}>
                            <Table
                                onRow={(record, rowIndex) => {
                                    return {
                                        onDoubleClick: event => selected(record), // double click row
                                    };
                                }}
                                {...GetTableConfigs(columnData, data.ObjectList, data.TotalItems, null, onTableChange, gridConfigOptions.pageNumber)}
                            />
                        </Col>
                    </Row>
                </Form>
            </Modal>
        </div>
    )
}

export default AlternateDeliveryAddressSelection