import React, { useState, useEffect } from 'react';
import { Form, Input, Modal, Select, Col, Row, Button, Table, DatePicker, Tooltip } from 'antd';
import { useTranslation } from 'react-i18next';
import LoadingContext from './../../../components/utils/loading/loadingContext'
import { BuyerService, Constant, CommonService } from '../../../services/services';
import { GetTableConfigs, ActionButtonsCell, StatusCell } from './../../../components/utils/gridUtils/gridUtils'
import { SelectOutlined } from '@ant-design/icons';

const { Option } = Select;
const layout = {
    labelCol: { span: 24 },
    wrapperCol: { span: 24 }
};



const OrderSearch = (props) => {
    const { t } = useTranslation();
    const { showLoading, dismissLoading } = React.useContext(LoadingContext)
    const [form] = Form.useForm();
    const [totalItems, setTotalItems] = useState(0)
    const [orderData, setOrderData] = useState([])
    const [gridConfigOptions, setGridConfigOptionsValue] = useState({
        pageNumber: 1,
        pageSize: Constant.PageSize,
        sort: ""
    })

    const closeForm = () => {
        props.handleCancel()
    }

    const onSubmit = (data) => {
        getPickslips(data, gridConfigOptions.pageNumber, gridConfigOptions.pageSize, gridConfigOptions.sort, true)
    };

    const getPickslips = (query, pageNumber, pageSize, sort, isShowLoading = false) => {
        if (isShowLoading)
            showLoading()
        BuyerService.getPickslips(query, pageNumber, pageSize, sort)
            .finally(() => {
                if (isShowLoading)
                    dismissLoading()
            })
            .then(result => {
                if (result.data) {
                    setOrderData(result.data.ObjectList)
                    setTotalItems(result.data.TotalItems)
                }
            })
            .catch(error => CommonService.handleErrorResponse(error))

    }

    const onTableChange = (pagination, filters, sorter, extra) => {
        let data = form.getFieldsValue()

        getPickslips(data, pagination.current, pagination.pageSize, CommonService.getSortString(sorter))
        setGridConfigOptionsValue({
            pageNumber: pagination.current,
            pageSize: pagination.pageSize,
            sort: CommonService.getSortString(sorter)
        })
    }

    const resetFilter = () => {
        form.setFieldsValue({
            ["OrderNo"]: "",
            ["PartNumber"]: "",
            ["PartDescription"]: "",
            ["FromPickslipDate"]: "",
            ["ToPickslipDate"]: ""
        });
        setOrderData([])
        setGridConfigOptionsValue({
            pageNumber: 1,
            pageSize: Constant.PageSize,
            sort: ""
        })
        setTotalItems(0)
    }

    const selectedPickslip = (data) => {
        props.handleCancel(data)
    }

    const columns = [
        {
            title: t('common.action'),
            key: 'PickslipNo',
            render: (value, record) => (
                <ActionButtonsCell btnList={[getActionButtonInfo(record)]} data={record} handleAction={selectedPickslip} />
            ),
            align: 'center',
            width: 80
        },
        {
            title: t('part_order.order_no'),
            dataIndex: 'OrderNo',
            key: 'OrderNo',
            sorter: false,
            ellipsis: true,
            width: 100,
        },
        {
            title: t('part_order.order_date'),
            dataIndex: 'PickslipDate',
            key: 'PickslipDate',
            sorter: false,
            ellipsis: true,
            render: (value, record) => (
                <label>{CommonService.getDateString(record.PickslipDate)}</label>
            ),
            width: 100
        },
        {
            title: t('part_enquiry.part_number'),
            dataIndex: 'PartNumber',
            key: 'PartNumber',
            sorter: false,
            ellipsis: true,
            width: 100
        },
        {
            title: t('part_enquiry.description'),
            dataIndex: 'PartDescription',
            key: 'PartDescription',
            sorter: false,
            ellipsis: true,
            width: 150
        },
        {
            title: t('part_order.qty'),
            dataIndex: 'OrderedQty',
            key: 'OrderedQty',
            sorter: false,
            ellipsis: true,
        },
        {
            title: t('part_order.bo'),
            dataIndex: 'BackOrderMethod',
            key: 'BackOrderMethod',
            sorter: false,
            ellipsis: true,
            render: (value, record) => {
                return <Tooltip placement="bottom" title={record.BackOrderMethodDescription}>
                    <span>{record.BackOrderMethod}</span>
                </Tooltip>
            }
        },
        {
            title: t('part_order.price'),
            dataIndex: 'Price',
            key: 'Price',
            sorter: false,
            ellipsis: true,
            width: 100,
            render: (value, record) => (
                <span>{CommonService.amountFormat(record.Price)}</span>
            )
        },
        {
            title: t('common.status'),
            dataIndex: 'EcommerceCreatingStatusCode',
            key: 'EcommerceCreatingStatusCode',
            sorter: false,
            render: (value, record) => (
                <StatusCell data={record}
                    colorClass={CommonService.getEcommerceCreatingStatusColorClass(record.EcommerceCreatingStatusCode)}
                    description={CommonService.getEcommerceCreatingStatusDescription(record.EcommerceCreatingStatusCode)} />
            ),
            align: 'center',
            width: 100,
        },
        {
            title: t('part_order.pickslip_number'),
            dataIndex: 'PickslipNo',
            key: 'PickslipNo',
            sorter: false,
            ellipsis: true,
            width: 100,
        }
    ]

    const getActionButtonInfo = (item) => {
        return {
            Description: t("common.select"),
            Icon: <SelectOutlined />,
            ColorClass: Constant.ColorClass.LightBlue,
            Hide: false
        }

    }

    const onBlurOrderNumber = (e) => {
        if (e.target && e.target.value) {
            let formatOrderNumber = CommonService.trimSpacesAndUppercaseText(e.target.value, true, true)
            if (formatOrderNumber != e.target.value)
                form.setFieldsValue({
                    OrderNo: formatOrderNumber
                })
        }
    }

    useEffect(() => {
        document.documentElement.style.setProperty(Constant.CssVariables.ModalFixItemsContainerHeight, CommonService.calculateScrollMaxHeightModal())
        let timeoutFn = setTimeout(() => document.documentElement.style.setProperty(Constant.CssVariables.FixItemsContainerHeight, CommonService.calculateTableBodyMaxHeight("filterGridId")), 100);

        if (props.orderList && props.orderList.length > 0) {
            setOrderData(props.orderList)
            setTotalItems(props.orderList.length)
        }
        return () => {
            BuyerService.cancelRequest()
            clearTimeout(timeoutFn);
        }
    }, [])

    useEffect(() => {
        if (props.queryData) {
            if (props.queryData.query) {
                form.setFieldsValue(props.queryData.query)
            }

            if (props.queryData.data) {
                setOrderData(props.queryData.data.ObjectList)
                setTotalItems(props.queryData.data.TotalItems)
            }
        }
    }, [props.queryData])

    return (
        <div>
            <Modal
                title={t("part_order.order_search")}
                visible={true}
                onOk={form.submit}
                confirmLoading={false}
                onCancel={closeForm}
                maskClosable={false}
                footer={null}
                width={1000}
            >
                <Form
                    {...layout}
                    form={form}
                    name="orderSearchForm"
                    onFinish={onSubmit}
                    initialValues={props.objectData}
                >
                    <Row align="middle" gutter={[Constant.SpaceConstant.HorizontalGutter]} id="filterGridId">
                        <Col xs={{ span: 8 }}>
                            <Form.Item
                                label={t("part_order.order_number")}
                                name="OrderNo" colon={false} labelAlign="left"
                            >
                                <Input className='uppercase-text' onBlur={onBlurOrderNumber} allowClear />
                            </Form.Item>
                        </Col>
                        <Col xs={{ span: 8 }}>
                            <Form.Item
                                label={t("part_enquiry.part_number")}
                                name="PartNumber" colon={false} labelAlign="left"
                            >
                                <Input allowClear />
                            </Form.Item>
                        </Col>
                        <Col xs={{ span: 8 }}>
                            <Form.Item
                                label={t("part_enquiry.description")}
                                name="PartDescription" colon={false} labelAlign="left"
                            >
                                <Input allowClear />
                            </Form.Item>
                        </Col>
                        <Col xs={{ span: 8 }}>
                            <Form.Item
                                label={t("part_order.from_date")}
                                name="FromPickslipDate" colon={false} labelAlign="left"
                            >
                                <DatePicker format={Constant.DateFormat} />
                            </Form.Item>
                        </Col>
                        <Col xs={{ span: 8 }}>
                            <Form.Item
                                label={t("part_order.to_date")}
                                name="ToPickslipDate" colon={false} labelAlign="left"
                            >
                                <DatePicker format={Constant.DateFormat} />
                            </Form.Item>
                        </Col>


                        <Col xs={{ span: 8 }} className="text-right m-t-10">
                            <Button size="large" onClick={resetFilter}>{t("common.reset")}</Button>
                            <Button size="large" className="m-l-10 secondary-btn" htmlType="submit">{t("common.search")}</Button>
                        </Col>

                    </Row>
                    <Row className="m-t-10" gutter={[, Constant.SpaceConstant.VerticalGutter]}>

                        <Col xs={{ span: 24 }}>
                            <Table
                                onRow={(record, rowIndex) => {
                                    return {
                                        onDoubleClick: event => selectedPickslip(record), // double click row
                                    };
                                }}
                                {...GetTableConfigs(columns, orderData, totalItems, "StockKey", onTableChange, gridConfigOptions.pageNumber)}
                            />
                        </Col>
                    </Row>
                </Form>
            </Modal>
        </div>
    )
}

export default OrderSearch