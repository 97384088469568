import React, { useState } from "react";
import "./loginAccount.scss";
import { Button, Form, Row, Col, Input, Select } from 'antd';
import { Constant, AuthService, CommonService, StorageService } from '../../services/services'
import { useTranslation } from 'react-i18next';
import { SupplierFillIcon, LockFillIcon } from '../icons/icons'
import LoadingContext from './../../components/utils/loading/loadingContext'
import { useLocation } from "react-router-dom";

const layout = {
    labelCol: { span: 24 },
    wrapperCol: { span: 24 }
};

const { Option } = Select;

export default function LoginAccount({ ...props }) {
    const { t, i18n } = useTranslation();
    const { showLoading, dismissLoading } = React.useContext(LoadingContext)
    const [suppliers, setSuppliers] = useState([])
    //const [suppliers, setSuppliers] = useState([])
    const [loginData, setLoginData] = useState({})
    const location = useLocation();

    const displayType = Object.freeze({
        login: 1,
        forgotPassword: 2,
        supplierSelect: 3
    })
    const [currentDisplayType, setCurrentDisplayType] = useState(displayType.login);

    const onSubmit = (data) => {
        let supplierId = null
        let supplierIdParam = location.search.toLowerCase().match("supplierid=(.*)")
        if (supplierIdParam) {
            supplierId = supplierIdParam[1]
        }
        doLogin(data.UserName, data.Password, supplierId)
    };

    const setDisplayType = (type) => {
        setCurrentDisplayType(type)
    };

    const onForgotPassword = (data) => {
        showLoading()
        AuthService.forgotPassword(data.UserName)
            .finally(() => dismissLoading())
            .then(() => {
                CommonService.presentToast("success", "Your password has been reset.")
                setDisplayType(displayType.login)
            })
            .catch(error => {
                CommonService.handleErrorResponse(error)
            })
    };

    const onSupplierSelect = (data) => {
        doLogin(loginData.UserName, loginData.Password, data.SupplierId)
    }

    const doLogin = (username, password, supplierId) => {
        showLoading()
        AuthService.login(username, password, supplierId)
            .finally(() => dismissLoading())
            .then(result => {
                if (result && props.onLogin) {
                    if (result.accountType == Constant.AccountType.Supplier) {
                        CommonService.applyDealerTheme(result.userId)
                        props.onLogin(Constant.Pages.BuyerManagement)
                    } else if (result.accountType == Constant.AccountType.Buyer) {
                        if (result.supplierId) {
                            CommonService.applyDealerTheme(result.supplierId)
                            if(result.functionCodes && result.functionCodes.includes(Constant.SupplierBuyerFunction.PartEnquiry)){
                                props.onLogin(Constant.Pages.PartEnquiry)
                            } else {
                                props.onLogin(Constant.Pages.BuyerDashboard)
                            }
                            
                        } else {
                            props.onLogin(Constant.Pages.SupplierManagement)
                        }
                    }
                }
            })
            .catch(error => {
                if (error.response && error.response.data && error.response.data.error == "SupplierSelectRequired") {
                    setSuppliers(JSON.parse(error.response.data.error_description))
                    setDisplayType(displayType.supplierSelect)
                    setLoginData({
                        UserName: username,
                        Password: password
                    })
                } else {
                    CommonService.handleErrorResponse(error);
                }
            })
    }

    return (
        <div className={`loginAccount ${props.className}`}>
            {currentDisplayType == displayType.login &&
                <div>
                    <p className="title heading-3 bold-text m-b-40" dangerouslySetInnerHTML={{ __html: t('landing_page.welcome') }}></p>
                    <Form
                        {...layout}
                        name="loginForm"
                        onFinish={onSubmit}
                        initialValues={props.objectData}
                        className="form-container"
                    >
                        <Row>
                            <Col span={24}>
                                <Form.Item
                                    name="UserName" colon={false} labelAlign="left"
                                    rules={[{ required: true, message: t("login.username_required") }]}
                                >
                                    <Input size="large" placeholder={ t("login.username") } prefix={<SupplierFillIcon />}  allowClear/>
                                </Form.Item>
                                <Form.Item
                                    name="Password" colon={false} labelAlign="left"
                                    rules={[{ required: true, message: t("login.password_required") }]}
                                >
                                    <Input.Password size="large" placeholder={t("login.password")} prefix={<LockFillIcon />}  allowClear/>
                                </Form.Item>
                            </Col>
                        </Row>
                        <Row className="m-t-15">
                            <Col span={24} className="footer">
                                <span className="forgot-pw" onClick={() => setDisplayType(displayType.forgotPassword)}>{t('login.forgot_password')}</span>
                                <Button size="large" className="login-button" type="primary" htmlType="submit">
                                    {t("login.login")}
                                </Button>
                            </Col>

                        </Row>
                    </Form>
                </div>
            }
            {currentDisplayType == displayType.forgotPassword &&
                <div className="stretch_container">
                    <p className="title heading-3 bold-text m-b-0" style={{ flexGrow: "1" }} dangerouslySetInnerHTML={{ __html: t('login.forgot_password_title') }}></p>

                    <Form
                        {...layout}
                        name="forgotPasswordForm"
                        onFinish={onForgotPassword}
                        initialValues={props.objectData}
                        className="form-container"
                        style={{ flexGrow: "2" }}
                    >
                        <Row>
                            <Col span={24}>
                                <Form.Item
                                    name="UserName" colon={false} labelAlign="left"
                                    rules={[{ required: true, message: t("login.username_required") }]}
                                >
                                    <Input size="large" placeholder={t("login.username")} prefix={<SupplierFillIcon />}  allowClear/>
                                </Form.Item>
                            </Col>
                        </Row>
                        <Row className="m-t-15 row-footer">
                            <Col span={24} className="forgot-footer">
                                <Button className="login-button" size="large" onClick={() => setDisplayType(displayType.login)}>
                                    {t("common.cancel")}
                                </Button>
                                <Button size="large" className="login-button m-l-10" type="primary" htmlType="submit">
                                    {t("common.submit")}
                                </Button>
                            </Col>

                        </Row>
                    </Form>
                </div>
            }
            {currentDisplayType == displayType.supplierSelect &&
                <div className="stretch_container">
                    <p className="title heading-3 bold-text m-b-0" style={{ flexGrow: "1" }} dangerouslySetInnerHTML={{ __html: t('login.select_supplier') }}></p>

                    <Form
                        {...layout}
                        name="supplierSelectForm"
                        onFinish={onSupplierSelect}
                        initialValues={props.objectData}
                        className="form-container"
                        style={{ flexGrow: "2" }}
                    >
                        <Row>
                            <Col span={24}>
                                <Form.Item
                                    label={t("common.supplier")}
                                    name="SupplierId" colon={false} labelAlign="left"
                                    rules={[{ required: true, message: t("login.supplier_required") }]}
                                >
                                    <Select allowClear dropdownClassName="wrap-text-dropdown">
                                        {
                                            suppliers.map((n, index) => <Option key={index} value={n.SupplierId} title={n.SupplierTradingName}>{n.SupplierTradingName}</Option>)
                                        }
                                    </Select>

                                </Form.Item>
                            </Col>
                        </Row>
                        <Row className="m-t-15 row-footer">
                            <Col span={24} className="footer">
                                <span className="forgot-pw" onClick={() => setDisplayType(displayType.login)}>{t('login.back_login')}</span>
                                <Button size="large" className="login-button m-l-10" type="primary" htmlType="submit">
                                    {t("common.select")}
                                </Button>
                            </Col>

                        </Row>
                    </Form>
                </div>
            }
        </div>

    );
}