import { createGlobalState } from 'react-hooks-global-state';

export const { useGlobalState, setGlobalState } = createGlobalState({
    suppliers: [],
    dealerships: [],
    notificationMessages: [],
    notificationOption: {},
    makeList: [],
    orderTypes: [], 
    partOrder: {},
    requestCreditReturnData: {},
    creditNoteReasonCodes: [],
    isActive: false,
    displayBackOrderEtaDates: false,
    debtorPartSundryCharges: [],
    isCollapsedPartQueryDetail: false,
    globalAreas: [],
    globalBureaucracies: [],
    globalCountryCodes: [],
    globalDeliveryMethods: [],
    globalDeliveryRuns: [],
    globalSystemAddressConfig: null,
    deliveryAddressDropdownData: null
});

export const resetGlobalState = () => {
    setGlobalState("suppliers", [])
    setGlobalState("dealerships", [])
    setGlobalState("notificationMessages", [])
    setGlobalState("notificationOption", {})
    setGlobalState("makeList", [])
    setGlobalState("orderTypes", [])
    setGlobalState("partOrder", {})
    setGlobalState("requestCreditReturnData", {})
    setGlobalState("creditNoteReasonCodes", [])
    setGlobalState("isActive", false)
    setGlobalState("displayBackOrderEtaDates", false)
    setGlobalState("debtorPartSundryCharges", [])
    setGlobalState("isCollapsedPartQueryDetail", false)
    setGlobalState("globalAreas", [])
    setGlobalState("globalBureaucracies", [])
    setGlobalState("globalCountryCodes", [])
    setGlobalState("globalDeliveryMethods", [])
    setGlobalState("globalDeliveryRuns", [])
    setGlobalState("globalSystemAddressConfig", null)
    setGlobalState("deliveryAddressDropdownData", null)
};