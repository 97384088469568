import axios from 'axios';
import { axiosPortal } from './authService'
import moment from 'moment';

const source = [];
let baseURL = "/Buyer"

export const OrderManagementService = {
    getPickslips: function (pageNumber, pageSize, filter, isExport = false) {
        let cancelToken = axios.CancelToken.source();
        source.push(cancelToken);
        let url = `${baseURL}/GetOrderListForBuyer`
        if (isExport) url = `${baseURL}/GetExportOrderManagement`
        url += `?PageNumber=${pageNumber}&PageSize=${pageSize}`

        if (filter) {
            if (filter.DateFrom) {
                let dateFormat = moment(filter.DateFrom).format("YYYY-MM-DD");
                url += `&DateFrom=${dateFormat}`;
            }
            if (filter.DateTo) {
                let dateFormat = moment(filter.DateTo).format("YYYY-MM-DD");
                url += `&DateTo=${dateFormat}`;
            }
            if (filter.OrderTypeCode) {
                url += `&OrderTypeCode=${filter.OrderTypeCode}`;
            }
            if (filter.OrderStatusCode) {
                url += `&OrderStatusCode=${filter.OrderStatusCode}`;
            }
            if (filter.PickslipNo) {
                url += `&PickslipNo=${filter.PickslipNo}`;
            }
            if (filter.OrderNumber) {
                url += `&OrderNumber=${filter.OrderNumber}`;
            }
            if (filter.InvoiceNo) {
                url += `&InvoiceNo=${filter.InvoiceNo}`;
            }
            if (filter.CreditNo) {
                url += `&CreditNo=${filter.CreditNo}`;
            }
            if (filter.DebtorPartNumber) {
                url += `&DebtorPartNumber=${filter.DebtorPartNumber}`;
            }
            if (filter.IsBackOrder) {
                url += `&IsBackOrder=${filter.IsBackOrder}`;
            }
            if (filter.MakeKey) {
                url += `&MakeKey=${filter.MakeKey}`;
            }
            if (filter.Sort) {
                url += `&Sort=${filter.Sort}`;
            }
        }
        var configs = { cancelToken: cancelToken.token };
        if (isExport) configs.responseType = 'blob'

        return axiosPortal.get(url, configs).finally(() => {
            let index = source.indexOf(cancelToken)
            if (index > -1) {
                source.splice(index, 1)
            }
        })
    },
    getOrderPartLines: function (pickslipKey, makeId) {
        let cancelToken = axios.CancelToken.source()
        source.push(cancelToken)
        let url = `${baseURL}/GetOrderPartLines?pickslipKey=${pickslipKey}`
        if (makeId)
            url += `&makeId=${makeId}`;

        return axiosPortal.get(url, {
            cancelToken: cancelToken.token
        }).finally(() => {
            let index = source.indexOf(cancelToken)
            if (index > -1) {
                source.splice(index, 1)
            }
        })
    },
    getOrderSundryLines: function (pickslipKey) {
        let cancelToken = axios.CancelToken.source()
        source.push(cancelToken)
        let url = `${baseURL}/GetOrderSundryLines?pickslipKey=${pickslipKey}`

        return axiosPortal.get(url, {
            cancelToken: cancelToken.token
        }).finally(() => {
            let index = source.indexOf(cancelToken)
            if (index > -1) {
                source.splice(index, 1)
            }
        })
    },
    cancelRequest: function () {
        if (source && source.length > 0) {
            for (var i = 0; i < source.length; i++) {
                source[i].cancel('Operation canceled.')
            }
            source.splice(0, source.length)
        }
    }
}