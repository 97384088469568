import axios from 'axios';
import { axiosPortal } from './authService'
import moment from 'moment';

const source = [];
let baseURL = "/Supplier"

export const SupplierSettingService = {
    getSupplierSetting: function () {
        let cancelToken = axios.CancelToken.source();
        source.push(cancelToken);

        return axiosPortal.get(`${baseURL}/GetSupplierSetting`, {
            cancelToken: cancelToken.token
        }).finally(() => {
            let index = source.indexOf(cancelToken)
            if (index > -1) {
                source.splice(index, 1)
            }
        })
    },
    updateSupplier: function (data) {
        let cancelToken = axios.CancelToken.source()
        source.push(cancelToken)
        return axiosPortal.put(`${baseURL}/UpdateSupplier`, data, {
            cancelToken: cancelToken.token
        }).finally(() => {
            let index = source.indexOf(cancelToken)
            if (index > -1) {
                source.splice(index, 1)
            }
        })
    },
    getDealershipThemes: function () {
        let cancelToken = axios.CancelToken.source();
        source.push(cancelToken);

        return axiosPortal.get(`${baseURL}/GetDealershipThemes`, {
            cancelToken: cancelToken.token
        }).finally(() => {
            let index = source.indexOf(cancelToken)
            if (index > -1) {
                source.splice(index, 1)
            }
        })
    },
    getLocationGeocode: function (address) {
        let cancelToken = axios.CancelToken.source();
        source.push(cancelToken);

        return axiosPortal.get(`https://maps.googleapis.com/maps/api/geocode/json?key=${process.env.REACT_APP_MAP_KEY}&address=${address}`, {
            cancelToken: cancelToken.token
        }).finally(() => {
            let index = source.indexOf(cancelToken)
            if (index > -1) {
                source.splice(index, 1)
            }
        })
    },
    cancelRequest: function () {
        if (source && source.length > 0) {
            for (var i = 0; i < source.length; i++) {
                source[i].cancel('Operation canceled.')
            }
            source.splice(0, source.length)
        }
    } 
}