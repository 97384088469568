import React, { useState, useEffect } from 'react';
import './editBuyer.scss';
import { Button, Row, Col, Form, Input, Radio, Switch, Modal, Breadcrumb } from 'antd';
import { ExclamationCircleOutlined } from '@ant-design/icons';
import { useTranslation } from 'react-i18next';
import { Redirect, Link, useLocation, useHistory } from "react-router-dom";
import { StatusCell, DateTimeCell } from '../../../components/utils/gridUtils/gridUtils'
import MultiSelectionListBox from "../../../components/multiSelectionListBox/multiSelectionListBox"
import { Constant, CommonService, SupplierBuyerService } from '../../../services/services';
import LoadingContext from '../../../components/utils/loading/loadingContext'
import { toast } from 'react-toastify';
import { ChevronLeftIcon } from './../../../components/icons/icons';

const { confirm } = Modal;
const { TextArea } = Input;

const layout = {
    labelCol: { span: 24 },
    wrapperCol: { span: 24 }
};

export default function BuyerManagement_EditBuyer(props) {
    const { t } = useTranslation();
    const [formApproved] = Form.useForm();
    const [formReject] = Form.useForm();
    const { showLoading, dismissLoading } = React.useContext(LoadingContext)
    const [actionCode, setActionCode] = useState(0);
    const [active, setActive] = useState(true);
    const [rejectReasons, setRejectReasons] = useState("");
    const [functions, setFunctions] = useState([]);
    const [selectedFunctions, setSelectedFunctions] = useState([]);

    const history = useHistory();
    const location = useLocation();
    const buyerItem = location.state;
    const [dmsDebtorId, setDmsDebtorId] = useState(buyerItem.DmsDebtorId);
    const [originalDmsDebtorId, setOriginalDmsDebtorId] = useState(buyerItem.DmsDebtorId);
    const [existsSubmittedOrder, setExistsSubmittedOrder] = useState(false);

    useEffect(() => {
        formApproved.setFieldsValue({
            id: 1
        });

        setActive(buyerItem.StatusCode === Constant.StatusCode.Active);
        if (isApprovedStatus(buyerItem.RequestStatusCode)) {
            setActionCode(Constant.SupplierBuyerActionCode.Approve);
        } else if (buyerItem.RequestStatusCode === Constant.AccountRequestStatusCode.Rejected) {
            setActionCode(Constant.SupplierBuyerActionCode.Reject);
        } else {
            setActionCode(0);
        }
        showLoading();
        Promise.all([
            SupplierBuyerService.getAppFunctions(),
            SupplierBuyerService.getSupplierBuyerFunctions(buyerItem.BuyerId, buyerItem.SupplierId),
            SupplierBuyerService.checkExistsSubmittedOrder(buyerItem.BuyerId, buyerItem.SupplierId, buyerItem.DmsDebtorId),
        ]).then(r => {
            if (r[0] && r[0].data) {
                let _fns = [];
                r[0].data.forEach(ele => {
                    _fns.push({ value: ele.Code, label: ele.Description });
                });
                setFunctions(_fns);
            }
            if (r[1] && r[1].data) {
                setSelectedFunctions(r[1].data.map(p => p.AppFunctionCode));
            }
            if (r[2] && r[2].data) {
                if (r[2].data.IsExists) {
                    setExistsSubmittedOrder(true);
                } else {
                    setExistsSubmittedOrder(false);
                }
            }
        }).finally(() => {
            dismissLoading();
        });

        if(buyerItem.DmsDebtorId && !buyerItem.DmsDebtorName){
            getAndValidateDmsDebtorId(buyerItem.DmsDebtorId, true)
        }

        return () => {
            SupplierBuyerService.cancelRequest()
        };
    }, []);

    if (!location.state) {
        return <Redirect to={Constant.Pages.BuyerManagement} />
    }

    const isApprovedStatus = code => {
        return (
            code === Constant.AccountRequestStatusCode.Approved
            || code === Constant.AccountRequestStatusCode.PendingForActivation
            || code === Constant.AccountRequestStatusCode.PendingForDeactivation
        );
    }

    const onCancel = () => {
        history.goBack();
    }

    const onChangeActive = checked => {
        setActive(checked);
    }

    const getAndValidateDmsDebtorId = (dmsDebtorId, isGetDmsDebtorOnly) => {
        showLoading()
        return SupplierBuyerService.getAndValidateDmsDebtorId(buyerItem.BuyerId, buyerItem.SupplierId, dmsDebtorId, isGetDmsDebtorOnly)
            .finally(() => dismissLoading())
            .then(result => {
                setDmsDebtorId(dmsDebtorId);
                if (result.data) {
                    setDmsDebtorName(result.data)
                }

                return true;
            })
            .catch(error => {
                setDmsDebtorName(null)
                CommonService.handleErrorResponse(error)
                return false
            });
    }

    const onSubmit_ApproveRequest = async (data) => {
        if (selectedFunctions.length > 0) {
            const check = await getAndValidateDmsDebtorId(data.DmsDebtorId);
            if (!check) return;
            let statusCode = active ? Constant.StatusCode.Active : Constant.StatusCode.Inactive;
            let updateData = () => {
                let updateData = {
                    BuyerId: buyerItem.BuyerId,
                    SupplierId: buyerItem.SupplierId,
                    StatusCode: statusCode,
                    AppFunctions: selectedFunctions,
                    DmsDebtorId: data.DmsDebtorId,
                    DmsDebtorName: data.DmsDebtorName
                };
                if (!isApprovedStatus(buyerItem.RequestStatusCode)) {
                    updateData["ActionCode"] = Constant.SupplierBuyerActionCode.Approve;
                }
                showLoading()
                SupplierBuyerService.updateSupplierBuyer(updateData)
                    .finally(() => dismissLoading())
                    .then(r => {
                        history.goBack();
                    });
            }
            if (buyerItem.StatusCode === statusCode) {
                updateData();
            } else {
                confirm({
                    title: t("common.confirm"),
                    icon: <ExclamationCircleOutlined />,
                    content: active ? t("buyer_management.confirm_active_request") : t("buyer_management.confirm_inactive_request"),
                    okText: t("common.ok"),
                    cancelText: t("common.cancel"),
                    onOk() {
                        updateData();
                    },
                    onCancel() { }
                });
            }
        } else {
            CommonService.presentToast(toast.TYPE.ERROR, t("buyer_management.select_functions_required"));
        }
    }

    const setDmsDebtorName = (value) => {
        formApproved.setFieldsValue({
            DmsDebtorName: value
        })
    }

    const dmsDebtorIdOnFocus = (e) => {
        if (e.target) {
            setOriginalDmsDebtorId(e.target.value)
        }
    }

    const onChangeAndCheckDmsDebtorId = async dmsDebtorId => {
        if (!dmsDebtorId) {
            setDmsDebtorName(null)
            return
        }
        if (dmsDebtorId == originalDmsDebtorId) return;
        getAndValidateDmsDebtorId(dmsDebtorId)
    }

    const onSubmit_RejectRequest = (data) => {
        let rejectData = () => {
            showLoading()
            SupplierBuyerService.updateSupplierBuyer({
                BuyerId: buyerItem.BuyerId,
                SupplierId: buyerItem.SupplierId,
                ActionCode: Constant.SupplierBuyerActionCode.Reject,
                RejectReasons: data.RejectReasons
            })
                .finally(() => dismissLoading())
                .then(r => {
                    history.goBack();
                });
        }
        if (buyerItem.RequestStatusCode === Constant.AccountRequestStatusCode.Rejected) {
            rejectData();
        } else {
            confirm({
                title: t("common.confirm"),
                icon: <ExclamationCircleOutlined />,
                content: t("buyer_management.confirm_reject_request"),
                okText: t("common.ok"),
                cancelText: t("common.cancel"),
                onOk() {
                    rejectData();
                },
                onCancel() { }
            });
        }
    };

    const onSave = () => {
        if (isApprovedStatus(buyerItem.RequestStatusCode)) {
            formApproved.submit();
        } else if (actionCode === Constant.SupplierBuyerActionCode.Approve) {
            formApproved.submit();
        } else if (actionCode === Constant.SupplierBuyerActionCode.Reject) {
            formReject.submit();
        }
    }

    const _componentHeader = () => {
        return (
            <Breadcrumb className='has-child'>
                <Breadcrumb.Item onClick={() => { history.replace(Constant.Pages.BuyerManagement, location.state.prevState) }}>
                    <span className='back-icon'><ChevronLeftIcon /></span>
                    <span>{t("menu.buyer_management")}</span>
                </Breadcrumb.Item>
                <Breadcrumb.Item>{t("common.edit")}</Breadcrumb.Item>
            </Breadcrumb>
        );
    }

    const _componentBuyerInfo = () => {
        return (
            <div>
                <Row gutter={[Constant.SpaceConstant.HorizontalGutter, Constant.SpaceConstant.VerticalGutter]}>
                    <Col xs={{ span: 6 }}>
                        <label className="title-field">{t("buyer_management.buyer_trading_name")}</label>
                        <label className="text-field">{buyerItem?.BuyerTradingName || '_'}</label>
                    </Col>
                    <Col xs={{ span: 6 }}>
                        <label className="title-field">{t("buyer_management.contact_name")}</label>
                        <label className="text-field">{buyerItem?.BuyerContactName || '_'}</label>
                    </Col>
                    <Col xs={{ span: 6 }}>
                        <label className="title-field">{t("buyer_management.contact_phone")}</label>
                        <label className="text-field">{buyerItem?.BuyerContactPhone || '_'}</label>
                    </Col>

                    <Col xs={{ span: 6 }}>
                        <label className="title-field">{t("common.email")}</label>
                        <label className="text-field">{buyerItem?.BuyerContactEmail || '_'}</label>
                    </Col>
                    <Col xs={{ span: 6 }}>
                        <label className="title-field">{t("buyer_management.requested_date")}</label>
                        <DateTimeCell className="text-field" value={buyerItem.RequestedDate} />
                    </Col>
                    <Col xs={{ span: 6 }} className='flex-items'>
                        <label className="title-field m-r-20">{t("orders_management.status")}</label>
                        <StatusCell
                            data={buyerItem}
                            colorClass={CommonService.getRequestStatusColorClass(buyerItem.RequestStatusCode)}
                            description={CommonService.getRequestStatusDescription(buyerItem.RequestStatusCode)} />
                    </Col>
                    <Col xs={{ span: 6 }} className='flex-items' hidden={!isApprovedStatus(buyerItem.RequestStatusCode)}>

                        <label className="title-field m-r-20">{t("common.status")}</label>
                        <Switch checked={active} onChange={onChangeActive} />
                    </Col>
                </Row>
                <Row gutter={[Constant.SpaceConstant.HorizontalGutter, 5]} hidden={isApprovedStatus(buyerItem.RequestStatusCode)}
                    className='m-t-10'>

                    <Col xs={{ span: 24 }} className='flex-items label-status'>
                        <label className="title-field m-r-30">{t("buyer_management.select_an_action")}</label>
                        <Radio.Group name="Action" defaultValue={actionCode} value={actionCode} onChange={(e) => { setActionCode(e.target.value) }}>
                            <Radio value={Constant.SupplierBuyerActionCode.Approve}>{t('common.approve')}</Radio>
                            <Radio value={Constant.SupplierBuyerActionCode.Reject}>{t('common.reject')}</Radio>
                        </Radio.Group>
                    </Col>
                </Row>
            </div>
        );
    }

    const _componentApproved = (isShow) => {
        return (
            <Row gutter={[, 20]} className='m-t-25'>
                <Col xs={{ span: 24 }}>
                    <Form {...layout} form={formApproved} onFinish={onSubmit_ApproveRequest}
                        initialValues={{ DmsDebtorId: buyerItem.DmsDebtorId, Functions: selectedFunctions, DmsDebtorName: buyerItem.DmsDebtorName }}
                        hidden={!isShow}>
                        <Row gutter={[Constant.SpaceConstant.HorizontalGutter, 5]}>
                            <Col xs={{ span: 5 }}>
                                <Form.Item
                                    label={t('buyer_management.dms_debtor_id')}
                                    name="DmsDebtorId" colon={false} labelAlign="left" required="true"
                                    rules={[{ required: true, message: t("buyer_management.dms_debtorid_required") }]}
                                >
                                    <Input disabled={existsSubmittedOrder} placeholder={t('buyer_management.dms_debtor_id')} onPressEnter={e => { onChangeAndCheckDmsDebtorId(e.target.value); }} onBlur={e => { onChangeAndCheckDmsDebtorId(e.target.value); }} allowClear onFocus={e => dmsDebtorIdOnFocus(e)} />
                                </Form.Item>
                            </Col>
                            <Col xs={{ span: 9 }}>
                                <Form.Item
                                    label={t('buyer_management.dms_debtor_name')}
                                    name="DmsDebtorName" colon={false} labelAlign="left"
                                >
                                    <Input disabled />
                                </Form.Item>
                            </Col>
                        </Row>
                        <Row gutter={[Constant.SpaceConstant.HorizontalGutter, 5]}>
                            <Col xs={{ span: 10 }}>
                                <Form.Item
                                    label={t('buyer_management.select_functions')}
                                    colon={false} labelAlign="left" required="true" name="Functions"
                                >
                                    <MultiSelectionListBox
                                        title={t('buyer_management.functions')}
                                        dataSource={functions}
                                        initialValues={selectedFunctions}
                                        onChange={(items) => { setSelectedFunctions(items) }} />
                                </Form.Item>
                            </Col>
                        </Row>
                    </Form>
                </Col>
            </Row>

        );
    }

    const _componentRejected = (isShow) => {
        return (
            <Form form={formReject} onFinish={onSubmit_RejectRequest}
                initialValues={{ RejectReasons: buyerItem.RejectReasons }} hidden={!isShow}
                labelCol={{ span: 3 }}
                labelAlign='left'
                wrapperCol={{ span: 16 }}>
                <Row gutter={[Constant.SpaceConstant.HorizontalGutter, 5]} className="m-t-20">
                    <Col xs={{ span: 24 }}>
                        <Form.Item
                            name="RejectReasons"
                            label={t('buyer_management.reject_reasons')}
                            rules={[{ required: true, message: t("buyer_management.reject_reasons_required") }]}>
                            <TextArea
                                value={rejectReasons}
                                onChange={(e) => setRejectReasons(e.target.value)}
                                placeholder={t('buyer_management.reject_reasons')}
                                autoSize={{ minRows: 5, maxRows: 6 }}
                                allowClear
                            />
                        </Form.Item>
                    </Col>
                </Row>
            </Form>
        );
    }

    const _componentToolbar = () => {
        return (
            <Row className="m-t-10" justify="end">
                <Col xs={{ span: 24 }} className="text-right">
                    <Button size="large" onClick={onCancel}>{t('common.cancel')}</Button>
                    {actionCode === Constant.SupplierBuyerActionCode.Approve
                        ? <Button size="large" type="primary" className="m-l-10" onClick={onSave}
                            disabled={(!dmsDebtorId || selectedFunctions.length === 0)}>
                            {t('common.save')}
                        </Button>
                        : ''
                    }
                    {actionCode === Constant.SupplierBuyerActionCode.Reject
                        ? <Button size="large" type="primary" className="m-l-10" onClick={onSave}
                            disabled={!rejectReasons}>
                            {t('common.save')}
                        </Button>
                        : ''
                    }
                </Col>
            </Row>
        );
    }

    return (
        <div className="edit-buyer-page">
            {_componentHeader()}
            <Row className="m-t-10">
                <Col xs={{ span: 24 }}>
                    {_componentBuyerInfo()}
                    {_componentApproved(actionCode === Constant.SupplierBuyerActionCode.Approve)}
                    {_componentRejected(actionCode === Constant.SupplierBuyerActionCode.Reject)}
                </Col>
            </Row>
            {_componentToolbar()}
        </div>
    );
}
