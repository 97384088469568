import React, { useState, useEffect, useContext } from 'react';
import './pickslipDetails.scss';
import { Button, Row, Col, Form, Table, Breadcrumb, Tabs, Typography, Modal, Tooltip } from 'antd';
import { useTranslation } from 'react-i18next';
import { GetTableConfigs, DateTimeCell, StatusCell, TextAndButtonCell, ActionButtonsCell } from '../../../components/utils/gridUtils/gridUtils';
import { ChevronLeftIcon, ViewIcon, DownloadIcon } from './../../../components/icons/icons';
import { Constant, OrderManagementService, CommonService, StorageService, BuyerService, MediaService } from '../../../services/services';
import LoadingContext from './../../../components/utils/loading/loadingContext';
import { Redirect, useLocation, useHistory, Link } from "react-router-dom";
import { useGlobalState } from '../../../utilities/globalState';

const { Text } = Typography;
const { TabPane } = Tabs;

export default function PickslipDetails(props) {
    const { t } = useTranslation();
    const location = useLocation();
    const history = useHistory();

    const { showLoading, dismissLoading } = useContext(LoadingContext)
    const [pickslipInfo, setPickslipInfo] = useState({});
    const [listOfPart, setListOfPart] = useState([]);
    const [listOfSundry, setListOfSundry] = useState([]);
    const [visibleCreditNoPopOut, setVisibleCreditNoPopOut] = useState(false);
    const [creditNoPopOutHeader, setCreditNoPopOutHeader] = useState({});
    const [taxIncluded, setTaxIncluded] = useState(true);
    const [orderTypes, setOrderTypes] = useGlobalState('orderTypes');
    const [visibleDownloadDocumentModal, setVisibleDownloadDocumentModal] = useState(false);

    let IsCreditPickslip = false;
    if(location.state.TotalPartsOrdered < 0 
        && (location.state.TotalAmountOrderedTaxExclusive < 0 
            || location.state.TotalAmountOrderedTaxInclusive < 0)
    ){
        IsCreditPickslip = true;
    }

    const columns_parts = [{
        "title": <div dangerouslySetInnerHTML={{ __html: t("orders_management.pickslip_line_id_wrapped") }}></div>,
        "dataIndex": "PickslipLineID",
        "key": "PickslipLineID",
        "width": 80
    }, {
        "title": t("orders_management.part_number"),
        "dataIndex": "Key",
        "key": "Key",
        "width": 200,
        render: (value, record) => (
            <TextAndButtonCell value={record.PartItemCode} tooltipText={t("common.view_part_details")}
                buttonType={Constant.ButtonWithModalType.PartEnquiry}
                data={record} icon={<ViewIcon />} includeWhiteSpaces={true} />
        )
    }, {
        "title": t("orders_management.description"),
        "dataIndex": "PartDescription",
        "key": "PartDescription",
        "width": 180
    }, {
        "title": t("orders_management.make"),
        "dataIndex": "MakeId",
        "key": "MakeId",
        "width": 120
    }, {
        "title": <div className="text-center" dangerouslySetInnerHTML={{ __html: taxIncluded ? t('orders_management.unit_price_inc') : t('orders_management.unit_price_ex') }}></div>,
        "dataIndex": "UnitPrice",
        "key": "UnitPrice",
        "width": 120,
        "align": "center",
        render: (value, record) => {
            if (record.IsKitComponent) {
                return <></>;
            }
            return (
                <>{taxIncluded
                    ? (record.PriceTaxInclusive ? record.PriceTaxInclusive.toFixed(Constant.NumberOfDecimalPlace) : null)
                    : (record.PriceTaxExclusive ? record.PriceTaxExclusive.toFixed(Constant.NumberOfDecimalPlace) : null)
                }</>
            );
        }
    }, {
        "title": t("orders_management.qty_ordered"),
        "dataIndex": "QtyOrdered",
        "key": "QtyOrdered",
        "width": 80,
        "align": "center",
        render: (value, record) => {
            if (record.IsKitComponent) {
                return <></>;
            }
            return <>{record.QtyOrdered}</>;
        }
    }, {
        "title": <div className="text-center" dangerouslySetInnerHTML={{ __html: taxIncluded ? t('orders_management.total_amt_inc') : t('orders_management.total_amt_ex') }}></div>,
        "dataIndex": "TotalAmt",
        "key": "TotalAmt",
        "width": 120,
        "align": "center",
        render: (value, record) => {
            if (record.IsKitComponent) {
                return <></>;
            }
            return (
                <>{taxIncluded
                    ? (record.TotalAmtTaxInclusive ? record.TotalAmtTaxInclusive.toFixed(Constant.NumberOfDecimalPlace) : null)
                    : (record.TotalAmtTaxExclusive ? record.TotalAmtTaxExclusive.toFixed(Constant.NumberOfDecimalPlace) : null)
                }</>
            );
        }
    }, {
        "title": t("orders_management.bo_method"),
        "dataIndex": "BackOrderMethod",
        "key": "BackOrderMethod",
        "width": 120,
        render: (value, record) => {
            return <Tooltip placement="bottom" title={record.BackOrderMethodDescription}>
                <span>{record.BackOrderMethod}</span>
            </Tooltip>
        }
    }, {
        "title": t("orders_management.location"),       
        "dataIndex": "CustomerNormalBinLocation",
        "key": "CustomerNormalBinLocation",
        "width": 120,      
    }, {
        "title": t("orders_management.cancelled_date"),
        "dataIndex": "CancelledDate",
        "key": "CancelledDate",
        "width": 120,
        "align": "center",
        render: (value, record) => (
            pickslipInfo.PickslipStatusCode === Constant.PartOrderStatus.Delete
                ? <DateTimeCell value={record.LastUpdateTimestamp} />
                : null
        )
    }, {
        "title": IsCreditPickslip ?  t("orders_management.credit_date") : t("orders_management.invoice_date"),
        "dataIndex": "InvoiceDate",
        "key": "InvoiceDate",
        "width": 120,
        "align": "center",
        render: (value, record) => {
            return record.InvoiceNo ? <DateTimeCell value={record.InvoiceDate} /> : <></>;
        }
    }, {
        "title": IsCreditPickslip ?  t("orders_management.credit_no") : t("orders_management.invoice_no"),
        "dataIndex": "InvoiceNo",
        "key": "InvoiceNo",
        "width": 120,
        render: (value, record) => (<>{ (!IsCreditPickslip && record.OriginalInvoiceNo) ? record.OriginalInvoiceNo : record.InvoiceNo}</>)
    }, {
        "title": IsCreditPickslip ?  t("orders_management.invoice_info") : t("orders_management.credit_info"),
        "dataIndex": "CreditNo",
        "key": "CreditNo",
        "width": 120,
        "align": "center",
        render: (value, record) => (
            <>
                {record.CreditNo &&
                    <Button icon={<ViewIcon />} size="small" className="action-btn" onClick={() => { showCreditNoPopout(record); }} />}
            </>
        )
    }];

    const columns_sundries = [{
        "title": t("orders_management.sundry"),
        "dataIndex": "SundryChargeId",
        "key": "SundryChargeId",
        "width": 250
    }, {
        "title": <div className="text-left" dangerouslySetInnerHTML={{ __html: t('orders_management.pickslip_line_id_2') }}></div>,
        "dataIndex": "PartLineCounter",
        "key": "PartLineCounter",
        "width": 200
    }, {
        "title": <div className="text-left" dangerouslySetInnerHTML={{ __html: taxIncluded ? t('orders_management.amount_inc') : t('orders_management.amount_ex') }}></div>,
        "dataIndex": "Price",
        "key": "Price",
        "width": 200,
        render: (value, record) => (
            <>{taxIncluded
                ? (record.PriceTaxInclusive ? record.PriceTaxInclusive.toFixed(Constant.NumberOfDecimalPlace) : null)
                : (record.PriceTaxExclusive ? record.PriceTaxExclusive.toFixed(Constant.NumberOfDecimalPlace) : null)
            }</>
        )
    }, {
        "title": <div className="text-left" dangerouslySetInnerHTML={{ __html: taxIncluded ? t('orders_management.credited_inc') : t('orders_management.credited_ex') }}></div>,
        "dataIndex": "TotalAmtCredited",
        "key": "TotalAmtCredited",
        "width": 200,
        render: (value, record) => (
            <>{!taxIncluded
                ? (record.TotalAmtCreditedTaxInclusive ? record.TotalAmtCreditedTaxInclusive.toFixed(Constant.NumberOfDecimalPlace) : null)
                : (record.TotalAmtCreditedTaxExclusive ? record.TotalAmtCreditedTaxExclusive.toFixed(Constant.NumberOfDecimalPlace) : null)
            }</>
        )
    }];

    const columns_credit_no_popout = [{
        "title": IsCreditPickslip ? t("orders_management.invoice_no") : t("orders_management.credit_no"),
        "dataIndex": "InvoiceNo",
        "key": "InvoiceNo",
        "width": 200,
        render: (value, record) => (<>{record.OriginalInvoiceNo ? record.OriginalInvoiceNo : record.InvoiceNo}</>)
    }, {
        "title": IsCreditPickslip ? t("orders_management.invoice_date") : t("orders_management.credit_date"),
        "dataIndex": "InvoiceDate",
        "key": "InvoiceDate",
        "width": 120,
        "align": "center",
        render: (value, record) => {
            if(record.OriginalInvoiceNo) {
                return record.OriginalInvoiceDate ? <DateTimeCell value={record.OriginalInvoiceDate} /> : <></>;
            } else {
                return record.InvoiceDate ? <DateTimeCell value={record.InvoiceDate} /> : <></>;
            }
        }
    },
    {
        "title": t("orders_management.qty"),
        "dataIndex": "Qty",
        "key": "Qty",
        "width": 200,
        "align": "center"
    }, {
        "title": <div className="text-center" dangerouslySetInnerHTML={{ __html: taxIncluded ? t("orders_management.total_amt_inc") : t("orders_management.total_amt_ex") }}></div>,
        "dataIndex": "Amt",
        "key": "Amt",
        "width": 200,
        "align": "center",
        render: (value, record) => (
            <>{taxIncluded 
                ? (record.Amt ? record.Amt.toFixed(Constant.NumberOfDecimalPlace) : null)
                : (record.AmtTaxExclusive ? record.AmtTaxExclusive.toFixed(Constant.NumberOfDecimalPlace) : null)}</>
        )
    }];

    const showCreditNoPopout = item => {
        item.CreditInformation.forEach(p => {
            p["OriginalInvoiceNo"] = item.OriginalInvoiceNo;
            p["OriginalInvoiceDate"] = item.OriginalInvoiceDate;
        });
        setCreditNoPopOutHeader(item);
        setVisibleCreditNoPopOut(true);
    }
    const cancelCreditNoPopout = () => {
        setVisibleCreditNoPopOut(false);
        setCreditNoPopOutHeader({});
    }

    const _getTableConfigs_Parts = () => {
        let configs = GetTableConfigs(columns_parts, listOfPart, null, "PickslipLineID", null, null, "", false);
        let totalQtyOrdered = (Array.isArray(listOfPart) && listOfPart.length > 0 ? listOfPart.filter(p => !p.IsKitComponent).map(p => p.QtyOrdered) : [0])
            .reduce((a, b) => a + b)
            .toFixed(Constant.NumberOfDecimalPlace);
        if (totalQtyOrdered && totalQtyOrdered.toString().length > (Constant.NumberOfDecimalPlace + 1)) {
            let decimalZero = "";
            for (let i = 0; i < Constant.NumberOfDecimalPlace; i++) {
                decimalZero += "0";
            }
            let decimalPart = totalQtyOrdered.toString().substr(totalQtyOrdered.length - (Constant.NumberOfDecimalPlace + 1));
            if (decimalPart == `.${decimalZero}` || decimalPart == `,${decimalZero}`) {
                totalQtyOrdered = totalQtyOrdered.toString().substr(0, totalQtyOrdered.length - (Constant.NumberOfDecimalPlace + 1));
            }
        }

        let totalTotalAmt = (Array.isArray(listOfPart) && listOfPart.length > 0 ? listOfPart.filter(p => !p.IsKitComponent).map(p => taxIncluded ? p.TotalAmtTaxInclusive : p.TotalAmtTaxExclusive) : [0])
            .reduce((a, b) => a + b)
            .toFixed(Constant.NumberOfDecimalPlace);

        configs["summary"] = () => (
            <Table.Summary.Row>
                <Table.Summary.Cell>
                    <Text className="table-summary-value">{t('orders_management.total')}</Text>
                </Table.Summary.Cell>
                <Table.Summary.Cell></Table.Summary.Cell>
                <Table.Summary.Cell></Table.Summary.Cell>
                <Table.Summary.Cell></Table.Summary.Cell>
                <Table.Summary.Cell></Table.Summary.Cell>
                <Table.Summary.Cell className="text-center">
                    <Text className="table-summary-value">{totalQtyOrdered}</Text>
                </Table.Summary.Cell>
                <Table.Summary.Cell className="text-center">
                    <Text className="table-summary-value">{totalTotalAmt}</Text>
                </Table.Summary.Cell>
                <Table.Summary.Cell></Table.Summary.Cell>
                <Table.Summary.Cell></Table.Summary.Cell>
                <Table.Summary.Cell></Table.Summary.Cell>
                <Table.Summary.Cell></Table.Summary.Cell>
                <Table.Summary.Cell></Table.Summary.Cell>
            </Table.Summary.Row>
        );
        return configs;
    }

    const _getTableConfigs_Sundries = () => {
        let configs = GetTableConfigs(columns_sundries, listOfSundry, null, "Key", null, null, "", false);
        let totalTotalPrice = (Array.isArray(listOfSundry) && listOfSundry.length > 0 ? listOfSundry.map(p => taxIncluded ? p.PriceTaxInclusive : p.PriceTaxExclusive) : [0])
            .reduce((a, b) => a + b)
            .toFixed(Constant.NumberOfDecimalPlace);

        let totalTotalCredited = (Array.isArray(listOfSundry) && listOfSundry.length > 0 ? listOfSundry.map(p => taxIncluded ? p.TotalAmtCreditedTaxInclusive : p.TotalAmtCreditedTaxExclusive) : [0])
            .reduce((a, b) => a + b)
            .toFixed(Constant.NumberOfDecimalPlace);
        configs["summary"] = () => (
            <Table.Summary.Row>
                <Table.Summary.Cell>
                    <Text className="table-summary-value">{t('orders_management.total')}</Text>
                </Table.Summary.Cell>
                <Table.Summary.Cell></Table.Summary.Cell>
                <Table.Summary.Cell>
                    <Text className="table-summary-value">{totalTotalPrice}</Text>
                </Table.Summary.Cell>
                <Table.Summary.Cell>
                    <Text className="table-summary-value">{totalTotalCredited}</Text>
                </Table.Summary.Cell>
            </Table.Summary.Row>
        );
        return configs;
    }

    useEffect(() => {
        let timeoutFn = setTimeout(() => document.documentElement.style.setProperty(Constant.CssVariables.FixItemsContainerHeight, CommonService.calculateTableBodyMaxHeight()), 100);
        if (!orderTypes || orderTypes.length === 0) {
            showLoading();
            BuyerService.getOrderTypes()
                .then(result => {
                    if (result.data) {
                        setOrderTypes(result.data)
                    }
                })
                .finally(() => {
                    dismissLoading();
                })
                .catch(error => CommonService.handleErrorResponse(error))
        }
        let sot = orderTypes.find(p => p.Code === location.state.SalesOrderType);
        setPickslipInfo({
            "PickslipDate": location.state.PickslipDate,
            "OrderType": sot ? sot.Description : null,
            "PickslipStatusCode": location.state.PickslipStatusCode,
            "PickslipNo": location.state.PickslipNo,
            "OrderNo": location.state.DebtorOrderNo
        });
        let authData = StorageService.getAuthData()
        if (authData) {
            setTaxIncluded(authData.isShowPriceTaxIncluded)
        }

        showLoading();
        Promise.all([
            OrderManagementService.getOrderPartLines(location.state.PickslipKey),
            OrderManagementService.getOrderSundryLines(location.state.PickslipKey)
        ]).then(r => {
            setListOfPart(r[0].data);
            setListOfSundry(r[1].data);
        }).finally(() => {
            dismissLoading();
        });
        return () => {
            OrderManagementService.cancelRequest()
            clearTimeout(timeoutFn);
        };
    }, []);

    if (!location.state) {
        return <Redirect to={Constant.Pages.OrderManagement} />
    }

    const onChangeTabs = tabKey => { }

    const _componentPickslipInfo = () => {
        return (
            <Row className="m-t-25" id={Constant.FixItemsContainerId} gutter={[Constant.SpaceConstant.HorizontalGutter, 5]}>
                <Col xs={{ span: 4 }}>
                    <label className="title-field">{t("orders_management.pickslip_date")}</label>
                    <DateTimeCell className="text-field" value={pickslipInfo.PickslipDate} />
                </Col>
                <Col xs={{ span: 4 }}>
                    <label className="title-field">{t("orders_management.pickslip_no")}</label>
                    <label className="text-field">{pickslipInfo?.PickslipNo || '_'}</label>
                </Col>
                <Col xs={{ span: 4 }}>
                    <label className="title-field">{t("orders_management.order_no")}</label>
                    <label className="text-field">{pickslipInfo?.OrderNo || '_'}</label>
                </Col>
                <Col xs={{ span: 4 }}>
                    <label className="title-field">{t("orders_management.order_type")}</label>
                    <label className="text-field">{pickslipInfo?.OrderType || '_'}</label>
                </Col>
                <Col xs={{ span: 4 }} >
                    <label className="title-field">{t("orders_management.status")}</label>
                    <StatusCell data={pickslipInfo}
                        colorClass={CommonService.getPickslipStatusColorClass(pickslipInfo.PickslipStatusCode)}
                        description={CommonService.getPickslipStatusDescription(pickslipInfo.PickslipStatusCode)} />
                </Col>
                <Col xs={{ span: 2 }}>
                    {
                        (location.state.downloadDocuments && location.state.downloadDocuments.length > 0 && CommonService.dmsVersionGreaterOrEqual(Constant.DmsVersion.version_1_36_18_0)) 
                            &&  
                            // <Tooltip placement="bottom" title={t("common.download_document")}>
                            //     <Button icon={<DownloadIcon />} style={{ float: "left" }} className={`m-l-5 action-btn`} onClick={() => { openDownloadDocument(); }} />
                            // </Tooltip>  
                            <ActionButtonsCell justifyContentStart={true} btnList={[
                                {
                                    Description: t("common.download_document"),
                                    Icon: <DownloadIcon />,
                                    Hide: false,
                                    ColorClass: Constant.ColorClass.LightBlue,
                                    NoMargin: true,
                                    handleAction: openDownloadDocument
                                }
                            ]} />
                    }
                </Col>
            </Row>
        );
    }

    const openDownloadDocument = () => {
        setVisibleDownloadDocumentModal(true);
    }

    const cancelDownloadDocumentModal = () => {
        setVisibleDownloadDocumentModal(false);
    }

    return (
        <div className="pickslip-details-page">
            <Breadcrumb className="has-child">
                <Breadcrumb.Item onClick={() => { 
                    history.replace(Constant.Pages.OrderManagement, 
                        {
                            ...location.state.prevState,
                            encryptKey: location.state.encryptKey, 
                            mediaServiceHostName: location.state.mediaServiceHostName
                        }) 
                    }}>
                    <span className='back-icon'><ChevronLeftIcon /></span>
                    <span>{t("menu.order_management")}</span>
                </Breadcrumb.Item>
                <Breadcrumb.Item>{t("orders_management.pickslip_details")}</Breadcrumb.Item>
            </Breadcrumb>
            {_componentPickslipInfo()}
            <Tabs defaultActiveKey="1" onChange={onChangeTabs}>
                <TabPane tab={t("orders_management.parts")} key="parts">
                    <Row className="m-t-25">
                        <Col xs={{ span: 24 }}>
                            <Table {..._getTableConfigs_Parts()} />
                        </Col>
                    </Row>
                </TabPane>
                <TabPane tab={t("orders_management.sundries")} key="sundries">
                    <Row className="m-t-25">
                        <Col xs={{ span: 24 }}>
                            <Table {..._getTableConfigs_Sundries()} />
                        </Col>
                    </Row>
                </TabPane>
            </Tabs>
            {visibleCreditNoPopOut &&
                <Modal
                    className="pickslip-details-page"
                    title={(IsCreditPickslip ? t("orders_management.credit_no") : t("orders_management.invoice_no")) + ": " + creditNoPopOutHeader.InvoiceNo}
                    visible={true}
                    confirmLoading={false}
                    onCancel={cancelCreditNoPopout}
                    maskClosable={false}
                    footer={null}
                    width={768}
                    centered
                >
                    <Row>
                        <Col span={24}><label className='title-field'>{t("orders_management.part_number") + ": " + creditNoPopOutHeader.PartItemCode}</label></Col>
                    </Row>
                    <Row>
                        <Col xs={{ span: 24 }}>
                            <Table  className="custom-table" columns={columns_credit_no_popout} 
                                    dataSource={creditNoPopOutHeader.CreditInformation} 
                                    rowKey="PickslipNo" 
                                    pagination={false} scroll={{y: 300}}/>
                        </Col>
                    </Row>
                </Modal>
            }
            {visibleDownloadDocumentModal &&
                <Modal
                    className="pickslip-details-page"
                    title={t("common.download_document")}
                    visible={true}
                    confirmLoading={false}
                    onCancel={cancelDownloadDocumentModal}
                    maskClosable={false}
                    footer={null}
                    width={768}
                    centered
                >
                    <Row>
                        <Col xs={{ span: 24 }}>
                            <Table className="custom-table" columns={CommonService.getDownloadDocumentColumns(location.state.mediaServiceHostName, location.state.encryptKey, showLoading, dismissLoading)}
                                dataSource={location.state.downloadDocuments}
                                rowKey="$id"
                                pagination={false} scroll={{ y: 300 }} />
                        </Col>
                    </Row>
                </Modal>
            }
        </div>
    );
}