import React, { useEffect, useState } from 'react';
import './buyerManagement.scss';
import { Button, Row, Col, Form, Input, Select, DatePicker, Table, Breadcrumb } from 'antd';
import { useTranslation } from 'react-i18next';
import { GetTableConfigs, StatusCell, DateTimeCell, ActionButtonsCell } from '../../../components/utils/gridUtils/gridUtils'
import { EditIcon } from '../../../components/icons/icons';
import { Link, useLocation, useHistory } from "react-router-dom";
import { Constant, CommonService, SupplierBuyerService } from './../../../services/services';
import LoadingContext from './../../../components/utils/loading/loadingContext'
import moment from 'moment';

const { Option } = Select;
const layout = {
    labelCol: { span: 24 },
    wrapperCol: { span: 24 }
};

export default function BuyerManagement(props) {
    const { t } = useTranslation();
    const [form] = Form.useForm();
    const location = useLocation();
    const history = useHistory();

    const { showLoading, dismissLoading } = React.useContext(LoadingContext)
    let stateObj = {};

    const getFormFilterEmpty = ()=>{
        return {
            RequestedDateFrom: null,
            RequestedDateTo: null,
            BuyerContactEmail: null,
            StatusCode: null
        };
    }

    if (history.action === "REPLACE") {
        stateObj = {
            totalItems: location.state && location.state.totalItems ? location.state.totalItems : 0,
            supplierBuyers: location.state && location.state.supplierBuyers ? location.state.supplierBuyers : [],
            gridConfigOptions: location.state && location.state.gridConfigOptions ? location.state.gridConfigOptions : {
                pageNumber: 1,
                pageSize: Constant.PageSize,
                sort: ""
            },
            formFilterData: {
                RequestedDateFrom: location.state.formFilterData.RequestedDateFrom ? moment(new Date(location.state.formFilterData.RequestedDateFrom)) : null,
                RequestedDateTo: location.state.formFilterData.RequestedDateTo ? moment(new Date(location.state.formFilterData.RequestedDateTo)) : null,
                BuyerContactEmail: location.state.formFilterData.BuyerContactEmail,
                StatusCode: location.state.formFilterData.StatusCode
            }
        };
    } else {
        stateObj = {
            totalItems: 0,
            supplierBuyers: [],
            gridConfigOptions: {
                pageNumber: 1,
                pageSize: Constant.PageSize,
                sort: ""
            },
            formFilterData: getFormFilterEmpty()
        };
    }

    const [totalItems, setTotalItems] = useState(0);
    const [supplierBuyers, setSupplierBuyers] = useState([]);
    const [gridConfigOptions, setGridConfigOptions] = useState(stateObj.gridConfigOptions);

    const [btnGroupWidth, setBtnGroupWidth] = useState('')

    const statusCodeItems = [
        { Code: 1, Description: t("common.approved") },
        { Code: 2, Description: t("common.rejected") },
        { Code: 3, Description: t("common.pending") }
    ];

    const getStateFilterData = () => {
        let data = form.getFieldsValue();
        return {
            RequestedDateFrom: data.RequestedDateFrom ? data.RequestedDateFrom.format("YYYY/MM/DD") : null,
            RequestedDateTo: data.RequestedDateTo ? data.RequestedDateTo.format("YYYY/MM/DD") : null,
            BuyerContactEmail: data.BuyerContactEmail,
            StatusCode: data.StatusCode
        };
    }

    const columns = [
        {
            title: t("common.action"),
            dataIndex: 'Action',
            key: 'Action',
            width: 71,
            ellipsis: true,
            render: (value, record) => (
                <Link className="pickslip-url" to={{
                    pathname: Constant.Pages.BuyerManagement_EditBuyer,
                    state: {
                        ...record, prevState: {
                            totalItems: totalItems,
                            supplierBuyers: supplierBuyers,
                            gridConfigOptions: gridConfigOptions,
                            formFilterData: getStateFilterData()
                        }
                    }
                }}><Button icon={<EditIcon />} className={`m-l-5 m-r-5 action-btn`} /></Link>
            ),
        },
        {
            title: t("register.user_id"),
            dataIndex: 'UserName',
            key: 'UserName',
            // width: 180,
            sorter: true,
            ellipsis: true
        },
        {
            title: t("buyer_management.buyer_trading_name"),
            dataIndex: 'BuyerTradingName',
            key: 'BuyerTradingName',
            // width: 180,
            sorter: true,
            ellipsis: true
        },
        {
            title: t("buyer_management.dms_debtor_id"),
            dataIndex: 'DmsDebtorId',
            key: 'DmsDebtorId',
            // width: 180,
            sorter: true,
            ellipsis: true
        },
        {
            title: t("buyer_management.contact_name"),
            dataIndex: 'BuyerContactName',
            key: 'BuyerContactName',
            // width: 164,
            sorter: true,
            ellipsis: true
        },
        {
            title: t("buyer_management.contact_phone"),
            dataIndex: 'BuyerContactPhone',
            key: 'BuyerContactPhone',
            width: 160,
            sorter: true,
            ellipsis: true
        },
        {
            title: t("common.email"),
            dataIndex: 'BuyerContactEmail',
            key: 'BuyerContactEmail',
            // width: 163,
            sorter: true,
            ellipsis: true
        },
        {
            title: t("buyer_management.requested_date"),
            dataIndex: 'RequestedDate',
            key: 'RequestedDate',
            width: 109,
            align: 'center',
            sorter: true,
            // ellipsis: true,
            render: (value, record) => (
                <DateTimeCell value={record.RequestedDate} />
            )
        },
        {
            title: t("common.status"),
            dataIndex: 'RequestStatusCode',
            key: 'RequestStatusCode',
            width: 108,
            sorter: true,
            render: (value, record) => (
                <StatusCell data={record}
                    colorClass={CommonService.getRequestStatusColorClass(record.RequestStatusCode)}
                    description={CommonService.getRequestStatusDescription(record.RequestStatusCode)} />
            ),
            align: 'center'
        },
        {
            title: t('common.active'),
            dataIndex: 'StatusCode',
            key: 'StatusCode',
            sorter: true,
            render: (value, record) => (
                <StatusCell data={record}
                    hide={!(record.StatusCode === Constant.StatusCode.Active || record.StatusCode === Constant.StatusCode.Inactive)}
                    colorClass={CommonService.getStatusColorClass(record.StatusCode)}
                    description={CommonService.getStatusDescription(record.StatusCode, true)} />
            ),
            align: 'center',
            width: 88
        }
    ];

    const _getSupplierBuyers = (pageNumber, pageSize, sort, buyerContactEmail, statusCode, requestedDateFrom, requestedDateTo, isShowLoading = true) => {
        if (isShowLoading) showLoading();
        SupplierBuyerService.getSupplierBuyers(pageNumber, pageSize, sort, buyerContactEmail, statusCode, requestedDateFrom, requestedDateTo)
            .finally(() => {
                if (isShowLoading) dismissLoading();
            })
            .then(r => {
                if (r.data) {
                    setSupplierBuyers(r.data.ObjectList);
                    setTotalItems(r.data.TotalItems);
                }

            })
            .catch(error => CommonService.handleErrorResponse(error));
    }



    useEffect(() => {
        let timeoutFn = setTimeout(() => {
            document.documentElement.style.setProperty(Constant.CssVariables.FixItemsContainerHeight, CommonService.calculateTableBodyMaxHeight())
            let width = CommonService.calcButtonGroupIdWidth()
            setBtnGroupWidth(width)
            document.documentElement.style.setProperty(Constant.CssVariables.ButtonGroupWidth, width)
        }, 100);
        // if(!supplierBuyers || supplierBuyers.length === 0){
        //     _getSupplierBuyers(gridConfigOptions.pageNumber, gridConfigOptions.pageSize, gridConfigOptions.sort, null, null, null, null);
        // }
        if (history.action === "REPLACE") {
            let data = form.getFieldsValue();
            _getSupplierBuyers(gridConfigOptions.pageNumber, gridConfigOptions.pageSize, gridConfigOptions.sort, data.BuyerContactEmail, data.StatusCode, data.RequestedDateFrom, data.RequestedDateTo);
        }
        return () => {
            SupplierBuyerService.cancelRequest()
            clearTimeout(timeoutFn);
        }
    }, []);

    const resetFilter = () => {
        stateObj.formFilterData = getFormFilterEmpty();
        form.setFieldsValue(stateObj.formFilterData);
        setGridConfigOptions(
            {
                ...gridConfigOptions,
                pageNumber: 1
            }
        )
        setSupplierBuyers([])
        setTotalItems(0)
        //_getSupplierBuyers(1, gridConfigOptions.pageSize, gridConfigOptions.sort, null, null, null, null);
    }

    const retrieveData = (data) => {
        _getSupplierBuyers(gridConfigOptions.pageNumber, gridConfigOptions.pageSize, gridConfigOptions.sort, data.BuyerContactEmail, data.StatusCode, data.RequestedDateFrom, data.RequestedDateTo);
    }

    const onTableChange = (pagination, filters, sorter, extra) => {
        let data = form.getFieldsValue();
        _getSupplierBuyers(pagination.current, pagination.pageSize, CommonService.getSortString(sorter), data.BuyerContactEmail, data.StatusCode, data.RequestedDateFrom, data.RequestedDateTo)

        setGridConfigOptions({
            pageNumber: pagination.current,
            pageSize: pagination.pageSize,
            sort: CommonService.getSortString(sorter)
        });
    }

    const _searchComponent = () => {
        return (
            <>
                <Row id={Constant.FixItemsContainerId} className="fix-item-container">
                    <Col xs={{ span: 24 }}>
                        <Form
                            {...layout}
                            form={form}
                            name="formName"
                            initialValues={stateObj.formFilterData}
                            onFinish={retrieveData}
                        >
                            <Row align="middle">
                                <Col flex="auto" className="item-group">
                                    <Row align="middle" gutter={[Constant.SpaceConstant.HorizontalGutter, 5]}>
                                        <Col xs={{ span: 6 }}>
                                            <Form.Item
                                                label={t('buyer_management.requested_date_from')}
                                                name="RequestedDateFrom" colon={false} labelAlign="left"
                                            >
                                                <DatePicker format={Constant.DateFormat} />
                                            </Form.Item>
                                        </Col>
                                        <Col xs={{ span: 6 }}>
                                            <Form.Item
                                                label={t('buyer_management.requested_date_to')}
                                                name="RequestedDateTo" colon={false} labelAlign="left"
                                            >
                                                <DatePicker format={Constant.DateFormat} />
                                            </Form.Item>
                                        </Col>
                                        <Col xs={{ span: 6 }}>
                                            <Form.Item
                                                label={t('buyer_management.buyer_emal')}
                                                name="BuyerContactEmail" colon={false} labelAlign="left"
                                            >
                                                <Input type="email" placeholder={t('buyer_management.buyer_emal')} allowClear />
                                            </Form.Item>
                                        </Col>

                                        <Col xs={{ span: 6 }}>
                                            <Form.Item
                                                label={t('common.status')}
                                                name="StatusCode" colon={false} labelAlign="left"
                                            >
                                                <Select placeholder={t('common.status')} allowClear>
                                                    {
                                                        statusCodeItems.map((n, index) => <Option key={index} value={n.Code}>{n.Description}</Option>)
                                                    }
                                                </Select>
                                            </Form.Item>
                                        </Col>
                                    </Row>
                                </Col>
                                <Col flex={btnGroupWidth} className="text-right action-btn-group">
                                    <div className="btn-m-t" id={Constant.ButtonGroupId}>
                                        <Button size="large" onClick={resetFilter}>{t("common.reset")}</Button>
                                        <Button size="large" className="m-l-10 secondary-btn" htmlType="submit">{t("common.retrieve")}</Button>
                                    </div>

                                </Col>
                            </Row>
                        </Form>
                    </Col>
                </Row>
            </>
        );
    }

    return (
        <div className="buyer-management-page">
            <Breadcrumb>
                <Breadcrumb.Item>{t("buyer_management.buyer_management")}</Breadcrumb.Item>
            </Breadcrumb>
            {_searchComponent()}
            <Row className="m-t-10">
                <Col xs={{ span: 24 }}>
                    <Table
                        {...GetTableConfigs(columns, supplierBuyers, totalItems, "BuyerId", onTableChange, gridConfigOptions.pageNumber)}
                    />
                </Col>
            </Row>
        </div>
    );
}
