import React, { useState, useEffect, useContext } from 'react';
import './supplierSetting.scss';
import { Button, Row, Col, Form, Input, Select, Checkbox, Upload, Breadcrumb } from 'antd';
import { DeleteOutlined } from '@ant-design/icons';
import { useTranslation } from 'react-i18next';
import { DateTimeCell } from '../../../components/utils/gridUtils/gridUtils';
import { GoogleMapWrapper } from '../../../components/googleMapWrapper/googleMapWrapper';
import { Constant, SupplierSettingService, CommonService } from './../../../services/services';
import LoadingContext from './../../../components/utils/loading/loadingContext';
import GoogleMapReact from 'google-map-react';
import AddImage from './../../../assets/icons/add-image.svg';
import { toast } from 'react-toastify';

const { TextArea } = Input;
const { Option } = Select;
const layout = {
    labelCol: { span: 24 },
    wrapperCol: { span: 24 }
};

export default function SupplierSetting(props) {
    const { t } = useTranslation();
    const [formSupplier] = Form.useForm();
    const [supplierInfo, setSupplierInfo] = useState({});
    const [imageBase64, setImageBase64] = useState();
    const [logoUrl, setLogoUrl] = useState()
    const [dealershipThemes, setDealershipThemes] = useState([]);
    const [mapPointLocation, setMapPointLocation] = useState({
        lat: -27.2311,
        lng: 153.0060
    });

    let previousFormData

    const { showLoading, dismissLoading } = useContext(LoadingContext)
    useEffect(() => {
        let isMounted = true;
        if (isMounted) {
            showLoading();
            Promise.all([
                SupplierSettingService.getSupplierSetting(),
                SupplierSettingService.getDealershipThemes()
            ]).then(r => {
                if (r[0] && r[0].data) {
                    setLogoUrl(r[0].data.LogoUrl);
                    setSupplierInfo(r[0].data);
                    formSupplier.setFieldsValue(r[0].data);
                    calculateLocation()
                }
                if (r[1] && r[1].data) {
                    setDealershipThemes(r[1].data);
                }
            }).finally(() => {
                dismissLoading();
            });
        }
        return () => isMounted = false;
    }, []);

    const removeLogo = () => {
        setImageBase64("");
        setLogoUrl("");
    }

    const beforeUploadPhoto = img => {
        CommonService.getImgBase64(img, imageUrl => {
            setImageBase64(imageUrl);
        });
    }

    const onCancel = () => {
        setImageBase64("");
        setLogoUrl(supplierInfo.LogoUrl);
        formSupplier.setFieldsValue(supplierInfo);
    }

    const onSave = data => {
        data.LogoBase64 = imageBase64 ? imageBase64 : logoUrl;
        showLoading();
        SupplierSettingService.updateSupplier(data)
            .then(r => {
                CommonService.presentToast(toast.TYPE.SUCCESS, t("supplier_setting.save_successfully"));
                CommonService.applyDealerTheme(supplierInfo.UserId)
            })
            .finally(() => {
                dismissLoading();
            });
    }

    const calculateLocation = () => {
        let address = formSupplier.getFieldValue("StoreAddress")
        if (address) {
            SupplierSettingService.getLocationGeocode(address)
                .then(response => {
                    if (response && response.data && response.data.results && response.data.results.length > 0){
                        let result = response.data.results[0]
                        if(result.geometry && result.geometry.location){
                            let geocode = {
                                lat: result.geometry.location.lat,
                                lng: result.geometry.location.lng
                            }

                            setMapPointLocation(geocode)
                        }
                    }
            })
        }
    }

    const setpreviousFormData = () => {
        previousFormData = formSupplier.getFieldsValue()
      }
    
      const checkFieldChange = (fieldName, newValue) => {
        if (previousFormData) {
          if (previousFormData[fieldName] != newValue) {
            return true
          }
        } else if (newValue) {
          return true
        }
    
        return false;
      }
    
      const checkAndCalculateLocation = (fieldData) => {
        let value = formSupplier.getFieldValue(fieldData)
        if (checkFieldChange(fieldData, value)) {
            calculateLocation()
        }
      }

    return (
        <div className="supplier-setting-page">
            <Breadcrumb>
                <Breadcrumb.Item>{t("supplier_setting.supplier_setting")}</Breadcrumb.Item>
            </Breadcrumb>
            <Row className="m-t-15">
                <Col xs={{ span: 16 }}>
                    <Row gutter={[Constant.SpaceConstant.HorizontalGutter, Constant.SpaceConstant.VerticalGutter]}>
                        <Col xs={{ span: 12 }}>
                            <label className="title-field">{t("supplier_setting.dealership")}</label>
                            <label className="text-field">{supplierInfo?.DealerName || '_'}</label>
                        </Col>
                        <Col xs={{ span: 12 }}>
                            <label className="title-field">{t("supplier_setting.store_id")}</label>
                            <label className="text-field">{supplierInfo?.StoreName || '_'}</label>
                        </Col>
                        <Col xs={{ span: 12 }}>
                            <label className="title-field">{t("supplier_setting.created_date")}</label>
                            <label className="text-field"><DateTimeCell value={supplierInfo.CreationTimestamp} /></label>
                        </Col>
                        <Col xs={{ span: 12 }}>
                            <label className="title-field">{t("supplier_setting.user_id")}</label>
                            <label className="text-field">{supplierInfo?.UserName || '_'}</label>
                        </Col>
                    </Row>
                </Col>
                <Col xs={{ span: 8 }}>
                    <Row gutter={[Constant.SpaceConstant.HorizontalGutter, Constant.SpaceConstant.VerticalGutter]}>
                        <Col xs={{ span: 24 }}>
                            <label className="title-field">{t("supplier_setting.trading_name")}</label>
                            <label className="text-field">{supplierInfo?.TradingName || '_'}</label>
                        </Col>
                    </Row>
                </Col>
                <Col xs={{ span: 24 }}>
                    <Form className="m-t-15" {...layout} form={formSupplier} onFinish={onSave} initialValues={supplierInfo}>
                        <Row gutter={[Constant.SpaceConstant.HorizontalGutter, 5]}>
                            <Col xs={{ span: 8 }}>
                                <Form.Item
                                    label={t('supplier_setting.contact_name')}
                                    colon={false} labelAlign="left" name="ContactName"
                                    rules={[{ required: true, message: t("supplier_setting.contact_name_required") }]}
                                >
                                    <Input placeholder={t('supplier_setting.contact_name')} allowClear />
                                </Form.Item>
                            </Col>
                            <Col xs={{ span: 8 }}>
                                <Form.Item
                                    label={t('supplier_setting.contact_email')}
                                    colon={false} labelAlign="left" name="Email"
                                    rules={[{ required: true, message: t("supplier_setting.contact_email_required") },
                                    { type: "email", message: t("common.email_invalid") }]}
                                >
                                    <Input placeholder={t('supplier_setting.contact_email')} allowClear />
                                </Form.Item>
                            </Col>
                            <Col xs={{ span: 8 }}>
                                <Form.Item
                                    label={t('supplier_setting.contact_phone')}
                                    colon={false} labelAlign="left" name="PhoneNumber"
                                    rules={[{ required: true, message: t("supplier_setting.contact_phone_required") }]}
                                >
                                    <Input placeholder={t('supplier_setting.contact_phone')} allowClear />
                                </Form.Item>
                            </Col>
                        </Row>
                        <Row gutter={[Constant.SpaceConstant.HorizontalGutter, 5]}>
                            <Col xs={{ span: 10 }}>
                                <Row gutter={[Constant.SpaceConstant.HorizontalGutter, 5]}>
                                    <Col xs={{ span: 16 }}>
                                        <Form.Item colon={false} labelAlign="left" name="IsPoNotificationRequired" valuePropName="checked">
                                            <Checkbox value={supplierInfo.IsPoNotificationRequired} >
                                                {t("supplier_setting.po_notification_require")}
                                            </Checkbox>
                                        </Form.Item>
                                        <Form.Item
                                            label={t('supplier_setting.supplier_theme')}
                                            colon={false} labelAlign="left" name="ThemeId"
                                        >
                                            <Select placeholder={t('supplier_setting.supplier_theme')} allowClear>
                                                {
                                                    dealershipThemes.map((n, index) => <Option key={index} value={n.Id}>{n.Name}</Option>)
                                                }
                                            </Select>
                                        </Form.Item>
                                    </Col>
                                    <Col xs={{ span: 8 }}>
                                        <Form.Item
                                            label={t('supplier_setting.supplier_logo')}
                                            colon={false} labelAlign="left"
                                        >
                                            <Upload
                                                className="avatar-uploader"
                                                listType="picture-card"
                                                showUploadList={false}
                                                beforeUpload={beforeUploadPhoto}
                                                accept="image/*"
                                            >
                                                {
                                                    imageBase64
                                                        ? <img alt={t('supplier_setting.supplier_logo')} className="supplier-logo" src={imageBase64} />
                                                        : (
                                                            logoUrl
                                                                ? <img alt={t('supplier_setting.supplier_logo')} className="supplier-logo" src={logoUrl} />
                                                                : <img alt={t('supplier_setting.supplier_logo')} className="supplier-logo no-image" src={AddImage} />
                                                        )
                                                }
                                            </Upload>
                                            {(logoUrl || imageBase64) &&
                                                <span className="remove-logo">
                                                    {/* <Button className="btn-delete-logo" type="primary" icon={<DeleteOutlined />} onClick={removeLogo} size="middle" /> */}
                                                    <Button type="primary" shape="circle" icon={<DeleteOutlined />} onClick={removeLogo} size="middle" />
                                                </span>
                                            }
                                        </Form.Item>
                                    </Col>
                                </Row>
                                <Row>
                                    <Col xs={{ span: 24 }}>
                                        <Form.Item
                                            label={t('supplier_setting.about_us')}
                                            colon={false} labelAlign="left" name="AboutUs"
                                        >
                                            <TextArea placeholder={t('supplier_setting.about_us')} rows="10" style={{ "resize": "none" }} allowClear />
                                        </Form.Item>
                                    </Col>
                                </Row>
                            </Col>
                            <Col xs={{ span: 13, offset: 1 }}>
                                <Form.Item
                                    label={t('supplier_setting.store_address')}
                                    colon={false} labelAlign="left" name="StoreAddress"
                                    rules={[{ required: true, message: t("supplier_setting.store_address_required") }]}
                                >
                                    <Input placeholder={t('supplier_setting.store_address')} onFocus={setpreviousFormData} onBlur={() => checkAndCalculateLocation("StoreAddress")} allowClear />
                                </Form.Item>
                                <div className="maps-container">
                                    <GoogleMapWrapper
                                        googleMapURL={`https://maps.googleapis.com/maps/api/js?key=${process.env.REACT_APP_MAP_KEY}`}
                                        loadingElement={<div style={{ height: `100%` }} />}
                                        containerElement={<div style={{ height: `385px` }} />}
                                        mapElement={<div style={{ height: `100%` }} />}
                                        latitude={mapPointLocation.lat}
                                        longitude={mapPointLocation.lng}
                                    >

                                    </GoogleMapWrapper>
                                </div>
                            </Col>
                        </Row>
                        <Row className="m-t-20">
                            <Col xs={{ span: 24 }} className="text-right">
                                <Button size="large" onClick={onCancel}>{t('common.cancel')}</Button>
                                <Button size="large" type="primary" className="m-l-10" htmlType="submit">{t('common.save')}</Button>
                            </Col>
                        </Row>
                    </Form>
                </Col>
            </Row>
        </div>
    );
}