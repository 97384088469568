import axios from 'axios';
import { axiosPortal } from './authService'
import { Constant } from './constant';

const source = [];
let baseURL = "MediaService/Document"

const downloadFile = function (fileName, blobData) {
    const url = window.URL.createObjectURL(new Blob([blobData]));
    const link = document.createElement('a');
    link.href = url;
    link.setAttribute('download', fileName);
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
}

export const MediaService = {
    downloadPdfDocument: function (type, docKey, key, mediaServiceHostName, encryptKey, showLoading, dismissLoading) {
        if(mediaServiceHostName && encryptKey) {
            let cancelToken = axios.CancelToken.source()
            source.push(cancelToken);
            let url = `${mediaServiceHostName}/${baseURL}/DownloadPdf?type=${type}&key=${key}`
            if(showLoading) showLoading();
            axiosPortal.get(url, {
                cancelToken: cancelToken.token,
                responseType: 'blob',
                headers: {
                    "api-key": `${encryptKey}`
                }
            }).then((response) => {
                if (response && response.data) {
                    let fileName = docKey + ".pdf";
                    downloadFile(fileName, response.data);
                }
            }).finally(() => {
                let index = source.indexOf(cancelToken);
                if (index > -1) {
                    source.splice(index, 1);
                }
                if(dismissLoading) dismissLoading();
            });
        }
    }
}