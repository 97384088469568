import axios from 'axios';
import { StorageService, Constant } from './services'
import PubSub from 'pubsub-js'

export const axiosPortal = axios.create();

const source = [];
let refreshTokenInProgress = false
let refreshTokenQueue = []

export const AuthService = {
    processQueue: function () {
        refreshTokenQueue.forEach(queue => {
            queue.resolve()
        });

        refreshTokenQueue = [];
    },
    registerIntercepter: function (history) {
        let authService = this
        axiosPortal.defaults.baseURL = process.env.REACT_APP_BASE_URL
        axiosPortal.interceptors.request.use(function (config) {
            let authData = StorageService.getAuthData()
            if (authData) {
                if (config.url
                    && config.url.indexOf("token") === -1
                    && config.url.indexOf("ForgotPassword") === -1
                    && config.url.indexOf("ResetPassword") === -1
                    && config.url.indexOf("/MediaService/") === -1
                    && config.url.indexOf("maps.googleapis.com") === -1) {
                    config.headers["Authorization"] = `bearer ${authData.token}`
                }
                if (authData.redirectApiUrl) {
                    config.baseURL = authData.redirectApiUrl
                }
                if (config.url.indexOf("/MediaService/") > -1) {
                    let urlSplit = config.url.split("/MediaService/");
                    let mediaServiceUrl = urlSplit[0];
                    axiosPortal.defaults.baseURL = mediaServiceUrl;
                    config.baseURL = mediaServiceUrl;
                    config.url = config.url.replace("/MediaService/", "/");
                } else {
                    axiosPortal.defaults.baseURL = process.env.REACT_APP_BASE_URL;
                    config.baseURL = process.env.REACT_APP_BASE_URL;
                }
            }

            if (config.url
                && config.url.indexOf("maps.googleapis.com") === -1) {
                config.headers = {
                    ...config.headers,
                    GroupId: process.env.REACT_APP_GROUP_ID
                }
            }

            return config;
        }, function (error) {
            return Promise.reject(error);
        });

        // Add a response interceptor
        axiosPortal.interceptors.response.use(function (response) {
            return response;
        }, function (error) {
            const originalRequest = error.config;
            if (error && error.response && error.response.status === 401 && originalRequest.baseURL === process.env.REACT_APP_BASE_URL) {
                if (originalRequest._retry) {
                    PubSub.publish(Constant.PubSupType.UserLogout, Constant.PubSupType.UserLogout);
                } else {
                    originalRequest._retry = true;
                    if (refreshTokenInProgress) {
                        return new Promise(function (resolve, reject) {
                            refreshTokenQueue.push({ resolve, reject });
                        })
                            .then(() => {
                                return axiosPortal(originalRequest);
                            })
                            .catch(err => {
                                return Promise.reject(err);
                            });
                    } else {
                        refreshTokenInProgress = true
                        let authData = StorageService.getAuthData()
                        if (authData && authData.refreshToken) {
                            const params = new URLSearchParams();
                            params.append('grant_type', 'refresh_token');
                            params.append('refresh_token', authData.refreshToken);
                            params.append('GroupId', process.env.REACT_APP_GROUP_ID);
                            return axiosPortal.post('/token', params)
                                .finally(() => {
                                    refreshTokenInProgress = false
                                })
                                .then(res => {
                                    if (res && res.data) {
                                        authData.token = res.data["access_token"]
                                        var expiredTime = new Date();
                                        expiredTime.setSeconds(expiredTime.getSeconds() + res.data["expires_in"])
                                        authData.expire = expiredTime
                                        authData.refreshToken = res.data["refresh_token"]
                                        authData.dmsVersion = res.data["dmsVersion"]

                                        StorageService.setAuthData(authData, authData.supplierId);
                                        PubSub.publish(Constant.PubSupType.RefreshToken, Constant.PubSupType.RefreshToken);

                                        authService.processQueue()
                                        return axiosPortal(originalRequest);
                                    }
                                }, (err) => {
                                    PubSub.publish(Constant.PubSupType.UserLogout, Constant.PubSupType.UserLogout);
                                    return Promise.reject(err)
                                })
                        }
                    }

                }
            }

            return Promise.reject(error);
        });
    },

    login: function (username, password, supplierId) {
        return new Promise((resolve, reject) => {
            const params = new URLSearchParams();
            params.append('grant_type', 'password');
            params.append('username', username);
            params.append('password', password);
            params.append('GroupId', process.env.REACT_APP_GROUP_ID);
            if (supplierId) {
                params.append('SupplierId', supplierId);
            }

            return axiosPortal.post("/token", params).then(res => {
                var expiredTime = new Date();
                expiredTime.setSeconds(expiredTime.getSeconds() + res.data["expires_in"])
                let data = {
                    name: res.data["userName"],
                    tradingName: res.data["tradingName"],
                    token: res.data["access_token"],
                    expire: expiredTime,
                    refreshToken: res.data["refresh_token"],
                    accountType: res.data["accountType"],
                    supplierId: res.data["supplierId"],
                    supplierTradingName: res.data["supplierTradingName"],
                    email: res.data["email"],
                    contactName: res.data["contactName"],
                    phoneNumber: res.data["phoneNumber"],
                    avatarUrl: res.data["avatarUrl"],
                    userId: res.data["userId"],
                    isShowPriceTaxIncluded: res.data["isShowPriceTaxIncluded"],
                    functionCodes: JSON.parse(res.data["functionCodes"]),
                    supplierLogoUrl: res.data["supplierLogoUrl"],
                    dmsVersion: res.data["dmsVersion"],
                    isCombineQtyArrivedIntoQtyInTransit: res.data["isCombineQtyArrivedIntoQtyInTransit"],
                    enableSellAtPackQtyOnly: res.data["enableSellAtPackQtyOnly"]
                };
                if (data.accountType == Constant.AccountType.Buyer) {
                    if (data.supplierId) {
                        supplierId = data.supplierId
                    } else {
                        supplierId = "none"
                    }
                }
                StorageService.setAuthData(data, supplierId)
                resolve(data);
            }, (error) => {
                reject(error)
            })
        })
    },
    hasAuthorized: function (isActive = true, autoLogOut = true) {
        let result = isActive
        let authData = StorageService.getAuthData()
        if (authData) {
            let expiredDate = new Date(authData.expire)
            let currentDate = new Date()
            if (expiredDate >= currentDate) {
                result = true
            }
        }
        if (!result && autoLogOut) {
            PubSub.publish(Constant.PubSupType.UserLogout, true);
        }
        return result
    },
    isSupplier: function () {
        let authData = StorageService.getAuthData()
        if (authData) {
            return authData.accountType == Constant.AccountType.Supplier
        }

        return true
    },
    registerSupplier: function (supplier) {
        let cancelToken = axios.CancelToken.source()
        source.push(cancelToken)

        return axiosPortal.post(`Account/RegisterSupplier`, supplier, {
            cancelToken: cancelToken.token
        }).finally(() => {
            let index = source.indexOf(cancelToken)
            if (index > -1) {
                source.splice(index, 1)
            }
        })
    },
    registerBuyer: function (buyer) {
        let cancelToken = axios.CancelToken.source()
        source.push(cancelToken)

        return axiosPortal.post(`Account/RegisterBuyer`, buyer, {
            cancelToken: cancelToken.token
        }).finally(() => {
            let index = source.indexOf(cancelToken)
            if (index > -1) {
                source.splice(index, 1)
            }
        })
    },
    confirmEmail: function (code, username) {
        let cancelToken = axios.CancelToken.source()
        source.push(cancelToken)
        let url = `/Account?Code=${code}&username=${username}`
        return axiosPortal.get(url, {
            cancelToken: cancelToken.token
        }).finally(() => {
            let index = source.indexOf(cancelToken)
            if (index > -1) {
                source.splice(index, 1)
            }
        })
    },
    forgotPassword: function (username) {
        let cancelToken = axios.CancelToken.source()
        source.push(cancelToken)
        let url = `/Account/ForgotPassword`
        let data = {
            UserName: username
        }
        return axiosPortal.post(url, data, {
            cancelToken: cancelToken.token
        }).finally(() => {
            let index = source.indexOf(cancelToken)
            if (index > -1) {
                source.splice(index, 1)
            }
        })
    },
    resetPassword: function (username, password, code) {
        let cancelToken = axios.CancelToken.source()
        source.push(cancelToken)
        let url = `/Account/ResetPassword`
        let data = {
            UserName: username,
            Password: password,
            ConfirmPassword: password,
            Code: code
        }
        return axiosPortal.post(url, data, {
            cancelToken: cancelToken.token
        }).finally(() => {
            let index = source.indexOf(cancelToken)
            if (index > -1) {
                source.splice(index, 1)
            }
        })
    },
    updateUser: function (data) {
        let cancelToken = axios.CancelToken.source()
        source.push(cancelToken)
        let url = `/Account/UpdateUser/${data.Id}`
        return axiosPortal.put(url, data, {
            cancelToken: cancelToken.token
        }).finally(() => {
            let index = source.indexOf(cancelToken)
            if (index > -1) {
                source.splice(index, 1)
            }
        })
    },
    getDealershipTheme: function (supplierId) {
        let cancelToken = axios.CancelToken.source()
        source.push(cancelToken)
        let url = `/Dealership/DealershipTheme?supplierId=${supplierId}`
        return axiosPortal.get(url, {
            cancelToken: cancelToken.token
        }).finally(() => {
            let index = source.indexOf(cancelToken)
            if (index > -1) {
                source.splice(index, 1)
            }
        })
    },
    getAppVersion: function (groupId) {
        let cancelToken = axios.CancelToken.source()
        source.push(cancelToken)
        let url = `/Dealership/AppVersion?groupId=${groupId}`
        return axiosPortal.get(url, {
            cancelToken: cancelToken.token
        }).finally(() => {
            let index = source.indexOf(cancelToken)
            if (index > -1) {
                source.splice(index, 1)
            }
        })
    }
}