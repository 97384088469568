import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import {
    Constant,
    CommonService,
    BuyerService,
    DealershipService,
    StorageService
} from './../../../services/services'
import { Table, Button, Row, Col, Select, Form, Input, Tabs, Modal, Breadcrumb, DatePicker } from 'antd';
import LoadingContext from './../../../components/utils/loading/loadingContext'
import { GetTableConfigs_SimplePaginator, AmountCell, DateTimeCell, ActionButtonsCell } from './../../../components/utils/gridUtils/gridUtils'
import { useLocation, useHistory } from "react-router-dom";
import { EditIcon } from './../../../components/icons/icons'
import SearchInput from './../../../components/searchInput/searchInput'
import NumericInput from './../../../components/numericInput/numericInput'
import { useGlobalState } from './../../../utilities/globalState'
import './requestCreditReturn.scss';
import moment from 'moment';

const layout = {
    labelCol: { span: 24 },
    wrapperCol: { span: 24 }
};
const { Option } = Select;

const RequestCreditReturn = (props) => {
    const { t } = useTranslation();
    const [form] = Form.useForm();
    const { showLoading, dismissLoading } = React.useContext(LoadingContext)
    const history = useHistory();
    const location = useLocation();
    const params = location.state;
    const emptyTableData = {
        ObjectList: [],
        TotalItems: 0
    }
    const initialGridConfig = {
        pageNumber: 1,
        pageSize: Constant.PageSize,
        sort: ""
    }
    let stateObj = {};
    const getFormFilterEmpty = () => {
        return {
            InvoiceDateFrom: null,
            InvoiceDateTo: null,
            InvoiceNo: null,
            PickslipNo: null,
            OrderNo: null
        };
    }
    if (history.action === "REPLACE") {
        stateObj = {
            gridConfigOptions: params && params.GridConfigOptions ?
                params.GridConfigOptions : initialGridConfig,
            formFilterData: params && params.SearchData ? {
                InvoiceNo: params.SearchData.InvoiceNo,
                PickslipNo: params.SearchData.PickslipNo,
                InvoiceDateFrom: params.SearchData.InvoiceDateFrom ? moment(new Date(params.SearchData.InvoiceDateFrom)) : null,
                InvoiceDateTo: params.SearchData.InvoiceDateTo ? moment(new Date(params.SearchData.InvoiceDateTo)) : null,
                OrderNo: params.SearchData.OrderNo
            } : getFormFilterEmpty(),
        };
    } else {
        stateObj = {
            gridConfigOptions: initialGridConfig,
            formFilterData: getFormFilterEmpty(),
        };
    }


    const [invoiceSearchData, setInvoiceSearchData] = useState(emptyTableData)
    const [gridConfigOptions, setGridConfigOptionsValue] = useState(stateObj.gridConfigOptions)
    const [taxIncluded, setTaxIncluded] = useState(true)
    const [creditNoteReasonCodes, setCreditNoteReasonCodes] = useGlobalState('creditNoteReasonCodes')
    //let history = useHistory();

    const reset = () => {
        form.resetFields()
        setGridConfigOptionsValue(
            {
                ...gridConfigOptions,
                pageNumber: 1
            }
        )
        //getInvoiceSearchData(1, gridConfigOptions.pageSize, gridConfigOptions.sort, null, null, null, null, null, true)
        setInvoiceSearchData({
            ObjectList: [],
            TotalItems: 0
        })
    }

    const editRequest = (item) => {
        if (item) {
            var formData = form.getFieldsValue()
            let searchData = Object.assign({}, formData)
            if (searchData) {
                if (searchData.InvoiceDateFrom) {
                    let dateFrom = moment(searchData.InvoiceDateFrom).format(Constant.APIDateFormat)
                    searchData.InvoiceDateFrom = dateFrom
                }
                if (searchData.InvoiceDateTo) {
                    let dateTo = moment(searchData.InvoiceDateTo).format(Constant.APIDateFormat)
                    searchData.InvoiceDateTo = dateTo
                }
            }

            history.push(Constant.Pages.RequestCreditReturn_Edit, {
                SearchData: searchData,
                Item: item,
                GridConfigOptions: gridConfigOptions,
                PreviousUrl: Constant.Pages.RequestCreditReturn
            });
        }
    }

    const getActionButtonsInfo = () => {
        return [
            {
                Description: t("common.edit"),
                Icon: <EditIcon />,
                Hide: false,
                handleAction: editRequest
            }
        ]
    }

    const columns = [
        {
            title: t('common.action'),
            key: 'Id',
            render: (value, record) => (
                <ActionButtonsCell btnList={getActionButtonsInfo(record)} data={record} />
            ),
            align: 'center',
            width: 50
        },
        {
            title: t('request_credit_return.invoice_number'),
            dataIndex: 'InvoiceNo',
            key: 'InvoiceNo',
            width: 150,
            sorter: true,
            ellipsis: true
        },
        {
            title: t('request_credit_return.invoice_date'),
            dataIndex: 'InvoiceDate',
            key: 'InvoiceDate',
            width: 150,
            sorter: true,
            ellipsis: true,
            render: (value, record) => (
                <DateTimeCell value={record.InvoiceDate} />
            )
        },
        {
            title: t('common.order_number'),
            dataIndex: 'OrderNo',
            key: 'OrderNo',
            sorter: true,
            ellipsis: true,
            width: 150,
        },
        {
            title: t('part_order.pickslip_number'),
            dataIndex: 'PickslipNo',
            key: 'PickslipNo',
            sorter: true,
            ellipsis: true,
            width: 150,
        },
        {
            title: <div className="text-center" dangerouslySetInnerHTML={{ __html: taxIncluded ? t('request_credit_return.invoice_amount_inc') : t('request_credit_return.invoice_amount_exc') }}></div>,
            dataIndex: 'InvoiceAmt',
            key: 'InvoiceAmt',
            align: "center",
            render: (value, record) => (
                <AmountCell value={record.InvoicedAmtTaxExclusive} valueTaxIncluded={record.InvoicedAmtTaxInclusive} emptyText="" />
            ),
            sorter: true,
            ellipsis: true,
            width: 150,
        }

    ]

    const getInvoiceSearchData = (pageNumber, pageSize, sort, invoiceNo,
        pickslipNo, orderNo, invoicedDateFrom, invoicedDateTo,
        isShowLoading = false) => {
        if (isShowLoading)
            showLoading()
        BuyerService.getInvoiceSearchData(invoiceNo, pickslipNo, orderNo, invoicedDateFrom,
            invoicedDateTo, pageNumber, pageSize, sort)
            .finally(() => {
                if (isShowLoading)
                    dismissLoading()
            })
            .then(result => {
                if (result.data) {
                    setInvoiceSearchData(result.data)
                }
            })
            .catch(error => CommonService.handleErrorResponse(error))
    }

    const handleSelectedPickslip = (objectData) => {

        if (objectData) {
            form.setFieldsValue({
                PickslipNo: objectData.PickslipNo
            });
        }
    }

    const handleSelectedOrder = (objectData) => {

    }

    const onTableChange = (pagination, filters, sorter) => {
        let data = form.getFieldsValue()

        getInvoiceSearchData(pagination.current, pagination.pageSize,
            CommonService.getSortString(sorter), data.InvoiceNo, data.PickslipNo,
            data.OrderNo, data.InvoiceDateFrom, data.InvoiceDateTo, true)
        setGridConfigOptionsValue({
            pageNumber: pagination.current,
            pageSize: pagination.pageSize,
            sort: CommonService.getSortString(sorter)
        })
    }

    const onSearch = (data) => {
        if (data) {
            getInvoiceSearchData(gridConfigOptions.pageNumber, gridConfigOptions.pageSize, gridConfigOptions.sort,
                data.InvoiceNo, data.PickslipNo, data.OrderNo,
                data.InvoiceDateFrom, data.InvoiceDateTo, true)
        }
        else {
            getInvoiceSearchData(gridConfigOptions.pageNumber, gridConfigOptions.pageSize, gridConfigOptions.sort,
                null, null, null, null, null, true)
        }
    }

    useEffect(() => {
        let timeoutFn = setTimeout(() => {
            document.documentElement.style.setProperty(Constant.CssVariables.FixItemsContainerHeight, CommonService.calculateTableBodyMaxHeight())
        }, 100);
        //let gridOptions = gridConfigOptions

        // let formData = form.getFieldsValue()
        // getInvoiceSearchData(gridOptions.pageNumber, gridOptions.pageSize, gridOptions.sort, formData?.InvoiceNo, formData?.PickslipNo, formData?.OrderNo, formData?.InvoiceDateFrom, formData?.InvoiceDateTo, true)
        if (history.action === "REPLACE") {
            let formData = form.getFieldsValue()
            getInvoiceSearchData(gridConfigOptions.pageNumber, gridConfigOptions.pageSize, gridConfigOptions.sort, formData?.InvoiceNo, formData?.PickslipNo, formData?.OrderNo, formData?.InvoiceDateFrom, formData?.InvoiceDateTo, true)
        }
        let authData = StorageService.getAuthData()
        if (authData) {
            setTaxIncluded(authData.isShowPriceTaxIncluded)
        }


        return () => {
            BuyerService.cancelRequest()
            clearTimeout(timeoutFn);
        }
    }, [])

    return (
        <div className="request-credit-return">
            <Breadcrumb>
                <Breadcrumb.Item>{t("menu.request_credit_return")}</Breadcrumb.Item>
            </Breadcrumb>
            <Row id={Constant.FixItemsContainerId}>
                <Col xs={{ span: 24 }}>
                    <Form
                        {...layout}
                        form={form}
                        onFinish={onSearch}
                        initialValues={stateObj.formFilterData}
                    >
                        <Row align="middle" gutter={[Constant.SpaceConstant.HorizontalGutter,]}>
                            <Col xs={{ span: 8 }} md={8} xl={4} xxl={{ span: 3 }}>
                                <Form.Item
                                    label={t("request_credit_return.invoice_number")}
                                    name="InvoiceNo" colon={false} labelAlign="left"
                                >
                                    <NumericInput allowClear={true}></NumericInput>
                                </Form.Item>
                            </Col>
                            <Col xs={{ span: 8 }} md={8} xl={5} xxl={{ span: 4 }}>
                                <Form.Item
                                    label={t("part_order.pickslip_number")}
                                    name="PickslipNo" colon={false} labelAlign="left"
                                >
                                    <SearchInput type={Constant.SearchModalType.PickslipNumber} getAllData={true} handleSelectedItem={handleSelectedPickslip}
                                        onPressEnter={() => { }}
                                    ></SearchInput>
                                </Form.Item>
                            </Col>
                            <Col xs={{ span: 8 }} md={8} xl={5} xxl={{ span: 4 }}>
                                <Form.Item
                                    label={t("part_order.order_number")}
                                    name="OrderNo" colon={false} labelAlign="left"
                                >
                                    <SearchInput type={Constant.SearchModalType.OrderNumber} handleSelectedItem={handleSelectedOrder}
                                        onPressEnter={() => { }} uppercase={true}></SearchInput>
                                </Form.Item>
                            </Col>
                            <Col xs={{ span: 8 }} md={8} xl={5} xxl={{ span: 4 }}>
                                <Form.Item
                                    label={t("request_credit_return.invoiced_date_from")}
                                    name="InvoiceDateFrom" colon={false} labelAlign="left"
                                >
                                    <DatePicker format={Constant.DateFormat} />
                                </Form.Item>
                            </Col>
                            <Col xs={{ span: 8 }} md={7} xl={5} xxl={{ span: 4 }}>
                                <Form.Item
                                    label={t("request_credit_return.invoiced_date_to")}
                                    name="InvoiceDateTo" colon={false} labelAlign="left"
                                >
                                    <DatePicker format={Constant.DateFormat} />
                                </Form.Item>
                            </Col>
                            <Col xs={{ span: 24 }} md={9} xl={24} xxl={{ span: 5 }} className="text-right m-t--btn-group">
                                <Button size="large" onClick={reset}>{t("common.reset_filter")}</Button>
                                <Button size="large" htmlType="submit" className="m-l-10 secondary-btn">{t("common.retrieve")}</Button>
                            </Col>
                        </Row>
                    </Form>
                </Col>
            </Row>
            <Row className='m-t--table' gutter={[, Constant.SpaceConstant.VerticalGutter]}>
                <Col xs={{ span: 24 }}>
                    <Table
                        {...GetTableConfigs_SimplePaginator(columns, invoiceSearchData?.ObjectList, invoiceSearchData?.TotalItems, "InvoiceNo", onTableChange, gridConfigOptions.pageNumber)}
                    />
                </Col>
            </Row>
        </div>
    )
}

export default RequestCreditReturn;