import React, { useState } from 'react';
import './numericInput.scss';
import { Input } from 'antd';
import { useTranslation } from 'react-i18next';

const NumericInput = (props) => {
    const [value, setValue] = useState("");

    const onChange = e => {
        let value = e.target.value;
        const reg = /^-?\d*(\.\d*)?$/;
        if ((!isNaN(value) && reg.test(value)) || value === '' || value === '-') {
            if (!isNaN(value)) {
                if ((props.min != null && props.min > Number(value)) ||
                (props.max != null && props.max < Number(value)))
                    value = ''
            }
            setValue(value)
            if (props.onChange) {
                props.onChange(value);
            }
        }
    };

    // '.' at the end or only '-' in the input box.
    const onBlur = () => {
        const { value, onBlur, onChange } = props;
        let valueTemp = value;
        if (value) {
            if (value.charAt && value.charAt(value.length - 1) === '.' || value === '-') {
                valueTemp = value.slice(0, -1);
                onChange(valueTemp.replace(/0*(\d+)/, '$1'));
            }
            onChange(valueTemp);
        }
        if (onBlur) {
            onBlur();
        }
    };

    const onPressEnter = () => {
        if (props.onPressEnter) {
            props.onPressEnter(value)
        }
    }

    return (
        <Input
            {...props}
            onChange={onChange}
            onBlur={onBlur}
            placeholder={props.placeholder}
            maxLength={props.maxLength ? props.maxLength : 25}
            value={value}
            disabled={props.disabled}
            allowClear={props.allowClear}
            onPressEnter={onPressEnter}
        />
    )
}

export default NumericInput