import React, { useEffect, useState } from 'react';
import './multiSelectionListBox.scss';
import { Row, Col, Checkbox, Input } from 'antd';
import { useTranslation } from 'react-i18next';

const CheckboxGroup = Checkbox.Group;
export default function MultiSelectionListBox(props) {
    const { t } = useTranslation(null, { useSuspense: false });

    const [dataSource, setDataSource] = useState(props.dataSource ? props.dataSource : []);
    const [selectedItems, setSelectedItems] = useState(props.initialValues ? props.initialValues : []);
    const [indeterminate, setIndeterminate] = useState(true);
    const [checkAll, setCheckAll] = useState(false);

    // console.log(selectedItems, "---", dataSource);

    useEffect(() => {
        setDataSource(props.dataSource);
    }, [props.dataSource]);

    useEffect(() => {
        setSelectedItems(props.initialValues);
    }, [props.initialValues]);

    const onSelectionChange = list => {
        if (props.onChange && typeof (props.onChange) === "function") {
            props.onChange(list);
        }
    }

    const onCheckItem = list => {
        setSelectedItems(list);
        setIndeterminate(!!list.length && list.length < dataSource.length);
        setCheckAll(list.length === dataSource.length);

        onSelectionChange(list);
    };

    const onCheckAll = e => {
        let selectedList = e.target.checked ? dataSource.map(p => p.value) : [];
        setSelectedItems(selectedList);
        setIndeterminate(false);
        setCheckAll(e.target.checked);

        onSelectionChange(selectedList);
    };

    const onSearch = e => {
        let _opts = props.dataSource.filter(d => d.label.match((new RegExp(e.target.value, "i"))) != null);
        setDataSource(_opts);
    }

    return (
        <div className={props.className ? ("multi-selection-listbox " + props.className) : "multi-selection-listbox"}>
            <Row>
                <Col xs={{ span: 18 }}>
                    <span className="list-title">{props.title}</span>
                </Col>
                <Col xs={{ span: 6 }}>
                    <Row justify="end" style={{float: 'right'}}>
                        <Checkbox indeterminate={indeterminate} onChange={onCheckAll} checked={checkAll}>{t('common.select_all')}</Checkbox>
                    </Row>
                </Col>
            </Row>
            <Row className="m-t-10 m-b-10">
                <Input className="list-filter" allowClear placeholder={t('common.filter')} onChange={onSearch} />
            </Row>
            <Row>
                <CheckboxGroup options={dataSource} value={selectedItems} onChange={onCheckItem} />
            </Row>
        </div>
    )
}