import React, { useContext, useState, useEffect, useRef } from 'react';
import './partOrder.scss'
import { useTranslation } from 'react-i18next';
import {
    Constant,
    CommonService,
    BuyerService,
    StorageService
} from './../../../services/services'
import { Table, Button, Row, Col, Select, Form, Input, Tabs, Modal, Breadcrumb, Popover, Typography, Spin, Tooltip } from 'antd';
import LoadingContext from './../../../components/utils/loading/loadingContext'
import { GetTableConfigs, StatusCell, ActionButtonsCell, EditableCell } from './../../../components/utils/gridUtils/gridUtils'
import PartSearch from './../../buyer/partSearch/partSearch'
import PartEnquiry from './../../buyer/partEnquiry/partEnquiry'
import SearchInput from './../../../components/searchInput/searchInput'
import DeliveryAddressSubmitted from './../../../components/modals/deliveryAddressSubmitted'
import { UploadIcon, CancelIcon, TrashIcon, ViewIcon, SupplierFillIcon, SubmittedIcon, PendingIcon } from './../../../components/icons/icons';
import ImportPartOrder from './../../../pages/buyer/partOrder/importPartOrder'
import { useGlobalState } from '../../../utilities/globalState'
import CSVReader from "react-csv-reader";
import moment from 'moment'
import {
    LoadingOutlined
} from '@ant-design/icons';

const { Option } = Select;
const { confirm } = Modal;
const { Text } = Typography;

const layout = {
    labelCol: { span: 24 },
    wrapperCol: { span: 24 }
};



const PartOrder = (props) => {
    const { t } = useTranslation();
    const { showLoading, dismissLoading } = React.useContext(LoadingContext)
    const [form] = Form.useForm();
    const [visiblePartSearch, setVisiblePartSearch] = useState(false);
    const [partSearchData, setPartSearchData] = useState([])
    const [partOrderData, setPartOrderData] = useState({})
    const [partOrderLines, setPartOrderLines] = useState([])
    const [visibleImportPartOrder, setVisibleImportPartOrder] = useState(false);
    const [visiblePartEnquiry, setVisiblePartEnquiry] = useState(false);
    const [visibleSubmitOrder, setVisibleSubmitOrder] = useState(false);
    const [stockKeyEnquiry, setStockKeyEnquiry] = useState(false);
    const [makeList, setMakeList] = useGlobalState('makeList')
    const [orderTypes, setorderTypes] = useGlobalState('orderTypes')
    const [importData, setImportData] = useState([])
    const orderInputRef = useRef();
    const pickslipInputRef = useRef();
    const [taxIncluded, setTaxIncluded] = useState(true)
    const [partOrderSummaryData, setPartOrderSummaryData] = useState({})

    const [totalTotalPrice, setTotalTotalPrice] = useState((0).toFixed(Constant.NumberOfDecimalPlace));
    const [totalQtyOrder, setTotalQtyOrder] = useState(0);
    const [totalQtySupply, setTotalQtySupply] = useState(0);
    const [totalQtyBackOrder, setTotalQtyBackOrder] = useState(0);
    const [isShowOrderSummaryLoading, setIsShowOrderSummaryLoading] = useState(false);
    const [enablePartEnquiry, setEnablePartEnquiry] = useState(false);
    const [, setGlobalAreas] = useGlobalState('globalAreas');
    const [, setGlobalBureaucracies] = useGlobalState('globalBureaucracies');
    const [, setGlobalCountryCodes] = useGlobalState('globalCountryCodes');
    const [, setGlobalDeliveryMethods] = useGlobalState('globalDeliveryMethods');
    const [, setGlobalDeliveryRuns] = useGlobalState('globalDeliveryRuns');
    const [globalSystemAddressConfig, setGlobalSystemAddressConfig] = useGlobalState('globalSystemAddressConfig');
    const [backOrderMethods, setBackOrderMethods] = useState([])
    const [btnGroupWidth, setBtnGroupWidth] = useState('')
    const [expKeys, setExpKeys] = useState([])
    const [vinValue, setVinValue] = useState("");
    const authData = StorageService.getAuthData()
    const [visiblePickslipSearch, setVisiblePickslipSearch] = useState(false);
    const [lineErrorTextPosition, setLineErrorTextPosition] = useState("bottom");
    const [deliveryAddressDropdownData, setDeliveryAddressDropdownData] = useGlobalState('deliveryAddressDropdownData');

    const papaparseOptions = {
        header: false,
        dynamicTyping: true,
        skipEmptyLines: true
    };
    const bitwiseNumber = Constant.PartSearchTypeBitwise.GetShipmentQty +
        Constant.PartSearchTypeBitwise.GetPricePerUnit +
        Constant.PartSearchTypeBitwise.GetHoldingBay +
        Constant.PartSearchTypeBitwise.GetSupercede +
        Constant.PartSearchTypeBitwise.GetKitParts +
        Constant.PartSearchTypeBitwise.GetDefaultBackOrderMethod

    const getPartOrderSummary = () => {
        setIsShowOrderSummaryLoading(true)
        BuyerService.getPartOrderSummary()
            .finally(() => setIsShowOrderSummaryLoading(false))
            .then(result => {
                if (result.data) {
                    setPartOrderSummaryData(result.data)
                }
            })
            .catch(error => CommonService.handleErrorResponse(error))

    }

    const getExpandRows = (partList) => {
        let defaultExpandRows = partList.map(x => x.Sequence)
        setExpKeys(defaultExpandRows)
    }
    useEffect(() => {
        if (partOrderLines && partOrderLines.length > 1) {
            const elements = document.getElementsByClassName("cell-select");
            if (elements.length > 0) {
                for (let i = 0; i < elements.length; i++) {
                    let antdSelectors = Array.from(elements[i].children).filter(x => x.className == "ant-select-selector")
                    if (antdSelectors.length > 0) {
                        for (let j = 0; j < antdSelectors.length; j++) {
                            let antdSelectionItems = Array.from(antdSelectors[j].children).filter(x => x.className == "ant-select-selection-item")
                            if (antdSelectionItems.length > 0) {
                                antdSelectionItems[0].removeAttribute("title")
                                break
                            }
                        }
                    }
                }
            }
        }

    }, [partOrderLines])
    useEffect(() => {
        let timeoutFn = setTimeout(() => {
            document.documentElement.style.setProperty(Constant.CssVariables.FixItemsContainerHeight, CommonService.calculateTableBodyMaxHeight(Constant.FixItemsContainerId, 0, 0))
            let width = CommonService.calcButtonGroupIdWidth()
            setBtnGroupWidth(width)
            document.documentElement.style.setProperty(Constant.CssVariables.ButtonGroupWidth, width)
        }, 100);

        if (!deliveryAddressDropdownData) {
            BuyerService.getDeliveryAddressDropdownData()
                .then(result => {
                    if (result.data) {
                        // if (result.data.Areas) {
                        //     setGlobalAreas(result.data.Areas);
                        // } else {
                        //     setGlobalAreas([]);
                        // }
                        // if (result.data.Bureaucracies) {
                        //     setGlobalBureaucracies(result.data.Bureaucracies);
                        // } else {
                        //     setGlobalBureaucracies([]);
                        // }
                        // if (result.data.CountryCodes) {
                        //     setGlobalCountryCodes(result.data.CountryCodes);
                        // } else {
                        //     setGlobalCountryCodes([]);
                        // }
                        // if (result.data.DeliveryMethods) {
                        //     setGlobalDeliveryMethods(result.data.DeliveryMethods);
                        // } else {
                        //     setGlobalDeliveryMethods([]);
                        // }
                        // if (result.data.DeliveryRuns) {
                        //     setGlobalDeliveryRuns(result.data.DeliveryRuns);
                        // } else {
                        //     setGlobalDeliveryRuns([]);
                        // }
                        setDeliveryAddressDropdownData(result.data)
                    }
                })
                .finally(() => {
                })
                .catch(error => CommonService.handleErrorResponse(error))
        }

        if (!globalSystemAddressConfig) {
            BuyerService.getSystemAddressConfig().then(result => {
                if (result.data) {
                    setGlobalSystemAddressConfig(result.data);
                }
            });
        }

        if (!makeList || makeList.length == 0) {
            BuyerService.getMakeList()
                .then(result => {
                    if (result.data) {
                        setMakeList(result.data)
                    }
                })
                .catch(error => CommonService.handleErrorResponse(error))
        }
        if (!orderTypes || orderTypes.length == 0) {
            BuyerService.getOrderTypes()
                .then(result => {
                    if (result.data) {
                        setorderTypes(result.data)
                        let defaultOrderType = result.data.find(o => o.IsDefault)
                        if (defaultOrderType) {
                            form.setFieldsValue({
                                "OrderTypeCode": defaultOrderType.Code
                            });
                        }
                    }
                })
                .catch(error => CommonService.handleErrorResponse(error))
        }
        else {
            let defaultOrderType = orderTypes.find(o => o.IsDefault)
            if (defaultOrderType) {
                form.setFieldsValue({
                    "OrderTypeCode": defaultOrderType.Code
                });
            }
        }

        setPartOrderLines([...partOrderLines, getBlankLine()])
        getPartOrderSummary()
        getExpandRows(partOrderLines)
        //let authData = StorageService.getAuthData()
        if (authData) {
            setTaxIncluded(authData.isShowPriceTaxIncluded);
            if (Array.isArray(authData.functionCodes)) {
                setEnablePartEnquiry(
                    (authData.functionCodes.filter(d => d === Constant.SupplierBuyerFunction.PartEnquiry).length > 0)
                );
            }
        }

        return () => {
            BuyerService.cancelRequest()
            clearTimeout(timeoutFn);
            setPartOrderData({})
        }
    }, [])

    const reset = () => {
        if (partOrderData && partOrderData.PickslipKey) {
            confirm({
                title: t("part_order.reset_confirm"),
                okText: t("common.yes"),
                cancelText: t("common.no"),
                onOk() {
                    clearAll()
                }
            });
        } else {
            clearAll()
        }
    }

    const deletePartItem = (item) => {
        if (item) {
            let deletedItems = [item]
            if (item.IsKitPart) {
                let kitItems = partOrderLines.filter(p => p.ParentSequence == item.Sequence)
                if (kitItems != null && kitItems.length > 0) {
                    deletedItems = [...deletedItems, ...kitItems]
                }
            }

            if (item.LineId) {
                confirm({
                    title: t("part_order.remove_line_confirm"),
                    okText: t("common.yes"),
                    cancelText: t("common.no"),
                    onOk() {
                        showLoading()
                        BuyerService.RemovePartOrderLine(deletedItems)
                            .finally(() => dismissLoading())
                            .then(result => {
                                if (result.data) {
                                    applyPartOder(result.data)
                                } else {
                                    clearAll()
                                }
                            })
                            .catch(error => CommonService.handleErrorResponse(error))
                    }
                });
            } else {
                if (item.Sequence > 0) {
                    let tempList = [...partOrderLines]
                    deletedItems.forEach(d => {
                        tempList.splice(tempList.indexOf(d), 1)
                    })

                    tempList = recalculateSequence(tempList)

                    if (item.Sequence - 1 == tempList.length) {
                        tempList.push(getBlankLine())
                    }
                    updateTotalRows(tempList);
                    setPartOrderLines(tempList)
                    getExpandRows(tempList)
                }
            }
        }
    }

    const recalculateSequence = (list) => {
        for (let i = 0; i < list.length; i++) {
            let kitList = []
            if (list[i].IsKitPart) {
                kitList = list.filter(p => p.ParentSequence == list[i].Sequence)
            }
            list[i].Sequence = i + 1

            if (kitList && kitList.length > 0) {
                kitList.forEach(k => {
                    k.ParentSequence = list[i].Sequence
                })
            }

            if (list[i].SaleOrderTypePricePerUnits) {
                list[i].SaleOrderTypePricePerUnits = list[i].SaleOrderTypePricePerUnits.sort((a, b) => {
                    return b.Qty - a.Qty
                })

                let orderTypeCode = form.getFieldValue("OrderTypeCode")
                list[i].CurrentOrderTypeCode = orderTypeCode
            }
        }
        return list
    }

    const viewPartDetail = (item) => {
        if (item && item.DmsStockKey) {
            setStockKeyEnquiry(item.DmsStockKey)
            setVisiblePartEnquiry(true)
        }

    }

    const getActionButtonsInfo = (item) => {
        return [
            {
                Description: t("common.view_part_details"),
                Icon: <ViewIcon />,
                ColorClass: Constant.ColorClass.LightBlue,
                Hide: false,
                handleAction: viewPartDetail
            },
            {
                Description: t("common.delete"),
                Icon: <TrashIcon />,
                ColorClass: Constant.ColorClass.LightOrange,
                Hide: item.ParentSequence > 0 ? true : false,
                handleAction: deletePartItem
            }
        ]
    }

    const onSearchPart = (data) => {
        let formData = form.getFieldsValue()
        if (formData && formData.OrderTypeCode) {
            if (data && data.PartNumber) {
                getSearchStock(data.PartNumber, "")
            }
        } else {
            form.setFields([{
                name: 'OrderTypeCode',
                errors: [t("part_order.order_type_required")]
            }])
        }
    }

    const getSearchStock = (partItemCode, makeId) => {
        setPartSearchData([])
        showLoading()
        BuyerService.getSearchStock(partItemCode, makeId)
            .finally(() => dismissLoading())
            .then(result => {
                if (!result.data || result.data.length == 0) {
                    CommonService.presentToast("warning", t("part_enquiry.part_not_found"))
                    return
                }
                if (result.data.length == 1 && !result.data[0].IsNonStockRecord) {
                    getPartDetails(result.data[0].StockKey)
                }
                else {//show part search
                    setPartSearchData(result.data)
                    showPartSearch()
                }
            })
            .catch(error => CommonService.handleErrorResponse(error))
    }

    const focusQtyOrder = (tempList, focusFirstEle = false, currentPart = null) => {
        setTimeout(() => {
            if (currentPart) {
                let focusedPart = tempList.filter(p => p.Sequence == currentPart.Sequence)
                if (focusedPart && focusedPart.length > 0) {
                    if (focusedPart[0].OrderedQty_onFocus) {
                        focusedPart[0].OrderedQty_onFocus()
                    }
                }
            } else {
                let eleIndex = focusFirstEle ? 0 : tempList.length - 1;
                if (tempList[eleIndex].PartNumber) {
                    if (tempList[eleIndex].OrderedQty_onFocus) {
                        tempList[eleIndex].OrderedQty_onFocus();
                    }
                } else {
                    if (tempList[eleIndex].PartNumber_onFocus) {
                        tempList[eleIndex].PartNumber_onFocus();
                    }
                }
            }
        }, 300);
    }

    const enableSellAtPackQty = (data) => {
        if (((authData && authData.enableSellAtPackQtyOnly) || data.EnableSellAtPackQtyOnly) && data.SupplierPackQty && data.SupplierPackQty > 1) {
            return true
        }
        return false
    }

    const getPartDetails = (stockKey, partNumber = null, makeId = null) => {
        showLoading()
        let bitwise = bitwiseNumber
        if (partNumber) {
            bitwise += Constant.PartSearchTypeBitwise.CreateNonStockRecord
        }
        BuyerService.getPartDetails(stockKey, bitwise, partNumber, makeId)
            .finally(() => dismissLoading())
            .then(result => {
                if (result.data) {
                    let line = mapPartOrderLine(result.data)
                    let isEnableSellAtPackQty = enableSellAtPackQty(line)
                    if (partOrderLines.length > 0) {
                        let tempList = [...partOrderLines]
                        line.Sequence = tempList.length
                        tempList[tempList.length - 1] = { ...tempList[tempList.length - 1], ...line }
                        if (isEnableSellAtPackQty) {
                            tempList[tempList.length - 1].OrderedQty = line.SupplierPackQty
                        }
                        if (line.StockKits && line.StockKits.length > 0) {
                            tempList[tempList.length - 1].IsKitPart = true
                            line.StockKits.forEach(kit => {
                                let kitLine = mapPartOrderLine(kit)
                                kitLine.ParentSequence = line.Sequence
                                if (isEnableSellAtPackQty) {
                                    var kitOrder = line.SupplierPackQty * kitLine.KitQty
                                    if (!isNaN(kitOrder)) {
                                        kitLine.OrderedQty = kitOrder
                                    }
                                    let totalPriceTaxEx = CommonService.calculateAmmount(kitLine.UnitPriceTaxExclusive, kitLine.OrderedQty)
                                    if (!isNaN(totalPriceTaxEx)) {
                                        kitLine.TotalPriceTaxExclusive = totalPriceTaxEx
                                    }

                                    let totalPriceTaxIn = CommonService.calculateAmmount(kitLine.UnitPriceTaxInclusive, kitLine.OrderedQty)
                                    if (!isNaN(totalPriceTaxIn)) {
                                        kitLine.TotalPriceTaxInclusive = totalPriceTaxIn
                                    }
                                }
                                tempList.push(kitLine)
                            })
                        }
                        tempList = recalculateSequence(tempList)
                        updateTotalRows(tempList);
                        setPartOrderLines(tempList)
                        focusQtyOrder(tempList, false, line);
                        getExpandRows(tempList)
                    }
                }
            })
            .catch(error => CommonService.handleErrorResponse(error))
    }

    const mapPartOrderLine = (data) => {
        let orderTypeCode = form.getFieldValue("OrderTypeCode")

        let line = {
            DmsStockKey: data.StockKey,
            MakeId: data.DPMakeId,
            DmsMakeId: data.MakeID,
            PartNumber: data.PartItemCode,
            PartDescription: data.Description,
            UnitPriceTaxInclusive: data.PricePerUnitTaxInclusive,
            UnitPriceTaxExclusive: data.PricePerUnitTaxExclusive,
            SaleOrderTypePricePerUnits: data.SaleOrderTypePricePerUnits.sort((a, b) => {
                return b.Qty - a.Qty
            }),
            AvailableQty: data.AvailableQty,
            InTransitQty: data.InTransitQty,
            ArrivedQty: data.ArrivedQty,
            RequiredDecimalQty: data.RequiredDecimalQty,
            BoCustomerQty: data.BoCustomerQty,
            SuppliedQty: data.SuppliedQty,
            QtyBackOrdered: data.QtyBackOrdered,
            IncludeNoteForEcommerce: data.IncludeNoteForEcommerce,
            Notes: data.Notes,
            ImmediateSuperceding: data.ImmediateSuperceding,
            SupercededTo: data.SupercededTo,
            ApplySupercessionCalculationAutomatically: data.ApplySupercessionCalculationAutomatically,
            UseBackwardSupercessionCalculation: data.UseBackwardSupercessionCalculation,
            StockKits: data.StockKits,
            KitQty: data.KitQty,
            DebtorDefaultBoMethodBySalesOrderTypeList: data.DebtorDefaultBoMethodBySalesOrderTypeList,
            CustomerStockSpecialPriceList: data.CustomerStockSpecialPriceList,
            CurrentOrderTypeCode: orderTypeCode,
            SupplierPackQty: data.SupplierPackQty,
            PackDescription: data.PackDescription,
            EnableSellAtPackQtyOnly: data.EnableSellAtPackQtyOnly
        }

        if (data.SaleOrderTypePricePerUnits && data.SaleOrderTypePricePerUnits.length > 0) {

            let pricePerUnit = data.SaleOrderTypePricePerUnits.find(p => p.SalesOrderType == orderTypeCode && p.Qty == 1)
            if (pricePerUnit) {
                line.UnitPriceTaxExclusive = pricePerUnit.PriceTaxExclusive
                line.UnitPriceTaxInclusive = pricePerUnit.PriceTaxInclusive
            }
        }

        if (line.DebtorDefaultBoMethodBySalesOrderTypeList && line.DebtorDefaultBoMethodBySalesOrderTypeList.length > 0) {
            let bo = line.DebtorDefaultBoMethodBySalesOrderTypeList.find(b => b.SalesOrderType == orderTypeCode)
            if (bo) {
                line.BackOrderMethod = bo.DefaultBackOrderMethod
                line.BackOrderMethodDescription = bo.DefaultBackOrderMethodDescription
            }
        }

        return line
    }

    const showPartSearch = () => {
        setVisiblePartSearch(true)
    }

    const handleCancel = (objectData) => {
        setVisiblePartSearch(false)
        if (objectData) {
            handleSelectedPart(objectData)
        }
    };

    const handleSelectedPart = (objectData) => {
        if (objectData) {
            setTimeout(() => {
                if (!objectData.IsNonStockRecord) {
                    getPartDetails(objectData.StockKey)
                } else {
                    getPartDetails(objectData.StockKey ? objectData.StockKey : -1, objectData.PartItemCode, objectData.Make)
                }
            }, 100)
        }
    }

    const handleSelectedPickslip = (objectData) => {
        if (objectData) {
            setTimeout(() => {
                getPartOrder(objectData.PickslipNo)
            }, 100)
        }
    }

    const getBlankLine = () => {
        return {
            Sequence: -1,
            PartNumber: "",
            OrderedQty: 0,
            TotalPriceTaxInclusive: 0,
            TotalPriceTaxExclusive: 0
        };
    };

    const onAddUpdatePartLine = (data, record) => {
        if (data && record) {
            if (!record.IsKitPart) {
                if (isNaN(Number(record.UnitPriceTaxExclusive)) ||
                    isNaN(Number(record.UnitPriceTaxInclusive)) ||
                    Number(record.UnitPriceTaxExclusive) == 0 ||
                    Number(record.UnitPriceTaxInclusive) == 0) {
                    CommonService.presentToast('warning', t("part_order.invalid_price_message", { supplierName: authData ? authData.supplierTradingName : t("common.supplier") }))
                    deletePartItem(record)
                    return
                }
                
                if (record.LineId || record.OrderedQty <= record.AvailableQty || enableSellAtPackQty(record) || ((!record.ImmediateSuperceding || record.ImmediateSuperceding.length == 0)
                    && (!record.SupercededTo || record.SupercededTo.length == 0))) {
                    doAddUpdatePartLine([record])
                } else {
                    applySupercession(record, true)
                }
            } else {
                kitPartList.push(record)
                let kitParts = partOrderLines.filter(p => p.ParentSequence == record.Sequence)
                if (kitParts && kitParts.length > 0) {
                    let invalidKit = kitParts.find(k => k.OrderedQty >= 100000)
                    if (invalidKit) {
                        return
                    }
                    let invalidPriceKit = kitParts.find(k => isNaN(Number(k.UnitPriceTaxExclusive)) ||
                        isNaN(Number(k.UnitPriceTaxInclusive)) ||
                        Number(k.UnitPriceTaxExclusive) == 0 ||
                        Number(k.UnitPriceTaxInclusive) == 0)
                    if (invalidPriceKit) {
                        kitPartList.pop(record)
                        CommonService.presentToast('warning', t("part_order.invalid_price_message", { supplierName: authData ? authData.supplierTradingName : t("common.supplier") }))
                        deletePartItem(record)
                        return
                    }
                    applySupercession(kitParts[0], true, true, kitParts.filter(p => p != kitParts[0]))
                }

                //doAddUpdatePartLine([record, ...kitParts])
            }
        }
    }

    const doAddUpdatePartLine = (data) => {
        let isUpdateSummary = false
        if (!partOrderData || !partOrderData.PickslipKey) {
            isUpdateSummary = true
        }

        data.forEach(record => {
            if (partOrderData) {
                record.PickslipKey = partOrderData.PickslipKey
                record.LockIdentifier = partOrderData.LockIdentifier
            }

            let formData = form.getFieldsValue()
            if (formData) {
                record.OrderNumber = formData.OrderNumber
                record.OrderTypeCode = formData.OrderTypeCode
                record.Vin = formData.Vin
            }
        });
     
        showLoading()
        BuyerService.AddUpdatePartOrderLine(data)
            .finally(() => {
                dismissLoading()
                refreshSupercession()
                kitPartList = []
            })
            .then(result => {
                if (result.data) {
                    applyPartOder(result.data)
                    if (isUpdateSummary)
                        getPartOrderSummary()
                }
            })
            .catch(error => {
                CommonService.handleErrorResponse(error)
                if(partOrderData && partOrderData.PickslipNo){
                    getPartOrder(partOrderData.PickslipNo)
                }
                else{
                    reset()
                }
            })
    }

    const refreshSupercession = () => {
        totalSupercessOrder = 0
        calculatedSupercessionPartList = []
        supercessionPartList = []
    }

    const applySupercession = (currentPart, isRoot, isKitPart = false, nextParts = []) => {
        let orderedPart = supercessionPartList.filter(p => p.DmsStockKey == currentPart.DmsStockKey)
        let alreadyOrderedQty = 0
        if (orderedPart && orderedPart.length > 0) {
            alreadyOrderedQty = orderedPart[0].OrderedQty
        }

        if (!currentPart.ApplySupercessionCalculationAutomatically &&
            ((isRoot && currentPart.OrderedQty > getRealAvailableQty(currentPart, isKitPart)) || (!isRoot && totalSupercessOrder > (currentPart.AvailableQty - alreadyOrderedQty))) &&
            ((currentPart.ImmediateSuperceding && currentPart.ImmediateSuperceding.length > 0) || (currentPart.UseBackwardSupercessionCalculation && currentPart.SupercededTo && currentPart.SupercededTo.length > 0))) {
            confirm({
                title: t("part_order.superceded_confirm").replace('{0}', currentPart.PartNumber),
                okText: t("common.yes"),
                cancelText: t("common.no"),
                onOk() {
                    if (isRoot) {
                        totalSupercessOrder = currentPart.OrderedQty
                    }
                    calculateSupercession(currentPart, isKitPart, nextParts)
                },
                onCancel() {
                    let existPart = supercessionPartList.filter(p => p.DmsStockKey == currentPart.DmsStockKey)
                    if (existPart.length == 0) {
                        currentPart.OrderedQty = currentPart.OrderedQty ? currentPart.OrderedQty + totalSupercessOrder : totalSupercessOrder
                        supercessionPartList.push(currentPart)
                    } else {
                        existPart[0].OrderedQty = existPart[0].OrderedQty ? existPart[0].OrderedQty + totalSupercessOrder : totalSupercessOrder
                    }

                    kitPartList.push(...supercessionPartList)
                    if (nextParts && nextParts.length > 0) {
                        refreshSupercession()
                        applySupercession(nextParts[0], false, isKitPart, nextParts.filter(p => p != nextParts[0]))
                    } else {
                        doAddUpdatePartLine(isKitPart ? kitPartList : supercessionPartList)
                    }
                }
            });
        } else {
            if (isRoot) {
                totalSupercessOrder = currentPart.OrderedQty
            }
            calculateSupercession(currentPart, isKitPart, nextParts)
        }
    }

    const getRealAvailableQty = (currentPart, isKitPart) => {
        let qty = currentPart.AvailableQty
        let processParts = isKitPart ? kitPartList : supercessionPartList
        let sameParts = processParts.filter(p => p.DmsStockKey == currentPart.DmsStockKey)
        if (sameParts && sameParts.length > 0) {
            sameParts.forEach(p => {
                qty += p.OrderedQty
            })
        }

        return qty
    }

    let totalSupercessOrder = 0
    let supercessionPartList = []
    let calculatedSupercessionPartList = []
    let kitPartList = []

    const calculateSupercession = (currentPart, isKitPart = false, nextParts = []) => {
        let calculatedPart = calculatedSupercessionPartList.filter(p => p.DmsStockKey == currentPart.DmsStockKey)

        if (calculatedPart.length > 0 || totalSupercessOrder > getRealAvailableQty(currentPart, isKitPart)) {
            if (calculatedPart.length == 0) {
                if (getRealAvailableQty(currentPart, isKitPart) > 0) {
                    currentPart.OrderedQty = getRealAvailableQty(currentPart, isKitPart)
                    totalSupercessOrder -= currentPart.OrderedQty
                    supercessionPartList.push(currentPart)
                }

                calculatedSupercessionPartList.push(currentPart)
            }

            if (totalSupercessOrder > 0) {
                if (currentPart.UseBackwardSupercessionCalculation && currentPart.SupercededTo && currentPart.SupercededTo.length > 0) {
                    for (let i = currentPart.SupercededTo.length - 1; i >= 0; i--) {
                        let supercededPart = currentPart.SupercededTo[i]

                        let calculatedSuperceded = calculatedSupercessionPartList.filter(p => p.DmsStockKey == supercededPart.StockKey)
                        if (totalSupercessOrder > 0 && getRealAvailableQty(supercededPart, isKitPart) > 0 && calculatedSuperceded.length == 0) {
                            let line = {
                                DmsStockKey: supercededPart.StockKey,
                                DmsMakeId: supercededPart.MakeID,
                                PartNumber: supercededPart.PartItemCode,
                                OrderedQty: supercededPart.IsKitPart ? totalSupercessOrder : Math.min(getRealAvailableQty(supercededPart, isKitPart), totalSupercessOrder),
                                ParentSequence: currentPart.ParentSequence,
                                IsKitPart: supercededPart.IsKitPart
                            }
                            supercessionPartList.push(line)
                            totalSupercessOrder -= line.OrderedQty
                            calculatedSupercessionPartList.push(line)
                        }
                    }
                }
            }

            if (totalSupercessOrder > 0) {
                if (currentPart.ImmediateSuperceding && currentPart.ImmediateSuperceding.length > 0) {
                    currentPart.ImmediateSuperceding.forEach(supercedingPart => {
                        let calculatedSuperceding = calculatedSupercessionPartList.filter(p => p.DmsStockKey == supercedingPart.StockKey)
                        if (totalSupercessOrder > 0 && getRealAvailableQty(supercedingPart, isKitPart) > 0 && calculatedSuperceding.length == 0) {
                            let line = {
                                DmsStockKey: supercedingPart.StockKey,
                                DmsMakeId: supercedingPart.MakeID,
                                PartNumber: supercedingPart.PartItemCode,
                                OrderedQty: supercedingPart.IsKitPart ? totalSupercessOrder : Math.min(getRealAvailableQty(supercedingPart, isKitPart), totalSupercessOrder),
                                ParentSequence: currentPart.ParentSequence,
                                IsKitPart: supercedingPart.IsKitPart
                            }

                            supercessionPartList.push(line)
                            totalSupercessOrder -= line.OrderedQty
                            calculatedSupercessionPartList.push(line)
                        }
                    })
                }
            }

            if (totalSupercessOrder > 0 && currentPart.ImmediateSuperceding && currentPart.ImmediateSuperceding.length > 0) {
                let firstPart = currentPart.ImmediateSuperceding[0]
                firstPart.ParentSequence = currentPart.ParentSequence
                replaceSupercessionPart(firstPart, isKitPart, nextParts)
            } else {
                if (totalSupercessOrder > 0) {
                    let part = {
                        ...currentPart,
                        OrderedQty: totalSupercessOrder
                    }
                    supercessionPartList.push(part)
                }

                if (supercessionPartList.length > 0) {
                    kitPartList.push(...supercessionPartList)
                    if (nextParts && nextParts.length > 0) {
                        refreshSupercession()
                        applySupercession(nextParts[0], true, isKitPart, nextParts.filter(p => p != nextParts[0]))
                    } else {
                        doAddUpdatePartLine(isKitPart ? kitPartList : supercessionPartList)
                    }
                } else {
                    if (!currentPart.OrderedQty) {
                        currentPart.OrderedQty = totalSupercessOrder
                    }
                    kitPartList.push(currentPart)
                    if (nextParts && nextParts.length > 0) {
                        refreshSupercession()
                        applySupercession(nextParts[0], true, isKitPart, nextParts.filter(p => p != nextParts[0]))
                    } else {
                        doAddUpdatePartLine(isKitPart ? kitPartList : [currentPart])
                    }
                }
            }
        } else {
            supercessionPartList.push(currentPart)
            kitPartList.push(...supercessionPartList)
            if (nextParts && nextParts.length > 0) {
                refreshSupercession()
                applySupercession(nextParts[0], true, isKitPart, nextParts.filter(p => p != nextParts[0]))
            } else {
                doAddUpdatePartLine(isKitPart ? kitPartList : supercessionPartList)
            }
        }
    }

    const replaceSupercessionPart = (currentPart, isKitPart = false, nextParts = []) => {
        showLoading()
        BuyerService.getPartDetails(currentPart.StockKey, bitwiseNumber)
            .finally(() => dismissLoading())
            .then(result => {
                if (result.data) {
                    let line = {
                        DmsStockKey: result.data.StockKey,
                        MakeId: result.data.DPMakeId,
                        DmsMakeId: result.data.MakeID,
                        PartNumber: result.data.PartItemCode,
                        PartDescription: result.data.Description,
                        UnitPriceTaxInclusive: result.data.PricePerUnitTaxInclusive,
                        UnitPriceTaxExclusive: result.data.PricePerUnitTaxExclusive,
                        AvailableQty: result.data.AvailableQty,
                        InTransitQty: result.data.InTransitQty,
                        ArrivedQty: result.data.ArrivedQty,
                        RequiredDecimalQty: result.data.RequiredDecimalQty,
                        BoCustomerQty: result.data.BoCustomerQty,
                        SuppliedQty: result.data.SuppliedQty,
                        QtyBackOrdered: result.data.QtyBackOrdered,
                        IncludeNoteForEcommerce: result.data.IncludeNoteForEcommerce,
                        Notes: result.data.Notes,
                        ImmediateSuperceding: result.data.ImmediateSuperceding,
                        SupercededTo: result.data.SupercededTo,
                        ApplySupercessionCalculationAutomatically: result.data.ApplySupercessionCalculationAutomatically,
                        UseBackwardSupercessionCalculation: result.data.UseBackwardSupercessionCalculation,
                        ParentSequence: currentPart.ParentSequence
                    }
                    applySupercession(line, false, isKitPart, nextParts)
                }
            })
            .catch(error => CommonService.handleErrorResponse(error))
    }

    const getSupersessionPart = (currentPart) => {
        if (currentPart.UseBackwardSupercessionCalculation && currentPart.SupercededTo && currentPart.SupercededTo.length > 0) {
            for (let i = currentPart.SupercededTo.length - 1; i >= 0; i--) {
                let supercededPart = currentPart.SupercededTo[i]
                if (supercededPart.AvailableQty > 0) {
                    supercededPart.DmsStockKey = supercededPart.StockKey
                    return supercededPart
                }
            }
        }

        if (currentPart.ImmediateSuperceding && currentPart.ImmediateSuperceding.length > 0) {
            for (let i = 0; i < currentPart.ImmediateSuperceding.length; i++) {
                let supercedingPart = currentPart.ImmediateSuperceding[i]
                if (supercedingPart.AvailableQty > 0) {
                    supercedingPart.DmsStockKey = supercedingPart.StockKey
                    return supercedingPart
                }
            }
        }

        return null
    }

    const submitOrder = () => {
        let data = form.getFieldsValue()
        if (data && data.PickslipNo && data.OrderNumber && data.OrderTypeCode) {
            setVisibleSubmitOrder(true);
        } else {
            form.validateFields();
        }
    }

    const cancelOrder = () => {
        let data = form.getFieldsValue()
        if (data && data.PickslipNo) {
            confirm({
                title: t("part_order.cancel_confirm"),
                okText: t("common.yes"),
                cancelText: t("common.no"),
                onOk() {
                    Object.assign(partOrderData, data)
                    showLoading()
                    BuyerService.CancelPartOrder(partOrderData)
                        .finally(() => dismissLoading())
                        .then(() => {
                            CommonService.presentToast('success', t("part_order.cancel_success"))
                            clearAll()
                            getPartOrderSummary()
                        })
                        .catch(error => CommonService.handleErrorResponse(error))
                }
            });
        }
    }

    const clearAll = () => {
        setPartOrderLines([getBlankLine()])
        setPartOrderData({})
        getExpandRows([])
        let defaultOrderType = orderTypes ? orderTypes.find(o => o.IsDefault) : null
        form.setFieldsValue({
            PickslipNo: null,
            OrderNumber: null,
            OrderTypeCode: defaultOrderType ? defaultOrderType.Code : null,
            Vin: null
        });
        setTotalTotalPrice(0)
        setTotalQtyOrder(0)
        setTotalQtySupply(0)
        setTotalQtyBackOrder(0)
    }

    const getPartOrder = (data, isShowLoading = true) => {
        if (data) {
            if (isShowLoading) {
                showLoading()
            }

            BuyerService.GetPartOrder(data)
                .finally(() => {
                    if (isShowLoading) {
                        dismissLoading()
                    }
                })
                .then(result => {
                    if (result && result.data) {

                        applyPartOder(result.data)
                    }
                })
                .catch(error => CommonService.handleErrorResponse(error))
        }
    };

    const applyPartOder = (partOrder) => {
        setPartOrderData(partOrder)
        form.setFieldsValue(partOrder)
        applyBackOrderMethodsForOrderType(partOrder.OrderTypeCode)
        if (partOrder.PartOrderLines && partOrder.PartOrderLines.length > 0) {
            partOrder.PartOrderLines = recalculateSequence(partOrder.PartOrderLines)
            partOrder.PartOrderLines.push(getBlankLine())
            setPartOrderLines(partOrder.PartOrderLines);
            updateTotalRows(partOrder.PartOrderLines);
            focusQtyOrder(partOrder.PartOrderLines);
            getExpandRows(partOrder.PartOrderLines)
        }
    }

    const getPartOrderByOrderNo = (data) => {
        if (partOrderData && partOrderData.PickslipKey) {
            return
        }

        if (data) {
            let query = {
                OrderNo: data
            }
            showLoading()
            BuyerService.getPickslips(query, 1, 10)
                .finally(() => dismissLoading())
                .then(result => {
                    if (result && result.data && result.data.ObjectList) {
                        if (result.data.TotalItems == 1) {
                            getPartOrder(result.data.ObjectList[0].PickslipNo)
                        } else if (result.data.TotalItems > 1) {
                            if (orderInputRef && orderInputRef.current) {
                                orderInputRef.current.showSearch(query, result.data)
                            }
                        }
                    }
                })
                .catch(error => CommonService.handleErrorResponse(error))
        }
    };

    const getPickslipForSummary = () => {
        showLoading()
        BuyerService.getPickslips({}, 1, 10, "")
            .finally(() => dismissLoading())
            .then(result => {
                if (result && result.data && result.data.ObjectList) {
                    if (pickslipInputRef && pickslipInputRef.current) {
                        pickslipInputRef.current.showSearch({}, result.data)
                    }
                }
            })
            .catch(error => CommonService.handleErrorResponse(error))
    };

    const updateTotalRows = (tempList) => {
        let totalPriceArray = (Array.isArray(tempList) && tempList.length > 0 ? tempList.map(p => !p.IsKitPart ? (taxIncluded ? p.TotalPriceTaxInclusive : p.TotalPriceTaxExclusive) : 0) : [0])
            .reduce((a, b) => (isNaN(a) ? 0 : a) + (isNaN(b) ? 0 : b))

        let _totalTotalPrice = 0

        if (totalPriceArray) {
            _totalTotalPrice = totalPriceArray.toFixed(Constant.NumberOfDecimalPlace);
        }
        setTotalTotalPrice(isNaN(_totalTotalPrice) ? 0 : _totalTotalPrice);

        let qtyOrder = (Array.isArray(tempList) && tempList.length > 0 ? tempList.map(p => !p.IsKitPart ? p.OrderedQty : 0) : [0])
            .reduce((a, b) => (isNaN(a) ? 0 : a) + (isNaN(b) ? 0 : b)) 
        let _qtyOrder  = 0
        if(qtyOrder)
        {
             _qtyOrder = qtyOrder.toFixed(Constant.NumberOfDecimalPlace);
        }   
        setTotalQtyOrder(isNaN(_qtyOrder) ? 0 : _qtyOrder);

        let qtySupplied = (Array.isArray(tempList) && tempList.length > 0 ? tempList.map(p => !p.IsKitPart ? p.SuppliedQty : 0) : [0])
            .reduce((a, b) => (isNaN(a) ? 0 : a) + (isNaN(b) ? 0 : b))
            let _qtySupplied  = 0
            if(qtySupplied)
            {
                _qtySupplied = qtySupplied.toFixed(Constant.NumberOfDecimalPlace);
            }
        setTotalQtySupply(isNaN(_qtySupplied) ? 0 : _qtySupplied);

        let qtyBO = (Array.isArray(tempList) && tempList.length > 0 ? tempList.map(p => !p.IsKitPart ? p.QtyBackOrdered : 0) : [0])
            .reduce((a, b) => (isNaN(a) ? 0 : a) + (isNaN(b) ? 0 : b))
        setTotalQtyBackOrder(isNaN(qtyBO) ? 0 : qtyBO); 
    };

    const onOrderedQtyChange = (value, record) => {
        if (record) {
            record.OrderedQty = value;
            let tempList = [...partOrderLines]
            tempList[record.Sequence - 1] = { ...record }
            if (record.IsKitPart) {
                let kitParts = tempList.filter(p => p.ParentSequence == record.Sequence)
                if (kitParts && kitParts.length > 0) {
                    kitParts.forEach(k => {
                        var kitOrder = record.OrderedQty * k.KitQty
                        if (!isNaN(kitOrder)) {
                            if(!k.RequiredDecimalQty)
                            {
                                kitOrder = parseInt(kitOrder)
                            }
                            k.OrderedQty = kitOrder
                        }

                        let totalPriceTaxEx = CommonService.calculateAmmount(k.UnitPriceTaxExclusive, k.OrderedQty)
                        if (!isNaN(totalPriceTaxEx)) {
                            k.TotalPriceTaxExclusive = totalPriceTaxEx
                        }

                        let totalPriceTaxIn = CommonService.calculateAmmount(k.UnitPriceTaxInclusive, k.OrderedQty)
                        if (!isNaN(totalPriceTaxIn)) {
                            k.TotalPriceTaxInclusive = totalPriceTaxIn
                        }
                    })
                }
            }
            updateTotalRows(tempList);
            setPartOrderLines(tempList);
            getExpandRows(tempList)
            let data = form.getFieldsValue()
            if (data && data.OrderTypeCode) {
                applyBackOrderMethodsForOrderType(data.OrderTypeCode)
            }
        }
    }

    const onBackOrderChange = (value, record) => {
        if (record) {
            record.BackOrderMethod = value;
            let tempList = [...partOrderLines]
            tempList[record.Sequence - 1] = { ...record }

            onAddUpdatePartLine(record, record)
        }
    }

    const onBinLocationChanged = (value, record) => {
        if (record) {
            record.BinLocation = value;
            let tempList = [...partOrderLines]
            tempList[record.Sequence - 1] = { ...record }
            setPartOrderLines(tempList);
        }
    }

    const getBOMethodDescriptionByCode = (code) => {
        if (backOrderMethods && code) {
            let selectedObjs = backOrderMethods.filter(x => x.Code == code)
            if (selectedObjs.length > 0) {
                return selectedObjs[0].Description
            }
        }
        return ''
    }

    const replaceSelectingPart = (part) => {
        if (part) {
            let supersession = getSupersessionPart(part)
            if (supersession && supersession.StockKey) {
                getPartDetails(supersession.StockKey)
            }
        }
    }

    const getSupersessionPartNumber = (part) => {
        if (part) {
            let supersession = getSupersessionPart(part)
            if (supersession && supersession.StockKey) {
                return supersession.PartItemCode.replace(' ', '')
            }
        }

        return ""
    }

    const getSupersessionPartQty = (part) => {
        if (part) {
            let supersession = getSupersessionPart(part)
            if (supersession && supersession.StockKey) {
                return supersession.AvailableQty
            }
        }

        return ""
    }

    const isShowSupersessionSugestion = () => {
        return !visibleImportPartOrder && !visiblePartEnquiry && !visiblePartSearch && !visiblePickslipSearch && !visibleSubmitOrder
    }

    const columns = [
        {
            title: t('common.action'),
            key: 'Id',
            render: (value, record) => {
                return {
                    props: {
                        className: record.ParentSequence > 0 ? "child-stock" : (record.IsKitPart > 0 ? "parent-stock" : "")
                    },
                    children: record.PartNumber ? <ActionButtonsCell btnList={getActionButtonsInfo(record)} data={record} /> : ""
                }
            },
            align: "center",
            width: 90
        },
        {
            title: t('part_order.id'),
            dataIndex: 'LineId',
            key: 'LineId',
            width: 70
        },
        {
            title: t('part_enquiry.part_number'),
            dataIndex: 'PartNumber',
            key: 'PartNumber',
            editable: true,
            extraData: makeList,
            required: true,
            cellType: Constant.CellType.PartOrderSearchInput,
            width: 280,
            handleSelectedItem: handleSelectedPart,
            handleFormSubmit: onSearchPart
        },
        {
            title: t('part_enquiry.make'),
            dataIndex: 'DmsMakeId',
            key: 'DmsMakeId',
            width: 200,
        },
        {
            title: t('part_enquiry.description'),
            dataIndex: 'PartDescription',
            key: 'PartDescription',
            width: 200,
        },
        {
            title: <Tooltip title={t('part_order.qty_order_tooltip')}>
                <div className="text-center" dangerouslySetInnerHTML={{ __html: t('part_order.qty_order') }} ></div>
            </Tooltip>,
            dataIndex: 'OrderedQty',
            key: 'OrderedQty',
            required: true,
            editable: true,
            cellType: Constant.CellType.PartOrderNumberInput,
            handleFormSubmit: onAddUpdatePartLine,
            handleOnChange: onOrderedQtyChange,
            width: 100,
            errorTextPosition: lineErrorTextPosition
        },
        {
            title: <Tooltip title={t('part_order.qty_available_tooltip')}>
                <div className="text-center" dangerouslySetInnerHTML={{ __html: t('part_order.qty_available') }}></div>
            </Tooltip>,
            dataIndex: 'AvailableQty',
            key: 'AvailableQty',
            width: 100,
            align: "center",
            render: (value, record) => {
                if (record.IsKitPart) {
                    setLineErrorTextPosition("bottom")
                    return <></>
                }

                if (record.DmsStockKey && !record.LineId && (!record.StockKits || record.StockKits.length == 0) && record.AvailableQty == 0 && getSupersessionPart(record)) {
                    setLineErrorTextPosition("top")
                    return <Popover overlayStyle={{ zIndex: 3 }} visible={true} placement="bottom"
                        content={
                            <div style={{ maxWidth: "500px" }}>
                                <p dangerouslySetInnerHTML={{ __html: t("part_order.supersession_suggestion", { supersession: getSupersessionPartNumber(record), supersessionQty: getSupersessionPartQty(record) }) }}></p>
                                <Row className="reverse-row">
                                    <Button size="small" className="m-l-10" type="primary" onClick={() => replaceSelectingPart(record)}>{t("common.select")}</Button>
                                    <Button size="small" className="secondary-btn" onClick={() => viewPartDetail(getSupersessionPart(record))}>{t("common.detail")}</Button>
                                </Row>
                            </div>
                        }
                    >
                        {record.AvailableQty}
                    </Popover>
                }

                if (record.ParentSequence > 0) {
                    setLineErrorTextPosition("left")
                } else {
                    setLineErrorTextPosition("bottom")
                }

                return <>{record.AvailableQty}</>

            }
        },
        {
            title: <Tooltip title={t('part_order.qty_supply_tooltip')}>
                <div className="text-center" dangerouslySetInnerHTML={{ __html: t('part_order.qty_supply') }}></div>
            </Tooltip>,
            dataIndex: 'SuppliedQty',
            key: 'SuppliedQty',
            width: 80,
            align: "center",
            render: (value, record) => {
                if (record.IsKitPart) {
                    return <></>
                }
                if (record.SuppliedQty < record.OrderedQty) {
                    return <span style={{ color: "red" }}>{record.SuppliedQty}</span>
                }
                return <>{record.SuppliedQty}</>
            }
        },
        {
            title: <Tooltip title={t('part_order.qty_bo_tooltip')}>
                <div className="text-center" dangerouslySetInnerHTML={{ __html: t('part_order.qty_back_order') }} title={t('part_order.qty_bo_tooltip')}></div>
            </Tooltip>,
            dataIndex: 'QtyBackOrdered',
            key: 'QtyBackOrdered',
            width: 80,
            align: "center",
            render: (value, record) => {
                if (record.IsKitPart) {
                    return <></>
                }
                return <>{record.QtyBackOrdered}</>
            }
        },
        {
            title: <Tooltip title={t('part_order.back_order_mathod')}>
                <span>{t('part_order.bo_method')}</span>
            </Tooltip>
            ,
            dataIndex: 'BackOrderMethod',
            key: 'BackOrderMethod',
            width: 100,
            render: (value, record) => {
                if (record.IsKitPart || isNaN(record.OrderedQty) || ((!record.LineId && record.AvailableQty >= record.OrderedQty) || (record.LineId && record.SuppliedQty >= record.OrderedQty))) {
                    return <></>
                } else {
                    if (backOrderMethods && backOrderMethods.length > 0 && record && record.DmsStockKey) {
                        return <Tooltip placement="bottom" title={getBOMethodDescriptionByCode(record.BackOrderMethod)}>
                            <Select className="cell-select" value={record.BackOrderMethod} onSelect={(value) => onBackOrderChange(value, record)} onClear={() => onBackOrderChange(null, record)} optionLabelProp="label" dropdownMatchSelectWidth={false}>
                                {
                                    backOrderMethods.map(n => <Option key={n.Code} value={n.Code} title={n.Code}>{n.Description}</Option>)
                                }
                            </Select>
                        </Tooltip>

                    } else {
                        return <Tooltip placement="bottom" title={record.BackOrderMethodDescription}>
                            <span>{record.BackOrderMethod}</span>
                        </Tooltip>
                    }
                }
            }
        },
        {
            title: t('part_order.bin_location'),
            dataIndex: 'BinLocation',
            key: 'BinLocation',
            editable: true,
            cellType: Constant.CellType.PartOrderInput,
            handleFormSubmit: onAddUpdatePartLine,
            handleOnChange: onBinLocationChanged,
            width: 170,
            hidden: CommonService.dmsVersionGreaterOrEqual(Constant.DmsVersion.version_1_36_6_0) ? false : true
        },
        {
            title: <div className="text-center" dangerouslySetInnerHTML={{ __html: taxIncluded ? t('part_order.unit_price_inc') : t('part_order.unit_price_ex') }}></div>,
            dataIndex: 'UnitPrice',
            key: 'UnitPrice',
            width: 100,
            align: "center",
            render: (value, record) => {
                if (record.IsKitPart) {
                    return <></>
                }
                return <>{
                    taxIncluded
                        ? (record.UnitPriceTaxInclusive ? record.UnitPriceTaxInclusive.toFixed(Constant.NumberOfDecimalPlace) : null)
                        : (record.UnitPriceTaxExclusive ? record.UnitPriceTaxExclusive.toFixed(Constant.NumberOfDecimalPlace) : null)
                }</>
            }
        },
        {
            title: <div className="text-center" dangerouslySetInnerHTML={{ __html: taxIncluded ? t('part_order.total_price_tax_inc') : t('part_order.total_price_tax_exc') }}></div>,
            dataIndex: 'TotalPrice',
            key: 'TotalPrice',
            width: 120,
            align: "center",
            render: (value, record) => {
                if (record.IsKitPart) {
                    return <></>
                }
                return <>{
                    taxIncluded
                        ? (record.TotalPriceTaxInclusive ? record.TotalPriceTaxInclusive.toFixed(Constant.NumberOfDecimalPlace) : null)
                        : (record.TotalPriceTaxExclusive ? record.TotalPriceTaxExclusive.toFixed(Constant.NumberOfDecimalPlace) : null)
                }</>
            }
        },
        {
            title: <div className="text-center" dangerouslySetInnerHTML={{ __html: t('part_order.quantity_in_transit') }}></div>,
            dataIndex: 'InTransitQty',
            key: 'InTransitQty',
            width: 80,
            align: "center",
            render: (value, record) => {
                if (record.IsKitPart) {
                    return <></>
                }
                return <>{record.InTransitQty}</>
            }
        },
        {
            title: <div className="text-center" dangerouslySetInnerHTML={{ __html: t('part_enquiry.quantity_arrived') }}></div>,
            dataIndex: 'ArrivedQty',
            key: 'ArrivedQty',
            width: 80,
            align: "center",
            render: (value, record) => {
                if (record.IsKitPart) {
                    return <></>
                }
                return <>{record.ArrivedQty}</>
            }
        }
    ].filter(c => !c.hidden)

    if (authData && authData.isCombineQtyArrivedIntoQtyInTransit) {
        var arrivedQtyColumnIndex = columns.findIndex(p => p.key === "ArrivedQty");
        if (arrivedQtyColumnIndex >= 0) {
            //remove column arrived qty
            columns.splice(arrivedQtyColumnIndex, 1);
        }
    }

    const columnsMapping = columns.map((col) => {
        if (!col.editable) {
            return col;
        }

        return {
            ...col,
            onCell: (record) => ({
                record,
                editable: col.editable,
                dataIndex: col.dataIndex,
                title: col.title,
                handleSelectedItem: col.handleSelectedItem,
                required: col.required,
                cellType: col.cellType,
                handleFormSubmit: col.handleFormSubmit,
                handleOnChange: col.handleOnChange,
                extraData: col.extraData,
                errorTextPosition: col.errorTextPosition
            }),
        };
    });

    const components = {
        body: {
            cell: EditableCell,
        },
    };

    const importPart = () => {
        let formData = form.getFieldsValue()
        if (formData && formData.OrderTypeCode) {
            document.getElementById('import-part').click()
        } else {
            form.setFields([{
                name: 'OrderTypeCode',
                errors: [t("part_order.order_type_required")]
            }])
        }

    }

    const handleCancelImport = (objectData) => {
        document.documentElement.style.setProperty(Constant.CssVariables.FixItemsContainerHeight, CommonService.calculateTableBodyMaxHeight(Constant.FixItemsContainerId, 0, 0))
        setVisibleImportPartOrder(false)

        if (objectData) {
            getPartOrder(objectData)
        }
    }

    const handleImportPart = (data, fileInfo) => {
        setImportData(data)
        setVisibleImportPartOrder(true)
        document.getElementById('import-part').value = null
    };

    const handleCancelPartEnquiry = () => {
        setVisiblePartEnquiry(false)
    }

    const handleCancelSubmitOrder = (objectData) => {
        if (objectData) {
            let data = form.getFieldsValue()
            if (data && data.PickslipNo && data.OrderNumber) {
                Object.assign(partOrderData, data);
                Object.assign(partOrderData, objectData);
                showLoading()
                BuyerService.SubmitPartOrder(partOrderData)
                    .finally(() => dismissLoading())
                    .then(result => {
                        CommonService.presentToast('success', t("part_order.submit_success"))
                        setVisibleSubmitOrder(false);
                        clearAll();
                        getPartOrderSummary()
                    })
                    .catch(error => CommonService.handleErrorResponse(error))
            }
        } else {
            setVisibleSubmitOrder(false);
        }
    }

    const onOrderTypeChange = (data) => {
        applyBackOrderMethodsForOrderType(data)

        if (partOrderData && partOrderData.PickslipKey) {
            confirm({
                title: t("part_order.order_type_change"),
                okText: t("common.yes"),
                cancelText: t("common.no"),
                onOk() {
                    let updatingLines = partOrderLines.filter(l => l.LineId)
                    if (updatingLines && updatingLines.length > 0) {
                        updatingLines.forEach(p => {
                            if (p.BackOrderMethod && p.DebtorDefaultBoMethodBySalesOrderTypeList && p.DebtorDefaultBoMethodBySalesOrderTypeList.length > 0) {
                                let bo = p.DebtorDefaultBoMethodBySalesOrderTypeList.find(o => o.SalesOrderType == data)
                                if (bo) {
                                    p.BackOrderMethod = bo.DefaultBackOrderMethod
                                    p.BackOrderMethodDescription = bo.DefaultBackOrderMethodDescription
                                }
                            }
                        })
                        doAddUpdatePartLine(updatingLines)
                    }
                },
                onCancel() {
                    form.setFieldsValue({
                        "OrderTypeCode": partOrderData.OrderTypeCode
                    });
                    applyBackOrderMethodsForOrderType(partOrderData.OrderTypeCode)
                }
            });
        } else {
            if (partOrderLines && partOrderLines.length > 0) {
                var isDirty = false
                let tempList = [...partOrderLines]
                tempList.forEach(p => {
                    if (p.SaleOrderTypePricePerUnits && p.SaleOrderTypePricePerUnits.length > 0) {
                        let price = p.SaleOrderTypePricePerUnits.find(o => o.SalesOrderType === data && (o.Qty <= p.OrderedQty || p.OrderedQty === 0))
                        if (!price && p.SaleOrderTypePricePerUnits.length > 0) {
                            price = p.SaleOrderTypePricePerUnits[0];
                        }
                        if (price) {
                            p.UnitPriceTaxExclusive = price.PriceTaxExclusive;
                            p.UnitPriceTaxInclusive = price.PriceTaxInclusive;
                            p.TotalPriceTaxExclusive = price.PriceTaxExclusive * p.OrderedQty;
                            p.TotalPriceTaxInclusive = price.PriceTaxInclusive * p.OrderedQty;
                            p.CurrentOrderTypeCode = data;
                            isDirty = true;
                        }
                    }

                    if (p.DebtorDefaultBoMethodBySalesOrderTypeList && p.DebtorDefaultBoMethodBySalesOrderTypeList.length > 0) {
                        if (!p.BackOrderMethod) {
                            let bo = p.DebtorDefaultBoMethodBySalesOrderTypeList.find(o => o.SalesOrderType == data)

                            if (bo) {
                                p.BackOrderMethod = bo.DefaultBackOrderMethod
                                p.BackOrderMethodDescription = bo.DefaultBackOrderMethodDescription
                                isDirty = true
                            }
                        }
                    }
                })

                if (isDirty) {
                    setPartOrderLines(tempList)
                    updateTotalRows(tempList)
                    getExpandRows(tempList)
                }
            }
        }

    }

    const vinChange = (e) => {
        let value = e.target.value;
        if (value) {
            value = value.trim().toString().toUpperCase();
        }
        const reg = /^[A-HJ-NP-Z0-9]*$/;
        if (!reg.test(value)) {
            value = value.replace(/[^A-HJ-NP-Z0-9]/g, '');
        }
        setVinValue(value);
        form.setFieldsValue({ "Vin": value });
    }

    const vinBlur = (e) => {
        let vinValue = e.target.value;
        if (partOrderData && partOrderData.PickslipKey) {
            let updatingLines = partOrderLines.filter(d => d.LineId && d.Vin !== vinValue);
            if (updatingLines && updatingLines.length > 0) {
                updatingLines.forEach(p => {
                    p.Vin = vinValue;
                });
                doAddUpdatePartLine(updatingLines);
            }
        }
    }

    const orderNumberBlur = (e) => {
        let orderNumberValue = e.target.value;
        if (partOrderData && partOrderData.PickslipKey) {
            let updatingLines = partOrderLines.filter(d => d.LineId && d.OrderNumber !== orderNumberValue);
            if (updatingLines && updatingLines.length > 0) {
                updatingLines.forEach(p => {
                    p.OrderNumber = orderNumberValue;
                });
                doAddUpdatePartLine(updatingLines);
            }
        }
    }

    const applyBackOrderMethodsForOrderType = (orderTypeCode) => {
        let orderType = orderTypes.find(o => o.Code == orderTypeCode)

        if (orderType && orderType.BackOrderMethods) {
            setBackOrderMethods(orderType.BackOrderMethods)
        } else {
            setBackOrderMethods([])
        }
    }

    const handleVisiblePickslipSearchChange = (value) => {
        setVisiblePickslipSearch(value)
    }

    const _getTableConfigs = () => {
        let configs = GetTableConfigs(columnsMapping, partOrderLines, partOrderLines.length, "Sequence", null, 1, "", false);
        configs["summary"] = () => (
            <Table.Summary.Row>
                <Table.Summary.Cell>
                    <Text className="table-summary-value">{t('part_order.total')}</Text>
                </Table.Summary.Cell>
                <Table.Summary.Cell></Table.Summary.Cell>
                <Table.Summary.Cell></Table.Summary.Cell>
                <Table.Summary.Cell></Table.Summary.Cell>
                <Table.Summary.Cell></Table.Summary.Cell>
                <Table.Summary.Cell>
                    <Text className="table-summary-value">{totalQtyOrder}</Text>
                </Table.Summary.Cell>
                <Table.Summary.Cell></Table.Summary.Cell>
                <Table.Summary.Cell className="text-center">
                    <Text className="table-summary-value text-center">{totalQtySupply}</Text>
                </Table.Summary.Cell>
                <Table.Summary.Cell className="text-center">
                    <Text className="table-summary-value text-center">{totalQtyBackOrder}</Text>
                </Table.Summary.Cell>
                <Table.Summary.Cell></Table.Summary.Cell>
                {(authData.dmsVersion >= Constant.DmsVersion.version_1_36_6_0)
                    && <Table.Summary.Cell></Table.Summary.Cell>}
                <Table.Summary.Cell></Table.Summary.Cell>
                <Table.Summary.Cell className="text-center">
                    <Text className="table-summary-value">{totalTotalPrice}</Text>
                </Table.Summary.Cell>
                <Table.Summary.Cell></Table.Summary.Cell>
                {(authData && !authData.isCombineQtyArrivedIntoQtyInTransit) && <Table.Summary.Cell></Table.Summary.Cell>}
            </Table.Summary.Row>
        );
        return configs;
    }

    return (
        <div className="part-order">
            <Breadcrumb>
                <Breadcrumb.Item>{t("menu.part_order")}</Breadcrumb.Item>
            </Breadcrumb>
            <Row className="m-b-15 fix-item-container" id={Constant.FixItemsContainerId}>
                <Col xs={{ span: 24 }} xxl={{ span: 12 }} className="overview">
                    {
                        isShowOrderSummaryLoading &&
                        <Spin indicator={<LoadingOutlined spin />}
                            className="loading-icon" />

                    }

                    <Row>
                        <Col xs={{ span: 2 }} xl={{ span: 3 }}>
                            <h5 className="semi-bold-text">{t("part_order.overview")}</h5>
                        </Col>
                        <Tooltip placement="bottom" title={t("common.view")}>
                            <Col xxl={{ span: 11 }} xl={{ span: 10 }} xs={{ span: 12 }}
                                className="flex-items align-space-between p-r-20 p-l-35 pending-status"
                                onClick={getPickslipForSummary}>

                                <div className="m-l-10">
                                    <h4>{partOrderSummaryData?.TotalPendingOrders || 0}</h4>
                                    <h6 className='pending-orders'>{t("part_order.total_pending_orders")}</h6>
                                </div>

                                <PendingIcon />
                            </Col>
                        </Tooltip>
                        <Col xxl={{ span: 10 }} xl={{ span: 8 }} xs={{ span: 10 }} className="flex-items align-space-between p-r-20 p-l-20">

                            <div>
                                <h4>{partOrderSummaryData?.TotalSubmittedOrders || 0}</h4>
                                <h6>{t("part_order.total_submit_orders")}</h6>

                            </div>
                            <SubmittedIcon />
                        </Col>
                    </Row>

                </Col>
                <Col xs={{ span: 24 }}>
                    <Form
                        {...layout}
                        form={form}
                        name="partOrderForm"
                    >
                        <Row align="middle">
                            <Col flex="auto" className="item-group">
                                <Row align="middle" gutter={[Constant.SpaceConstant.HorizontalGutter,]}>
                                    <Col xs={6} xxl={6}>
                                        <Form.Item
                                            label={t("part_order.pickslip_number")}
                                            name="PickslipNo" colon={false} labelAlign="left"
                                        >
                                            <SearchInput type={Constant.SearchModalType.PickslipNumber} handleSelectedItem={handleSelectedPickslip}
                                                onPressEnter={getPartOrder}
                                                ref={pickslipInputRef}
                                                visibleSearchModalChanged={handleVisiblePickslipSearchChange}
                                                disabled={partOrderData && partOrderData.PickslipKey ? true : false}></SearchInput>
                                        </Form.Item>
                                    </Col>
                                    <Col xs={6} xxl={6}>
                                        <Form.Item
                                            label={t("part_order.order_number")}
                                            name="OrderNumber" colon={false} labelAlign="left"
                                            rules={[{ required: true, message: t("part_order.order_number_required") }]}
                                        >
                                            <SearchInput type={Constant.SearchModalType.OrderNumber}
                                                handleSelectedItem={handleSelectedPickslip}
                                                ref={orderInputRef}
                                                onPressEnter={getPartOrderByOrderNo}
                                                disableSearch={partOrderData && partOrderData.PickslipKey ? true : false}
                                                uppercase={true}
                                                onBlur={orderNumberBlur}
                                                visibleSearchModalChanged={handleVisiblePickslipSearchChange}
                                                trimValue={true}></SearchInput>
                                        </Form.Item>
                                    </Col>
                                    <Col xs={6} xxl={6}>
                                        <Form.Item
                                            label={t("part_order.order_type")}
                                            name="OrderTypeCode" colon={false} labelAlign="left"
                                            rules={[{ required: true, message: t("part_order.order_type_required") }]}
                                        >
                                            <Select onSelect={onOrderTypeChange}>
                                                {
                                                    orderTypes.map((n, index) => <Option key={index} value={n.Code}>{n.Description}</Option>)
                                                }
                                            </Select>
                                        </Form.Item>
                                    </Col>
                                    <Col xs={6} xxl={6} hidden={CommonService.dmsVersionGreaterOrEqual(Constant.DmsVersion.version_1_36_9_0) ? false : true}>
                                        <Form.Item
                                            label={t("part_order.vin")}
                                            name="Vin" colon={false} labelAlign="left"
                                            rules={[
                                                { type: 'string', max: 17 },
                                            ]}>
                                            <Input size="large" onBlur={vinBlur} onChange={vinChange} value={vinValue} maxLength={17} allowClear />
                                        </Form.Item>
                                    </Col>
                                </Row>
                            </Col>
                            <Col flex={btnGroupWidth} className="text-right action-btn-group">
                                <div className="btn-m-t btn-m-b flex-items align-item-end" id={Constant.ButtonGroupId}>
                                    <Button size="large" onClick={reset}>{t("common.new")}</Button>
                                    <Button size="large" disabled={!partOrderData || !partOrderData.PickslipKey} className="m-l-10 secondary-btn included-icon orange-btn" onClick={cancelOrder} icon={<CancelIcon />}>{t("common.cancel")}</Button>
                                    <Button size="large" className="m-l-10 secondary-btn included-icon" onClick={importPart} icon={<UploadIcon />}>{t("common.upload")}</Button>
                                    <CSVReader
                                        onFileLoaded={handleImportPart}
                                        parserOptions={papaparseOptions}
                                        cssInputClass="import-part"
                                        inputId="import-part"
                                    />
                                    <Button size="large" disabled={!partOrderData || !partOrderData.PickslipKey} className="m-l-10" type="primary" onClick={submitOrder}>{t("common.submit")}</Button>
                                </div>
                            </Col>
                        </Row>
                    </Form>

                </Col>
            </Row>
            <Row gutter={[, Constant.SpaceConstant.VerticalGutter]}>
                <Col xs={{ span: 24 }}>
                    <Table
                        {..._getTableConfigs()}
                        components={components}
                        rowClassName={() => 'editable-row'}
                        expandable={{
                            expandedRowRender: record => <div className='p-l-50 note-container'>
                                {record.SupplierPackQty && record.SupplierPackQty > 1 && <div>
                                    <span className='title'>
                                        {t('part_enquiry.pack_quantity')}
                                    </span>
                                    <span className='color-dark-green bold-text'>{record.SupplierPackQty}</span>
                                    <span className='color-dark-green bold-text'>&nbsp;({record.PackDescription})</span>
                                </div>
                                }
                                {record.Notes && record.IncludeNoteForEcommerce && <div>
                                    <span className='title'>
                                        {t('part_enquiry.notes')}
                                    </span>
                                    <span className='color-blue bold-text'>{record.Notes}</span>
                                </div>
                                }

                            </div>,
                            rowExpandable: record => (record.Notes && record.IncludeNoteForEcommerce) || (record.SupplierPackQty && record.SupplierPackQty > 1),
                            expandedRowKeys: expKeys,
                            showExpandColumn: false
                        }}
                    />
                </Col>
            </Row>
            {
                visiblePartSearch && <PartSearch objectData={form.getFieldsValue()} makeList={makeList} handleCancel={handleCancel} partList={partSearchData}></PartSearch>
            }
            {
                visibleImportPartOrder && <ImportPartOrder data={importData} Pickslip={partOrderData} orderNumber={form.getFieldValue("OrderNumber")}
                    orderTypeCode={form.getFieldValue("OrderTypeCode")} handleCancel={handleCancelImport}></ImportPartOrder>
            }
            {
                visibleSubmitOrder && <DeliveryAddressSubmitted handleCancel={handleCancelSubmitOrder} />
                //<DeliveryAddressSubmitted  handleCancel={handleCancelSubmitOrder}/>
            }
            {
                enablePartEnquiry && visiblePartEnquiry &&
                <Modal
                    title={t("menu.part_enquiry")}
                    visible={true}
                    confirmLoading={false}
                    onCancel={handleCancelPartEnquiry}
                    maskClosable={false}
                    footer={null}
                    width={1300}
                >
                    <PartEnquiry stockKey={stockKeyEnquiry}></PartEnquiry>
                </Modal>
            }
        </div >
    )

}

export default PartOrder;