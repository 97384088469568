import React, { useState, useEffect } from 'react';
import './partSearch.scss'
import { Form, Input, Modal, Select, Col, Row, Button, Table, Checkbox } from 'antd';
import { useTranslation } from 'react-i18next';
import LoadingContext from './../../../components/utils/loading/loadingContext'
import { BuyerService, Constant, CommonService, StorageService } from '../../../services/services';
import { GetTableConfigs_SimplePaginator, ActionButtonsCell } from './../../../components/utils/gridUtils/gridUtils'
import { SelectOutlined } from '@ant-design/icons';
import _ from 'underscore'
import { useGlobalState } from '../../../utilities/globalState';

const { Option } = Select;
const layout = {
    labelCol: { span: 24 },
    wrapperCol: { span: 24 }
};



const PartSearch = (props) => {
    const { t } = useTranslation();
    const { showLoading, dismissLoading } = React.useContext(LoadingContext)
    const [form] = Form.useForm();
    const emptyTableData = {
        ObjectList: [],
        TotalItems: 0
    }
    const [partData, setPartData] = useState(emptyTableData)
    const [makeData, setMakeData] = useGlobalState('makeList')
    const [columnData, setColumnData] = useState([])
    const [gridConfigOptions, setGridConfigOptionsValue] = useState({
        pageNumber: 1,
        pageSize: Constant.PageSize,
        sort: ""
    })
    const [isSortFromApi, setSortFromApi] = useState(true)
    const [enablePartSearch, setEnablePartSearch] = useState(false);
    const [enableTyreSearchOption, setEnableTyreSearchOption] = useState(false);

    const closeForm = () => {
        props.handleCancel()
    }

    const onSubmit = (data) => {
        if (!data.TyreSearchOnly && !data.PartItemCode && !data.PartDescription) {
            CommonService.presentToast("warning", t("part_enquiry.part_search_required_mes"))
            return
        }
        if (!isSortFromApi)
            setSortFromApi(true)
        setGridConfigOptionsValue(
            {
                ...gridConfigOptions,
                pageNumber: 1
            })
        getSearchContainStocks(data.PartItemCode, data.MakeId, data.PartDescription, data.ShowQtyAvailableOnly, data.TyreSearchOnly, data.TyreWidth, data.TyreProfile, data.TyreDiameter, gridConfigOptions.pageNumber, gridConfigOptions.pageSize, gridConfigOptions.sort, true)
    };

    const getSearchContainStocks = (partItemCode, makeId, partDescription, showQtyAvailableOnly, tyreSearchOnly, tyreWidth, tyreProfile, tyreDiameter, pageNumber, pageSize, sort, isShowLoading = false) => {
        if (isShowLoading)
            showLoading()
        BuyerService.getSearchContainStocks(partItemCode, makeId, partDescription, showQtyAvailableOnly, tyreSearchOnly, tyreWidth, tyreProfile, tyreDiameter, pageNumber, pageSize, sort)
            .finally(() => {
                if (isShowLoading)
                    dismissLoading()
            })
            .then(result => {
                if (result.data) {
                    gridColumnConfig(result.data.ObjectList)

                    setPartData(result.data)
                }

            })
            .catch(error => CommonService.handleErrorResponse(error))

    }

    const sortFields = {
        PartItemCode: 'PartItemCode',
        Description: 'Description',
        Make: 'Make',
        AvailableQty: 'AvailableQty'
    }

    const onTableChange = (pagination, filters, sorter, extra) => {
        let data = form.getFieldsValue()
        let sortValue = CommonService.getSortString(sorter)
        if (isSortFromApi && partData.TotalItems > gridConfigOptions.pageSize) {
            getSearchContainStocks(data.PartItemCode, data.MakeId, data.PartDescription, data.ShowQtyAvailableOnly, data.TyreSearchOnly, data.TyreWidth, data.TyreProfile, data.TyreDiameter, pagination.current, pagination.pageSize, sortValue, true)
        }
        else {
            if (sortValue && partData.ObjectList.length > 0) {
                let sortDirection = sortValue.indexOf(Constant.SortDirection.Asc) >= 0 ? Constant.SortDirection.Asc : Constant.SortDirection.Desc
                let sortedList = [...partData.ObjectList]
                if (sortValue.indexOf(sortFields.PartItemCode) >= 0) {
                    sortedList = sortDirection == Constant.SortDirection.Asc ? _.sortBy(sortedList, item => item.PartItemCode.toLowerCase()) : _.sortBy(sortedList, item => item.PartItemCode.toLowerCase()).reverse()
                }
                else if (sortValue.indexOf(sortFields.Make) >= 0) {
                    sortedList = sortDirection == Constant.SortDirection.Asc ? _.sortBy(sortedList, item => item.Make) : _.sortBy(sortedList, item => item.Make).reverse()
                }
                else if (sortValue.indexOf(sortFields.Description) >= 0) {
                    sortedList = sortDirection == Constant.SortDirection.Asc ? _.sortBy(sortedList, item => item.Description.toLowerCase()) : _.sortBy(sortedList, item => item.Description.toLowerCase()).reverse()
                }
                else if (sortValue.indexOf(sortFields.AvailableQty) >= 0) {
                    sortedList = sortDirection == Constant.SortDirection.Asc ? _.sortBy(sortedList, item => item.AvailableQty) : _.sortBy(sortedList, item => item.AvailableQty).reverse()
                }
                setPartData({
                    ...partData,
                    ObjectList: sortedList
                })
            }
        }

        setGridConfigOptionsValue({
            pageNumber: pagination.current,
            pageSize: pagination.pageSize,
            sort: sortValue
        })
    }

    const resetFilter = () => {
        form.resetFields();
        setPartData(emptyTableData)
        setGridConfigOptionsValue(
            {
                ...gridConfigOptions,
                pageNumber: 1
            })
    }

    const selectedPart = (data) => {
        props.handleCancel(data)
    }

    let columns = [
        {
            title: t('common.action'),
            key: 'StockKey',
            render: (value, record) => (
                <ActionButtonsCell btnList={[getActionButtonInfo(record)]} data={record} handleAction={selectedPart} />
            ),
            align: 'center',
            width: 70
        },
        {
            title: t('part_enquiry.part_number'),
            dataIndex: 'PartItemCode',
            key: 'PartItemCode',
            sorter: true,
            ellipsis: true,
            render: (value, record) => (
                <span className="included-spaces">{record.PartItemCode}</span>
            ),
            width: 230
        },
        {
            title: t('part_enquiry.description'),
            dataIndex: 'Description',
            key: 'Description',
            sorter: true,
            ellipsis: true,
            width: 360
        },
        {
            title: t('part_enquiry.make'),
            dataIndex: 'Make',
            key: 'Make',
            sorter: true,
            ellipsis: true,
            width: 170
        },
        {
            title: <div className="text-center" dangerouslySetInnerHTML={{ __html: t('part_enquiry.quantity_available') }} ></div>,
            dataIndex: 'AvailableQty',
            key: 'AvailableQty',
            sorter: true,
            ellipsis: true,
            align: "center",
            width: 80,
        },
        {
            title: <div className="text-center" dangerouslySetInnerHTML={{ __html: t('part_enquiry.quantity_arrived') }} ></div>,
            dataIndex: 'ArrivedQty',
            key: 'ArrivedQty',
            sorter: false,
            ellipsis: true,
            align: "center",
            width: 75,
        },
        {
            title: <div className="text-center" dangerouslySetInnerHTML={{ __html: t('part_enquiry.quantity_in_transit') }} ></div>,
            dataIndex: 'InTransitQty',
            key: 'InTransitQty',
            sorter: false,
            ellipsis: true,
            align: "center",
            width: 80,
        }
    ]

    const getActionButtonInfo = (item) => {
        return {
            Description: t("common.select"),
            Icon: <SelectOutlined />,
            ColorClass: Constant.ColorClass.LightBlue,
            Hide: false
        }

    }

    const gridColumnConfig = (partList) => {
        let combineQtyArrivedList = partList.filter(x => x.IsCombineQtyArrivedIntoQtyInTransit)
        if (combineQtyArrivedList && combineQtyArrivedList.length > 0) {
            let newColumns = columns.filter(x => x.dataIndex != "ArrivedQty")
            setColumnData(newColumns)
        }
        else
            setColumnData(columns)
    }

    const onTyreSearchOnlyChange = (e) => {
        setEnableTyreSearchOption(e.target.checked);
        if (!e.target.checked) {
            form.setFieldsValue({
                TyreWidth: "",
                TyreProfile: "",
                TyreDiameter: ""
            })
        }
    };

    const onShowAvailableQtyChange = (e) => {
        StorageService.setShowAvailableQty(e.target.checked)
    }

    useEffect(() => {
        let authData = StorageService.getAuthData();
        setEnablePartSearch((authData
            && Array.isArray(authData.functionCodes)
            && (authData.functionCodes.filter(d => d === Constant.SupplierBuyerFunction.PartSearch).length > 0)
        ));
        if (authData && authData.isCombineQtyArrivedIntoQtyInTransit) {
            var arrivedQtyColumnIndex = columns.findIndex(p => p.key === "ArrivedQty");
            if (arrivedQtyColumnIndex >= 0) {
                //remove column arrived qty
                columns.splice(arrivedQtyColumnIndex, 1);
            }
        }

        document.documentElement.style.setProperty(Constant.CssVariables.ModalFixItemsContainerHeight, CommonService.calculateScrollMaxHeightModal())
        let timeoutFn = setTimeout(() => document.documentElement.style.setProperty(Constant.CssVariables.FixItemsContainerHeight, CommonService.calculateTableBodyMaxHeight("filterGridId")), 100);

        if (!makeData || makeData.length == 0) {
            BuyerService.getMakeList()
                .then(result => {
                    if (result.data) {
                        setMakeData(result.data)
                    }
                })
                .catch(error => CommonService.handleErrorResponse(error))
        }

        if (props.partList && props.partList.length > 0) {
            gridColumnConfig(props.partList)
            setSortFromApi(false)
            setPartData({
                ObjectList: props.partList,
                TotalItems: props.partList.length
            })


        }
        else {
            setColumnData(columns)
        }

        let showAvailableQty = StorageService.getShowAvailableQty();
        form.setFieldsValue({
            ShowQtyAvailableOnly: showAvailableQty
        })

        return () => {
            BuyerService.cancelRequest()
            clearTimeout(timeoutFn);
        }
    }, [])

    return (
        <div>
            <Modal
                title={t("part_enquiry.part_search")}
                visible={true}
                onOk={form.submit}
                confirmLoading={false}
                onCancel={closeForm}
                maskClosable={false}
                footer={null}
                width={1350}
            >
                <Form
                    {...layout}
                    form={form}
                    name="partSearchForm"
                    onFinish={onSubmit}
                    initialValues={props.objectData}
                >
                    {enablePartSearch && <Row align="middle" gutter={[Constant.SpaceConstant.HorizontalGutter, 0]} id="filterGridId">

                        <Col xs={{ span: 7 }}>
                            <Form.Item
                                label={t("part_enquiry.make")}
                                name="MakeId" colon={false} labelAlign="left"
                            >

                                <Select showSearch
                                    allowClear={true}
                                    optionFilterProp="children"
                                    filterOption={(input, option) =>
                                        option?.children.toUpperCase().indexOf(input.toUpperCase()) >= 0
                                    }>
                                    {
                                        makeData.map((n, index) => <Option key={index} value={n.MakeId}>{n.MakeId}</Option>)
                                    }
                                </Select>
                            </Form.Item>
                        </Col>
                        <Col xs={{ span: 6 }}>
                            <Form.Item
                                label={t("part_enquiry.part_number")}
                                name="PartItemCode" colon={false} labelAlign="left"
                            >
                                <Input allowClear className='uppercase-text' />
                            </Form.Item>
                        </Col>
                        <Col xs={{ span: 11 }}>
                            <Form.Item
                                label={t("part_enquiry.description")}
                                name="PartDescription" colon={false} labelAlign="left"
                            >
                                <Input allowClear />
                            </Form.Item>
                        </Col>

                        <Col xs={{ span: 4 }}>
                            <Form.Item colon={false} labelAlign="left" name="TyreSearchOnly" valuePropName="checked" className='m-b-0'>
                                <Checkbox onChange={onTyreSearchOnlyChange}>
                                    {t("part_enquiry.tyre_search_only")}
                                </Checkbox>
                            </Form.Item>
                        </Col>
                        <Col xs={{ span: 3 }}>
                            <Form.Item
                                label={t("part_enquiry.width")}
                                name="TyreWidth" colon={false} labelAlign="left"
                            >
                                <Input allowClear disabled={!enableTyreSearchOption} />
                            </Form.Item>
                        </Col>
                        <Col xs={{ span: 3 }}>
                            <Form.Item
                                label={t("part_enquiry.profile")}
                                name="TyreProfile" colon={false} labelAlign="left"
                            >
                                <Input allowClear disabled={!enableTyreSearchOption} />
                            </Form.Item>
                        </Col>
                        <Col xs={{ span: 3 }}>
                            <Form.Item
                                label={t("part_enquiry.diameter")}
                                name="TyreDiameter" colon={false} labelAlign="left"
                            >
                                <Input allowClear disabled={!enableTyreSearchOption} />
                            </Form.Item>
                        </Col>
                        <Col xs={{ span: 6 }} xl={{ span: 7 }} className="text-right p-r-0">
                            <Form.Item colon={false} labelAlign="left" name="ShowQtyAvailableOnly" valuePropName="checked" className='m-b-0'>
                                <Checkbox onChange={onShowAvailableQtyChange}>
                                    {t("part_enquiry.show_available_only")}
                                </Checkbox>
                            </Form.Item>
                        </Col>
                        <Col xs={{ span: 5 }} xl={{ span: 4 }} className="text-right">
                            <Button size="large" onClick={resetFilter}>{t("common.reset")}</Button>
                            <Button size="large" className="m-l-10 secondary-btn" htmlType="submit">{t("common.search")}</Button>
                        </Col>

                    </Row>}
                    <Row className="m-t-10" gutter={[, Constant.SpaceConstant.VerticalGutter]}>

                        <Col xs={{ span: 24 }}>
                            <Table
                                onRow={(record, rowIndex) => {
                                    return {
                                        onDoubleClick: event => selectedPart(record), // double click row
                                    };
                                }}
                                {...GetTableConfigs_SimplePaginator(columnData, partData.ObjectList, partData.TotalItems, "StockKey", onTableChange, gridConfigOptions.pageNumber)}
                            />
                        </Col>
                    </Row>
                </Form>
            </Modal>
        </div>
    )
}

export default PartSearch