import React, { useState, useEffect } from 'react';
import { Form, Input, Modal, Select, Col, Row, Button, Table, DatePicker, Tooltip } from 'antd';
import { useTranslation } from 'react-i18next';
import LoadingContext from './../../../components/utils/loading/loadingContext'
import { BuyerService, Constant, CommonService } from '../../../services/services';
import { GetTableConfigs_SimplePaginator, ActionButtonsCell, StatusCell } from './../../../components/utils/gridUtils/gridUtils'
import { SelectOutlined } from '@ant-design/icons';
import { useGlobalState } from '../../../utilities/globalState'
import NumericInput from './../../../components/numericInput/numericInput'

const { Option } = Select;
const layout = {
    labelCol: { span: 24 },
    wrapperCol: { span: 24 }
};



const PickslipSearch = (props) => {
    const { t } = useTranslation();
    const { showLoading, dismissLoading } = React.useContext(LoadingContext)
    const [form] = Form.useForm();
    const [totalItems, setTotalItems] = useState(0)
    const [pickslipData, setPickslipData] = useState([])
    const [orderTypes] = useGlobalState('orderTypes')
    const [gridConfigOptions, setGridConfigOptionsValue] = useState({
        pageNumber: 1,
        pageSize: Constant.PageSize,
        sort: ""
    })

    const closeForm = () => {
        props.handleCancel()
    }

    const onSubmit = (data) => {
        setGridConfigOptionsValue({
            pageNumber: 1,
            pageSize: Constant.PageSize,
            sort: ""
        })
        setTotalItems(0)
        getPickslips(data, 1, gridConfigOptions.pageSize, gridConfigOptions.sort, true)
    };

    const getPickslips = (query, pageNumber, pageSize, sort, isShowLoading = false) => {
        if (isShowLoading)
            showLoading()
        if (query.FromPickslipDate) {
            let dateStr = `${query.FromPickslipDate.year()}-${query.FromPickslipDate.month() + 1}-${query.FromPickslipDate.date()}`
            query.FromPickslipDate = dateStr
        }
        if (query.ToPickslipDate) {
            let dateStr = `${query.ToPickslipDate.year()}-${query.ToPickslipDate.month() + 1}-${query.ToPickslipDate.date()}`
            query.ToPickslipDate = dateStr
        }

        BuyerService.getPickslips(query, pageNumber, pageSize, sort, props.getAllData ? true : false)
            .finally(() => {
                if (isShowLoading)
                    dismissLoading()
            })
            .then(result => {
                if (result.data && result.data.ObjectList) {
                    let resultList = result.data.ObjectList.map(p => {
                        p.Key = result.data.ObjectList.indexOf(p)
                        return p
                    })
                    setPickslipData(resultList)
                    setTotalItems(result.data.TotalItems)
                }
            })
            .catch(error => CommonService.handleErrorResponse(error))

    }

    const onTableChange = (pagination, filters, sorter, extra) => {
        let data = form.getFieldsValue()

        getPickslips(data, pagination.current, pagination.pageSize, CommonService.getSortString(sorter))
        setGridConfigOptionsValue({
            pageNumber: pagination.current,
            pageSize: pagination.pageSize,
            sort: CommonService.getSortString(sorter)
        })
    }

    const resetFilter = () => {
        form.resetFields();
        setPickslipData([])
        setGridConfigOptionsValue({
            pageNumber: 1,
            pageSize: Constant.PageSize,
            sort: ""
        })
        setTotalItems(0)
    }

    const selectedPickslip = (data) => {
        props.handleCancel(data)
    }

    const columns = [
        {
            title: t('common.action'),
            key: 'PickslipNo',
            render: (value, record) => (
                <ActionButtonsCell btnList={[getActionButtonInfo(record)]} data={record} handleAction={selectedPickslip} />
            ),
            align: 'center',
            width: 60
        },
        {
            title: t('part_order.pickslip_number'),
            dataIndex: 'PickslipNo',
            key: 'PickslipNo',
            sorter: true,
            ellipsis: true,
            width: 100
        },
        {
            title: t('part_order.pickslip_date'),
            dataIndex: 'PickslipDate',
            key: 'PickslipDate',
            sorter: true,
            ellipsis: true,
            render: (value, record) => (
                <label>{CommonService.getDateString(record.PickslipDate)}</label>
            ),
            width: 100
        },
        {
            title: t('part_enquiry.part_number'),
            dataIndex: 'PartNumber',
            key: 'PartNumber',
            sorter: true,
            ellipsis: true,
            width: 100
        },
        {
            title: t('part_enquiry.description'),
            dataIndex: 'PartDescription',
            key: 'PartDescription',
            sorter: true,
            ellipsis: true,
            width: 150
        },
        {
            title: t('part_order.qty'),
            dataIndex: 'OrderedQty',
            key: 'OrderedQty',
            sorter: true,
            ellipsis: true,
            width: 70
        },
        {
            title: t('part_order.bo'),
            dataIndex: 'BackOrderMethod',
            key: 'BackOrderMethod',
            sorter: true,
            ellipsis: true,
            width: 70,
            render: (value, record) => {
                return <Tooltip placement="bottom" title={record.BackOrderMethodDescription}>
                    <span>{record.BackOrderMethod}</span>
                </Tooltip>
            }
        },
        {
            title: t('part_order.order_no'),
            dataIndex: 'OrderNo',
            key: 'OrderNo',
            sorter: true,
            ellipsis: true,
            width: 120,
        },
        {
            title: t('part_order.order_type'),
            dataIndex: 'OrderTypeCode',
            key: 'OrderTypeCode',
            sorter: true,
            ellipsis: true,
            width: 120,
            render: (value, record) => (
                orderTypes && orderTypes.length > 0 && orderTypes.find(t => t.Code == record.OrderTypeCode)
                    ? orderTypes.find(t => t.Code == record.OrderTypeCode).Description : record.OrderTypeCode
            )
        },
        {
            title: t('common.status'),
            dataIndex: 'EcommerceCreatingStatusCode',
            key: 'EcommerceCreatingStatusCode',
            sorter: true,
            render: (value, record) => (
                <StatusCell data={record}
                    colorClass={CommonService.getEcommerceCreatingStatusColorClass(record.EcommerceCreatingStatusCode)}
                    description={CommonService.getEcommerceCreatingStatusDescription(record.EcommerceCreatingStatusCode)} />
            ),
            align: 'center',
            width: 100,
        }
    ]

    const getActionButtonInfo = (item) => {
        return {
            Description: t("common.select"),
            Icon: <SelectOutlined />,
            ColorClass: Constant.ColorClass.LightBlue,
            Hide: false
        }

    }


    useEffect(() => {
        document.documentElement.style.setProperty(Constant.CssVariables.ModalFixItemsContainerHeight, CommonService.calculateScrollMaxHeightModal())
        let timeoutFn = setTimeout(() => document.documentElement.style.setProperty(Constant.CssVariables.FixItemsContainerHeight, CommonService.calculateTableBodyMaxHeight("filterGridId")), 100);

        if (props.pickslipList && props.pickslipList.length > 0) {
            setPickslipData(props.pickslipList)
            setTotalItems(props.pickslipList.length)
        }

        if (props.queryData.query) {
            form.setFieldsValue(props.queryData.query)
        }

        if (props.queryData.data) {
            setPickslipData(props.queryData.data.ObjectList)
            setTotalItems(props.queryData.data.TotalItems)
        }
        return () => {
            setPickslipData([])
            setTotalItems(0)
            BuyerService.cancelRequest()
            clearTimeout(timeoutFn);
        }
    }, [])

    return (
        <div>
            <Modal
                title={t("part_order.pickslip_search")}
                visible={true}
                onOk={form.submit}
                confirmLoading={false}
                onCancel={closeForm}
                maskClosable={false}
                footer={null}
                width={1200}
            >
                <Form
                    {...layout}
                    form={form}
                    name="pickslipSearchForm"
                    onFinish={onSubmit}
                    initialValues={props.objectData}
                >
                    <Row align="middle" gutter={[Constant.SpaceConstant.HorizontalGutter]} id="filterGridId">
                        <Col xs={{ span: 8 }}>
                            <Form.Item
                                label={t("part_order.pickslip_number")}
                                name="PickslipNo" colon={false} labelAlign="left"
                            >
                                <NumericInput allowClear={true} />
                            </Form.Item>
                        </Col>
                        <Col xs={{ span: 8 }}>
                            <Form.Item
                                label={t("part_enquiry.part_number")}
                                name="PartNumber" colon={false} labelAlign="left"
                            >
                                <Input allowClear />
                            </Form.Item>
                        </Col>
                        <Col xs={{ span: 8 }}>
                            <Form.Item
                                label={t("part_enquiry.description")}
                                name="PartDescription" colon={false} labelAlign="left"
                            >
                                <Input allowClear />
                            </Form.Item>
                        </Col>
                        <Col xs={{ span: 8 }}>
                            <Form.Item
                                label={t("part_order.from_date")}
                                name="FromPickslipDate" colon={false} labelAlign="left"
                            >
                                <DatePicker format={Constant.DateFormat} />
                            </Form.Item>
                        </Col>
                        <Col xs={{ span: 8 }}>
                            <Form.Item
                                label={t("part_order.to_date")}
                                name="ToPickslipDate" colon={false} labelAlign="left"
                            >
                                <DatePicker format={Constant.DateFormat} />
                            </Form.Item>
                        </Col>


                        <Col xs={{ span: 8 }} className="text-right m-t-10">
                            <Button size="large" onClick={resetFilter}>{t("common.reset")}</Button>
                            <Button size="large" className="m-l-10 secondary-btn" htmlType="submit">{t("common.search")}</Button>
                        </Col>

                    </Row>
                    <Row className="m-t-10" gutter={[, Constant.SpaceConstant.VerticalGutter]}>

                        <Col xs={{ span: 24 }}>
                            <Table
                                onRow={(record, rowIndex) => {
                                    return {
                                        onDoubleClick: event => selectedPickslip(record), // double click row
                                    };
                                }}
                                {...GetTableConfigs_SimplePaginator(columns, pickslipData, totalItems, "Key", onTableChange, gridConfigOptions.pageNumber)}
                            />
                        </Col>
                    </Row>
                </Form>
            </Modal>
        </div>
    )
}

export default PickslipSearch