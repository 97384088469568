import React from 'react';
import { ReactComponent as BackOrderManagement } from './../../assets/icons/back-order-management.svg';
import { ReactComponent as Calendar } from './../../assets/icons/calendar.svg';
import { ReactComponent as Cancel } from './../../assets/icons/cancel.svg';
import { ReactComponent as CancelPartOrder } from './../../assets/icons/cancel-part-order.svg';
import { ReactComponent as CancelPartOrderApproval } from './../../assets/icons/cancel-part-order-approval.svg';
import { ReactComponent as Check } from './../../assets/icons/check.svg';
import { ReactComponent as CreditReturn } from './../../assets/icons/credit-return.svg';
import { ReactComponent as CreditReturnApproval } from './../../assets/icons/credit-return-approval.svg';
import { ReactComponent as Edit } from './../../assets/icons/edit.svg';
import { ReactComponent as View } from './../../assets/icons/eye.svg';
import { ReactComponent as Lock } from './../../assets/icons/lock.svg';
import { ReactComponent as LockFill } from './../../assets/icons/lock-fill.svg';
import { ReactComponent as LogOut } from './../../assets/icons/log-out.svg';
import { ReactComponent as Mail } from './../../assets/icons/mail.svg';
import { ReactComponent as Location } from './../../assets/icons/map-pin.svg';
import { ReactComponent as MenuBar } from './../../assets/icons/menu-bar.svg';
import { ReactComponent as OrderHistory } from './../../assets/icons/order-history.svg';
import { ReactComponent as OrderManagement } from './../../assets/icons/order-management.svg';
import { ReactComponent as OutstandingCreditNotes } from './../../assets/icons/outstanding-credit-notes.svg';
import { ReactComponent as PartEnquiry } from './../../assets/icons/part-enquiry.svg';
import { ReactComponent as PartOrder } from './../../assets/icons/part-order.svg';
import { ReactComponent as Phone } from './../../assets/icons/phone.svg';
import { ReactComponent as Plus } from './../../assets/icons/plus.svg';
import { ReactComponent as Search } from './../../assets/icons/search.svg';
import { ReactComponent as Setting } from './../../assets/icons/setting.svg';
import { ReactComponent as Supplier } from './../../assets/icons/supplier.svg';
import { ReactComponent as SupplierFill } from './../../assets/icons/supplier-fill.svg';
import { ReactComponent as Trash } from './../../assets/icons/trash.svg';
import { ReactComponent as UserFill } from './../../assets/icons/user-fill.svg';
import { ReactComponent as UserManagement } from './../../assets/icons/user-management.svg';
import { ReactComponent as Users } from './../../assets/icons/users.svg';
import { ReactComponent as Home } from './../../assets/icons/home.svg';
import { ReactComponent as User } from './../../assets/icons/user.svg';
import { ReactComponent as Download } from './../../assets/icons/download.svg';
import { ReactComponent as FileText } from './../../assets/icons/file-text.svg';
import { ReactComponent as Part } from './../../assets/icons/part.svg';
import { ReactComponent as Upload } from './../../assets/icons/upload.svg';
import { ReactComponent as ToggleLeft } from './../../assets/icons/toggle-left.svg';
import { ReactComponent as ToggleRight } from './../../assets/icons/toggle-right.svg';
import { ReactComponent as Bell } from './../../assets/icons/bell.svg';
import { ReactComponent as CornerUpRight } from './../../assets/icons/corner-up-right.svg';
import { ReactComponent as ChevronRight } from './../../assets/icons/chevron-right.svg';
import { ReactComponent as Vector } from './../../assets/icons/vector.svg';
import { ReactComponent as Submitted } from './../../assets/icons/submitted.svg';
import { ReactComponent as Pending } from './../../assets/icons/pending.svg';
import { ReactComponent as Copy } from './../../assets/icons/copy.svg';
import { ReactComponent as ChevronLeft } from './../../assets/icons/chevron-left.svg';
import { ReactComponent as PowerBy } from './../../assets/icons/power-by.svg';
import { ReactComponent as AvailableFrom } from './../../assets/icons/available-from.svg';

export const BackOrderManagementIcon = () => {
    return (
        <BackOrderManagement />
    );
}

export const CalendarIcon = () => {
    return (
        <Calendar />
    );
}

export const CancelIcon = () => {
    return (
        <Cancel />
    );
}

export const CancelPartOrderIcon = () => {
    return (
        <CancelPartOrder />
    );
}

export const CancelPartOrderApprovalIcon = () => {
    return (
        <CancelPartOrderApproval />
    );
}

export const CheckIcon = () => {
    return (
        <Check />
    );
}

export const CreditReturnIcon = () => {
    return (
        <CreditReturn />
    );
}

export const CreditReturnApprovalIcon = () => {
    return (
        <CreditReturnApproval />
    );
}

export const EditIcon = () => {
    return (
        <Edit />
    );
}

export const ViewIcon = () => {
    return (
        <View />
    );
}

export const LockIcon = () => {
    return (
        <Lock />
    );
}

export const LockFillIcon = () => {
    return (
        <LockFill />
    );
}

export const LogOutIcon = () => {
    return (
        <LogOut />
    );
}

export const MailIcon = () => {
    return (
        <Mail />
    );
}

export const LocationIcon = () => {
    return (
        <Location />
    );
}

export const MenuBarIcon = () => {
    return (
        <MenuBar />
    );
}

export const OrderHistoryIcon = () => {
    return (
        <OrderHistory />
    );
}

export const OrderManagementIcon = () => {
    return (
        <OrderManagement />
    );
}

export const OutstandingCreditNotesIcon = () => {
    return (
        <OutstandingCreditNotes />
    );
}

export const PartEnquiryIcon = () => {
    return (
        <PartEnquiry />
    );
}

export const PartOrderIcon = () => {
    return (
        <PartOrder />
    );
}

export const PhoneIcon = () => {
    return (
        <Phone />
    );
}

export const PlusIcon = () => {
    return (
        <Plus />
    );
}

export const SearchIcon = () => {
    return (
        <Search />
    );
}

export const SettingIcon = () => {
    return (
        <Setting />
    );
}

export const SupplierIcon = () => {
    return (
        <Supplier />
    );
}

export const SupplierFillIcon = () => {
    return (
        <SupplierFill />
    );
}

export const TrashIcon = () => {
    return (
        <Trash />
    );
}

export const UserFillIcon = () => {
    return (
        <UserFill />
    );
}

export const UserManagementIcon = () => {
    return (
        <UserManagement />
    );
}

export const UsersIcon = () => {
    return (
        <Users />
    );
}

export const HomeIcon = () => {
    return (
        <Home />
    );
}

export const UserIcon = () => {
    return (
        <User />
    );
}

export const DownloadIcon = () => {
    return (
        <Download />
    );
}

export const FileTextIcon = () => {
    return (
        <FileText />
    );
}

export const PartIcon = () => {
    return (
        <Part />
    );
}

export const UploadIcon = () => {
    return (
        <Upload />
    );
}

export const ToggleLeftIcon = () => {
    return (
        <ToggleLeft />
    );
}

export const ToggleRightIcon = () => {
    return (
        <ToggleRight />
    );
}

export const BellIcon = () => {
    return (
        <Bell />
    );
}

export const CornerUpRightIcon = () => {
    return (
        <CornerUpRight />
    );
}

export const ChevronRightIcon = () => {
    return (
        <ChevronRight />
    );
}

export const VectorIcon = () => {
    return (
        <Vector />
    );
}

export const SubmittedIcon = () => {
    return (
        <Submitted />
    );
}

export const PendingIcon = () => {
    return (
        <Pending />
    );
}

export const CopyIcon = () => {
    return (
        <Copy />
    );
}

export const ChevronLeftIcon = () => {
    return (
        <ChevronLeft />
    );
}

export const PowerByIcon = () => {
    return (
        <PowerBy />
    );
}

export const AvailableFromIcon = () => {
    return (
        <AvailableFrom />
    );
}