import React from "react";
import { useGlobalState } from './globalState'
import { Route, Switch, Redirect } from "react-router-dom";
import NotFound from "../pages/notFound/notFound";
import { AuthService, Constant } from "../services/services"
import BuyerDashboard from "../pages/buyer/buyerDashboard/buyerDashboard";
import BackOrderManagement from "../pages/buyer/backOrderManagement/backOrderManagement";
import LandingPage from "../pages/landingPage/landingPage";
import SupplierDashboard from "../pages/supplier/supplierDashboard/supplierDashboard";
import MainLayout from "../layouts/mainLayout/mainLayout";
import Sample from "../pages/sample/sample";
import SupplierSetting from "../pages/supplier/supplierSetting/supplierSetting";

import SupplierManagement from "./../pages/buyer/supplierManagement/supplierManagement";
import BuyerManagement from "../pages/supplier/buyerManagement/buyerManagement";
import BuyerManagement_EditBuyer from "../pages/supplier/buyerManagement/editBuyer";
import PartOrderHistory from "../pages/buyer/partOrderHistory/partOrderHistory";
import PartOrderHistoryDetails from "../pages/buyer/partOrderHistory/partOrderHistoryDetails";
import PartEnquiry from "../pages/buyer/partEnquiry/partEnquiry";
import PartOrder from "./../pages/buyer/partOrder/partOrder"
import OrderManagement from "../pages/buyer/orderManagement/orderManagement";
import PickslipDetails from "../pages/buyer/orderManagement/pickslipDetails";
import OutstandingCreditNote from "./../pages/buyer/outstandingCreditNote/outstandingCreditNote"
import OutstandingCreditNote_Details from "./../pages/buyer/outstandingCreditNote/outstandingCreditNoteDetails"
import RequestCreditReturn from "../pages/buyer/requestCreditReturn/requestCreditReturn";
import EditRequestCreditReturn from "./../pages/buyer/requestCreditReturn/editRequestCreditReturn"

export default function Routes() {
    const [isActive] = useGlobalState('isActive')

    function appRoute(pathName, Component, LayoutComponent, needAuthentication, isSupplier) {
        if (!needAuthentication) {
            return (
                <Route exact path={pathName} component={Component} />
            )
        } else {  
            return (
                <Route exact path={pathName} render={(props => AuthService.hasAuthorized(isActive) && AuthService.isSupplier() == isSupplier
                    ? <LayoutComponent isSupplier={isSupplier} {...props}><Component {...props} /></LayoutComponent>
                    : <Redirect to={{ pathname: Constant.Pages.Login, state: { from: props.location } }}
                    />)} />                
            )
        }
    }

    return (
        <Switch>
            {/* <Redirect from="/" exact={true} to={AuthService.isSupplier() ? Constant.Pages.SupplierDashboard : Constant.Pages.BuyerDashboard} /> */}

            {appRoute(Constant.Pages.Landing, LandingPage, false)}

            {appRoute(Constant.Pages.BuyerDashboard, BuyerDashboard, MainLayout, true, false)}
            {appRoute(Constant.Pages.SupplierManagement, SupplierManagement, MainLayout, true, false)}
            {appRoute(Constant.Pages.PartEnquiry, PartEnquiry, MainLayout, true, false)}
            {appRoute(Constant.Pages.PartOrder, PartOrder, MainLayout, true, false)}
            {appRoute(Constant.Pages.RequestCancelPartOrder, BuyerDashboard, MainLayout, true, false)}
            {appRoute(Constant.Pages.OrderManagement, OrderManagement, MainLayout, true, false)}
            {appRoute(Constant.Pages.OrderManagement_PickslipDetails, PickslipDetails, MainLayout, true, false)}
            {appRoute(Constant.Pages.PartOrderHistory, PartOrderHistory, MainLayout, true, false)}
            {appRoute(Constant.Pages.PartOrderHistory_Details, PartOrderHistoryDetails, MainLayout, true, false)}
            {appRoute(Constant.Pages.RequestCreditReturn, RequestCreditReturn, MainLayout, true, false)}
            {appRoute(Constant.Pages.RequestCreditReturn_Edit, EditRequestCreditReturn, MainLayout, true, false)}
            {appRoute(Constant.Pages.BackOrderManagement, BackOrderManagement, MainLayout, true, false)}
            {appRoute(Constant.Pages.OutstandingCreditNotesEnquiry, OutstandingCreditNote, MainLayout, true, false)}
            {appRoute(Constant.Pages.OutstandingCreditNotesEnquiry_Details, OutstandingCreditNote_Details, MainLayout, true, false)}

            {appRoute(Constant.Pages.SupplierDashboard, SupplierDashboard, MainLayout, true, true)}
            {appRoute(Constant.Pages.BuyerManagement, BuyerManagement, MainLayout, true, true)}
            {appRoute(Constant.Pages.BuyerManagement_EditBuyer, BuyerManagement_EditBuyer, MainLayout, true, true)}
            {appRoute(Constant.Pages.CancelPartOrderApproval, SupplierDashboard, MainLayout, true, true)}
            {appRoute(Constant.Pages.SupplierSetting, SupplierSetting, MainLayout, true, true)}

            {appRoute(Constant.Pages.Sample, Sample, MainLayout)}
            <Route>
                <NotFound />
            </Route>
        </Switch>
    );
}