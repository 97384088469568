import React, { useEffect, useState } from 'react';
import { Form, Input, Modal, Switch, Col, Row, Avatar, Upload, Button, message, Checkbox } from 'antd';
import { useTranslation } from 'react-i18next';
import LoadingContext from './../../components/utils/loading/loadingContext'
import { CommonService, Constant, AuthService, StorageService } from './../../services/services'
import { TrashIcon } from './../icons/icons'
import { DeleteOutlined, PlusOutlined } from '@ant-design/icons';
import AddImage from './../../assets/icons/add-image.svg'

const layout = {
    labelCol: { span: 24 },
    wrapperCol: { span: 24 }
};

const Profile = (props) => {
    const { t } = useTranslation();
    const { showLoading, dismissLoading } = React.useContext(LoadingContext)
    const [form] = Form.useForm();
    const [isChangePassword, setChangePassword] = useState(false)
    const [imageUrl, setImageUrl] = useState()
    const [imageBase64, setImageBase64] = useState()
    const [loading, setLoading] = useState(false)

    const onSubmit = (data) => {
        data.AvatarBase64 = imageBase64
        data.AvatarUrl = imageUrl
        let authData = StorageService.getAuthData()
        if (authData) {
            data.AccountTypeCode = Number(authData.accountType)
            data.UserName = authData.name
            data.Id = authData.userId
            showLoading()
            AuthService.updateUser(data)
                .finally(() => dismissLoading())
                .then(result => {
                    if (!result.data) {
                        CommonService.presentToast('success', t('register.profile_update_successfull'))
                        authData.tradingName = data.TradingName
                        authData.contactName = data.ContactName
                        authData.phoneNumber = data.PhoneNumber
                        authData.email = data.Email
                        authData.avatarUrl = data.AvatarUrl
                        authData.avatarBase64 = imageBase64
                        if(!CommonService.isSupplierAccount()){
                            authData.isShowPriceTaxIncluded = data.IsShowPriceTaxIncluded
                        }
                        StorageService.setAuthData(authData)
                        closeForm()
                    }

                })
                .catch(error => CommonService.handleErrorResponse(error))
        }
    };

    const closeForm = () => {
        props.handleCancel()
    }

    const onChangePasswordSwitch = (value) => {
        setChangePassword(value)
    }

    const getBase64 = (img, callback) => {
        const reader = new FileReader();
        reader.addEventListener('load', () => callback(reader.result));
        reader.readAsDataURL(img);
    }

    const beforeUpload = (file) => {

        const isJpgOrPng = file.type === 'image/jpeg' || file.type === 'image/png' || file.type === 'image/gif';
        if (!isJpgOrPng) {
            CommonService.presentToast('warning', t("landing_page.upload_image_message"))
            return false
        }
        const isLimit = file.size / 1024 / 1024 < 5;
        if (!isLimit) {
            CommonService.presentToast('warning', t("landing_page.limit_file_message"))
            return false
        }
        getBase64(file, imageUrl => {
            setImageBase64(imageUrl)
        });

        //return false here to prevent default uploading request
        return false
    }

    const reloadImage = (e, item) => {
        if (item.Url) {
            if (!item.Count)
                item.Count = 1
            else {
                item.Count++
            }

            if (item.Count <= 3) {
                console.log("Image loaded fail")
                e.target.onerror = null;
                e.target.src = item.Url
            }
        }
    }

    const removeAvatar = () => {
        setImageBase64("")
        setImageUrl("")
    }

    useEffect(() => {
        if (props.objectData.AvatarUrl) {
            setImageUrl(props.objectData.AvatarUrl)
        }
        if (props.objectData.AvatarBase64) {
            setImageBase64(props.objectData.AvatarBase64)
        }
    }, [])

    return (
        <div>
            <Modal
                title=""
                visible={true}
                onOk={form.submit}
                confirmLoading={false}
                onCancel={closeForm}
                okText={t("common.save")}
                cancelText={t("common.cancel")}
                maskClosable={false}
                width={700}
            >
                <Form
                    {...layout}
                    form={form}
                    name="profileForm"
                    onFinish={onSubmit}
                    initialValues={props.objectData}
                >
                    <Row className="m-b-20 m-t-20">
                        <Col xs={{ span: 24 }} className="text-center avatar-profile">
                            <div className="avatar-contain">
                                <Upload
                                    className="avatar-uploader"
                                    listType="picture-card"
                                    showUploadList={false}
                                    beforeUpload={beforeUpload}
                                >
                                    {
                                        !imageBase64 && imageUrl && <img src={imageUrl} alt="avatar" onError={e => reloadImage(e, { Url: imageUrl })} />
                                    }

                                    {imageBase64 && <img src={imageBase64} alt="avatar" />}
                                    {!imageBase64 && !imageUrl && <img src={AddImage} className="no-image" />}
                                </Upload>
                                {(imageUrl || imageBase64) &&
                                    <span className="remove-avatar">
                                        <Button type="primary" shape="circle" icon={<DeleteOutlined />} onClick={removeAvatar} size="middle" />
                                    </span>
                                }
                            </div>
                            <h4 className="m-t-15">{CommonService.getUsernameLogin()}</h4>
                        </Col>
                    </Row>
                    <Row align="middle" gutter={[Constant.SpaceConstant.HorizontalGutter, 5]}>
                        <Col xs={{ span: 12 }}>
                            <Form.Item
                                label={t("register.trading_name")}
                                name="TradingName" colon={false} labelAlign="left"
                                rules={[{ required: true, message: t("register.trading_name_required") }]}
                            >
                                <Input allowClear />
                            </Form.Item>
                        </Col>
                        <Col xs={{ span: 12 }}>
                            <Form.Item
                                label={t("register.contact_name")}
                                name="ContactName" colon={false} labelAlign="left"
                                rules={[{ required: true, message: t("register.trading_name_required") }]}
                            >
                                <Input allowClear />
                            </Form.Item>
                        </Col>
                        <Col xs={{ span: 12 }} >
                            <Form.Item
                                label={t("register.email")}
                                name="Email" colon={false} labelAlign="left"
                                rules={[{ required: true, message: t("register.email_required") },
                                { type: "email", message: t("common.email_invalid") }]}
                            >
                                <Input allowClear />
                            </Form.Item>
                        </Col>
                        <Col xs={{ span: 12 }}>
                            <Form.Item
                                label={t("register.phone_no")}
                                name="PhoneNumber" colon={false} labelAlign="left"
                                rules={[{ required: true, message: t("register.phone_no_required") }]}
                            >
                                <Input allowClear />
                            </Form.Item>
                        </Col>
                        {
                            !CommonService.isSupplierAccount() &&
                            <Col xs={{ span: 12 }} className="m-t-10">
                                <Form.Item colon={false} labelAlign="left" name="IsShowPriceTaxIncluded" valuePropName="checked">
                                    <Checkbox>
                                        {t("register.show_price_tax_included")}
                                    </Checkbox>
                                </Form.Item>
                            </Col>
                        }
                    </Row>
                    <Row align="middle"
                        gutter={[Constant.SpaceConstant.HorizontalGutter]}>
                        <Col xs={{ span: 12 }}>
                            <Form.Item labelCol={{ xs: { span: 16 } }} wrapperCol={{ xs: { span: 8 } }}
                                label={t("register.change_password")}
                                name="IsChangePassword" colon={false} labelAlign="left"
                                valuePropName="checked"
                                className="switch-item"
                            >
                                <Switch onChange={value => onChangePasswordSwitch(value)} />
                            </Form.Item>
                        </Col>
                    </Row>
                    {
                        isChangePassword &&
                        <Row gutter={[Constant.SpaceConstant.HorizontalGutter]}>
                            <Col xs={{ span: 8 }}>
                                <Form.Item
                                    label={t("register.current_password")}
                                    name="OldPassword" colon={false} labelAlign="left"
                                    rules={[{ required: true, message: t("register.current_password_required") }]}
                                >
                                    <Input.Password maxLength={Constant.LimitLength.Length256} allowClear />
                                </Form.Item>
                            </Col>
                            <Col xs={{ span: 8 }}>
                                <Form.Item
                                    label={t("register.password")}
                                    name="Password" colon={false} labelAlign="left"
                                    rules={[{ required: true, message: t("register.password_required") }]}
                                >
                                    <Input.Password maxLength={Constant.LimitLength.Length256} allowClear />
                                </Form.Item>
                            </Col>
                            <Col xs={{ span: 8 }}>
                                <Form.Item
                                    label={t("register.confirm_password")}
                                    name="ConfirmPassword" colon={false} labelAlign="left"
                                    rules={[{ required: true, message: t("register.confirm_password_required") },
                                    ({ getFieldValue }) => ({
                                        validator(rule, value) {
                                            if (value) {
                                                let password = getFieldValue('Password')
                                                if (value != password) {
                                                    return Promise.reject(t("register.confirm_password_mismatch"));
                                                }
                                            }
                                            return Promise.resolve();
                                        },
                                    })]}
                                >
                                    <Input.Password maxLength={Constant.LimitLength.Length256} allowClear />
                                </Form.Item>
                            </Col>
                        </Row>
                    }
                </Form>
            </Modal>
        </div>
    )
}

export default Profile