import React, { useState, useEffect, useContext } from 'react';
import './outstandingCreditNoteDetails.scss';
import { Row, Col, Table, Breadcrumb, Tabs, Typography, Modal, Select, Button, Collapse, InputNumber, Tooltip } from 'antd';
import { useTranslation } from 'react-i18next';
import { GetTableConfigs, DateTimeCell, TextAndButtonCell, ActionButtonsCell, StatusCell } from '../../../components/utils/gridUtils/gridUtils';
import { Constant, CommonService, OutstandingCreditNoteService, StorageService, BuyerService, MediaService } from '../../../services/services';
import LoadingContext from './../../../components/utils/loading/loadingContext';
import { Redirect, useLocation, useHistory } from "react-router-dom";
import { useGlobalState } from './../../../utilities/globalState';
import { ViewIcon, FileTextIcon, ChevronLeftIcon, DownloadIcon } from './../../../components/icons/icons'
import { TrashIcon } from './../../../components/icons/icons'

const { Text } = Typography;
const { TabPane } = Tabs;
const { Panel } = Collapse;
const { Option } = Select;

export default function OutstandingCreditNote_Details(props) {
    const { t } = useTranslation();
    const location = useLocation();
    const history = useHistory();
    const [creditInfo, setCreditInfo] = useState({});
    const [listOfPart, setListOfPart] = useState([]);
    const [listOfSundry, setListOfSundry] = useState([]);
    const [auditData, setAuditData] = useState([]);
    const [visibleDownloadDocumentModal, setVisibleDownloadDocumentModal] = useState(false);

    let taxIncluded = true;
    let authData = StorageService.getAuthData();
    if (authData) {
        taxIncluded = authData.isShowPriceTaxIncluded;
    }

    const { showLoading, dismissLoading } = useContext(LoadingContext);
    const [visibleAudit, setVisibleAudit] = useState(false);
    const [creditNoteReasonCodes, setCreditNoteReasonCodes] = useGlobalState('creditNoteReasonCodes');
    const [debtorPartSundryCharges, setDebtorPartSundryCharges] = useState([]);

    const [partSundryInvoiceSearchItems, setPartSundryInvoiceSearchItems] = useState([]);
    const [totalQtyRequest, setTotalQtyRequest] = useState(0);
    const [totalRequestAmount, setTotalRequestAmount] = useState(0);

    const [partChanged, setPartChanged] = useState(false);
    const [sundryChanged, setSundryChanged] = useState(false);

    // location.state.CreditRequisitionStatus = Constant.CreditRequisitionStatusValue.Approved;
    // location.state.CreditRequisitionHeaderKey =2;//35
    // location.state.InvoiceNo = "1950997";

    const [creditRequisitionStatus, setCreditRequisitionStatus] = useState(location.state.CreditRequisitionStatus);
    const [headerCreditNoteReasonCode, setHeaderCreditNoteReasonCode] = useState(location.state.HeaderCreditNoteReasonCode);

    const onChangePartLine = (obj, colName, value) => {
        setPartChanged(true);
        //update grid data
        let _listOfPart = JSON.parse(JSON.stringify(listOfPart));
        let part = _listOfPart.find(d => d.Key === obj.Key);
        if (part) {
            part[colName] = value;
        }
        setListOfPart(_listOfPart);

        let _totalQtyRequest = (Array.isArray(_listOfPart) && _listOfPart.length > 0 ? _listOfPart.map(p => p.QtyRequest ? p.QtyRequest : 0) : [0])
            .reduce((a, b) => (parseFloat(a) ? parseFloat(a) : 0) + (parseFloat(b) ? parseFloat(b) : 0));
        setTotalQtyRequest(_totalQtyRequest);
    }
    const onChangeSundryLine = (obj, colName, value) => {
        setSundryChanged(true);
        //update grid data
        let sundry = listOfSundry.find(d => d.$index === obj.$index);
        if (sundry) {
            sundry[colName] = value;
        }
        if (value && colName === "DebtorPartSundryChargeKey") {
            let sundryInvoiceSearch = partSundryInvoiceSearchItems.find(p => p.SundryChargeKey === parseInt(value));
            if (sundryInvoiceSearch) {
                obj.DebtorStockOrderLineKey = sundryInvoiceSearch.DebtorStockOrderLineKey;
                obj.SalesAmtTaxInclusive = sundryInvoiceSearch.PriceTaxInclusive;
                obj.SalesAmtTaxExclusive = sundryInvoiceSearch.SalesChargeAmt;
                obj.CreditAmtTaxInclusive = sundryInvoiceSearch.CreditAmtTaxInclusive;
                obj.CreditAmtTaxExclusive = sundryInvoiceSearch.CreditAmtTaxExclusive;
                obj.OutstandingAmountTaxInclusive = sundryInvoiceSearch.OutstandingAmountTaxInclusive;
                obj.OutstandingAmountTaxExclusive = sundryInvoiceSearch.OutstandingAmountTaxExclusive;
            }
            if (obj.$isNew) {
                delete obj.$isNew;
                let newSundryItem = getNewSundryItem();
                newSundryItem["$index"] = listOfSundry.length;
                listOfSundry.push(newSundryItem);
            }
        }
        if (colName === "RequestAmtTaxInclusive" || colName === "RequestAmtTaxExclusive") {
            let _totalRequestAmount =
                (Array.isArray(listOfSundry) && listOfSundry.length > 0
                    ? (taxIncluded
                        ? listOfSundry.map(p => p.RequestAmtTaxInclusive)
                        : listOfSundry.map(p => p.RequestAmtTaxExclusive)
                    ) : [0]).reduce((a, b) => (parseFloat(a) ? parseFloat(a) : 0) + (parseFloat(b) ? parseFloat(b) : 0))?.toFixed(Constant.NumberOfDecimalPlace);
            setTotalRequestAmount(_totalRequestAmount);
        }
        setListOfSundry([...listOfSundry]);
    }

    const deleteSundryLine = (record) => {
        let _removeLine = () => {
            let _listOfSundry = [];
            let idx = 0;
            listOfSundry.forEach((val) => {
                if (val.$index !== record.$index) {
                    val.$index = idx;
                    _listOfSundry.push(val);
                    idx++;
                }
            });
            let _totalRequestAmount =
                (Array.isArray(_listOfSundry) && _listOfSundry.length > 0
                    ? (taxIncluded
                        ? _listOfSundry.map(p => p.RequestAmtTaxInclusive)
                        : _listOfSundry.map(p => p.RequestAmtTaxExclusive)
                    ) : [0]).reduce((a, b) => (parseFloat(a) ? parseFloat(a) : 0) + (parseFloat(b) ? parseFloat(b) : 0))?.toFixed(Constant.NumberOfDecimalPlace);
            setTotalRequestAmount(_totalRequestAmount);

            setListOfSundry([]);
            setTimeout(() => {
                setListOfSundry(_listOfSundry);
            }, 300);
        }
        if (record.Key) {
            beginCallApi(apiProcessId.DeleteCreditRequisitionSundry);
            OutstandingCreditNoteService.DeleteCreditRequisitionSundry(location.state.CreditRequisitionHeaderKey, record.Key)
                .then(result => {
                    _removeLine();
                })
                .finally(() => {
                    commitCallApi(apiProcessId.DeleteCreditRequisitionSundry);
                })
                .catch(error => CommonService.handleErrorResponse(error));
        } else {
            _removeLine();
        }
    }

    const calcTotalAmtCredited = (record) => {
        if (record.QtyReceived || record.QtyApprove) {
            /*
             *  Total Credit Amt should = Unit Price x Coalesce(Qty Received, Qty Approved) x (1-Penalty Rate%)
             *  If Penalty Rate is null, then Total Credit Amt = Unit Price x  Coalesce(Qty Received, Qty Approved)
             */
            let coalesceQty = record.QtyReceived ?? record.QtyApprove ?? 0;
            if (record.PenaltyRate) {
                return taxIncluded
                    ? (record.UnitPriceTaxInclusive ? (((100 - record.PenaltyRate) / 100) * coalesceQty * record.UnitPriceTaxInclusive) : null)
                    : (record.UnitPriceTaxExclusive ? (((100 - record.PenaltyRate) / 100) * coalesceQty * record.UnitPriceTaxExclusive) : null)
            } else {
                return taxIncluded
                    ? (record.UnitPriceTaxInclusive ? (coalesceQty * record.UnitPriceTaxInclusive) : null)
                    : (record.UnitPriceTaxExclusive ? (coalesceQty * record.UnitPriceTaxExclusive) : null)
            }
        }
        return null;
    }

    const auditPart = (item) => {
        setVisibleAudit(true);
        getAuditData(item);
    }

    const getActionButtonInfo = (item) => {
        return [{
            Description: t("outstanding_credit_note.display_audit_detail"),
            Icon: <FileTextIcon />,
            ColorClass: Constant.ColorClass.LightBlue,
            Hide: false,
            handleAction: auditPart
        }]
    }

    const columns = {
        common: [{
            "title": <div dangerouslySetInnerHTML={{ __html: t("outstanding_credit_note.pickslip_line_id") }}></div>,
            "dataIndex": "PickslipLineId",
            "key": "PickslipLineId",
            "width": 80
        }, {
            "title": t("outstanding_credit_note.part_no"),
            "dataIndex": "PartItemCode",
            "key": "PartItemCode",
            "width": 150,
            render: (value, record) => (
                <TextAndButtonCell value={record.PartItemCode} tooltipText={t("common.view_part_details")}
                    buttonType={Constant.ButtonWithModalType.PartEnquiry}
                    data={record} icon={<ViewIcon />} includeWhiteSpaces={true} />
            )
        }, {
            "title": t("outstanding_credit_note.make"),
            "dataIndex": "MakeID",
            "key": "MakeID",
            "width": 120
        }, {
            "title": <div dangerouslySetInnerHTML={{ __html: t("outstanding_credit_note.part_description") }}></div>,
            "dataIndex": "PartDescription",
            "key": "PartDescription",
            "width": 150
        }, {
            title: <div dangerouslySetInnerHTML={{ __html: taxIncluded ? t('outstanding_credit_note.unit_price_inc') : t('outstanding_credit_note.unit_price_ex') }}></div>,
            "dataIndex": "UnitPrice",
            "key": "UnitPrice",
            "width": 80,
            "align": "center",
            render: (value, record) => (
                <>{taxIncluded
                    ? (record.UnitPriceTaxInclusive ? record.UnitPriceTaxInclusive.toFixed(Constant.NumberOfDecimalPlace) : null)
                    : (record.UnitPriceTaxExclusive ? record.UnitPriceTaxExclusive.toFixed(Constant.NumberOfDecimalPlace) : null)
                }</>
            )
        }
        ],
        audit: {
            "title": t("outstanding_credit_note.audit"),
            "width": 100,
            align: "center",
            render: (value, record) => (
                <ActionButtonsCell btnList={getActionButtonInfo(record)} data={record} />
            )
        },
        qty_outstanding: {
            "title": <div dangerouslySetInnerHTML={{ __html: t("outstanding_credit_note.qty_outstanding") }}></div>,
            "dataIndex": "QtyOutstanding",
            "key": "QtyOutstanding",
            "width": 110,
            "align": "center",
        },
        outstanding_amount: {
            "title": <div dangerouslySetInnerHTML={{ __html: taxIncluded ? t('outstanding_credit_note.outstanding_amount_inc') : t('outstanding_credit_note.outstanding_amount_ex') }}></div>,
            "dataIndex": "OutstandingAmount",
            "key": "OutstandingAmount",
            "width": 110,
            render: (value, record) => (
                <>{taxIncluded
                    ? (record.OutstandingAmountTaxInclusive || record.OutstandingAmountTaxInclusive === 0 ? (record.OutstandingAmountTaxInclusive).toFixed(Constant.NumberOfDecimalPlace) : null)
                    : (record.OutstandingAmountTaxExclusive || record.OutstandingAmountTaxExclusive === 0 ? (record.OutstandingAmountTaxExclusive).toFixed(Constant.NumberOfDecimalPlace) : null)
                }</>
            ),
            "align": "center",
        },
        qty_requested: {
            "title": <div dangerouslySetInnerHTML={{ __html: t("outstanding_credit_note.qty_requested") }}></div>,
            "dataIndex": "QtyRequest",
            "key": "QtyRequest",
            "width": 100,
            "align": "center",
        },
        amt_request: {
            "title": <div dangerouslySetInnerHTML={{ __html: taxIncluded ? t('outstanding_credit_note.amt_request_inc') : t('outstanding_credit_note.amt_request_ex') }}></div>,
            "dataIndex": "RequestAmt",
            "key": "RequestAmt",
            "width": 120,
            "align": "center",
            render: (value, record) => (
                <>{taxIncluded
                    ? (record.UnitPriceTaxInclusive ? (record.QtyRequest * record.UnitPriceTaxInclusive).toFixed(Constant.NumberOfDecimalPlace) : null)
                    : (record.UnitPriceTaxExclusive ? (record.QtyRequest * record.UnitPriceTaxExclusive).toFixed(Constant.NumberOfDecimalPlace) : null)
                }</>
            )
        },
        qty_approved: {
            "title": <div dangerouslySetInnerHTML={{ __html: t("outstanding_credit_note.qty_approved") }}></div>,
            "dataIndex": "QtyApprove",
            "key": "QtyApprove",
            "width": 100,
            "align": "center",
        },
        qty_rejected: {
            "title": <div dangerouslySetInnerHTML={{ __html: t("outstanding_credit_note.qty_rejected") }}></div>,
            "dataIndex": "QtyReject",
            "key": "QtyReject",
            "width": 100,
            "align": "center",
        },
        penalty_rate: {
            "title": <div dangerouslySetInnerHTML={{ __html: t("outstanding_credit_note.penalty_rate") }}></div>,
            "dataIndex": "PenaltyRate",
            "key": "PenaltyRate",
            "width": 100,
            "align": "center",
            render: (value, record) => { return record.PenaltyRate ? <>{record.PenaltyRate}%</> : <></> }
        },
        total_credit_amt: {
            "title": <div dangerouslySetInnerHTML={{ __html: t("outstanding_credit_note.total_credit_amt") }}></div>,
            "dataIndex": "TotalAmtCredited",
            "key": "",
            "width": 100,
            "align": "center",
            render: (value, record) => {
                let _totalAmtCredited = calcTotalAmtCredited(record);
                return (_totalAmtCredited || _totalAmtCredited === 0) ? _totalAmtCredited.toFixed(Constant.NumberOfDecimalPlace) : null;
            }
        },
        qty_received: {
            "title": <div dangerouslySetInnerHTML={{ __html: t("outstanding_credit_note.qty_received") }}></div>,
            "dataIndex": "QtyReceived",
            "key": "QtyReceived",
            "width": 100,
            "align": "center",
        },
        received_amount: {
            "title": <div dangerouslySetInnerHTML={{ __html: t("outstanding_credit_note.received_amount") }}></div>,
            "dataIndex": "QtyReceived",
            "key": "QtyReceived",
            "width": 100,
            "align": "center",
            render: (value, record) => {
                let qtyReceived = record.QtyReceived ? record.QtyReceived : 0;
                return (<>{
                    taxIncluded
                        ? (record.UnitPriceTaxInclusive ? (qtyReceived * record.UnitPriceTaxInclusive).toFixed(Constant.NumberOfDecimalPlace) : null)
                        : (record.UnitPriceTaxExclusive ? (qtyReceived * record.UnitPriceTaxExclusive).toFixed(Constant.NumberOfDecimalPlace) : null)
                }</>);
            }
        },
        credit_note: {
            "title": <div dangerouslySetInnerHTML={{ __html: t("outstanding_credit_note.credit_note") }}></div>,
            "dataIndex": "CreditNo",
            "key": "CreditNo",
            "width": 100,
            render: (value, record) => (
                record.QtyApprove > 0 && record.CreditNo ? <>{record.CreditNo}</> : <></>
            )
        },
        credit_note_date: {
            "title": <div dangerouslySetInnerHTML={{ __html: t("outstanding_credit_note.credit_note_date") }}></div>,
            "dataIndex": "CreditNoteDate",
            "key": "CreditNoteDate",
            "width": 120,
            render: (value, record) => (
                record.QtyApprove > 0 && record.CreditNo ? <DateTimeCell value={record.CreditNoteDate} /> : <></>
            )
        },
        quantity_invoiced: {
            "title": <div dangerouslySetInnerHTML={{ __html: t("outstanding_credit_note.quantity_invoiced") }}></div>,
            "dataIndex": "QtyInvoiced",
            "key": "QtyInvoiced",
            "width": 100,
            "align": "center",
        },
        qty_already_credited: {
            "title": <div dangerouslySetInnerHTML={{ __html: t("outstanding_credit_note.qty_already_credited") }}></div>,
            "dataIndex": "QtyAlreadyCredit",
            "key": "QtyAlreadyCredit",
            "width": 100,
            "align": "center",
        },
        qty_request: {
            "title": <div dangerouslySetInnerHTML={{ __html: t("outstanding_credit_note.qty_request") }}></div>,
            "dataIndex": "QtyRequest",
            "key": "QtyRequest",
            "width": 100,
            "align": "center",
        },
        qty_request_edit: {
            "title": <div dangerouslySetInnerHTML={{ __html: t("outstanding_credit_note.qty_request") }}></div>,
            "dataIndex": "QtyRequest",
            "key": "QtyRequest",
            "width": 100,
            "align": "center",
            render: (value, record) => (
                <InputNumber min="0" max={record.QtyOutstanding} defaultValue={record.QtyRequest} onChange={(value) => { onChangePartLine(record, "QtyRequest", value) }} />
            )
        },
        credit_details_reason: {
            "title": <div dangerouslySetInnerHTML={{ __html: t("outstanding_credit_note.credit_details_reason") }}></div>,
            "dataIndex": "CreditComment",
            "key": "CreditComment",
            "width": 150
        },
        details_reason_edit: {
            "title": t('outstanding_credit_note.details_reason'),
            "dataIndex": 'CreditComment',
            "key": 'CreditComment',
            render: (value, record) => (
                record.CreditComment ?
                    <TextAndButtonCell value={record.CreditComment} tooltipText={t("common.view")}
                        buttonType={Constant.ButtonWithModalType.RequestCreditReturnAddEditDetailsReason}
                        data={record} icon={<ViewIcon />}
                        modalViewOnly={true} /> : <></>
            ),
            width: 170
        },
        rejected_reason: {
            "title": <div dangerouslySetInnerHTML={{ __html: t("outstanding_credit_note.rejected_reason") }}></div>,
            "dataIndex": "RejectReason",
            "key": "RejectReason",
            "width": 100
        },

        invoice_no: {
            "title": <div dangerouslySetInnerHTML={{ __html: t("outstanding_credit_note.invoice_no") }}></div>,
            "dataIndex": "InvoiceNo",
            "key": "InvoiceNo",
            "width": 100
        },
        sundry: {
            "title": <div dangerouslySetInnerHTML={{ __html: t("outstanding_credit_note.sundry") }}></div>,
            "dataIndex": "DebtorPartSundryChargeKey",
            "key": "DebtorPartSundryChargeKey",
            "width": 150,
            render: (value, record) => {
                let f = debtorPartSundryCharges.find(p => p.Key === record.DebtorPartSundryChargeKey);
                return (f ? <>{f.SundryChargeID}</> : <></>);
            }
        },
        sundry_edit: {
            "title": <div dangerouslySetInnerHTML={{ __html: t("outstanding_credit_note.sundry") }}></div>,
            "dataIndex": "DebtorPartSundryChargeKey",
            "key": "DebtorPartSundryChargeKey",
            "width": 150,
            render: (value, record) => {
                if (record.InvoiceNo === location.state.InvoiceNo) {
                    return (
                        <Select disabled={!!(record.DebtorPartSundryChargeKey)} defaultValue={record.DebtorPartSundryChargeKey} onChange={(value) => { onChangeSundryLine(record, "DebtorPartSundryChargeKey", value) }} style={{ width: '100%' }} allowClear={false}>
                            {
                                partSundryInvoiceSearchItems.map(
                                    (n, index) =>
                                        //sundryDataChanged.find(p => p.DebtorPartSundryChargeKey === n.SundryChargeKey)|| 
                                        <Option disabled={listOfSundry.find(p => p.DebtorPartSundryChargeKey === n.SundryChargeKey)}
                                            key={index} value={n.SundryChargeKey}>{n.SundryChargeID}</Option>
                                )
                            }
                        </Select>
                    );
                } else {
                    let f = debtorPartSundryCharges.find(p => p.Key === record.DebtorPartSundryChargeKey);
                    return (f ? <>{f.SundryChargeID}</> : <></>);
                }
            }
        },
        invoice_amount: {
            "title": <div dangerouslySetInnerHTML={{ __html: taxIncluded ? t('outstanding_credit_note.invoice_amount_inc') : t('outstanding_credit_note.invoice_amount_ex') }}></div>,
            "dataIndex": "SalesAmt",
            "key": "SalesAmt",
            "width": 100,
            "align": "center",
            render: (value, record) => (
                <>{taxIncluded
                    ? (record.SalesAmtTaxInclusive ? record.SalesAmtTaxInclusive.toFixed(Constant.NumberOfDecimalPlace) : null)
                    : (record.SalesAmtTaxExclusive ? record.SalesAmtTaxExclusive.toFixed(Constant.NumberOfDecimalPlace) : null)
                }</>
            )
        },
        credited_amount: {
            "title": <div dangerouslySetInnerHTML={{ __html: taxIncluded ? t('outstanding_credit_note.credited_amount_inc') : t('outstanding_credit_note.credited_amount_ex') }}></div>,
            "dataIndex": "CreditAmtTaxInclusive",
            "key": "CreditAmtTaxInclusive",
            "width": 100,
            "align": "center",
            render: (value, record) => (
                <>{taxIncluded
                    ? (record.CreditAmtTaxInclusive ? record.CreditAmtTaxInclusive.toFixed(Constant.NumberOfDecimalPlace) : null)
                    : (record.CreditAmtTaxExclusive ? record.CreditAmtTaxExclusive.toFixed(Constant.NumberOfDecimalPlace) : null)
                }</>
            )
        },
        request_amount: {
            "title": <div className="text-center" dangerouslySetInnerHTML={{ __html: taxIncluded ? t('outstanding_credit_note.request_amount_inc') : t('outstanding_credit_note.request_amount_ex') }}></div>,
            "dataIndex": "RequestAmt",
            "key": "RequestAmt",
            "width": 100,
            "align": "center",
            render: (value, record) => (
                <>{taxIncluded
                    ? (record.RequestAmtTaxInclusive ? record.RequestAmtTaxInclusive.toFixed(Constant.NumberOfDecimalPlace) : null)
                    : (record.RequestAmtTaxExclusive ? record.RequestAmtTaxExclusive.toFixed(Constant.NumberOfDecimalPlace) : null)
                }</>
            )
        },
        request_amount_edit: {
            "title": <div className="text-center" dangerouslySetInnerHTML={{ __html: taxIncluded ? t('outstanding_credit_note.request_amount_inc') : t('outstanding_credit_note.request_amount_ex') }}></div>,
            "dataIndex": "RequestAmt",
            "key": "RequestAmt",
            "width": 100,
            "align": "center",
            render: (value, record) => {
                let defVal = taxIncluded ? record.RequestAmtTaxInclusive : record.RequestAmtTaxExclusive;
                if (record.InvoiceNo === location.state.InvoiceNo) {
                    let _maxValue = 0;
                    if (taxIncluded) {
                        _maxValue = record.OutstandingAmountTaxInclusive ? record.OutstandingAmountTaxInclusive : 0;
                    } else {
                        _maxValue = record.OutstandingAmountTaxExclusive ? record.OutstandingAmountTaxExclusive : 0;
                    }
                    return (
                        <InputNumber
                            disabled={!record.DebtorPartSundryChargeKey}
                            min={0}
                            max={_maxValue}
                            style={{ width: "100%" }}
                            defaultValue={defVal}
                            onChange={(value) => { onChangeSundryLine(record, taxIncluded ? "RequestAmtTaxInclusive" : "RequestAmtTaxExclusive", value) }} stringMode
                        />
                    );
                } else {
                    return (
                        <>{taxIncluded
                            ? (record.RequestAmtTaxInclusive ? record.RequestAmtTaxInclusive.toFixed(Constant.NumberOfDecimalPlace) : null)
                            : (record.RequestAmtTaxExclusive ? record.RequestAmtTaxExclusive.toFixed(Constant.NumberOfDecimalPlace) : null)
                        }</>
                    );
                }
            }
        },
        sundry_action: {
            title: t("outstanding_credit_note.action"),
            width: 50,
            align: "center",
            render: (value, record) => (
                !record.$isNew ? <ActionButtonsCell btnList={[{
                    Description: t("common.delete"),
                    Icon: <TrashIcon />,
                    ColorClass: Constant.ColorClass.LightOrange,
                    Hide: false,
                    handleAction: deleteSundryLine
                }]} data={record} /> : null
            ),
        },
        approve_amount: {
            "title": <div className="text-center" dangerouslySetInnerHTML={{ __html: taxIncluded ? t('outstanding_credit_note.approved_amount_inc') : t('outstanding_credit_note.approved_amount_ex') }}></div>,
            "dataIndex": "ApproveAmt",
            "key": "ApproveAmt",
            "width": 100,
            "align": "center",
            render: (value, record) => (
                <>{taxIncluded
                    ? (record.ApproveAmtTaxInclusive ? record.ApproveAmtTaxInclusive.toFixed(Constant.NumberOfDecimalPlace) : null)
                    : (record.ApproveAmtTaxExclusive ? record.ApproveAmtTaxExclusive.toFixed(Constant.NumberOfDecimalPlace) : null)
                }</>
            )
        },
        credit_amount: {
            "title": <div dangerouslySetInnerHTML={{ __html: taxIncluded ? t('outstanding_credit_note.credit_amount_inc') : t('outstanding_credit_note.credit_amount_ex') }}></div>,
            "dataIndex": "CreditAmt",
            "key": "CreditAmt",
            "width": 100,
            "align": "center",
            render: (value, record) => (
                <>{taxIncluded
                    ? (record.CreditAmtTaxInclusive ? record.CreditAmtTaxInclusive.toFixed(Constant.NumberOfDecimalPlace) : null)
                    : (record.CreditAmtTaxExclusive ? record.CreditAmtTaxExclusive.toFixed(Constant.NumberOfDecimalPlace) : null)
                }</>
            )
        },
    };

    const columns_part_pending = [
        ...columns.common,
        columns.quantity_invoiced,
        columns.qty_already_credited,
        columns.qty_outstanding,
        columns.qty_request_edit,
        columns.amt_request,
        columns.details_reason_edit,
        columns.audit
    ];
    const columns_part_approved = [
        ...columns.common,
        columns.qty_outstanding,
        columns.qty_requested,
        columns.amt_request,
        columns.qty_approved,
        columns.qty_rejected,
        columns.penalty_rate,
        columns.total_credit_amt,
        columns.audit
    ];
    const columns_part_arrived = [
        ...columns.common,
        columns.qty_outstanding,
        columns.qty_approved,
        columns.qty_received,
        columns.received_amount,
        columns.penalty_rate,
        columns.total_credit_amt,
        columns.audit
    ];
    const columns_part_finalized = [
        ...columns.common,
        columns.qty_outstanding,
        columns.qty_approved,
        columns.qty_received,
        columns.received_amount,
        columns.penalty_rate,
        columns.total_credit_amt,
        columns.credit_note,
        columns.credit_note_date,
        columns.audit
    ];
    const columns_part_rejected = [
        ...columns.common,
        columns.quantity_invoiced,
        columns.qty_already_credited,
        columns.qty_outstanding,
        columns.qty_request,
        columns.amt_request,
        columns.credit_details_reason,
        columns.qty_rejected,
        columns.rejected_reason,
        columns.audit
    ];

    let _columns_sundry = [
        columns.invoice_no,
        columns.sundry,
        columns.invoice_amount,
        columns.credited_amount,
        columns.outstanding_amount,
        columns.request_amount
    ];
    if (location.state.CreditRequisitionStatus === Constant.CreditRequisitionStatusValue.Approved
        || location.state.CreditRequisitionStatus === Constant.CreditRequisitionStatusValue.Arrived
        || location.state.CreditRequisitionStatus === Constant.CreditRequisitionStatusValue.Finalized) {
        _columns_sundry = [
            columns.invoice_no,
            columns.sundry,
            // columns.outstanding_amount,
            columns.request_amount,
            columns.approve_amount
        ];
    }
    if (location.state.CreditRequisitionStatus === Constant.CreditRequisitionStatusValue.Arrived
        || location.state.CreditRequisitionStatus === Constant.CreditRequisitionStatusValue.Finalized) {
        _columns_sundry.push(columns.credit_amount);
    }
    const columns_sundry = _columns_sundry;

    const columns_sundry_edit = [
        columns.sundry_action,
        columns.invoice_no,
        columns.sundry_edit,
        columns.invoice_amount,
        columns.credited_amount,
        columns.outstanding_amount,
        columns.request_amount_edit
    ];

    const apiProcessId = {
        GetCreditRequisitionLineAudit: 1,
        GetPartsCreditRequestDetail: 2,
        getCreditNoteReasonCodes: 3,
        getDebtorPartSundryChargeList: 4,
        getPartSundryInvoiceSearch: 5,
        AddUpdateCreditRequisitionDetail: 6,
        DeleteCreditRequisitionSundry: 7
    };
    let _runningApis = [];
    const beginCallApi = (processId) => {
        if (!_runningApis.includes(processId)) {
            _runningApis.push(processId);
        }
        if (_runningApis.length === 1) {
            showLoading();
        }
        // console.log("beginCallApi", JSON.stringify(_runningApis));
    };
    const commitCallApi = (processId) => {
        _runningApis = _runningApis.filter(p => p !== processId);
        if (_runningApis.length === 0) {
            dismissLoading();
        }
        // console.log("commitCallApi", JSON.stringify(_runningApis));
    };

    const getAuditData = item => {
        beginCallApi(apiProcessId.GetCreditRequisitionLineAudit);
        OutstandingCreditNoteService.GetCreditRequisitionLineAudit(item.Key)
            .then(r => {
                if (r.data) {
                    setAuditData(r.data);
                }
            }).finally(() => {
                commitCallApi(apiProcessId.GetCreditRequisitionLineAudit);
            });
    }

    const _getTableConfigs_Parts = () => {
        let totalQtyInvoiced = (Array.isArray(listOfPart) && listOfPart.length > 0 ? listOfPart.map(p => p.QtyInvoiced ? p.QtyInvoiced : 0) : [0])
            .reduce((a, b) => a + b);

        let totalQtyAlreadyCredit = (Array.isArray(listOfPart) && listOfPart.length > 0 ? listOfPart.map(p => p.QtyAlreadyCredit ? p.QtyAlreadyCredit : 0) : [0])
            .reduce((a, b) => a + b);

        let totalQtyOutstanding = (Array.isArray(listOfPart) && listOfPart.length > 0 ? listOfPart.map(p => p.QtyOutstanding ? p.QtyOutstanding : 0) : [0])
            .reduce((a, b) => a + b);

        let totalQtyApprove = (Array.isArray(listOfPart) && listOfPart.length > 0 ? listOfPart.map(p => p.QtyApprove ? p.QtyApprove : 0) : [0])
            .reduce((a, b) => a + b);

        let totalQtyReject = (Array.isArray(listOfPart) && listOfPart.length > 0 ? listOfPart.map(p => p.QtyReject ? p.QtyReject : 0) : [0])
            .reduce((a, b) => a + b);

        let _totalRequestAmt = 0;
        let _totalTotalAmtCredited = 0;
        let _totalAmtReceived = 0;
        listOfPart.forEach(record => {
            let qtyReceived = record.QtyReceived ? record.QtyReceived : 0;
            if (taxIncluded) {
                _totalRequestAmt += (record.QtyRequest * record.UnitPriceTaxInclusive);
                _totalAmtReceived += qtyReceived * record.UnitPriceTaxInclusive;
            } else {
                _totalRequestAmt += (record.QtyRequest * record.UnitPriceTaxExclusive);
                _totalAmtReceived += qtyReceived * record.UnitPriceTaxExclusive;
            }

            let _totalAmtCredited = calcTotalAmtCredited(record);
            if (_totalAmtCredited) {
                _totalTotalAmtCredited += _totalAmtCredited;
            }
        });
        let totalRequestAmt = _totalRequestAmt.toFixed(Constant.NumberOfDecimalPlace);
        let totalTotalAmtCredited = _totalTotalAmtCredited.toFixed(Constant.NumberOfDecimalPlace);
        let totalAmtReceived = _totalAmtReceived.toFixed(Constant.NumberOfDecimalPlace);

        let totalQtyReceived = (Array.isArray(listOfPart) && listOfPart.length > 0 ? listOfPart.map(p => p.QtyReceived ? p.QtyReceived : 0) : [0])
            .reduce((a, b) => a + b);

        let configs = null;
        if (creditRequisitionStatus === Constant.CreditRequisitionStatusValue.Pending) {
            configs = GetTableConfigs(columns_part_pending, listOfPart, null, "Key", null, null, "", false);
            configs["summary"] = () => (
                <Table.Summary.Row>
                    <Table.Summary.Cell>
                        <Text className="table-summary-value">{t('outstanding_credit_note.totals')}</Text>
                    </Table.Summary.Cell>
                    <Table.Summary.Cell></Table.Summary.Cell>
                    <Table.Summary.Cell></Table.Summary.Cell>
                    <Table.Summary.Cell></Table.Summary.Cell>
                    <Table.Summary.Cell></Table.Summary.Cell>
                    <Table.Summary.Cell className="text-center">
                        <Text className="table-summary-value">{totalQtyInvoiced}</Text>
                    </Table.Summary.Cell>
                    <Table.Summary.Cell className="text-center">
                        <Text className="table-summary-value">{totalQtyAlreadyCredit}</Text>
                    </Table.Summary.Cell>
                    <Table.Summary.Cell className="text-center">
                        <Text className="table-summary-value">{totalQtyOutstanding}</Text>
                    </Table.Summary.Cell>
                    <Table.Summary.Cell className="text-center">
                        <Text className="table-summary-value">{totalQtyRequest}</Text>
                    </Table.Summary.Cell>
                    <Table.Summary.Cell className="text-center">
                        <Text className="table-summary-value">{totalRequestAmt}</Text>
                    </Table.Summary.Cell>
                    <Table.Summary.Cell></Table.Summary.Cell>
                    <Table.Summary.Cell></Table.Summary.Cell>
                </Table.Summary.Row>
            );
        } else if (creditRequisitionStatus === Constant.CreditRequisitionStatusValue.Approved) {
            configs = GetTableConfigs(columns_part_approved, listOfPart, null, "Key", null, null, "", false);
            configs["summary"] = () => (
                <Table.Summary.Row>
                    <Table.Summary.Cell>
                        <Text className="table-summary-value">{t('outstanding_credit_note.totals')}</Text>
                    </Table.Summary.Cell>
                    <Table.Summary.Cell></Table.Summary.Cell>
                    <Table.Summary.Cell></Table.Summary.Cell>
                    <Table.Summary.Cell></Table.Summary.Cell>
                    <Table.Summary.Cell></Table.Summary.Cell>
                    <Table.Summary.Cell></Table.Summary.Cell>
                    <Table.Summary.Cell className="text-center">
                        <Text className="table-summary-value">{totalQtyRequest}</Text>
                    </Table.Summary.Cell>
                    <Table.Summary.Cell className="text-center">
                        <Text className="table-summary-value">{totalRequestAmt}</Text>
                    </Table.Summary.Cell>
                    <Table.Summary.Cell className="text-center">
                        <Text className="table-summary-value">{totalQtyApprove}</Text>
                    </Table.Summary.Cell>
                    <Table.Summary.Cell className="text-center">
                        <Text className="table-summary-value">{totalQtyReject}</Text>
                    </Table.Summary.Cell>
                    <Table.Summary.Cell></Table.Summary.Cell>
                    <Table.Summary.Cell className="text-center">
                        <Text className="table-summary-value">{totalTotalAmtCredited}</Text>
                    </Table.Summary.Cell>
                    <Table.Summary.Cell></Table.Summary.Cell>
                </Table.Summary.Row>
            );
        } else if (creditRequisitionStatus === Constant.CreditRequisitionStatusValue.Rejected) {
            configs = GetTableConfigs(columns_part_rejected, listOfPart, null, "Key", null, null, "", false);
            configs["summary"] = () => (
                <Table.Summary.Row>
                    <Table.Summary.Cell>
                        <Text className="table-summary-value">{t('outstanding_credit_note.totals')}</Text>
                    </Table.Summary.Cell>
                    <Table.Summary.Cell></Table.Summary.Cell>
                    <Table.Summary.Cell></Table.Summary.Cell>
                    <Table.Summary.Cell></Table.Summary.Cell>
                    <Table.Summary.Cell></Table.Summary.Cell>
                    <Table.Summary.Cell></Table.Summary.Cell>
                    <Table.Summary.Cell></Table.Summary.Cell>
                    <Table.Summary.Cell></Table.Summary.Cell>
                    <Table.Summary.Cell className="text-center">
                        <Text className="table-summary-value">{totalQtyRequest}</Text>
                    </Table.Summary.Cell>
                    <Table.Summary.Cell></Table.Summary.Cell>
                    <Table.Summary.Cell></Table.Summary.Cell>
                    <Table.Summary.Cell className="text-center">
                        <Text className="table-summary-value">{totalQtyReject}</Text>
                    </Table.Summary.Cell>
                    <Table.Summary.Cell></Table.Summary.Cell>
                    <Table.Summary.Cell></Table.Summary.Cell>
                </Table.Summary.Row>
            );
        } else if (creditRequisitionStatus === Constant.CreditRequisitionStatusValue.Arrived) {
            configs = GetTableConfigs(columns_part_arrived, listOfPart, null, "Key", null, null, "", false);
            configs["summary"] = () => (
                <Table.Summary.Row>
                    <Table.Summary.Cell>
                        <Text className="table-summary-value">{t('outstanding_credit_note.totals')}</Text>
                    </Table.Summary.Cell>
                    <Table.Summary.Cell></Table.Summary.Cell>
                    <Table.Summary.Cell></Table.Summary.Cell>
                    <Table.Summary.Cell></Table.Summary.Cell>
                    <Table.Summary.Cell></Table.Summary.Cell>
                    <Table.Summary.Cell className="text-center">
                        <Text className="table-summary-value">{totalQtyOutstanding}</Text>
                    </Table.Summary.Cell>
                    <Table.Summary.Cell className="text-center">
                        <Text className="table-summary-value">{totalQtyApprove}</Text>
                    </Table.Summary.Cell>
                    <Table.Summary.Cell className="text-center">
                        <Text className="table-summary-value">{totalQtyReceived}</Text>
                    </Table.Summary.Cell>
                    <Table.Summary.Cell className="text-center">
                        <Text className="table-summary-value">{totalAmtReceived}</Text>
                    </Table.Summary.Cell>
                    <Table.Summary.Cell></Table.Summary.Cell>
                    <Table.Summary.Cell className="text-center">
                        <Text className="table-summary-value">{totalTotalAmtCredited}</Text>
                    </Table.Summary.Cell>
                    <Table.Summary.Cell></Table.Summary.Cell>
                </Table.Summary.Row>
            );
        } else if (creditRequisitionStatus === Constant.CreditRequisitionStatusValue.Finalized) {
            configs = GetTableConfigs(columns_part_finalized, listOfPart, null, "Key", null, null, "", false);
            configs["summary"] = () => (
                <Table.Summary.Row>
                    <Table.Summary.Cell>
                        <Text className="table-summary-value">{t('outstanding_credit_note.totals')}</Text>
                    </Table.Summary.Cell>
                    <Table.Summary.Cell></Table.Summary.Cell>
                    <Table.Summary.Cell></Table.Summary.Cell>
                    <Table.Summary.Cell></Table.Summary.Cell>
                    <Table.Summary.Cell></Table.Summary.Cell>
                    <Table.Summary.Cell className="text-center">
                        <Text className="table-summary-value">{totalQtyOutstanding}</Text>
                    </Table.Summary.Cell>
                    <Table.Summary.Cell className="text-center">
                        <Text className="table-summary-value">{totalQtyApprove}</Text>
                    </Table.Summary.Cell>
                    <Table.Summary.Cell className="text-center">
                        <Text className="table-summary-value">{totalQtyReceived}</Text>
                    </Table.Summary.Cell>
                    <Table.Summary.Cell className="text-center">
                        <Text className="table-summary-value">{totalAmtReceived}</Text>
                    </Table.Summary.Cell>
                    <Table.Summary.Cell></Table.Summary.Cell>
                    <Table.Summary.Cell className="text-center">
                        <Text className="table-summary-value">{totalTotalAmtCredited}</Text>
                    </Table.Summary.Cell>
                    <Table.Summary.Cell></Table.Summary.Cell>
                    <Table.Summary.Cell></Table.Summary.Cell>
                    <Table.Summary.Cell></Table.Summary.Cell>
                </Table.Summary.Row>
            );
        }
        return configs;
    };

    const _getTableConfigs_Sundries = (editable) => {
        let _totalOutstandingAmount = 0;
        let _totalInvoiceAmount = 0;
        let _totalCreditAmtTax = 0;
        let _totalApproveAmt = 0;
        listOfSundry.forEach(record => {
            if (taxIncluded) {
                _totalOutstandingAmount += (record.OutstandingAmountTaxInclusive) ? record.OutstandingAmountTaxInclusive : 0;
                _totalInvoiceAmount += (record.SalesAmtTaxInclusive) ? record.SalesAmtTaxInclusive : 0;
                _totalCreditAmtTax += record.CreditAmtTaxInclusive ? record.CreditAmtTaxInclusive : 0;
                _totalApproveAmt += record.ApproveAmtTaxInclusive ? record.ApproveAmtTaxInclusive : 0;
            } else {
                _totalOutstandingAmount += (record.OutstandingAmountTaxExclusive) ? record.OutstandingAmountTaxExclusive : 0;
                _totalInvoiceAmount += (record.SalesAmtTaxExclusive) ? record.SalesAmtTaxExclusive : 0;
                _totalCreditAmtTax += record.CreditAmtTaxExclusive ? record.CreditAmtTaxExclusive : 0;
                _totalApproveAmt += record.ApproveAmtTaxExclusive ? record.ApproveAmtTaxExclusive : 0;
            }
        });
        let totalOutstandingAmount = _totalOutstandingAmount.toFixed(Constant.NumberOfDecimalPlace);
        let totalInvoiceAmount = _totalInvoiceAmount.toFixed(Constant.NumberOfDecimalPlace);
        let totalCreditAmtTax = _totalCreditAmtTax.toFixed(Constant.NumberOfDecimalPlace);
        let totalApproveAmt = _totalApproveAmt.toFixed(Constant.NumberOfDecimalPlace);

        let configs = null;
        if (editable === true && creditRequisitionStatus === Constant.CreditRequisitionStatusValue.Pending) {
            configs = GetTableConfigs(columns_sundry_edit, listOfSundry, null, "$index", null, null, "", false);
            configs["summary"] = () => (
                <Table.Summary.Row>
                    <Table.Summary.Cell>
                        <Text className="table-summary-value">{t('orders_management.total')}</Text>
                    </Table.Summary.Cell>
                    <Table.Summary.Cell></Table.Summary.Cell>
                    <Table.Summary.Cell></Table.Summary.Cell>
                    <Table.Summary.Cell className="text-center">
                        <Text className="table-summary-value">{totalInvoiceAmount}</Text>
                    </Table.Summary.Cell>
                    <Table.Summary.Cell className="text-center">
                        <Text className="table-summary-value">{totalCreditAmtTax}</Text>
                    </Table.Summary.Cell>
                    <Table.Summary.Cell className="text-center">
                        <Text className="table-summary-value">{totalOutstandingAmount}</Text>
                    </Table.Summary.Cell>
                    <Table.Summary.Cell className="text-center">
                        <Text className="table-summary-value">{totalRequestAmount}</Text>
                    </Table.Summary.Cell>
                </Table.Summary.Row>
            );
        } else {
            configs = GetTableConfigs(columns_sundry, listOfSundry, null, "$index", null, null, "", false);
            configs["summary"] = () => (
                <Table.Summary.Row>
                    <Table.Summary.Cell>
                        <Text className="table-summary-value">{t('orders_management.total')}</Text>
                    </Table.Summary.Cell>
                    <Table.Summary.Cell></Table.Summary.Cell>
                    {
                        (location.state.CreditRequisitionStatus === Constant.CreditRequisitionStatusValue.Rejected)
                        && (
                            <>
                                <Table.Summary.Cell className="text-center">
                                    <Text className="table-summary-value">{totalInvoiceAmount}</Text>
                                </Table.Summary.Cell>
                                <Table.Summary.Cell className="text-center">
                                    <Text className="table-summary-value">{totalCreditAmtTax}</Text>
                                </Table.Summary.Cell>
                                <Table.Summary.Cell className="text-center">
                                    <Text className="table-summary-value">{totalOutstandingAmount}</Text>
                                </Table.Summary.Cell>
                            </>
                        )
                    }
                    <Table.Summary.Cell className="text-center">
                        <Text className="table-summary-value">{totalRequestAmount}</Text>
                    </Table.Summary.Cell>
                    {
                        (location.state.CreditRequisitionStatus === Constant.CreditRequisitionStatusValue.Approved
                            || location.state.CreditRequisitionStatus === Constant.CreditRequisitionStatusValue.Arrived
                            || location.state.CreditRequisitionStatus === Constant.CreditRequisitionStatusValue.Finalized
                        ) && (
                            <Table.Summary.Cell className="text-center">
                                <Text className="table-summary-value">{totalApproveAmt}</Text>
                            </Table.Summary.Cell>
                        )
                    }
                    {
                        (location.state.CreditRequisitionStatus === Constant.CreditRequisitionStatusValue.Arrived
                            || location.state.CreditRequisitionStatus === Constant.CreditRequisitionStatusValue.Finalized
                        ) && (
                            <Table.Summary.Cell className="text-center">
                                <Text className="table-summary-value">{totalCreditAmtTax}</Text>
                            </Table.Summary.Cell>
                        )
                    }
                </Table.Summary.Row>
            );
        }
        return configs;
    }

    const getNewSundryItem = () => {
        return {
            "$isNew": true,
            "Key": null,
            "InvoiceNo": location.state.InvoiceNo,
            "InvoiceDate": location.state.InvoiceDate,
            "DebtorPartSundryChargeKey": null,
            "QtyInvoiced": 0,
            "TotalAmtCredited": 0,
            "QtyOutstanding": 0,
            "RequestAmt": 0,
            "RequestAmtTaxInclusive": 0,
            "RequestAmtTaxExclusive": 0,
            "DebtorStockOrderLineKey": null
        };
    }

    const convertDataDetail = (result) => {
        let lstSundry = [];
        if (result.data) {
            setListOfPart(result.data.PartList ? result.data.PartList : []);
            lstSundry = result.data.SundryList ? result.data.SundryList : [];

            let _totalQtyRequest = (Array.isArray(result.data.PartList) && result.data.PartList.length > 0 ? result.data.PartList.map(p => p.QtyRequest ? p.QtyRequest : 0) : [0])
                .reduce((a, b) => (parseFloat(a) ? parseFloat(a) : 0) + (parseFloat(b) ? parseFloat(b) : 0));
            setTotalQtyRequest(_totalQtyRequest);

            let _totalRequestAmount =
                (Array.isArray(result.data.SundryList) && result.data.SundryList.length > 0
                    ? (taxIncluded
                        ? result.data.SundryList.map(p => p.RequestAmtTaxInclusive)
                        : result.data.SundryList.map(p => p.RequestAmtTaxExclusive)
                    ) : [0]).reduce((a, b) => (parseFloat(a) ? parseFloat(a) : 0) + (parseFloat(b) ? parseFloat(b) : 0))?.toFixed(Constant.NumberOfDecimalPlace);
            setTotalRequestAmount(_totalRequestAmount);
        }
        lstSundry.forEach((v, i) => { v["$index"] = i; })
        setListOfSundry(lstSundry);
        return lstSundry;
    };

    const getPartsCreditRequestDetail = () => {
        beginCallApi(apiProcessId.GetPartsCreditRequestDetail);
        OutstandingCreditNoteService.GetPartsCreditRequestDetail(location.state.CreditRequisitionHeaderKey)
            .then(result => {
                convertDataDetail(result);
                getPartSundryInvoiceSearch(result.data.SundryList);
            })
            .finally(() => {
                commitCallApi(apiProcessId.GetPartsCreditRequestDetail);
            })
            .catch(error => CommonService.handleErrorResponse(error));
    }

    useEffect(() => {
        let timeoutFn = setTimeout(() => document.documentElement.style.setProperty(Constant.CssVariables.FixItemsContainerHeight, CommonService.calculateTableBodyMaxHeight()), 100);
        setCreditInfo({
            "CreditRequisitionHeaderKey": location.state.CreditRequisitionHeaderKey,
            "CreditReference": location.state.CreditReference,
            "RequestDate": location.state.RequestDate,
            "InvoiceNo": location.state.InvoiceNo,
            "InvoiceDate": location.state.InvoiceDate,
            "DebtorOrderNo": location.state.DebtorOrderNo,
            "CreditRequisitionStatus": creditRequisitionStatus,
            "ApproveDate": location.state.ApproveDate,
            "CreditNo": location.state.CreditNo,
            "CreditNoteDate": location.state.CreditNoteDate,
            "Key": location.state.Key,
            "RequestAmtTaxInclusive": location.state.RequestAmtTaxInclusive,
            "RequestAmtTaxExclusive": location.state.RequestAmtTaxExclusive,
            "ApproveAmtTaxInclusive": location.state.ApproveAmtTaxInclusive,
            "ApproveAmtTaxExclusive": location.state.ApproveAmtTaxExclusive,
            "RejectAmtTaxInclusive": location.state.RejectAmtTaxInclusive,
            "RejectAmtTaxExclusive": location.state.RejectAmtTaxExclusive,
            "HeaderCreditNoteReasonCode": location.state.HeaderCreditNoteReasonCode
        });

        if (location.state.CreditRequisitionHeaderKey) {
            beginCallApi(apiProcessId.GetPartsCreditRequestDetail);
            Promise.all([
                OutstandingCreditNoteService.GetPartsCreditRequestDetail(location.state.CreditRequisitionHeaderKey),
                BuyerService.getDebtorPartSundryChargeList()
            ])
                .then(result => {
                    let _debtorPartSundryCharges = [];
                    if (result[1].data) {
                        setDebtorPartSundryCharges(result[1].data);
                        _debtorPartSundryCharges = result[1].data;
                    }
                    let _listOfSundry = convertDataDetail(result[0]);
                    getPartSundryInvoiceSearch(_listOfSundry, _debtorPartSundryCharges);
                })
                .finally(() => {
                    commitCallApi(apiProcessId.GetPartsCreditRequestDetail);
                })
                .catch(error => CommonService.handleErrorResponse(error));
        }
        const _displayHeaderCreditNoteReason = (reasonCodes) => {
            let _reason = reasonCodes.find(d => d.Code === location.state.HeaderCreditNoteReasonCode);
            if (_reason) {
                setHeaderCreditNoteReasonCode(_reason.Code + " - " + _reason.Description);
            } else {
                setHeaderCreditNoteReasonCode(location.state.HeaderCreditNoteReasonCode);
            }
        }
        if (!creditNoteReasonCodes || creditNoteReasonCodes.length === 0) {
            beginCallApi(apiProcessId.getCreditNoteReasonCodes);
            BuyerService.getCreditNoteReasonCodes()
                .then(result => {
                    if (result.data) {
                        setCreditNoteReasonCodes(result.data);
                        _displayHeaderCreditNoteReason(result.data);
                    }
                })
                .finally(() => {
                    commitCallApi(apiProcessId.getCreditNoteReasonCodes);
                })
                .catch(error => CommonService.handleErrorResponse(error))
        } else {
            _displayHeaderCreditNoteReason(creditNoteReasonCodes);
        }
        return () => {
            OutstandingCreditNoteService.cancelRequest();
            clearTimeout(timeoutFn);
        };
    }, []);

    const getPartSundryInvoiceSearch = (_listOfSundry, _debtorPartSundryCharges) => {
        _debtorPartSundryCharges = _debtorPartSundryCharges ?? debtorPartSundryCharges;
        _listOfSundry = _listOfSundry ?? listOfSundry;
        if (location.state.InvoiceNo) {
            beginCallApi(apiProcessId.getPartSundryInvoiceSearch);
            BuyerService.getPartSundryInvoiceSearch(location.state.InvoiceNo)
                .then(result => {
                    if (result.data) {
                        let sundryChargeData = result.data;
                        sundryChargeData.forEach(v => {
                            let f = _debtorPartSundryCharges.find(p => p.Key === v.SundryChargeKey);
                            if (f) {
                                v.SundryChargeID = f.SundryChargeID;
                            }
                        });
                        setPartSundryInvoiceSearchItems(sundryChargeData);

                        _listOfSundry.forEach(obj => {
                            let sundryInvoiceSearch = sundryChargeData.find(p => p.SundryChargeKey === parseInt(obj.DebtorPartSundryChargeKey));
                            if (sundryInvoiceSearch) {
                                obj.DebtorStockOrderLineKey = sundryInvoiceSearch.DebtorStockOrderLineKey;
                                obj.SalesAmtTaxInclusive = sundryInvoiceSearch.PriceTaxInclusive;
                                obj.SalesAmtTaxExclusive = sundryInvoiceSearch.SalesChargeAmt;
                                // obj.CreditAmtTaxInclusive = sundryInvoiceSearch.CreditAmtTaxInclusive;
                                // obj.CreditAmtTaxExclusive = sundryInvoiceSearch.CreditAmtTaxExclusive;
                                obj.OutstandingAmountTaxInclusive = sundryInvoiceSearch.OutstandingAmountTaxInclusive;
                                obj.OutstandingAmountTaxExclusive = sundryInvoiceSearch.OutstandingAmountTaxExclusive;
                            }
                        });
                        if (sundryChargeData.length > 0 && creditRequisitionStatus === Constant.CreditRequisitionStatusValue.Pending) {
                            let lstSundry = JSON.parse(JSON.stringify(_listOfSundry));
                            let newSundryItem = getNewSundryItem();
                            lstSundry.push(newSundryItem);
                            lstSundry.forEach((v, i) => { v["$index"] = i; })
                            setListOfSundry(lstSundry);
                        }
                    }
                })
                .finally(() => {
                    commitCallApi(apiProcessId.getPartSundryInvoiceSearch);
                })
                .catch(error => CommonService.handleErrorResponse(error));
        }
    }

    if (!location.state) {
        return <Redirect to={Constant.Pages.OutstandingCreditNotesEnquiry} />
    }

    const _auditFields = [{
        FieldName: "QTY_REQUEST",
        FieldNameDisplay: t('outstanding_credit_note.audit_qty_request')
    }, {
        FieldName: "QTY_APPROVE",
        FieldNameDisplay: t('outstanding_credit_note.audit_qty_approve')
    }, {
        FieldName: "QTY_RECEIVED",
        FieldNameDisplay: t('outstanding_credit_note.audit_qty_received')
    }];

    const columns_audit = [{
        "title": <div dangerouslySetInnerHTML={{ __html: t("outstanding_credit_note.mode") }}></div>,
        "dataIndex": "OperationMode",
        "key": "OperationMode",
        "width": 120
    }, {
        "title": <div dangerouslySetInnerHTML={{ __html: t("outstanding_credit_note.changed_by_emp_id") }}></div>,
        "dataIndex": "CreationEmpID",
        "key": "CreationEmpID",
        "width": 120
    }, {
        "title": <div dangerouslySetInnerHTML={{ __html: t("outstanding_credit_note.changed_at") }}></div>,
        "dataIndex": "CreationTimestamp",
        "key": "CreationTimestamp",
        "width": 120,
        render: (value, record) => (
            <DateTimeCell value={record.CreationTimestamp} showTime="true" />
        )
    }, {
        "title": <div dangerouslySetInnerHTML={{ __html: t("outstanding_credit_note.old_value") }}></div>,
        "dataIndex": "OldValue",
        "key": "OldValue",
        "width": 120
    }, {
        "title": <div dangerouslySetInnerHTML={{ __html: t("outstanding_credit_note.new_value") }}></div>,
        "dataIndex": "NewData",
        "key": "NewData",
        "width": 120
    }];

    const handleCancelAudit = () => {
        setVisibleAudit(false);
    }

    const _getRequestData = () => {
        return {
            Key: location.state.CreditRequisitionHeaderKey,
            InvoiceNo: location.state.InvoiceNo,
            InvoiceDate: location.state.InvoiceDate,
            CreditRequisitionLines: []
        };
    };

    const updateStatusOnPrevState = (request, isDeleteWholeCR) => {
        if (request.CreditRequisitionLines && request.CreditRequisitionLines.length > 0) {
            let isRejected = partChanged && !(request.CreditRequisitionLines.find(p => p.QtyRequest > 0));
            if (isRejected || isDeleteWholeCR) {
                let rejectedItem = location.state.prevState.listOfItems.find(p => p.CreditRequisitionHeaderKey === request.Key);
                if (rejectedItem) {
                    rejectedItem.CreditRequisitionStatus = Constant.CreditRequisitionStatusValue.Rejected;
                    setCreditRequisitionStatus(Constant.CreditRequisitionStatusValue.Rejected);
                    let _creditInfo = creditInfo;
                    _creditInfo.CreditRequisitionStatus = Constant.CreditRequisitionStatusValue.Rejected;
                    setCreditInfo(_creditInfo);
                }
            }
        }
    }

    const deleteWholeCreditRequest = () => {
        beginCallApi(apiProcessId.AddUpdateCreditRequisitionDetail);
        let request = _getRequestData();
        listOfPart.forEach((v, i) => {
            v.QtyRequest = 0;
            v.QtyReject = 0;
            setTotalQtyRequest(0);
            request.CreditRequisitionLines.push(v);
        });
        OutstandingCreditNoteService.AddUpdateCreditRequisitionDetail(request)
            .then(result => {
                updateStatusOnPrevState(request, true);
                getPartsCreditRequestDetail();
            })
            .finally(() => {
                commitCallApi(apiProcessId.AddUpdateCreditRequisitionDetail);
                setPartChanged(false);
                setSundryChanged(false);
            })
            .catch(error => CommonService.handleErrorResponse(error));
    }
    const updateCreditRequest = () => {
        if (partChanged || sundryChanged) {
            beginCallApi(apiProcessId.AddUpdateCreditRequisitionDetail);
            let request = _getRequestData();
            listOfPart.forEach((v, i) => {
                if (v.Key) {
                    request.CreditRequisitionLines.push(v);
                }
            });
            listOfSundry.forEach((v, i) => {
                if (v.DebtorPartSundryChargeKey) {
                    if (taxIncluded) {
                        v.RequestAmtTaxExclusive = null;
                    } else {
                        v.RequestAmtTaxInclusive = null;
                    }
                    request.CreditRequisitionLines.push(v);
                }
            });
            OutstandingCreditNoteService.AddUpdateCreditRequisitionDetail(request)
                .then(result => {
                    updateStatusOnPrevState(request);
                    getPartsCreditRequestDetail();
                })
                .finally(() => {
                    commitCallApi(apiProcessId.AddUpdateCreditRequisitionDetail);
                })
                .catch(error => CommonService.handleErrorResponse(error));
        }
    }

    const openDownloadDocument = () => {
        setVisibleDownloadDocumentModal(true);
    }

    const cancelDownloadDocumentModal = () => {
        setVisibleDownloadDocumentModal(false);
    }

    const getRequestStatusColorClass = (item) => {
        if (item) {
            if (item.CreditRequisitionStatus == Constant.CreditRequisitionStatusValue.Approved)
                return Constant.ColorClass.LightGreen
            else if (item.CreditRequisitionStatus == Constant.CreditRequisitionStatusValue.Pending)
                return Constant.ColorClass.LightYellow
            else if (item.CreditRequisitionStatus == Constant.CreditRequisitionStatusValue.Rejected)
                return Constant.ColorClass.LightOrange
            else if (item.CreditRequisitionStatus == Constant.CreditRequisitionStatusValue.Arrived)
                return Constant.ColorClass.LightBlue
        }
        else {
            return Constant.ColorClass.LightBlue
        }
    }

    const getRequestStatusDescription = (item) => {
        if (item) {
            let sot = Constant.CreditRequisitionStatus.find(p => p.Code === item.CreditRequisitionStatus)
            if (sot)
                return sot.Description
        }
        return ''
    }

    const _componentCreditInfo = () => {
        return (
            <Row id={Constant.FixItemsContainerId}>
                <Col xs={{ span: 24 }}>
                    <Row gutter={[Constant.SpaceConstant.HorizontalGutter, Constant.SpaceConstant.VerticalGutter]}>
                        <Col xs={{ span: 5 }}>
                            <label className="title-field">{t('outstanding_credit_note.credit_request_id')}</label>
                            <label className="text-field">{creditInfo.CreditRequisitionHeaderKey}</label>
                        </Col>
                        <Col xs={{ span: 5 }}>
                            <label className="title-field">{t('outstanding_credit_note.credit_request_no')}</label>
                            <label className="text-field">{creditInfo.CreditReference}</label>
                        </Col>
                        <Col xs={{ span: 5 }}>
                            <label className="title-field">{t('outstanding_credit_note.invoice_no')}</label>
                            <label className="text-field">{creditInfo.InvoiceNo}</label>
                        </Col>
                        <Col xs={{ span: 5 }}>
                            <label className="title-field">{t('outstanding_credit_note.order_no')}</label>
                            <label className="text-field">{creditInfo.DebtorOrderNo}</label>
                        </Col>
                        <Col xs={{ span: 4 }} className="flex-items m-t-10">
                            <label className="title-field m-r-20">{t('outstanding_credit_note.status')}</label>
                            {/* <label className="text-field">
                                {Constant.CreditRequisitionStatus.find(d => d.Code === creditInfo.CreditRequisitionStatus)?.Description}
                            </label> */}
                            <StatusCell data={creditInfo}
                                colorClass={getRequestStatusColorClass(creditInfo)}
                                description={getRequestStatusDescription(creditInfo)} />
                        </Col>
                        <Col xs={{ span: 5 }}>
                            <label className="title-field">{t('outstanding_credit_note.credit_request_date')}</label>
                            <label className="text-field">
                                <DateTimeCell value={creditInfo.RequestDate} />
                            </label>
                        </Col>
                        <Col xs={{ span: 5 }}>
                            <label className="title-field">{t('outstanding_credit_note.credit_approved_date')}</label>
                            <label className="text-field">
                                <DateTimeCell value={creditInfo.ApproveDate} />
                            </label>
                        </Col>
                        <Col xs={{ span: 5 }}>
                            <label className="title-field">{t('outstanding_credit_note.invoice_date')}</label>
                            <label className="text-field">
                                <DateTimeCell value={creditInfo.InvoiceDate} />
                            </label>
                        </Col>
                        <Col xs={{ span: 5 }}>
                            <label className="title-field">{t('outstanding_credit_note.credit_reason')}</label>
                            <label className="text-field">{headerCreditNoteReasonCode}</label>
                        </Col>
                        <Col xs={{ span: 4 }}>
                            {
                                (location.state.downloadDocuments && location.state.downloadDocuments.length > 0 && CommonService.dmsVersionGreaterOrEqual(Constant.DmsVersion.version_1_36_18_0))
                                &&
                                <ActionButtonsCell justifyContentStart={true} btnList={[
                                    {
                                        Description: t("common.download_document"),
                                        Icon: <DownloadIcon />,
                                        Hide: false,
                                        ColorClass: Constant.ColorClass.LightBlue,
                                        NoMargin: true,
                                        handleAction: openDownloadDocument
                                    }
                                ]} />
                            }
                        </Col>
                    </Row>
                </Col>
            </Row>
        );
    }

    return (
        <div className="pickslip-details-page">
            <Breadcrumb className="has-child">
                <Breadcrumb.Item onClick={() => {
                    history.replace(Constant.Pages.OutstandingCreditNotesEnquiry,
                        {
                            ...location.state.prevState,
                            encryptKey: location.state.encryptKey,
                            mediaServiceHostName: location.state.mediaServiceHostName
                        })
                }}>
                    <span className='back-icon'><ChevronLeftIcon /></span>
                    <span>{t("menu.credit_request_query")}</span>
                </Breadcrumb.Item>
                <Breadcrumb.Item>{t("outstanding_credit_note.details")}</Breadcrumb.Item>
            </Breadcrumb>
            {_componentCreditInfo()}
            <Tabs defaultActiveKey="1">
                <TabPane tab={t("outstanding_credit_note.parts")} key="parts">
                    <Row>
                        <Col xs={{ span: 24 }}>
                            <Table {..._getTableConfigs_Parts()} />
                        </Col>
                    </Row>
                </TabPane>
                <TabPane tab={t("outstanding_credit_note.sundries")} key="sundries">
                    <Row>
                        <Col xs={{ span: 24 }}>
                            {partSundryInvoiceSearchItems.length > 0 && <Table {..._getTableConfigs_Sundries(true)} />}
                            {partSundryInvoiceSearchItems.length === 0 && <Table {..._getTableConfigs_Sundries(false)} />}
                        </Col>
                    </Row>
                </TabPane>
            </Tabs>
            {
                (creditRequisitionStatus === Constant.CreditRequisitionStatusValue.Pending) && (
                    <Row className="m-t-20">
                        <Col xs={{ span: 24 }} className="text-right">
                            <Button size="large" onClick={deleteWholeCreditRequest} className="secondary-btn">{t("outstanding_credit_note.delete_whole_credit_request")}</Button>
                            <Button size="large" onClick={updateCreditRequest} className="m-l-10" type="primary" disabled={(!partChanged && !sundryChanged)}>{t("outstanding_credit_note.update")}</Button>
                        </Col>
                    </Row>
                )
            }
            {visibleAudit &&
                <Modal
                    className="pickslip-details-page"
                    title={t("outstanding_credit_note.audit")}
                    visible={true}
                    confirmLoading={false}
                    onCancel={handleCancelAudit}
                    maskClosable={false}
                    footer={null}
                    width={1000}
                    centered
                >
                    <Collapse className="audit-field-title" defaultActiveKey={_auditFields.map(d => d.FieldName)}>
                        {
                            _auditFields.map((field, index) => {
                                let _auditItems = auditData.filter((d, i) => { d["$index"] = i; return d.FieldName === field.FieldName });
                                return (
                                    (
                                        _auditItems.length > 0 && (
                                            <Panel header={field.FieldNameDisplay} key={field.FieldName}>
                                                <Table className="custom-table" columns={columns_audit} dataSource={_auditItems} rowKey="$index" pagination={false} />
                                            </Panel>
                                        )
                                    )
                                );
                            })
                        }
                    </Collapse>
                </Modal>
            }
            {visibleDownloadDocumentModal &&
                <Modal
                    className="pickslip-details-page"
                    title={t("common.download_document")}
                    visible={true}
                    confirmLoading={false}
                    onCancel={cancelDownloadDocumentModal}
                    maskClosable={false}
                    footer={null}
                    width={768}
                    centered
                >
                    <Row>
                        <Col xs={{ span: 24 }}>
                            <Table className="custom-table" columns={CommonService.getDownloadDocumentColumns(location.state.mediaServiceHostName, location.state.encryptKey, showLoading, dismissLoading)}
                                dataSource={location.state.downloadDocuments}
                                rowKey="$id"
                                pagination={false} scroll={{ y: 300 }} />
                        </Col>
                    </Row>
                </Modal>
            }
        </div>
    );
}