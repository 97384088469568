import axios from 'axios';
import { axiosPortal } from './authService'
import { Constant } from './constant';
import moment from 'moment';


const source = [];
let baseURL = "/Buyer"

export const BuyerService = {
    getRequestedSuppliers: function (pageNumber, pageSize, sort, storeName, statusCode, requestedDateFrom, requestedDateTo) {
        let cancelToken = axios.CancelToken.source()
        source.push(cancelToken)
        let url = `${baseURL}/GetRequestedSupplierList?PageNumber=${pageNumber}&PageSize=${pageSize}&Sort=${sort}`
        if (storeName) {
            url += `&StoreName=${storeName}`
        }
        if (statusCode) {
            url += `&StatusCode=${statusCode}`
        }
        if (requestedDateFrom) {
            let dateFormat = moment(requestedDateFrom).format("YYYY-MM-DD")
            url += `&RequestedDateFrom=${dateFormat}`
        }
        if (requestedDateTo) {
            let dateFormat = moment(requestedDateTo).format("YYYY-MM-DD")
            url += `&RequestedDateTo=${dateFormat}`
        }

        return axiosPortal.get(url, {
            cancelToken: cancelToken.token
        }).finally(() => {
            let index = source.indexOf(cancelToken)
            if (index > -1) {
                source.splice(index, 1)
            }
        })
    },
    addSupplierForBuyer: function (data) {
        let cancelToken = axios.CancelToken.source()
        source.push(cancelToken)
        return axiosPortal.post(`${baseURL}/AddSupplierForBuyer`, data, {
            cancelToken: cancelToken.token
        }).finally(() => {
            let index = source.indexOf(cancelToken)
            if (index > -1) {
                source.splice(index, 1)
            }
        })
    },
    updateSupplierForBuyer: function (data) {
        let cancelToken = axios.CancelToken.source()
        source.push(cancelToken)
        return axiosPortal.put(`${baseURL}/UpdateSupplierForBuyer/${data.SupplierId}`, data, {
            cancelToken: cancelToken.token
        }).finally(() => {
            let index = source.indexOf(cancelToken)
            if (index > -1) {
                source.splice(index, 1)
            }
        })
    },
    deleteSupplierForBuyer: function (id) {
        let cancelToken = axios.CancelToken.source()
        source.push(cancelToken)
        return axiosPortal.put(`${baseURL}/DeleteSupplierForBuyer/${id}`, {
            cancelToken: cancelToken.token
        }).finally(() => {
            let index = source.indexOf(cancelToken)
            if (index > -1) {
                source.splice(index, 1)
            }
        })
    },
    getPartDetails: function (stockKey, bitwiseNumber, partNumber = null, makeId = null) {
        let cancelToken = axios.CancelToken.source()
        source.push(cancelToken)
        let url = `${baseURL}/GetPartDetails?stockKey=${stockKey}&bitwiseNumber=${bitwiseNumber}`
        if (partNumber) {
            url += `&partNumber=${partNumber}`
        }
        if (makeId) {
            url += `&makeId=${makeId}`
        }

        return axiosPortal.get(url, {
            cancelToken: cancelToken.token
        }).finally(() => {
            let index = source.indexOf(cancelToken)
            if (index > -1) {
                source.splice(index, 1)
            }
        })
    },
    getSearchContainStocks: function (partItemCode, makeId, partDescription, showQtyAvailableOnly, tyreSearchOnly, tyreWidth, tyreProfile, tyreDiameter, pageNumber, pageSize, sort) {
        let cancelToken = axios.CancelToken.source()
        source.push(cancelToken)
        let url = `${baseURL}/GetSearchContainStocks?PageNumber=${pageNumber}&PageSize=${pageSize}&Sort=${sort}`
        if (partItemCode) {
            url += `&PartItemCode=${partItemCode}`
        }
        if (makeId) {
            url += `&MakeId=${makeId}`
        }
        if (partDescription) {
            url += `&PartDescription=${partDescription}`
        }
        if (showQtyAvailableOnly)
            url += `&ShowQtyAvailableOnly=${showQtyAvailableOnly}`
        if (tyreSearchOnly)
            url += `&TyreSearchOnly=${tyreSearchOnly}`
        if (tyreWidth) {
            url += `&TyreWidth=${tyreWidth}`
        }
        if (tyreProfile) {
            url += `&TyreProfile=${tyreProfile}`
        }
        if (tyreDiameter) {
            url += `&TyreDiameter=${tyreDiameter}`
        }

        return axiosPortal.get(url, {
            cancelToken: cancelToken.token
        }).finally(() => {
            let index = source.indexOf(cancelToken)
            if (index > -1) {
                source.splice(index, 1)
            }
        })
    },
    getSearchStock: function (partItemCode, makeId = null, getNonStockRecord = true) {
        let cancelToken = axios.CancelToken.source()
        source.push(cancelToken)
        if (!getNonStockRecord)
            getNonStockRecord = false
        let url = `${baseURL}/GetSearchStock?partItemCode=${partItemCode}&getNonStockRecord=${getNonStockRecord}`
        if (makeId) {
            url += `&makeId=${makeId}`
        }


        return axiosPortal.get(url, {
            cancelToken: cancelToken.token
        }).finally(() => {
            let index = source.indexOf(cancelToken)
            if (index > -1) {
                source.splice(index, 1)
            }
        })
    },
    getMakeList: function () {
        let cancelToken = axios.CancelToken.source()
        source.push(cancelToken)
        let url = `${baseURL}/GetMakeList`

        return axiosPortal.get(url, {
            cancelToken: cancelToken.token
        }).finally(() => {
            let index = source.indexOf(cancelToken)
            if (index > -1) {
                source.splice(index, 1)
            }
        })
    },
    getOrderTypes: function () {
        let cancelToken = axios.CancelToken.source()
        source.push(cancelToken)
        let url = `${baseURL}/SaleOrderTypes`

        return axiosPortal.get(url, {
            cancelToken: cancelToken.token
        }).finally(() => {
            let index = source.indexOf(cancelToken)
            if (index > -1) {
                source.splice(index, 1)
            }
        })
    },
    AddUpdatePartOrderLine: function (data) {
        let cancelToken = axios.CancelToken.source()
        source.push(cancelToken)
        return axiosPortal.post(`${baseURL}/PartOrderLine`, data, {
            cancelToken: cancelToken.token
        }).finally(() => {
            let index = source.indexOf(cancelToken)
            if (index > -1) {
                source.splice(index, 1)
            }
        })
    },
    RemovePartOrderLine: function (data) {
        let cancelToken = axios.CancelToken.source()
        source.push(cancelToken)
        return axiosPortal.post(`${baseURL}/RemovePartOrderLine`, data, {
            cancelToken: cancelToken.token
        }).finally(() => {
            let index = source.indexOf(cancelToken)
            if (index > -1) {
                source.splice(index, 1)
            }
        })
    },
    SubmitPartOrder: function (data) {
        let cancelToken = axios.CancelToken.source()
        source.push(cancelToken)
        return axiosPortal.post(`${baseURL}/SubmitPartOrder`, data, {
            cancelToken: cancelToken.token
        }).finally(() => {
            let index = source.indexOf(cancelToken)
            if (index > -1) {
                source.splice(index, 1)
            }
        })
    },
    CancelPartOrder: function (data) {
        let cancelToken = axios.CancelToken.source()
        source.push(cancelToken)
        return axiosPortal.post(`${baseURL}/CancelPartOrder`, data, {
            cancelToken: cancelToken.token
        }).finally(() => {
            let index = source.indexOf(cancelToken)
            if (index > -1) {
                source.splice(index, 1)
            }
        })
    },
    LockPartOrder: function (pickslipKey, lockIdentifier) {
        let cancelToken = axios.CancelToken.source()
        source.push(cancelToken)
        return axiosPortal.post(`${baseURL}/LockPartOrder?pickslipKey=${pickslipKey}&lockIdentifier=${lockIdentifier}`, null, {
            cancelToken: cancelToken.token
        }).finally(() => {
            let index = source.indexOf(cancelToken)
            if (index > -1) {
                source.splice(index, 1)
            }
        })
    },
    UnlockPartOrder: function (pickslipKey, lockIdentifier) {
        let cancelToken = axios.CancelToken.source()
        source.push(cancelToken)
        return axiosPortal.post(`${baseURL}/UnlockPartOrder?pickslipKey=${pickslipKey}&lockIdentifier=${lockIdentifier}`, null, {
            cancelToken: cancelToken.token
        }).finally(() => {
            let index = source.indexOf(cancelToken)
            if (index > -1) {
                source.splice(index, 1)
            }
        })
    },
    GetPartOrder: function (pickslipNo) {
        let cancelToken = axios.CancelToken.source()
        source.push(cancelToken)
        return axiosPortal.get(`${baseURL}/partOrder?PickslipNo=${pickslipNo}`, {
            cancelToken: cancelToken.token
        }).finally(() => {
            let index = source.indexOf(cancelToken)
            if (index > -1) {
                source.splice(index, 1)
            }
        })
    },
    getPickslips: function (query, pageNumber, pageSize, sort, getAllData = false) {
        let cancelToken = axios.CancelToken.source()
        source.push(cancelToken)
        let url = `${baseURL}/PickslipSearch?PageNumber=${pageNumber}&PageSize=${pageSize}&Sort=${sort}&GetAllPickslip=${getAllData}`
        if (query.PickslipNo) {
            url += `&PickslipNo=${query.PickslipNo}`
        }
        if (query.OrderNo) {
            url += `&OrderNo=${query.OrderNo}`
        }
        if (query.FromPickslipDate) {
            let dateFormat = moment(query.FromPickslipDate).format(Constant.APIDateFormat)
            url += `&FromPickslipDate=${dateFormat}`;
        }
        if (query.ToPickslipDate) {
            let dateFormat = moment(query.ToPickslipDate).format(Constant.APIDateFormat)
            url += `&ToPickslipDate=${dateFormat}`;
        }
        if (query.PartNumber) {
            url += `&PartNumber=${query.PartNumber}`
        }
        if (query.PartDescription) {
            url += `&PartDescription=${query.PartDescription}`
        }

        return axiosPortal.get(url, {
            cancelToken: cancelToken.token
        }).finally(() => {
            let index = source.indexOf(cancelToken)
            if (index > -1) {
                source.splice(index, 1)
            }
        })
    },
    getPartOrderSummary: function () {
        let cancelToken = axios.CancelToken.source()
        source.push(cancelToken)
        return axiosPortal.get(`${baseURL}/GetPartOrderSummary`, {
            cancelToken: cancelToken.token
        }).finally(() => {
            let index = source.indexOf(cancelToken)
            if (index > -1) {
                source.splice(index, 1)
            }
        })
    },

    cancelRequest: function () {
        if (source && source.length > 0) {
            for (var i = 0; i < source.length; i++) {
                source[i].cancel('Operation canceled.')
            }
            source.splice(0, source.length)
        }
    },
    getDealerPartDeliveryAddress: function (language = "en") {
        let cancelToken = axios.CancelToken.source()
        source.push(cancelToken)
        return axiosPortal.get(`${baseURL}/GetDealerPartDeliveryAddress?language=${language}`, {
            cancelToken: cancelToken.token
        }).finally(() => {
            let index = source.indexOf(cancelToken)
            if (index > -1) {
                source.splice(index, 1)
            }
        })
    },
    getDeliveryAddressDropdownData: function (dropdownDataTableType = null, language = "en") {
        let cancelToken = axios.CancelToken.source()
        source.push(cancelToken)
        let url = `${baseURL}/GetDeliveryAddressDropdownData?language=${language}`;
        if (dropdownDataTableType)
            url += `&dropdownDataTableType=${dropdownDataTableType}`;
        return axiosPortal.get(url, {
            cancelToken: cancelToken.token
        }).finally(() => {
            let index = source.indexOf(cancelToken)
            if (index > -1) {
                source.splice(index, 1)
            }
        })
    },
    getSearchPartOrders: function (pageNumber, pageSize, sort, statusCode, requestedDateFrom, requestedDateTo, stockKey, orderType) {
        let cancelToken = axios.CancelToken.source();
        source.push(cancelToken);
        let url = `${baseURL}/GetSearchPartOrdersByStockKey?PageNumber=${pageNumber}&PageSize=${pageSize}`;

        if (stockKey) {
            url += `&StockKey=${stockKey}`;
        }
        if (statusCode) {
            url += `&StatusCode=${statusCode}`;
        }
        if (requestedDateFrom) {
            let dateFormat = moment(requestedDateFrom).format("YYYY-MM-DD")
            url += `&DateFrom=${dateFormat}`;
        }
        if (requestedDateTo) {
            let dateFormat = moment(requestedDateTo).format("YYYY-MM-DD")
            url += `&DateTo=${dateFormat}`;
        }
        if (sort) {
            url += `&Sort=${sort}`;
        }
        if (orderType) {
            url += `&OrderType=${orderType}`;
        }
        return axiosPortal.get(url, {
            cancelToken: cancelToken.token
        }).finally(() => {
            let index = source.indexOf(cancelToken)
            if (index > -1) {
                source.splice(index, 1)
            }
        })
    },
    getInvoiceSearchData: function (invoiceNo,
        pickslipNo,
        orderNo,
        invoicedDateFrom,
        invoicedDateTo,
        pageNumber, pageSize, sort) {
        let cancelToken = axios.CancelToken.source()
        source.push(cancelToken)
        let url = `${baseURL}/GetInvoiceSearchData?PageNumber=${pageNumber}&PageSize=${pageSize}&Sort=${sort}`
        if (invoiceNo) {
            url += `&InvoiceNo=${invoiceNo}`
        }
        if (pickslipNo) {
            url += `&PickslipNo=${pickslipNo}`
        }
        if (orderNo) {
            url += `&OrderNo=${orderNo}`
        }
        if (invoicedDateFrom) {
            let dateFormat = moment(invoicedDateFrom).format(Constant.APIDateFormat)
            url += `&InvoicedDateFrom=${dateFormat}`
        }
        if (invoicedDateTo) {
            let dateFormat = moment(invoicedDateTo).format(Constant.APIDateFormat)
            url += `&InvoicedDateTo=${dateFormat}`
        }

        return axiosPortal.get(url, {
            cancelToken: cancelToken.token
        }).finally(() => {
            let index = source.indexOf(cancelToken)
            if (index > -1) {
                source.splice(index, 1)
            }
        })
    },
    getPartInvoiceData: function (pickslipKey, invoiceNo, debtorTranKeyList) {
        let cancelToken = axios.CancelToken.source()
        source.push(cancelToken)
        let url = `${baseURL}/GetPartInvoiceData?pickslipKey=${pickslipKey}&invoiceNo=${invoiceNo}&debtorTranKeyList=${debtorTranKeyList}`
        return axiosPortal.get(url, {
            cancelToken: cancelToken.token
        }).finally(() => {
            let index = source.indexOf(cancelToken)
            if (index > -1) {
                source.splice(index, 1)
            }
        })
    },
    getCreditNoteReasonCodes: function () {
        let cancelToken = axios.CancelToken.source()
        source.push(cancelToken)
        let url = `${baseURL}/CreditNoteReasonCodes`

        return axiosPortal.get(url, {
            cancelToken: cancelToken.token
        }).finally(() => {
            let index = source.indexOf(cancelToken)
            if (index > -1) {
                source.splice(index, 1)
            }
        })
    },
    getDebtorPartSundryChargeList: function () {
        let cancelToken = axios.CancelToken.source();
        source.push(cancelToken);
        let url = `${baseURL}/GetDebtorPartSundryChargeList`;
        return axiosPortal.get(url, {
            cancelToken: cancelToken.token
        }).finally(() => {
            let index = source.indexOf(cancelToken)
            if (index > -1) {
                source.splice(index, 1)
            }
        })
    },
    requestCreditParts: function (data) {
        let cancelToken = axios.CancelToken.source()
        source.push(cancelToken)
        return axiosPortal.post(`${baseURL}/RequestCreditParts`, data, {
            cancelToken: cancelToken.token
        }).finally(() => {
            let index = source.indexOf(cancelToken)
            if (index > -1) {
                source.splice(index, 1)
            }
        })
    },
    getDisplayBackOrderEtaDates: function () {
        let cancelToken = axios.CancelToken.source()
        source.push(cancelToken)
        let url = `${baseURL}/DisplayBackOrderEtaDates`

        return axiosPortal.get(url, {
            cancelToken: cancelToken.token
        }).finally(() => {
            let index = source.indexOf(cancelToken)
            if (index > -1) {
                source.splice(index, 1)
            }
        })
    },
    getPartSundryInvoiceSearch: function (invoiceNo) {
        let cancelToken = axios.CancelToken.source()
        source.push(cancelToken)
        let url = `${baseURL}/GetPartSundryInvoiceSearch?InvoiceNo=${invoiceNo}`;

        return axiosPortal.get(url, {
            cancelToken: cancelToken.token
        }).finally(() => {
            let index = source.indexOf(cancelToken)
            if (index > -1) {
                source.splice(index, 1)
            }
        })
    },
    getSystemAddressConfig: async function () {
        let cancelToken = axios.CancelToken.source()
        source.push(cancelToken)
        let url = `${baseURL}/GetSystemAddressConfig`;

        return axiosPortal.get(url, {
            cancelToken: cancelToken.token
        }).finally(() => {
            let index = source.indexOf(cancelToken)
            if (index > -1) {
                source.splice(index, 1)
            }
        })
    }
}