import React, { useEffect, useState } from 'react';
import './partEnquiry.scss';
import { useTranslation } from 'react-i18next';
import { Constant, CommonService, BuyerService, StorageService } from './../../../services/services'
import { Table, Button, Row, Col, Select, Form, Tabs, Breadcrumb, Card, Collapse, Tooltip } from 'antd';
import LoadingContext from './../../../components/utils/loading/loadingContext'
import { GetTableConfigs, StatusCell, TextAndButtonCell } from './../../../components/utils/gridUtils/gridUtils'
import PartSearch from './../../buyer/partSearch/partSearch'
import SearchInput from './../../../components/searchInput/searchInput'
import { useLocation } from "react-router-dom";
import { ChevronRightIcon, ViewIcon, CopyIcon } from '../../../components/icons/icons';
import { useGlobalState } from '../../../utilities/globalState';
import { DownOutlined, UpOutlined } from '@ant-design/icons'
const layout = {
    labelCol: { span: 24 },
    wrapperCol: { span: 24 }
};
const { Option } = Select;
const { TabPane } = Tabs;
const { Panel } = Collapse;

const PartEnquiry = (props) => {
    const partTab = {
        SupercessionTab: 1,
        PriceInfoTab: 2,
        SalesData: 3,
        KitComponent: 4
    }
    const { t } = useTranslation();
    const { showLoading, dismissLoading } = React.useContext(LoadingContext)
    const [makeList, setMakeList] = useGlobalState('makeList')
    const [form] = Form.useForm();
    const [visibleModal, setVisibleModal] = useState(false);
    const [partSearchData, setPartSearchData] = useState([])
    const [stockData, setStockData] = useState(null);
    const [specialPriceTypeData, setSpecialPriceTypeData] = useState("");
    const [stockKey, setStockKey] = useState(null);
    const [taxIncluded, setTaxIncluded] = useState(true);
    const [nonRecordPart, setNonRecordPart] = useState(null);
    const [isCollapsedPartQueryDetail, setIsCollapsedPartQueryDetail] = useGlobalState('isCollapsedPartQueryDetail')
    const [activeTabs, setActiveTabs] = useState([])
    const [activeTabKey, setActiveTabKey] = useState(partTab.PriceInfoTab.toString());

    const curPeriodID = 'Cur'
    const salesMonths = [curPeriodID, '-1', '-2', '-3', '-4', '-5', '-6', '-7', '-8', '-9', '-10', '-11', '-12']
    let authData = StorageService.getAuthData();

    const getInitialSalesColumns = (columnList) => {
        let tempList = []
        tempList.push({
            title: '',
            dataIndex: 'Sales',
            key: 'Sales',
            sorter: false,
            render: (value, record) =>
            (
                <span>{t('part_enquiry.sales')}</span>
            )
        })
        for (let i = 0; i < columnList.length; i++) {
            tempList.push({
                title: columnList[i] == curPeriodID ? t('common.current') : columnList[i],
                dataIndex: columnList[i],
                key: columnList[i],
                sorter: false,
                align: 'center'
            })
        }
        return tempList
    }
    const [salesDataColumns, setSalesDataColumns] = useState(getInitialSalesColumns(salesMonths));

    const location = useLocation();
    const params = location.state;
    const bitwiseNumber = Constant.PartSearchTypeBitwise.GetHoldingBay +
        Constant.PartSearchTypeBitwise.GetStockHistoryInfo +
        Constant.PartSearchTypeBitwise.GetDebtorPartPriceType +
        Constant.PartSearchTypeBitwise.GetDebtorPricingOverrideBySalesOrderType +
        Constant.PartSearchTypeBitwise.GetDebtorStore +
        Constant.PartSearchTypeBitwise.GetMultiStore +
        Constant.PartSearchTypeBitwise.GetSupercede +
        Constant.PartSearchTypeBitwise.GetPrices +
        Constant.PartSearchTypeBitwise.GetShipmentQty +
        Constant.PartSearchTypeBitwise.GetCustomerStockSpecialPrice +
        Constant.PartSearchTypeBitwise.GetSalesOrderType +
        Constant.PartSearchTypeBitwise.AccumulateQuantityAvailable +
        Constant.PartSearchTypeBitwise.GetKitParts +
        Constant.PartSearchTypeBitwise.GetPricePerUnit +
        Constant.PartSearchTypeBitwise.GetStockTradeClubPrice;

    const calculationType = {
        ActualPrice: 1,
        DiscountOffListPrice: 2,
        CustomerPriceType: 3
    }

    const resetFilter = () => {
        form.setFieldsValue({
            ["MakeId"]: "",
            ["PartItemCode"]: ""
        });
        setStockData(null)
        setStockKey(null)
        setSpecialPriceTypeData("")
        setPartSearchData([])
        setNonRecordPart(null)
        setSalesDataColumns(getInitialSalesColumns(salesMonths))
    }

    const onSearch = (data) => {
        getSearchStock(data.PartItemCode, data.MakeId)
    }

    const getSearchStock = (partItemCode, makeId) => {
        setPartSearchData([])
        showLoading()
        BuyerService.getSearchStock(partItemCode, makeId)
            .finally(() => dismissLoading())
            .then(result => {
                if (!result.data || result.data.length == 0) {
                    CommonService.presentToast("warning", t("part_enquiry.part_not_found"))
                    resetFilter()
                    return
                }
                if (result.data.length == 1 && !result.data[0].IsNonStockRecord) {
                    getPartDetails(result.data[0].StockKey)
                }
                else {//show part search
                    setPartSearchData(result.data)
                    showPartSearch()
                }
            })
            .catch(error => CommonService.handleErrorResponse(error))
    }

    const getPartDetails = (stockKey, partNumber = null, makeId = null) => {
        showLoading()
        let bitwise = bitwiseNumber
        if (partNumber) {
            bitwise += Constant.PartSearchTypeBitwise.CreateNonStockRecord
        }
        BuyerService.getPartDetails(stockKey, bitwise, partNumber, makeId)
            .finally(() => dismissLoading())
            .then(result => {
                if (result.data) {
                    if (result.data?.StockHistoryData && result.data.StockHistoryData.length > 0) {
                        let saleObject = {}
                        let saleColumnList = []
                        result.data.StockHistoryData.forEach(item => {
                            saleColumnList.push(item.PeriodID)
                            saleObject[item.PeriodID] = item.StockUsage;
                        });
                        result.data.SalesData = [saleObject]
                        setSalesDataColumns(getInitialSalesColumns(saleColumnList))
                    }

                    setStockData(result.data)
                    if (result.data.SpecialPriceTypeList && result.data.SpecialPriceTypeList.length > 0)
                        setSpecialPriceTypeData(result.data.SpecialPriceTypeList[0].Type)
                    else
                        setSpecialPriceTypeData("")

                }
            })
            .catch(error => CommonService.handleErrorResponse(error))
    }

    const showPartSearch = () => {
        setVisibleModal(true)
    }

    const handleCancel = (objectData) => {
        setVisibleModal(false)
        if (objectData) {
            handleSelectedPart(objectData)
        }
    };

    const handleSelectedPart = (objectData) => {
        if (!objectData.IsNonStockRecord) {
            setStockKey(objectData.StockKey)
        } else {
            setNonRecordPart(objectData)
        }
    }

    const getKitAvailableQty = (stockKits) => {
        if (stockKits && stockKits.length > 0) {
            return Math.min(...stockKits.map(m => Math.floor(m.AvailableQty / m.KitQty)));
        }
        return '_';
    }

    const getStockKitTotalPrice = (stockKits) => {
        if (stockKits && stockKits.length > 0) {
            let result = taxIncluded ? stockKits.map(m => m.TotalPriceTaxInclusive).reduce((a, b) => a + b, 0) : stockKits.map(m => m.TotalPriceTaxExclusive).reduce((a, b) => a + b, 0);
            return result.toFixed(Constant.NumberOfDecimalPlace)
        }
        return '_';
    }

    const supercessionPartColumns = [
        {
            title: t('part_enquiry.supercession_part'),
            dataIndex: 'PartItemCode',
            key: 'PartItemCode',
            sorter: false,
            ellipsis: true,
            render: (value, record) =>
            (
                isViewOnly() ? <span>{record.PartItemCode}</span> :
                    <TextAndButtonCell value={record.PartItemCode} tooltipText={t("common.view_part_details")}
                        buttonType={Constant.ButtonWithModalType.PartEnquiry}
                        data={record} icon={<ViewIcon />} includeWhiteSpaces={true} />
            )

        },
        {
            title: t('part_enquiry.available'),
            dataIndex: 'AvailableQty',
            key: 'AvailableQty',
            sorter: false,
            ellipsis: true,
            width: 100,
        },
        {
            title: t('part_enquiry.make'),
            dataIndex: 'Make',
            key: 'Make',
            sorter: false,
            ellipsis: true,
        }
    ]

    const priceInfoColumns = [
        {
            title: t('part_enquiry.price_type'),
            dataIndex: 'Type',
            key: 'Type',
            sorter: false,
            ellipsis: true
        },
        {
            title: t('part_enquiry.price_exclusive'),
            dataIndex: 'TaxExc',
            key: 'TaxExc',
            sorter: false,
            ellipsis: true,
            width: 110,
            render: (value, record) => (
                <span>{CommonService.amountFormat(record.TaxExc)}</span>
            )
        },
        {
            title: t('part_enquiry.price_inclusive'),
            dataIndex: 'TaxInc',
            key: 'TaxInc',
            sorter: false,
            ellipsis: true,
            width: 110,
            render: (value, record) => (
                <span>{CommonService.amountFormat(record.TaxInc)}</span>
            )
        }
    ]

    const orderPriceTypeColumns = [
        {
            title: t('part_enquiry.sales_order_type'),
            dataIndex: 'SalesOrderType',
            key: 'SalesOrderType',
            sorter: false,
            ellipsis: true,
            render: (value, record) => {
                let text = `${record.SalesOrderType} - ${record.SalesOrderTypeDescription}`
                return <span title={text}>{text}</span>
            }
        },
        {
            title: t('part_enquiry.price_exclusive'),
            dataIndex: 'PriceTaxExclusive',
            key: 'PriceTaxExclusive',
            sorter: false,
            ellipsis: true,
            width: 110,
            render: (value, record) => (
                <span>{CommonService.amountFormat(record.PriceTaxExclusive)}</span>
            )
        },
        {
            title: t('part_enquiry.price_inclusive'),
            dataIndex: 'PriceTaxInclusive',
            key: 'PriceTaxInclusive',
            sorter: false,
            ellipsis: true,
            width: 110,
            render: (value, record) => (
                <span>{CommonService.amountFormat(record.PriceTaxInclusive)}</span>
            )
        }
    ]

    const kitPartColumns = [
        {
            title: t('part_enquiry.part_number'),
            dataIndex: 'PartItemCode',
            key: 'PartItemCode',
            sorter: false,
            ellipsis: true,
            width: 150,
            render: (value, record) => (
                <TextAndButtonCell value={record.PartItemCode} tooltipText={t("common.view_part_details")}
                    buttonType={Constant.ButtonWithModalType.PartEnquiry}
                    data={record} icon={<ViewIcon />} includeWhiteSpaces={true} />
            )
        },
        {
            title: t('part_enquiry.description'),
            dataIndex: 'Description',
            key: 'Description',
            sorter: false,
            ellipsis: true,
            width: 200,
        },
        {
            title: t('part_enquiry.make'),
            dataIndex: 'MakeID',
            key: 'MakeID',
            sorter: false,
            ellipsis: true,
            width: 100,
        },
        {
            title: t('part_enquiry.qty'),
            dataIndex: 'KitQty',
            key: 'KitQty',
            sorter: false,
            ellipsis: true,
            width: 100,
            align: 'right',
        },
        {
            title: t('part_enquiry.available'),
            dataIndex: 'AvailableQty',
            key: 'AvailableQty',
            sorter: false,
            ellipsis: true,
            width: 100,
            align: 'right',
        },
        {
            title: t('part_enquiry.unit_price_inclusive'),
            dataIndex: 'PricePerUnitTaxInclusive',
            key: 'PricePerUnitTaxInclusive',
            sorter: false,
            ellipsis: true,
            isHiden: !taxIncluded,
            width: 100,
            align: 'right',
            render: (value, record) => (
                <span>{CommonService.amountFormat(record.PricePerUnitTaxInclusive)}</span>
            )
        },
        {
            title: t('part_enquiry.unit_price_exclusive'),
            dataIndex: 'PricePerUnitTaxExclusive',
            key: 'PricePerUnitTaxExclusive',
            sorter: false,
            ellipsis: true,
            isHiden: taxIncluded,
            width: 100,
            align: 'right',
            render: (value, record) => (
                <span>{CommonService.amountFormat(record.PricePerUnitTaxExclusive)}</span>
            )
        },
        {
            title: t('part_enquiry.total_price_inclusive'),
            dataIndex: 'TotalPriceTaxInclusive',
            key: 'TotalPriceTaxInclusive',
            sorter: false,
            ellipsis: true,
            isHiden: !taxIncluded,
            width: 100,
            align: 'right',
            render: (value, record) => (
                <span>{CommonService.amountFormat(record.TotalPriceTaxInclusive)}</span>
            )
        },
        {
            title: t('part_enquiry.total_price_exclusive'),
            dataIndex: 'TotalPriceTaxExclusive',
            key: 'TotalPriceTaxExclusive',
            sorter: false,
            ellipsis: true,
            isHiden: taxIncluded,
            width: 100,
            align: 'right',
            render: (value, record) => (
                <span>{CommonService.amountFormat(record.TotalPriceTaxExclusive)}</span>
            )
        },
    ]

    const getPriceInfoData = () => {
        if (stockData) {
            let priceList = []
            for (let i = 0; i < stockData.PriceTypes.length; i++) {
                let price = {
                    Type: stockData.PriceTypes[i],
                    TaxInc: stockData.PricesTaxInclusive[i],
                    TaxExc: stockData.PricesTaxExclusive[i]
                }
                priceList.push(price);
            }
            if (stockData.TradeClubPriceInformation && stockData.TradeClubPriceInformation.length > 0) {
                stockData.TradeClubPriceInformation.forEach(p => {
                    priceList.push({
                        Type: p.MakeID + " " + t('part_enquiry.trade_club'),
                        TaxInc: p.TradeClubPriceTaxInclusive,
                        TaxExc: p.TradeClubPriceTaxExclusive
                    });
                });
            }
            return priceList;
        }
        return [];
    }

    const tableCard = (title, columns, data, rowKey, tableClass = "", hidden = false, tableSummary = null) => {
        columns = columns.filter(f => !f.isHiden);
        let tableConfig = GetTableConfigs(columns, data, data?.length, rowKey, null, 1, tableClass, false);
        if (tableSummary) {
            tableConfig["summary"] = tableSummary;
        }
        return (
            <>
                <label hidden={hidden} className="bold-text card-title-text-color">{title}</label>
                <Card hidden={hidden} bordered={false}>
                    <Table {...tableConfig} />
                </Card>
            </>
        );
    }

    const oldSpecialPriceColumns = [
        {
            title: t('part_enquiry.min_qty'),
            dataIndex: 'Qty',
            key: 'Qty',
            sorter: false,
            ellipsis: true,
            width: 80,
            calculationTypes: [calculationType.ActualPrice, calculationType.DiscountOffListPrice, calculationType.CustomerPriceType]
        },
        {
            title: t('part_enquiry.price_type'),
            dataIndex: 'PriceTypeDescription',
            key: 'PriceTypeDescription',
            sorter: false,
            ellipsis: true,
            calculationTypes: [calculationType.CustomerPriceType]
        },
        {
            title: specialPriceTypeData == calculationType.ActualPrice ? t('part_enquiry.actual_price') : t('part_enquiry.discount'),
            dataIndex: 'DiscountPriceCalculated',
            key: 'DiscountPriceCalculated',
            sorter: false,
            ellipsis: true,
            width: 100,
            calculationTypes: [calculationType.ActualPrice, calculationType.DiscountOffListPrice, calculationType.CustomerPriceType],
            render: (value, record) => (
                <span>{CommonService.amountFormat(record.DiscountPriceCalculated)}</span>
            )
        },
        {
            title: t('part_enquiry.surcharge'),
            dataIndex: 'SurchargePriceCalculated',
            key: 'SurchargePriceCalculated',
            sorter: false,
            ellipsis: true,
            width: 100,
            calculationTypes: [calculationType.DiscountOffListPrice, calculationType.CustomerPriceType],
            render: (value, record) => (
                <span>{CommonService.amountFormat(record.SurchargePriceCalculated)}</span>
            )
        }
    ]

    const specialPriceColumns = [
        {
            title: t('part_enquiry.min_qty'),
            dataIndex: 'Qty',
            key: 'Qty',
            sorter: false,
            width: 80,
        },
        {
            title: t('part_enquiry.price_exclusive'),
            dataIndex: 'PriceTaxExclusive',
            key: 'PriceTaxExclusive',
            sorter: false,
            render: (value, record) => (
                <span>{CommonService.amountFormat(record.PriceTaxExclusive)}</span>
            )
        },
        {
            title: t('part_enquiry.price_inclusive'),
            dataIndex: 'PriceTaxInclusive',
            key: 'PriceTaxInclusive',
            sorter: false,
            render: (value, record) => (
                <span>{CommonService.amountFormat(record.PriceTaxInclusive)}</span>
            )
        }
    ]

    const getSpecialPriceColumns = () => {
        if (CommonService.dmsVersionGreaterOrEqual(Constant.DmsVersion.version_1_36_8_0)) {
            return specialPriceColumns
        } else {
            if (specialPriceTypeData) {
                let temp = oldSpecialPriceColumns
                return temp.filter(x => x.calculationTypes.includes(specialPriceTypeData))
            }
            return oldSpecialPriceColumns
        }
    }

    const specialPriceTypeChange = (value) => {
        setSpecialPriceTypeData(value)
    }

    const getStockSpecialPriceBySpecialPriceType = (type) => {
        if (stockData) {
            var item = stockData.CustomerStockSpecialPriceList.filter(x => x.SpecialPriceType == type)
            if (item && item.length > 0)
                return item[0]
        }
        return null
    }

    const getStockSpecialPriceByQtyList = (specialPriceType) => {
        var item = getStockSpecialPriceBySpecialPriceType(specialPriceType)
        if (item) {
            if (CommonService.dmsVersionGreaterOrEqual(Constant.DmsVersion.version_1_36_8_0)) {
                if (item.IsQtyBased) {
                    return item.CustomerStockSpecialPriceByQtyList
                } else {
                    return [
                        {
                            Qty: 1,
                            PriceTaxExclusive: item.PriceTaxExclusive,
                            PriceTaxInclusive: item.PriceTaxInclusive
                        }
                    ]
                }
            } else {
                return item.CustomerStockSpecialPriceByQtyList
            }
        }
        return []
    }

    const panes = [
        {
            title: t("part_enquiry.price_info"),
            content:
                <>
                    <Row gutter={[30, 20]} className="p-b-10 equal-height-row">
                        <Col xs={{ span: 8 }}>
                            {tableCard(t("part_enquiry.order_price_type"), orderPriceTypeColumns, stockData?.DebtorPricingOverrideBySalesOrderTypeList, "SalesOrderType", "expand-table")}
                        </Col>
                        <Col xs={{ span: 8 }}>
                            {tableCard(t("part_enquiry.pricing"), priceInfoColumns, getPriceInfoData(), "Type", "expand-table")}
                        </Col>
                        <Col xs={{ span: 8 }}>
                            <Row hidden={!specialPriceTypeData}>
                                <Col xs={{ span: 24 }}>
                                    <label className="bold-text card-title-text-color">{t("part_enquiry.special_price")}</label>
                                </Col>
                                <Col xs={{ span: 24 }}>
                                    <Card bordered={false}>
                                        <Row gutter={[30, 15]}>
                                            <Col xs={{ span: 24 }} xxl={{ span: 14 }} hidden={true}>
                                                <label className="title-field m-l-10">{t("part_enquiry.calculation_type")}</label>
                                                <Select className="full-width" value={specialPriceTypeData} onChange={v => specialPriceTypeChange(v)}>
                                                    {
                                                        stockData?.SpecialPriceTypeList.map((n, index) => <Option key={index} value={n.Type}>{n.Description}</Option>)
                                                    }
                                                </Select>
                                            </Col>
                                            <Col xs={{ span: 24 }} className="flex-items">
                                                <label className="title-field">{t("part_enquiry.applied_date_range")}</label>
                                                <label className="text-field m-l-20 m-b-0" hidden={!specialPriceTypeData}>
                                                    {CommonService.getDateString(getStockSpecialPriceBySpecialPriceType(specialPriceTypeData)?.DateEffectiveFrom) || '_'}&nbsp;-&nbsp;
                                                    {CommonService.getDateString(getStockSpecialPriceBySpecialPriceType(specialPriceTypeData)?.DateEffectiveTo) || '_'}
                                                </label>
                                            </Col>
                                            <Col xs={{ span: 24 }} hidden={!specialPriceTypeData}>
                                                <Table
                                                    {...GetTableConfigs(getSpecialPriceColumns(), getStockSpecialPriceByQtyList(specialPriceTypeData), getStockSpecialPriceByQtyList(specialPriceTypeData).length, "CustomerStockSpecialPriceByQtyKey", null, 1, "fix-height-table", false)}
                                                />
                                            </Col>
                                        </Row>
                                    </Card>
                                </Col>
                            </Row>
                        </Col>
                    </Row>
                </>
            , key: partTab.PriceInfoTab
        },
        {
            title: t("part_enquiry.supercession_alternate"),
            content:
                <>
                    <Row gutter={[30, 20]} className="p-b-10 equal-height-row">
                        <Col xs={{ span: 8 }}>
                            {tableCard(t("part_enquiry.backward_supercession"), supercessionPartColumns, stockData?.SupercededTo, "StockKey", "fix-height-table")}
                        </Col>
                        <Col xs={{ span: 8 }}>
                            {tableCard(t("part_enquiry.forward_supercession"), supercessionPartColumns, stockData?.SupercededBy, "StockKey", "fix-height-table")}
                        </Col>
                        <Col xs={{ span: 8 }}>
                            {tableCard(t("part_enquiry.alternate_list"), supercessionPartColumns, stockData?.AlternatePart, "StockKey", "fix-height-table")}
                        </Col>
                    </Row>
                </>
            , key: partTab.SupercessionTab
        },
        {
            title: t("part_enquiry.sales_data"),
            content:
                <>
                    <Row gutter={[30, 20]} className="p-b-10 equal-height-row">
                        <Col xs={{ span: 24 }}>
                            {tableCard(t("part_enquiry.sales_data"), salesDataColumns, stockData?.SalesData, curPeriodID, "sale-table")}
                        </Col>
                    </Row>
                </>
            , key: partTab.SalesData
        },
        {
            title: t("part_enquiry.kit_component"),
            content:
                <>
                    <Row gutter={[30, 20]} className="p-b-10 equal-height-row">
                        <Col xs={{ span: 24 }}>
                            {tableCard(t("part_enquiry.kit_component"), kitPartColumns, stockData?.StockKits, "StockKey", "fix-height-table", false,
                                () => (
                                    <Table.Summary.Row>
                                        <Table.Summary.Cell></Table.Summary.Cell>
                                        <Table.Summary.Cell></Table.Summary.Cell>
                                        <Table.Summary.Cell></Table.Summary.Cell>
                                        <Table.Summary.Cell className="text-right">
                                            <label className="title-field bold-text card-title-text-color">{t("part_enquiry.kit_available")}</label>
                                        </Table.Summary.Cell>
                                        <Table.Summary.Cell className="text-right">
                                            <label className="text-field m-l-20 card-title-text-color">{stockData?.StockKits && getKitAvailableQty(stockData?.StockKits)}</label>
                                        </Table.Summary.Cell>
                                        <Table.Summary.Cell className="text-right">
                                            <label className="title-field bold-text card-title-text-color">{t("part_enquiry.kit_total")}</label>
                                        </Table.Summary.Cell>
                                        <Table.Summary.Cell className="text-right">
                                            <label className="text-field m-l-20 card-title-text-color">{stockData?.StockKits && getStockKitTotalPrice(stockData?.StockKits)}</label>
                                        </Table.Summary.Cell>
                                    </Table.Summary.Row>
                                )
                            )}
                        </Col>

                    </Row>
                    {/* <Row gutter={[30, 20]} className="p-b-10 equal-height-row p-r-15">
                        <Col xs={{ span: 8 }}></Col>
                        <Col xs={{ span: 16 }} className="flex-items right">
                            <div className="total-qty flex-items right"></div>
                            <div className="total-price flex-items right"></div>
                        </Col>
                    </Row> */}
                </>
            , key: partTab.KitComponent
        },
    ];

    const getStatusCode = () => {
        if (stockData) {
            return (
                <StatusCell
                    colorClass={CommonService.getStatusColorClass(stockData.StockStoreStatusCode)}
                    description={CommonService.getStatusDescription(stockData.StockStoreStatusCode, false, t("common.current"), t("common.close"))} />
            )
        }
        return ""
    }

    const isViewOnly = () => {
        if ((!params || !params.StockKey || !params.PreviousUrl) && !props.stockKey)
            return false
        return true
    }

    const getSearchFields = () => {
        return form.getFieldsValue()
    }

    const collapse = (key) => {
        if (!key || key.length == 0)
            setIsCollapsedPartQueryDetail(true)
        else
            setIsCollapsedPartQueryDetail(false)
    }

    const customExpandIcon = (props) => {
        if (props.isActive) {
            return <UpOutlined />

        } else {
            return <DownOutlined />
        }
    }

    const copyPartNumberToClipboard = (e, partNumber) => {
        e.stopPropagation()
        if (partNumber) {
            navigator.clipboard.writeText(partNumber)
            CommonService.presentToast("info", t("part_enquiry.copied_part_number_id"))
        }

    }

    useEffect(() => {
        if (!makeList || makeList.length == 0) {
            showLoading()
            BuyerService.getMakeList()
                .finally(() => {
                    dismissLoading()
                })
                .then(result => {
                    if (result.data) {
                        setMakeList(result.data)
                    }
                })
                .catch(error => CommonService.handleErrorResponse(error))
        }
        return () => {
            BuyerService.cancelRequest()
        };
    }, [])

    useEffect(() => {
        if (params && params.StockKey) {
            setStockKey(params.StockKey)
        } else if (props.stockKey) {
            setStockKey(props.stockKey)
        }
        if (authData) {
            setTaxIncluded(authData.isShowPriceTaxIncluded);
        }
    }, [])

    useEffect(() => {
        if (stockKey) {
            getPartDetails(stockKey)
        }
    }, [stockKey])

    useEffect(() => {
        if (nonRecordPart) {
            getPartDetails(nonRecordPart.StockKey ? nonRecordPart.StockKey : -1, nonRecordPart.PartItemCode, nonRecordPart.Make)
            setNonRecordPart(null)
        }
    }, [nonRecordPart])

    useEffect(() => {
        let hidePanes = [];
        let activeTabKeyChanged = false;
        let tempTabs = [
            partTab.PriceInfoTab,
            partTab.SupercessionTab,
            partTab.SalesData,
            partTab.KitComponent
        ]
        if (stockData) {
            if (!stockData.IsDisplayMonthlyPartSalesQuantities
                || (stockData.IsDisplayMonthlyPartSalesQuantities && stockData.StockKits && stockData.StockKits.length > 0)
            ) {
                hidePanes.push(partTab.SalesData);
            }
            if (stockData.StockKits && stockData.StockKits.length > 0) {
                hidePanes.push(partTab.PriceInfoTab);
            } else {
                hidePanes.push(partTab.KitComponent);
            }
            if ((!stockData.SupercededTo || stockData.SupercededTo.length === 0) &&
                (!stockData.SupercededBy || stockData.SupercededBy.length === 0) &&
                (!stockData.AlternatePart || stockData.AlternatePart.length === 0)
            ) {
                hidePanes.push(partTab.SupercessionTab)
            }
            if (stockData.IsDisplayMonthlyPartSalesQuantities && stockData.StockKits && stockData.StockKits.length > 0) {
                setActiveTabKey(partTab.KitComponent.toString());
                activeTabKeyChanged = true;
            } else if (
                (stockData.SupercededBy && stockData.SupercededBy.length > 0) //Fordward Supercession
                ||
                (stockData.SupercededTo && stockData.SupercededTo.length > 0 && stockData.SupercededTo.some(p => p.AvailableQty > 0)) //Backward Supercession
            ) {
                setActiveTabKey(partTab.SupercessionTab.toString());
                activeTabKeyChanged = true;
            }
        } else {
            hidePanes.push(partTab.KitComponent)
        }

        tempTabs = tempTabs.filter(f => hidePanes.indexOf(f) < 0);
        setActiveTabs(tempTabs);
        if (!activeTabKeyChanged) {
            setActiveTabKey(tempTabs[0].toString());
        }
    }, [stockData])

    const onChangeTab = activeKey => {
        setActiveTabKey(activeKey);
    };

    return (
        <div className="part-enquiry">
            {
                !isViewOnly() &&
                <>
                    <Breadcrumb separator={<ChevronRightIcon />}>
                        <Breadcrumb.Item>{t("menu.part_enquiry")}</Breadcrumb.Item>
                    </Breadcrumb>
                    <Row gutter={[, 20]}>
                        <Col xs={{ span: 24 }}>
                            <Form
                                {...layout}
                                form={form}
                                name="partEnquiryForm"
                                onFinish={onSearch}
                            >
                                <Row align="middle" gutter={[Constant.SpaceConstant.HorizontalGutter, 5]}>
                                    <Col xs={8} lg={8} xl={7} xxl={6}>
                                        <Form.Item
                                            label={t("part_enquiry.make")}
                                            name="MakeId" colon={false} labelAlign="left"
                                        >
                                            <Select showSearch
                                                allowClear={true}
                                                optionFilterProp="children"
                                                filterOption={(input, option) =>
                                                    option?.children.toUpperCase().indexOf(input.toUpperCase()) >= 0
                                                }>
                                                {
                                                    makeList.map((n, index) => <Option key={index} value={n.MakeId}>{n.MakeId}</Option>)
                                                }
                                            </Select>
                                        </Form.Item>
                                    </Col>
                                    <Col xs={7} lg={7} xl={7} xxl={6}>
                                        <Form.Item
                                            label={t("part_enquiry.part_number")}
                                            name="PartItemCode" colon={false} labelAlign="left"
                                            rules={[{ required: true, message: t("part_enquiry.part_number_required_mes") }]}
                                        >
                                            <SearchInput allowClear objectData={getSearchFields()} getSearchFields={getSearchFields}
                                                makeList={makeList} handleSelectedItem={handleSelectedPart}
                                                uppercase={true}></SearchInput>
                                        </Form.Item >
                                    </Col >
                                    <Col xs={9} lg={9} xl={8} xxl={6} className="m-t-10">
                                        <Button size="large" onClick={resetFilter}>{t("common.reset_filter")}</Button>
                                        <Button size="large" className="m-l-10 secondary-btn" htmlType="submit">{t("common.retrieve")}</Button>
                                    </Col>
                                </Row >
                            </Form >
                        </Col >
                    </Row >
                </>
            }
            <Collapse defaultActiveKey={isCollapsedPartQueryDetail ? [] : ['1']} className='collapse-custom'
                expandIconPosition='right'
                expandIcon={(props) => customExpandIcon(props)}
                onChange={collapse}>
                <Panel header={
                    <Row align="middle" gutter={[Constant.SpaceConstant.HorizontalGutter,]}>
                        <Col xs={{ span: 6 }}>
                            <label className="title-field">{t("part_enquiry.make")}</label>
                            <label className="text-field color-blue bold-text">{stockData?.MakeID || '_'}</label>
                        </Col>
                        <Col xs={{ span: 6 }}>
                            <div className='part-container'>
                                <label className="title-field">{t("part_enquiry.part_number")}</label>
                                {
                                    stockData?.PartItemCodeCompressed &&
                                    <Tooltip placement="bottom" title={t("part_enquiry.copy_part_number_id")}>
                                        <Button icon={<CopyIcon />}
                                            className={`m-l-5 action-btn action-btn--light-yellow-color`}
                                            onClick={e => copyPartNumberToClipboard(e, stockData?.PartItemCodeCompressed)} />
                                    </Tooltip>
                                }
                            </div>
                            <label className="text-field included-spaces color-blue bold-text">{stockData?.PartItemCode || '_'}</label>
                        </Col>
                        <Col xs={{ span: 6 }}>
                            <label className="title-field">{t("part_enquiry.part_description")}</label>
                            <label className="text-field color-blue bold-text">{stockData?.Description || '_'}</label>
                        </Col>
                        {(!stockData || !stockData.StockKits || stockData.StockKits.length < 1) && <Col xs={{ span: 6 }} className='available-qty-padding'>
                            <label className="title-field">{t("part_enquiry.quantity_available")}</label>
                            <label className="text-field color-blue bold-text">{(stockData && stockData.AvailableQty != null) ? stockData.AvailableQty : '_'}</label>
                        </Col>}
                    </Row>
                } key="1">
                    <Row gutter={[Constant.SpaceConstant.HorizontalGutter,]}>
                        <Col xs={{ span: 6 }}>
                            <Row align="middle">
                                <Col xs={{ span: 24 }}>
                                    <label className="title-field">{t("part_enquiry.pack_description")}</label>
                                    <label className="text-field">{stockData?.PackDescription || '_'}</label>
                                </Col>
                                <Col xs={{ span: 24 }}>
                                    <label className="title-field">{t("part_enquiry.stock_group")}</label>
                                    <label className="text-field">{stockData?.ManufacturerStockGroupID || '_'}</label>
                                </Col>
                                <Col xs={{ span: 24 }} className="flex-items m-t-10">
                                    <label className="title-field m-r-20">{t("common.status")}</label>
                                    {getStatusCode()}
                                </Col>
                            </Row>
                        </Col>
                        <Col xs={{ span: 6 }}>
                            <Row align="middle">
                                <Col xs={{ span: 24 }}>
                                    <label className="title-field">{t("part_enquiry.pack_quantity")}</label>
                                    <label className="text-field">{(stockData && stockData.SupplierPackQty != null) ? stockData.SupplierPackQty : '_'}</label>
                                </Col>
                                <Col xs={{ span: 24 }}>
                                    <label className="title-field">{t("part_enquiry.price_group")}</label>
                                    <label className="text-field">{stockData?.DiscountGroupID || '_'}</label>
                                </Col>
                                <Col xs={{ span: 24 }}>
                                    <label className="title-field">{t("part_enquiry.introduced")}</label>
                                    <label className="text-field">{stockData?.DateIntroduced || '_'}</label>
                                </Col>
                            </Row>
                        </Col>
                        <Col xs={{ span: 6 }}>
                            <Row align="middle">
                                <Col xs={{ span: 24 }}>
                                    <label className="title-field">{t("part_enquiry.pack_description")}</label>
                                    <label className="text-field">{stockData?.PackDescription || '_'}</label>
                                </Col>
                                <Col xs={{ span: 24 }}>
                                    <label className="title-field">{t("part_enquiry.deposite_amount")}</label>
                                    <label className="text-field">{(stockData && stockData.DepositAmt != null) ? CommonService.amountFormat(stockData.DepositAmt) : '_'}</label>
                                </Col>
                                <Col xs={{ span: 24 }}>
                                    <label className="title-field">{t("part_enquiry.tax_code")}</label>
                                    <label className="text-field">{stockData?.TaxCodeDescription || '_'}</label>
                                </Col>
                            </Row>
                        </Col>
                        <Col xs={{ span: 6 }}>
                            <Row>
                                <Col xs={{ span: 24 }}>
                                    <table className='qty-table'>
                                        {(!authData.isCombineQtyArrivedIntoQtyInTransit) &&
                                            <>
                                                {(!stockData || !stockData.StockKits || stockData.StockKits.length < 1) &&
                                                    <>
                                                        {(!stockData || !stockData.IsCombineQtyArrivedIntoQtyInTransit) &&
                                                            <>
                                                                <tr>
                                                                    <td className="title-field">{t("part_enquiry.quantity_arrived")}</td>
                                                                    <td className="text-field">{(stockData && stockData.ArrivedQty != null) ? stockData.ArrivedQty : '_'}</td>
                                                                </tr>
                                                            </>
                                                        }
                                                    </>
                                                }
                                            </>
                                        }
                                        {(!stockData || !stockData.StockKits || stockData.StockKits.length < 1) &&
                                            <tr>
                                                <td className="title-field">{t("part_enquiry.quantity_in_transit")}</td>
                                                <td className="text-field">{(stockData && stockData.InTransitQty != null) ? stockData.InTransitQty : '_'}</td>
                                            </tr>
                                        }
                                        {(!stockData || !stockData.StockKits || stockData.StockKits.length < 1) &&
                                            <tr>
                                                <td className="title-field">{t("part_enquiry.quantity_on_order")}</td>
                                                <td className="text-field">{(stockData && stockData.QtyOnOrderSupplier != null) ? stockData.QtyOnOrderSupplier : '_'}</td>
                                            </tr>
                                        }
                                        {(!stockData || !stockData.StockKits || stockData.StockKits.length < 1) &&
                                            CommonService.dmsVersionGreaterOrEqual(Constant.DmsVersion.version_1_37_6_0) &&
                                            <tr>
                                                <td className="title-field">{t("part_enquiry.quantity_bo_customer")}</td>
                                                <td className="text-field">{(stockData && stockData.BoCustomerQty != null) ? stockData.BoCustomerQty : '_'}</td>
                                            </tr>
                                        }
                                    </table>
                                </Col>
                            </Row>
                        </Col>
                    </Row>
                </Panel>
                {
                    (!stockData || stockData.IncludeNoteForEcommerce) &&
                    <Panel showArrow={false} header={
                        <Row gutter={[Constant.SpaceConstant.HorizontalGutter, Constant.SpaceConstant.VerticalGutter]}>
                            <Col xs={{ span: 24 }}>
                                <label className="title-field">{t("part_enquiry.notes")}</label>
                                <label className="text-field color-blue bold-text">{stockData?.Notes || '_'}</label>
                            </Col>
                        </Row>
                    } key="2">

                    </Panel>

                }
            </Collapse>
            {
                <Tabs activeKey={activeTabKey} onChange={onChangeTab}>
                    {
                        panes.filter(x => activeTabs.indexOf(x.key) >= 0).map(pane => <TabPane tab={pane.title} key={pane.key}>{pane.content}</TabPane>)
                    }
                </Tabs>
            }
            {
                visibleModal && <PartSearch objectData={form.getFieldsValue()} makeList={makeList} handleCancel={handleCancel} partList={partSearchData}></PartSearch>
            }
        </div >
    )
}

export default PartEnquiry;