import React, { useEffect, useState } from 'react';
import './App.scss';
import Routes from "./utilities/routes";
import { ToastContainer, Slide } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import LoadingProvider from './components/utils/loading/loadingProvider'
import Spinner from './components/utils/loading/spinner/spinner'
import { NotificationService, Constant, AuthService, CommonService, StorageService } from './services/services'
import PubSub from 'pubsub-js'
import { useGlobalState, resetGlobalState } from './utilities/globalState'
import { notification, Modal } from 'antd';
import { useLocation, useHistory } from "react-router-dom";
import { useTranslation } from 'react-i18next';

function App() {
  const { t, i18n } = useTranslation();
  const [notificationMessages, setNotificationMessages] = useGlobalState('notificationMessages')
  const [, setNotificationOption] = useGlobalState('notificationOption')
  const [, setIsActive] = useGlobalState('isActive')
  const [isNewVersion, setIsNewVersion] = useState(false)
  const location = useLocation();
  const history = useHistory();

  var appVersion
  var intervalId
  var connection

  useEffect(() => {
    if (AuthService.hasAuthorized(false)) {
      init()
    }

    applyDealerTheme()
    checkNewVersion()

    var loginToken = PubSub.subscribe(Constant.PubSupType.UserLogin, init);

    var logoutToken = PubSub.subscribe(Constant.PubSupType.UserLogout, stopConnection);

    var refreshToken = PubSub.subscribe(Constant.PubSupType.RefreshToken, refreshConnection);

    var messageToken = PubSub.subscribe(Constant.PubSupType.NotificationMessage, (key, data) => {
      let obj = JSON.parse(data)
      let args = {
        message: 'Notification',
        description: obj.Message,
        duration: 5,
      };
      notification.open(args);

      getNotification(Constant.InitNotificationOption)
    });

    return () => {
      PubSub.unsubscribe(loginToken);
      PubSub.unsubscribe(logoutToken);
      PubSub.unsubscribe(messageToken);
      PubSub.unsubscribe(refreshToken);
      clearInterval(intervalId)
    }
  }, [])

  const checkNewVersion = () => {
    if (!intervalId || intervalId < 0) {
      intervalId = setInterval(() => {
        AuthService.getAppVersion(process.env.REACT_APP_GROUP_ID)
          .then(result => {
            if (result && result.data) {
              if (!appVersion) {
                //setAppVersion(result.data)
                appVersion = result.data
              } else if (appVersion != result.data) {
                setIsNewVersion(true)
              }
            }
          })
      }, 3600000);//1 hour
    }
  }

  const applyDealerTheme = () => {
    let supplierId = null
    let supplierIdParam = location.search.toLowerCase().match("supplierid=(.*)")
    if (supplierIdParam) {
      supplierId = supplierIdParam[1]
      CommonService.applyDealerTheme(supplierId)
    } else {
      let authData = StorageService.getAuthData()
      if (authData) {
        if (authData.accountType == Constant.AccountType.Supplier) {
          CommonService.applyDealerTheme(authData.userId)
        } else {
          CommonService.applyDealerTheme(authData.supplierId)
        }
      }
    }
  }

  const init = () => {
    connection = NotificationService.loadService()
    getNotification(Constant.InitNotificationOption)

    setTimeout(() => {
      var currentUrl = new URL(window.location.href)
      let isOnRedirect = currentUrl.searchParams.get(Constant.UrlParam.IsOnRedirect)
      if (!isOnRedirect) {
        let auth = StorageService.getAuthData()
        if (auth && auth.redirectAppUrl) {
          window.location.reload();
        }
      }
    }, 500)
  }

  const getNotification = (option) => {
    NotificationService.getNotifications(option.pageNumber, option.pageSize, option.sort)
      .then(result => {
        if (result.data) {
          setNotificationMessages(result.data.ObjectList)
          setNotificationOption({
            ...option,
            total: result.data.TotalItems,
            totalUnread: result.data.TotalUnread
          })
        }
      })
  }
  const stopConnection = (key, stayCurrentPage) => {
    StorageService.removeAuthData();
    StorageService.removeShowAvailableQty();
    if (stayCurrentPage != true) {
      history.push(Constant.Pages.Landing);
    }

    setIsActive(true)
    setNotificationMessages([])
    setNotificationOption(Constant.InitNotificationOption)
    resetGlobalState()
    if (connection) {
      connection.stop()
    }
  }

  const refreshConnection = () => {
    if (connection) {
      connection.stop()
    }

    connection = NotificationService.loadService()
  }

  const onReloadPage = () => {
    window.location.reload()
  }

  const onCancelReloadPage = () => {
    setIsNewVersion(false)
  }

  return (
    <div className="App">
      <ToastContainer
        position="top-right"
        transition={Slide}
        autoClose={false}
      />
      <LoadingProvider>
        <Spinner color="#fafafa" />
        <Routes />
      </LoadingProvider>
      <Modal title={t("common.new_version_title")} visible={isNewVersion} onOk={onReloadPage} onCancel={onCancelReloadPage}>
        <p>{t("common.new_version_warning")}</p>
      </Modal>
    </div>
  );
}

export default App;
