import React, { useEffect, useState, forwardRef } from 'react';
import { GoogleMap, Marker, withGoogleMap, withScriptjs } from "react-google-maps"

export const GoogleMapWrapper = withScriptjs(withGoogleMap((props) => {
    return (
        <>
            <GoogleMap
                defaultZoom={15}
                defaultCenter={{ lat: -27.231183609086408, lng: 153.00602289797453 }}
                center={{ lat: props.latitude, lng: props.longitude }}
            >
                <Marker position={{ lat: props.latitude, lng: props.longitude }} />
            </GoogleMap>
        </>

    )
}))

