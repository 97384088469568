import React from 'react';
import { toast } from 'react-toastify';
import i18n from '../i18n';

export const Constant = {
    Pages: {
        SupplierLogin: '/supplier-login',
        BuyerLogin: '/buyer-login',
        DashBoard: '/dashBoard',
        Landing: '/',
        BuyerDashboard: '/bDashboard',
        SupplierDashboard: '/sDashboard',

        SupplierManagement: '/supplier-management',
        PartEnquiry: '/part-enquiry',
        PartOrder: '/part-order',
        RequestCancelPartOrder: '/request-cancel-part-order',
        OrderManagement: '/order-management',
        OrderManagement_PickslipDetails: '/order-management/pickslipDetails',

        PartOrderHistory: '/part-order-history',
        PartOrderHistory_Details: '/part-order-history/details',
        RequestCreditReturn: '/request-credit-return',
        RequestCreditReturn_Edit: '/request-credit-return/edit',
        OutstandingCreditNotesEnquiry: '/outstanding-credit-notes-enquiry',
        OutstandingCreditNotesEnquiry_Details: '/outstanding-credit-notes-enquiry/details',
        BackOrderManagement: '/back-order-management',

        BuyerManagement: '/buyer-management',
        BuyerManagement_EditBuyer: '/buyer-management/edit-buyer',

        CancelPartOrderApproval: '/cancel-part-order-approval',
        SupplierSetting: '/supplier-setting',
        Sample: '/sample'
    },
    ToastTemplate: ({ toastType, text }) => {
        return (
            <div>
                <div className="toast-icon">
                    {
                        toastType === toast.TYPE.ERROR &&
                        <svg aria-hidden="true" height="16" width="12" viewBox="0 0 12 16"><path fillRule="evenodd" d="M5.05.01c.81 2.17.41 3.38-.52 4.31C3.55 5.37 1.98 6.15.9 7.68c-1.45 2.05-1.7 6.53 3.53 7.7-2.2-1.16-2.67-4.52-.3-6.61-.61 2.03.53 3.33 1.94 2.86 1.39-.47 2.3.53 2.27 1.67-.02.78-.31 1.44-1.13 1.81 3.42-.59 4.78-3.42 4.78-5.56 0-2.84-2.53-3.22-1.25-5.61-1.52.13-2.03 1.13-1.89 2.75.09 1.08-1.02 1.8-1.86 1.33-.67-.41-.66-1.19-.06-1.78C8.18 5.01 8.68 2.15 5.05.02L5.03 0l.02.01z"></path></svg>
                    }
                    {
                        toastType === toast.TYPE.INFO &&
                        <svg aria-hidden="true" height="16" width="14" viewBox="0 0 14 16"><path fillRule="evenodd" d="M6.3 5.71a.942.942 0 0 1-.28-.7c0-.28.09-.52.28-.7.19-.18.42-.28.7-.28.28 0 .52.09.7.28.18.19.28.42.28.7 0 .28-.09.52-.28.7a1 1 0 0 1-.7.3c-.28 0-.52-.11-.7-.3zM8 8.01c-.02-.25-.11-.48-.31-.69-.2-.19-.42-.3-.69-.31H6c-.27.02-.48.13-.69.31-.2.2-.3.44-.31.69h1v3c.02.27.11.5.31.69.2.2.42.31.69.31h1c.27 0 .48-.11.69-.31.2-.19.3-.42.31-.69H8V8v.01zM7 2.32C3.86 2.32 1.3 4.86 1.3 8c0 3.14 2.56 5.7 5.7 5.7s5.7-2.55 5.7-5.7c0-3.15-2.56-5.69-5.7-5.69v.01zM7 1c3.86 0 7 3.14 7 7s-3.14 7-7 7-7-3.12-7-7 3.14-7 7-7z"></path></svg>
                    }
                    {
                        toastType === toast.TYPE.SUCCESS &&
                        <svg aria-hidden="true" height="16" width="12" viewBox="0 0 12 16"><path fillRule="evenodd" d="M12 5.5l-8 8-4-4L1.5 8 4 10.5 10.5 4 12 5.5z"></path></svg>
                    }
                    {
                        toastType === toast.TYPE.WARNING &&
                        <svg aria-hidden="true" height="16" width="16" viewBox="0 0 16 16"><path fillRule="evenodd" d="M8.893 1.5c-.183-.31-.52-.5-.887-.5s-.703.19-.886.5L.138 13.499a.98.98 0 0 0 0 1.001c.193.31.53.501.886.501h13.964c.367 0 .704-.19.877-.5a1.03 1.03 0 0 0 .01-1.002L8.893 1.5zm.133 11.497H6.987v-2.003h2.039v2.003zm0-3.004H6.987V5.987h2.039v4.006z"></path></svg>
                    }

                </div>
                <div className="toast-content">{text}</div>
            </div>
        )
    },
    UserActions: {
        RegisterBuyer: 1,
        RegisterSupplier: 2,
        VewProfile: 3,
        Logout: 4
    },
    ColorClass: {
        LightOrange: "--light-orange-color",
        LightGreen: "--light-green-color",
        LightYellow: "--light-yellow-color",
        LightBlue: "--light-blue-color"
    },
    StatusCode: {
        Active: 1,
        Inactive: 2
    },
    AccountType:
    {
        Supplier: 1,
        Buyer: 2
    },
    ConfirmType: {
        Register: 1,
        ForgotPassword: 2
    },
    AccountRequestStatusCode: {
        PendingForApproval: 1,
        Rejected: 2,
        Approved: 3,
        PendingForActivation: 4,
        PendingForDeactivation: 5
    },
    BuyerActionCode: {
        RequestDeactivation: 1,
        RequestAgain: 2,
        RequestActivation: 3,
        CancelRequest: 4
    },
    SupplierBuyerActionCode: {
        Approve: 1,
        Reject: 2
    },
    SupplierBuyerFilterStatusList: [
        {
            Code: 1,
            Description: i18n.t("supplier_managament.approved")
        },
        {
            Code: 2,
            Description: i18n.t("supplier_managament.rejected")
        },
        {
            Code: 3,
            Description: i18n.t("supplier_managament.pending")
        }
    ],
    PageSize: 10,
    DateFormat: "DD/MM/YYYY",
    DateFileFormat: "DD-MM-YYYY",
    PubSupType: {
        NotificationMessage: "Notification",
        UserLogin: "UserLogin",
        UserLogout: "UserLogout",
        RefreshToken: "RefreshToken"
    },
    InitNotificationOption: {
        pageNumber: 1,
        pageSize: 10,
        sort: "",
        isLoadMore: true,
        total: 0
    },
    SpaceConstant: {
        HorizontalGutter: 30,
        VerticalGutter: 10
    },
    LimitLength: {
        Length512: 512,
        Length256: 256,
        LengthInt: 9
    },
    BadgeType: {
        Dot: 1
    },
    FixItemsContainerId: "fixItemsContainerId",
    CssVariables: {
        FixItemsContainerHeight: "--fix-items-container-height",
        ModalFixItemsContainerHeight: "--modal-fix-items-container-height",
        ButtonGroupWidth: "--button-group-width"
    },
    NumberOfDecimalPlace: 2,
    SearchModalType: {
        PickslipNumber: 1,
        OrderNumber: 2
    },
    CellType: {
        PartOrderSearchInput: 1,
        PartOrderNumberInput: 2,
        Checkbox: 3,
        Selection: 4,
        Input: 5,
        NumberInput: 6,
        PartOrderInput: 7
    },
    ImportColumnName: {
        PartNumber: "PartNumber",
        Make: "DmsMakeId",
        OrderedQty: "OrderedQty",
        BinLocation: "BinLocation"
    },
    NotificationType: {
        Normal: 1,
        Navigation: 2,
        BuyerRegisterRequest: 3,
        UserActivationRequest: 4,
        UserDeactivationRequest: 5,
        PurchaseOrderSubmited: 6,
        BuyerRegisterApprove: 7,
        BuyerRegisterReject: 8,
        UserActivationApprove: 9,
        UserDeactivationApprove: 10
    },
    AppFunction: {
        BuyerManagement: 1,
        BuyerManagementEdit: 2,
        CancelPartApproval: 3,
        CancelPartApprovalEdit: 4,
        CreditReturnApproval: 5,
        CreditReturnApprovalEdit: 6,
        SupplierSetting: 7,
        SupplierManagement: 8
    },
    EcommerceCreatingStatusCode: {
        Creating: 1,
        Locking: 2,
        Completed: 3
    },
    PartOrderRequestType: {
        CancelPart: 1,
        ReturnPart: 2
    },
    PartOrderStatus: {
        Active: 1,
        FullyInvoiced: 2,
        PartiallyInvoiced: 3,
        Delete: 4
    },
    PartOrderStatusItems : [
        {
            Code: 1,
            Description: i18n.t("orders_management.active")
        },
        {
            Code: 2,
            Description: i18n.t("orders_management.fully_invoiced")
        },
        {
            Code: 3,
            Description: i18n.t("orders_management.partially_invoiced")
        }
        // {
        //     Code: 4,
        //     Description: i18n.t("orders_management.delete")
        // }
    ],
    SortDirection: {
        Asc: "ascend",
        Desc: "descend"
    },
    PartSearchTypeBitwise:
    {
        GetStoreZone: 1,
        GetTempLocation: 2,
        GetPickslip: 4,
        GetHoldingBay: 8,
        GetStockHistoryInfo: 16,
        GetDebtorPartPriceType: 32,
        GetDebtorPricingOverrideBySalesOrderType: 64,
        GetDebtorStore: 128,
        GetBatches: 256,
        GetMultiStore: 512,
        GetSupercede: 1024,
        GetPrices: 2048,
        GetShipmentQty: 4096,
        GetCustomerStockSpecialPrice: 8192,
        GetPricePerUnit: 16384,
        GetSalesOrderType: 32768,
        AccumulateQuantityAvailable: 65536,
        GetKitParts: 131072,
        GetDefaultBackOrderMethod: 262144,
        CreateNonStockRecord: 524288,
        GetStockTradeClubPrice : 1048576
    },
    DropDownDataTable: {
        Area: 862,
        Bureaucracy: 180,
        CountryCode: 54,
        DeliveryMethod: 33,
        DeliveryRunWithCurrentDepartureTime: 556
    },
    CountryCode: {
        Thailand: 41,
        Laos: 56,
        Kuwait: 60
    },
    SupplierBuyerFunction: {
        Public: 0,
        PartEnquiry: 1,
        PartSearch: 2,
        PlacePurchaseOrders: 3,
        RequestReturnPart: 4,
        RequestCancelOrder: 5,
        OrderManagement: 6,
        OutstandingCreditNotesEnquiry: 7,
        BackOrderManagement: 8,
        PartOrderHistory: 9,
        StockLocator: 10
    },
    APIDateFormat: "YYYY-MM-DD",
    ButtonWithModalType: {
        PartEnquiry: 1,
        RequestCreditReturnAddEditDetailsReason: 2
    },
    CreditRequisitionStatus: [
        {
            Code: 0,
            Description: i18n.t("outstanding_credit_note.all")
        },
        {
            Code: 1,
            Description: i18n.t("outstanding_credit_note.pending")
        },
        {
            Code: 2,
            Description: i18n.t("outstanding_credit_note.approved")
        },
        {
            Code: 3,
            Description: i18n.t("outstanding_credit_note.rejected")
        },
        {
            Code: 4,
            Description: i18n.t("outstanding_credit_note.arrived")
        },
        {
            Code: 5,
            Description: i18n.t("outstanding_credit_note.finalized")
        }
    ],
    CreditRequisitionStatusValue: {
        Pending: 1,
        Approved: 2,
        Rejected: 3,
        Arrived: 4,
        Finalized: 5
    },
    DownloadPdfDocumentType: {
        Invoice: 1,
        CreditRequest: 2,
        CreditNote: 3,
    },
    UrlParam: {
        SupplierId: "SupplierId",
        IsOnRedirect: "IsOnRedirect"
    },
    ButtonGroupId: "ButtonGroupId",
    SvgLogoPath: {
        GreenLogo: '/green_logo.svg', //default color
        BlueLogo: '/blue_logo.svg',
        OrangeLogo: '/orange_logo.svg',
        RedLogo: '/red_logo.svg',
        BlackLogo: '/black_logo.svg',
        GrayLogo: '/gray_logo.svg'
    },
    DealershipThemeId: {
        Blue: 1,
        Orange: 2,
        Red: 3,
        Black: 4,
        Gray: 5
    },
    DmsVersion: {
        version_1_36_6_0: "1.36.6.0",
        version_1_36_8_0: "1.36.8.0",
        version_1_36_9_0: "1.36.9.0",
        version_1_36_18_0: "1.36.18.0",
        version_1_37_6_0: "1.37.6.0"
    },
    RegionCode: {
        Au: "AU",
        Me: "ME",
        Nz: "NZ"
    },
    ContactDetails: [
        {
            PowerBy: "Titan DMS",
            PowerByLink: "http://www.titandms.com.au/features/",
            AvailableFrom: "Titan Dealer Management Solutions",
            AvailableFromLink: "http://www.titandms.com.au/",
            Address: "2 Flinders Parade, North Lakes, QLD 4509, Australia",
            Phone: "+61 (0) 7 3166 9907",
            Email: "enquiry@dealerparts.com.au",
            RegionCode: "AU"
        },
        {
            PowerBy: "Titan DMS",
            PowerByLink: "http://www.titandms.com.au/features/",
            AvailableFrom: "Sensus Automotive Solutions LLC",
            AvailableFromLink: "https://www.sensusuae.com/",
            Address: "DWC Office 249, 2nd Floor, Building C, Dubai",
            Phone: "+971 4887 9284",
            Email: "enquiry@dealerparts.ae",
            RegionCode: "ME"
        },
        {
            PowerBy: "Titan DMS",
            PowerByLink: "http://www.titandms.com.au/features/",
            AvailableFrom: "Titan Dealer Management Solutions",
            AvailableFromLink: "http://www.titandms.co.nz/",
            Address: "Level 3, Building 10, 666 Great South Road, Ellerslie, Auckland, New Zealand",
            Phone: "+64 (0)9 972 1458",
            Email: "enquiry@dealerparts.co.nz",
            RegionCode: "NZ"
        }
    ]
}