import React, { useState, useEffect } from 'react';
import { BuyerService, StorageService, CommonService } from '../../../services/services';
import './buyerDashboard.scss';

function BuyerDashboard(props) {
    return (
        <div className="buyer-dashboard-page">
            This is buyer dashBoard
        </div>
    );
}

export default BuyerDashboard;