import React, { useState, useEffect, useContext } from 'react';
import './orderManagement.scss';
import { Button, Row, Col, Form, Input, Typography, Select, Table, Breadcrumb, DatePicker, Modal, Tooltip } from 'antd';
import { useTranslation } from 'react-i18next';
import { CalcNumberOfItems_SimplePaginator, GetTableConfigs_SimplePaginator, DateTimeCell, StatusCell, ActionButtonsCell } from '../../../components/utils/gridUtils/gridUtils';
import { Constant, OrderManagementService, CommonService, BuyerService, StorageService, MediaService } from '../../../services/services';
import LoadingContext from '../../../components/utils/loading/loadingContext';
import { Link, useLocation, useHistory } from "react-router-dom";
import { useGlobalState } from '../../../utilities/globalState';
import moment from 'moment';
import NumericInput from './../../../components/numericInput/numericInput'
import { DownloadIcon } from './../../../components/icons/icons'

const { Option } = Select;
const { Text } = Typography;
const layout = {
    labelCol: { span: 24 },
    wrapperCol: { span: 24 }
};

export default function OrderManagement(props) {
    const { t } = useTranslation();
    const [formFilter] = Form.useForm();
    const location = useLocation();
    const history = useHistory();
    const [taxIncluded, setTaxIncluded] = useState(true)
    const [visibleDownloadDocumentModal, setVisibleDownloadDocumentModal] = useState(false);
    const [downloadDocuments, setDownloadDocuments] = useState([]);
    const { showLoading, dismissLoading } = useContext(LoadingContext);
    let stateObj = {};

    const getFormFilterEmpty = () => {
        return {
            PickslipNo: null,
            OrderNumber: null,
            InvoiceNo: null,
            CreditNo: null,
            DateFrom: null,
            DateTo: null,
            OrderType: null,
            Status: null
        };
    }

    if (history.action === "REPLACE") {
        stateObj = {
            totalItems: location.state && location.state.totalItems ? location.state.totalItems : 0,
            listOfOrders: location.state && location.state.listOfOrders ? location.state.listOfOrders : [],
            gridConfigOptions: location.state && location.state.gridConfigOptions ? location.state.gridConfigOptions : {
                pageNumber: 1,
                pageSize: Constant.PageSize,
                sort: ""
            },
            formFilterData: {
                PickslipNo: location.state.formFilterData.PickslipNo,
                OrderNumber: location.state.formFilterData.OrderNumber,
                InvoiceNo: location.state.formFilterData.InvoiceNo,
                CreditNo: location.state.formFilterData.CreditNo,
                DateFrom: location.state.formFilterData.DateFrom ? moment(new Date(location.state.formFilterData.DateFrom)) : null,
                DateTo: location.state.formFilterData.DateTo ? moment(new Date(location.state.formFilterData.DateTo)) : null,
                OrderType: location.state.formFilterData.OrderType,
                Status: location.state.formFilterData.Status
            },
            encryptKey: location.state.encryptKey,
            mediaServiceHostName: location.state.mediaServiceHostName
        };
    } else {
        stateObj = {
            totalItems: 0,
            listOfOrders: [],
            gridConfigOptions: {
                pageNumber: 1,
                pageSize: Constant.PageSize,
                sort: ""
            },
            formFilterData: getFormFilterEmpty(),
            encryptKey: "",
            mediaServiceHostName: ""
        };
    }
    const [totalItems, setTotalItems] = useState(0);
    const [listOfOrders, setListOfOrders] = useState([]);
    const [orderTypes, setOrderTypes] = useGlobalState('orderTypes')
    const [gridConfigOptions, setGridConfigOptions] = useState(stateObj.gridConfigOptions);
    const [encryptKey, setEncryptKey] = useState(stateObj.encryptKey);
    const [mediaServiceHostName, setMediaServiceHostName] = useState(stateObj.mediaServiceHostName);

    const getStateFilterData = () => {
        let data = formFilter.getFieldsValue();
        return {
            PickslipNo: data.PickslipNo,
            OrderNumber: data.OrderNumber,
            InvoiceNo: data.InvoiceNo,
            CreditNo: data.CreditNo,
            DateFrom: data.DateFrom ? data.DateFrom.format("YYYY/MM/DD") : null,
            DateTo: data.DateTo ? data.DateTo.format("YYYY/MM/DD") : null,
            OrderType: data.OrderType,
            Status: data.Status
        };
    }

    const openDownloadDocument = (record) => {
        let _documentItems = CommonService.orderByDownloadDocuments(record);
        setDownloadDocuments(_documentItems);
        setVisibleDownloadDocumentModal(true);
    }

    const cancelDownloadDocumentModal = () => {
        setDownloadDocuments([]);
        setVisibleDownloadDocumentModal(false);
    }

    const getDocumentDetails = (record) => {
        return {
            downloadDocuments: CommonService.orderByDownloadDocuments(record),
            mediaServiceHostName: mediaServiceHostName,
            encryptKey: encryptKey
        }
    }

    const columns = [
        {
            title: t("orders_management.pickslip_no"),
            dataIndex: 'PickslipNo',
            key: 'PickslipNo',
            width: 150,
            render: (value, record) => (
                <div className='flex-items align-space-between'>
                    <Link className="pickslip-url" to={{
                        pathname: Constant.Pages.OrderManagement_PickslipDetails,
                        state: {
                            ...record,
                            prevState: {
                                totalItems: totalItems,
                                listOfOrders: listOfOrders,
                                gridConfigOptions: gridConfigOptions,
                                formFilterData: getStateFilterData()
                            },
                            ...getDocumentDetails(record)
                        }
                    }}>
                        {record.PickslipNo}</Link>
                    {
                        (record.DocumentItems && record.DocumentItems.length > 0 && CommonService.dmsVersionGreaterOrEqual(Constant.DmsVersion.version_1_36_18_0))
                        &&
                        <ActionButtonsCell data={record} btnList={[
                            {
                                Description: t("common.download_document"),
                                Icon: <DownloadIcon />,
                                Hide: false,
                                ColorClass: Constant.ColorClass.LightBlue,
                                NoMargin: true,
                                handleAction: openDownloadDocument
                            }
                        ]} />
                    }
                </div>
            ),
            sorter: true
        },
        {
            title: t("orders_management.total_parts_ordered"),
            dataIndex: 'TotalPartsOrdered',
            key: 'TotalPartsOrdered',
            width: 120,
            align: "center"
        },
        {
            title: <div className="text-center" dangerouslySetInnerHTML={{ __html: taxIncluded ? t("orders_management.total_amount_ordered_inc") : t("orders_management.total_amount_ordered_ex") }}></div>,
            dataIndex: 'TotalAmountOrdered',
            key: 'TotalAmountOrdered',
            width: 150,
            align: "center",
            render: (value, record) => (
                <>{taxIncluded ? (record.TotalAmountOrderedTaxInclusive ? record.TotalAmountOrderedTaxInclusive.toFixed(Constant.NumberOfDecimalPlace) : null)
                    : (record.TotalAmountOrderedTaxExclusive ? record.TotalAmountOrderedTaxExclusive.toFixed(Constant.NumberOfDecimalPlace) : null)}</>
            )
        },
        {
            title: t("orders_management.order_type"),
            dataIndex: 'SalesOrderType',
            key: 'SalesOrderType',
            width: 150,
            align: "center",
            sorter: true,
            render: (value, record) => {
                let sot = orderTypes.find(p => p.Code === record.SalesOrderType);
                return sot ? <>{sot.Description}</> : <>{record.SalesOrderType}</>;
            }
        },
        {
            title: t("orders_management.order_number"),
            dataIndex: 'DebtorOrderNo',
            key: 'DebtorOrderNo',
            width: 150,
            sorter: true
        },
        {
            title: t("orders_management.pickslip_creation_date"),
            dataIndex: 'PickslipCreationTimestamp',
            key: 'PickslipCreationTimestamp',
            width: 130,
            align: "center",
            render: (value, record) => (
                <DateTimeCell value={record.PickslipCreationTimestamp} />
            ),
            sorter: true
        },
        {
            title: <div className="text-center" dangerouslySetInnerHTML={{ __html: taxIncluded ? t("orders_management.total_amount_invoice_inc") : t("orders_management.total_amount_invoice_ex") }}></div>,
            dataIndex: 'TotalAmountInvoice',
            key: 'TotalAmountInvoice',
            width: 150,
            align: "center",
            render: (value, record) => (
                <>{taxIncluded ? (record.TotalAmountInvoiceTaxInclusive ? record.TotalAmountInvoiceTaxInclusive.toFixed(Constant.NumberOfDecimalPlace) : null)
                    : (record.TotalAmountInvoiceTaxExclusive ? record.TotalAmountInvoiceTaxExclusive.toFixed(Constant.NumberOfDecimalPlace) : null)}</>
            )
        },
        {
            title: t("common.status"),
            dataIndex: 'PickslipStatusCode',
            key: 'PickslipStatusCode',
            render: (value, record) => (
                <StatusCell data={record}
                    colorClass={CommonService.getPickslipStatusColorClass(record.PickslipStatusCode)}
                    description={CommonService.getPickslipStatusDescription(record.PickslipStatusCode)} />
            ),
            width: 150,
            align: 'center',
            sorter: true
        }
    ];

    const onTableChange = (pagination, filters, sorter, extra) => {
        let data = formFilter.getFieldsValue();
        setGridConfigOptions({
            pageNumber: pagination.current,
            pageSize: pagination.pageSize
        });

        _getPickslips(pagination.current, {
            DateFrom: data.DateFrom,
            DateTo: data.DateTo,
            OrderTypeCode: data.OrderType,
            OrderStatusCode: data.Status,
            PickslipNo: data.PickslipNo,
            OrderNumber: data.OrderNumber,
            InvoiceNo: data.InvoiceNo,
            CreditNo: data.CreditNo,
            Sort: CommonService.getSortString(sorter)
        });
    }

    useEffect(() => {
        let timeoutFn = setTimeout(() => document.documentElement.style.setProperty(Constant.CssVariables.FixItemsContainerHeight, CommonService.calculateTableBodyMaxHeight()), 100);
        if (!orderTypes || orderTypes.length === 0) {
            showLoading();
            BuyerService.getOrderTypes()
                .then(result => {
                    if (result.data) {
                        setOrderTypes(result.data)
                    }
                })
                .finally(() => {
                    dismissLoading();
                })
                .catch(error => CommonService.handleErrorResponse(error))
        }

        let authData = StorageService.getAuthData()
        if (authData) {
            setTaxIncluded(authData.isShowPriceTaxIncluded)
        }
        if (history.action === "REPLACE") {
            let data = formFilter.getFieldsValue();
            _getPickslips(gridConfigOptions.pageNumber, {
                DateFrom: data.DateFrom,
                DateTo: data.DateTo,
                OrderTypeCode: data.OrderType,
                OrderStatusCode: data.Status,
                PickslipNo: data.PickslipNo,
                OrderNumber: data.OrderNumber,
                InvoiceNo: data.InvoiceNo,
                CreditNo: data.CreditNo,
                Sort: gridConfigOptions.sort
            });
        }
        return () => {
            BuyerService.cancelRequest();
            clearTimeout(timeoutFn);
        }
    }, []);

    const _getPickslips = (pageNumber, dataQuery) => {
        showLoading();
        OrderManagementService.getPickslips(pageNumber, Constant.PageSize, dataQuery)
            .then(r => {
                if (r.data) {
                    setEncryptKey(r.data.EncryptKey);
                    setMediaServiceHostName(r.data.MediaServiceHostName);
                    let _objectList = r.data.ObjectList;
                    setListOfOrders(CommonService.getPartOrderDocumentInfo(_objectList));
                    let numberForPaging = CalcNumberOfItems_SimplePaginator(pageNumber, r.data.ObjectList.length);
                    setTotalItems(numberForPaging);
                }
            }).finally(() => {
                dismissLoading();
            });
    }

    const resetFilter = () => {
        stateObj.formFilterData = getFormFilterEmpty();
        formFilter.setFieldsValue(stateObj.formFilterData);
        setGridConfigOptions({
            pageNumber: 1,
            pageSize: Constant.PageSize
        });
        setListOfOrders([]);
        setTotalItems(0);
    }

    const onRetrieve = (data) => {
        setGridConfigOptions({
            pageNumber: 1,
            pageSize: Constant.PageSize
        });
        _getPickslips(1, {
            DateFrom: data.DateFrom,
            DateTo: data.DateTo,
            OrderTypeCode: data.OrderType,
            OrderStatusCode: data.Status,
            PickslipNo: data.PickslipNo,
            OrderNumber: data.OrderNumber,
            InvoiceNo: data.InvoiceNo,
            CreditNo: data.CreditNo
        });
    }

    const onBlurOrderNumber = (e) => {
        if (e.target && e.target.value) {
            let formatOrderNumber = CommonService.trimSpacesAndUppercaseText(e.target.value, true, true)
            if (formatOrderNumber != e.target.value)
                formFilter.setFieldsValue({
                    OrderNumber: formatOrderNumber
                })
        }
    }

    return (
        <div className="orders-management-page">
            <Breadcrumb>
                <Breadcrumb.Item>{t("orders_management.orders_management")}</Breadcrumb.Item>
            </Breadcrumb>
            <Row id={Constant.FixItemsContainerId}>
                <Col xs={{ span: 24 }}>
                    <Form {...layout} form={formFilter} onFinish={onRetrieve} initialValues={stateObj.formFilterData}>
                        <Row gutter={[Constant.SpaceConstant.HorizontalGutter,]} align="middle">
                            <Col xs={{ span: 6 }}>
                                <Form.Item
                                    label={t('orders_management.pickslip_no')}
                                    colon={false} labelAlign="left" name="PickslipNo"
                                >
                                    <NumericInput allowClear={true} placeholder={t('orders_management.pickslip_no')} maxLength={Constant.LimitLength.LengthInt}></NumericInput>
                                </Form.Item>
                            </Col>
                            <Col xs={{ span: 6 }}>
                                <Form.Item
                                    label={t('orders_management.order_number')}
                                    colon={false} labelAlign="left" name="OrderNumber"
                                >
                                    <Input className='uppercase-text' placeholder={t('orders_management.order_number')} allowClear
                                        onBlur={onBlurOrderNumber} />

                                </Form.Item>
                            </Col>
                            <Col xs={{ span: 6 }}>
                                <Form.Item
                                    label={t('orders_management.invoice_no_or_credit_no')}
                                    colon={false} labelAlign="left" name="InvoiceNo"
                                >
                                    <NumericInput allowClear={true} placeholder={t('orders_management.invoice_no_or_credit_no')} maxLength={Constant.LimitLength.LengthInt}></NumericInput>
                                </Form.Item>
                            </Col>
                            <Col xs={{ span: 6 }}>
                                <Form.Item
                                    label={t('orders_management.status')}
                                    colon={false} labelAlign="left" name="Status"
                                >
                                    <Select placeholder={t('orders_management.status')} allowClear>
                                        {
                                            Constant.PartOrderStatusItems.map((n, index) => <Option key={index} value={n.Code}>{n.Description}</Option>)
                                        }
                                    </Select>
                                </Form.Item>
                            </Col>
                            <Col xs={{ span: 6 }} xxl={3}>
                                <Form.Item
                                    label={t('orders_management.date_from')}
                                    colon={false} labelAlign="left" name="DateFrom"
                                >
                                    <DatePicker placeholder={t('common.select_date')} format={Constant.DateFormat} />
                                </Form.Item>
                            </Col>
                            <Col xs={{ span: 6 }} xxl={3}>
                                <Form.Item
                                    label={t('orders_management.date_to')}
                                    colon={false} labelAlign="left" name="DateTo"
                                >
                                    <DatePicker placeholder={t('common.select_date')} format={Constant.DateFormat} />
                                </Form.Item>
                            </Col>
                            <Col xs={{ span: 6 }}>
                                <Form.Item
                                    label={t('orders_management.order_type')}
                                    colon={false} labelAlign="left" name="OrderType"
                                >
                                    <Select placeholder={t('orders_management.order_type')} allowClear>
                                        {
                                            orderTypes.map((n, index) => <Option key={index} value={n.Code}>{n.Description}</Option>)
                                        }
                                    </Select>
                                </Form.Item>
                            </Col>
                            <Col xs={{ span: 6 }}></Col>
                            <Col xs={{ span: 24 }} xxl={6} className="text-right">
                                <div className="m-t-10">
                                    <Button size="large" onClick={resetFilter}>{t("common.reset_filter")}</Button>
                                    <Button size="large" className="m-l-10 secondary-btn" htmlType="submit">{t("common.retrieve")}</Button>
                                </div>

                            </Col>
                        </Row>
                        {/* <Row className="m-t-10">
                            <Col xs={{ span: 24 }} className="text-right">
                                <Button size="large" onClick={resetFilter}>{t("common.reset_filter")}</Button>
                                <Button size="large" className="m-l-10 secondary-btn" htmlType="submit">{t("common.retrieve")}</Button>
                            </Col>
                        </Row> */}
                    </Form>
                </Col>
            </Row>

            <Row className="m-t-15">
                <Col xs={{ span: 24 }}>
                    <Table {...GetTableConfigs_SimplePaginator(columns, listOfOrders, totalItems, "PickslipNo", onTableChange, gridConfigOptions.pageNumber)} />
                </Col>
            </Row>

            {visibleDownloadDocumentModal &&
                <Modal
                    className="pickslip-details-page"
                    title={t("common.download_document")}
                    visible={true}
                    confirmLoading={false}
                    onCancel={cancelDownloadDocumentModal}
                    maskClosable={false}
                    footer={null}
                    width={768}
                    centered
                >
                    <Row>
                        <Col xs={{ span: 24 }}>
                            <Table className="custom-table" columns={CommonService.getDownloadDocumentColumns(mediaServiceHostName, encryptKey, showLoading, dismissLoading)}
                                dataSource={downloadDocuments}
                                rowKey="$id"
                                pagination={false} scroll={{ y: 300 }} />
                        </Col>
                    </Row>
                </Modal>
            }
        </div>
    );
}