import React, { useState } from 'react';
import './supplierDashboard.scss';
import { Button, Row, Col, Form, Input, Select, DatePicker, Table } from 'antd';
import { PlusIcon } from './../../../components/icons/icons';
import { GetTableConfigs, StatusCell } from './../../../components/utils/gridUtils/gridUtils'
import { Constant } from '../../../services/constant';
import { useTranslation } from 'react-i18next';

const { Option } = Select;
const layout = {
    labelCol: { span: 24 },
    wrapperCol: { span: 24 }
};

function SupplierDashboard(props) {

    return (
        <div className="supplier-dashboard-page">
            This is supplier dashBoard
        </div>
    );
}

export default SupplierDashboard;