
export const StorageService = {
    authKey: "authorizationData",
    supplierIdKey: "supplierId",
    showAvailableQtyKey: "showAvailableQty",
    setAuthData: function (authData, supplierId) {
        let key = this.authKey

        if (supplierId) {
            sessionStorage.setItem(this.supplierIdKey, supplierId)
        }
        else {
            supplierId = sessionStorage.getItem(this.supplierIdKey)
        }
        if(supplierId)
            key += "-" + supplierId

        localStorage.setItem(key, JSON.stringify(authData));
    },
    getAuthData: function () {
        let key = this.authKey
        let supplierId = sessionStorage.getItem(this.supplierIdKey)
        if (supplierId && supplierId != "undefined") {
            key += "-" + supplierId
        }

        let data = localStorage.getItem(key)
        if (data) {
            return JSON.parse(data);
        }
        return null
    },
    removeAuthData: function () {
        let key = this.authKey
        let supplierId = sessionStorage.getItem(this.supplierIdKey)
        if (supplierId && supplierId != "undefined") {
            key += "-" + supplierId
        }

        localStorage.removeItem(key);
        sessionStorage.removeItem(this.supplierIdKey);
    },
    setSupplierTheme: function (supplierId, data) {
        let key = "theme-" + supplierId;
        localStorage.setItem(key, JSON.stringify(data));
    },
    getSupplierTheme: function (supplierId) {
        let key = "theme-" + supplierId;
        let data = localStorage.getItem(key)
        if (data) {
            return JSON.parse(data);
        }
        return null
    },
    removeSupplierTheme: function (supplierId) {
        let key = "theme-" + supplierId;
        localStorage.removeItem(key);
    },
    setShowAvailableQty: function (value) {
        localStorage.setItem(this.showAvailableQtyKey, JSON.stringify(value));
    },
    getShowAvailableQty: function () {

        let data = localStorage.getItem(this.showAvailableQtyKey)
        return data == "true" ? true : false
    },
    removeShowAvailableQty: function () {
        localStorage.removeItem(this.showAvailableQtyKey);
    },
}