import React from 'react';
import './notFound.scss';
import { useTranslation } from 'react-i18next';

function NotFound() {
    const { t, i18n } = useTranslation();
    return (
        <div className="not-found">
            <h3>{t("common.page_not_found")}</h3>
        </div>
    );
}

export default NotFound;