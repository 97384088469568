import React, { useState, useEffect, useImperativeHandle, forwardRef, useRef } from 'react';
import { Input } from 'antd';
import LoadingContext from './../../components/utils/loading/loadingContext'
import PartSearch from './../../pages/buyer/partSearch/partSearch'
import { BuyerService, Constant, CommonService } from './../../services/services';
import { useTranslation } from 'react-i18next';
import OrderSearch from './../../pages/buyer/orderSearch/orderSearch'
import PickslipSearch from './../../pages/buyer/pickslipSearch/pickslipSearch'
import './searchInput.scss'

const SearchInput = forwardRef((props, ref) => {
    const { t } = useTranslation();
    const { showLoading, dismissLoading } = React.useContext(LoadingContext)
    const [visibleModal, setVisibleModal] = useState(false);
    const [value, setValue] = useState("");
    const [isFocus, setIsFocus] = useState(false);
    const [queryData, setQueryData] = useState({});
    const inputRef = useRef(null);

    useImperativeHandle(ref, () => ({
        showSearch(query, data) {
            let obj = {
                query: query,
                data: data
            }
            setQueryData(obj)
            setVisibleModal(true)
        },
        focus() {
            inputRef.current.focus()
        }

    }));

    const showPartSearch = (data, e) => {
        e.persist();
        if (!props.disableSearch) {
            //Show search modal if user clicks to search icon
            if (e && e.type == "click" && e.currentTarget && e.currentTarget.classList.contains("ant-btn")) {
                setVisibleModal(true)
            }
        }
    }

    const handleCancel = (objectData) => {
        setVisibleModal(false)
        setQueryData({})
        if (objectData) {
            props.handleSelectedItem(objectData)
        }
    }

    const inputChange = (e) => {
        let value = e.target.value;
        if (value) {
            if (props.trimValue) {
                value = value.trim()
            }
            if (props.uppercase)
                value = value.toString().toUpperCase()
        }

        if (props.type) {
            if (props.type == Constant.SearchModalType.PickslipNumber) {
                const reg = /^-?\d*(\.\d*)?$/;
                if ((!isNaN(value) && reg.test(value)) || value === '' || value === '-') {
                    if (!isNaN(value)) {
                        if ((props.min != null && props.min > Number(value)) ||
                            (props.max != null && props.max < Number(value)))
                            value = ''
                    }

                }
                else {
                    value = ''
                }
            }
        }
        setValue(value)
        if (props.onChange) {
            props.onChange(value)
        }

    }

    const getPartObjectData = () => {
        if (props.getSearchFields)
            return props.getSearchFields()
    }

    const getSearchModalTemplate = () => {
        if (props.type) {
            if (props.type == Constant.SearchModalType.PickslipNumber) {
                return (<PickslipSearch handleCancel={handleCancel} queryData={queryData} getAllData={props.getAllData}></PickslipSearch>)
            }
            else if (props.type == Constant.SearchModalType.OrderNumber) {
                return (<OrderSearch handleCancel={handleCancel} queryData={queryData}></OrderSearch>)
            }
        }
        return (
            <PartSearch objectData={getPartObjectData()} makeList={props?.makeList} handleCancel={handleCancel}></PartSearch>
        )
    }

    const onPressEnter = (e) => {
        if (props.onPressEnter) {
            props.onPressEnter(value,e)
        }
    }

    const setFocus = () => {
        if (true != isFocus) {
            setIsFocus(true)
        }
    }

    const setBlur = (e) => {
        const { value, onBlur, onChange } = props;
        if (props.type) {
            if (props.type == Constant.SearchModalType.PickslipNumber) {
                let valueTemp = value;
                if (value) {
                    if (value.charAt(value.length - 1) === '.' || value === '-') {
                        valueTemp = value.slice(0, -1);
                    }
                    onChange(valueTemp.replace(/0*(\d+)/, '$1'));
                }
            }
        }

        if (false != isFocus) {
            setIsFocus(false)
        }

        if (props.onBlur) {
            props.onBlur(e)
        }
    }


    useEffect(() => {
        setValue(props.value)
    }, [props.value])

    useEffect(() => {
        if (props.visibleSearchModalChanged) {
            props.visibleSearchModalChanged(visibleModal)
        }
    }, [visibleModal])

    return (
        <span className="search-input">
            <Input.Search ref={inputRef} className={`${isFocus ? "input-focused" : ""} ${props.uppercase ? "uppercase-text" : ""}`} allowClear onFocus={setFocus} onBlur={setBlur} onSearch={showPartSearch}
                onChange={inputChange} value={value} disabled={props.disabled} onPressEnter={(e)=>onPressEnter(e)} placeholder={props.placeholder} 
                maxLength={props.type == Constant.SearchModalType.PickslipNumber ? Constant.LimitLength.LengthInt : Constant.LimitLength.Length256 }/>
            {
                visibleModal && getSearchModalTemplate()
            }
        </span>

    )
})

export default SearchInput