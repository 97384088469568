import React, { useEffect, useState } from 'react';
import './backOrderManagement.scss';
import { useTranslation } from 'react-i18next';
import {
    Constant,
    CommonService,
    BuyerService,
    OrderManagementService,
    StorageService
} from '../../../services/services'
import LoadingContext from '../../../components/utils/loading/loadingContext'
import { Table, Button, Row, Col, Select, Form, Input, DatePicker, Breadcrumb, Modal, Tooltip } from 'antd';
import { GetTableConfigs_SimplePaginator, StatusCell, DateTimeCell, CalcNumberOfItems_SimplePaginator, TextAndButtonCell } from '../../../components/utils/gridUtils/gridUtils'
import { useGlobalState } from '../../../utilities/globalState';
import PartEnquiry from '../partEnquiry/partEnquiry';
import { ViewIcon, DownloadIcon } from './../../../components/icons/icons'
import moment from 'moment'
import NumericInput from './../../../components/numericInput/numericInput'
import PartSearch from './../../buyer/partSearch/partSearch'
import SearchInput from './../../../components/searchInput/searchInput'

const { Option } = Select;

const layout = {
    labelCol: { span: 24 },
    wrapperCol: { span: 24 }
};

const BackOrderManagement = (props) => {
    const { t } = useTranslation();
    const [form] = Form.useForm();
    const { showLoading, dismissLoading } = React.useContext(LoadingContext)
    const [totalItems, setTotalItems] = useState(0)
    const [expKeys, setExpKeys] = useState([])
    const [partOrderData, setPartOrderData] = useState([])
    const [gridConfigOptions, setGridConfigOptionsValue] = useState({
        pageNumber: 1,
        pageSize: Constant.PageSize,
        sort: "PickslipNo-descend",
    })
    const [orderTypes, setorderTypes] = useGlobalState('orderTypes')
    const [makeList, setMakeList] = useGlobalState('makeList')
    const [visibleExportReportButton, setVisibleExportReportButton] = useState(true);
    const [displayBackOrderEtaDates, setDisplayBackOrderEtaDates] = useGlobalState('displayBackOrderEtaDates')
    const [visibleModal, setVisibleModal] = useState(false);
    const [partSearchData, setPartSearchData] = useState([])

    const columns = [
        {
            title: t('common.pickslip_no'),
            dataIndex: 'PickslipNo',
            key: 'PickslipNo',
            sorter: true,
            ellipsis: true,
            width: 150,
            defaultSortOrder: 'descend',
        },
        {
            title: t('back_order_management.pickslip_creation_date'),
            dataIndex: 'PickslipCreationTimestamp',
            key: 'PickslipCreationTimestamp',
            sorter: true,
            ellipsis: true,
            width: 150,
            render: (value, record) => (
                <DateTimeCell value={record.PickslipCreationTimestamp} />
            )
        }, {
            title: t('common.order_type'),
            dataIndex: 'SalesOrderType',
            key: 'SalesOrderType',
            sorter: true,
            ellipsis: true,
            width: 150,
            render: (value, record) => {
                let sot = orderTypes.find(p => p.Code === record.SalesOrderType);
                return sot ? <>{sot.Description}</> : <>{record.SalesOrderType}</>;
            }
        },
        {
            title: t('common.order_no'),
            dataIndex: 'DebtorOrderNo',
            key: 'DebtorOrderNo',
            sorter: true,
            ellipsis: true,
            width: 150,
        },
        {
            title: t('back_order_management.hold_util_date'),
            dataIndex: 'HoldPartOrderUntilDate',
            key: 'HoldPartOrderUntilDate',
            sorter: true,
            render: (value, record) => (
                <DateTimeCell value={record.HoldPartOrderUntilDate} />
            ),
            align: 'center',
            width: 100
        },
        {
            title: t('back_order_management.hold_util_complete'),
            dataIndex: 'HoldPartOrderUntilComplete',
            key: 'HoldPartOrderUntilComplete',
            sorter: true,
            render: (value, record) => (
                <StatusCell data={record}
                    colorClass={CommonService.getStatusColorClass(record.HoldPartOrderUntilComplete)}
                    description={CommonService.getStatusDescription(record.HoldPartOrderUntilComplete, true)} />
            ),
            align: 'center',
            width: 150
        },
        {
            title: t('back_order_management.sales_person'),
            dataIndex: 'OrderClerkEmpId',
            key: 'OrderClerkEmpId',
            sorter: true,
            width: 80
        }
    ]

    const expandedRowRender = (record, index, indent, expanded) => {

        const expandColumns = [
            {
                title: <div dangerouslySetInnerHTML={{ __html: t("orders_management.pickslip_line_id_wrapped") }}></div>,
                dataIndex: 'PickslipLineID',
                key: 'PickslipLineID',
                sorter: false,
                ellipsis: true,
                width: 70,
            },
            {
                title: t('common.part_number'),
                dataIndex: 'PartItemCode',
                key: 'PartItemCode',
                sorter: false,
                ellipsis: true,
                width: 150,
                render: (value, record) => (
                    <TextAndButtonCell value={record.PartItemCode} tooltipText={t("common.view_part_details")}
                        buttonType={Constant.ButtonWithModalType.PartEnquiry}
                        data={record} icon={<ViewIcon />} includeWhiteSpaces={true} />
                )
            },
            {
                title: t('common.description'),
                dataIndex: 'PartDescription',
                key: 'PartDescription',
                sorter: false,
                ellipsis: true,
                width: 150
            },
            {
                title: t('back_order_management.make'),
                dataIndex: 'MakeId',
                key: 'MakeId',
                sorter: false,
                width: 100
            },
            {
                title: t('back_order_management.qty_bo'),
                dataIndex: 'BackOrderMethod',
                key: 'BackOrderMethod',
                sorter: false,
                align: 'right',
                width: 70,
                render: (value, record) => (
                    <span>{
                        record.BackOrderMethod ? (record.QtyOrdered - record.QtySupplied) : "0"
                    }</span>
                )
            }, {
                title: t('back_order_management.bo_method'),
                dataIndex: 'BackOrderMethod',
                key: 'BackOrderMethod',
                sorter: false,
                width: 70,
                render: (value, record) => (
                    <Tooltip placement="bottom" title={record.BackOrderMethodDescription}>
                        <span>{record.BackOrderMethod}</span>
                    </Tooltip>
                )
            }, {
                title: t('back_order_management.po_number'),
                dataIndex: 'PONo',
                key: 'PONo',
                sorter: false,
                width: 70
            }, {
                title: t('back_order_management.eta_date'),
                dataIndex: 'EtaDate',
                key: 'EtaDate',
                isHiden: !displayBackOrderEtaDates,
                sorter: false,

                render: (value, record) => (
                    <DateTimeCell value={record.EtaDate} />
                ),
                align: 'center',
                width: 100
            }
            , {
                title: t('back_order_management.comments'),
                dataIndex: 'Remark',
                key: 'Remark',
                sorter: false,
                width: 100
            },
        ]
        const data = record.children;
        let columnData = expandColumns.filter(f => !f.isHiden);
        return <Table columns={columnData} dataSource={data} pagination={false} rowKey={chilRecord => `${record.PickslipKey}-${chilRecord.PickslipLineID}`} />;

    };
    const getRequestedPartOrders = (pageNumber, pageSize, sort, searchData, isShowLoading = false, isExport = false) => {
        if (isShowLoading)
            showLoading()

        let params = { ...searchData };
        if (!params) params = {};
        if (sort) params['Sort'] = sort;
        params['IsBackOrder'] = true;

        OrderManagementService.getPickslips(pageNumber, pageSize, params, isExport)
            .finally(() => {
                if (isShowLoading)
                    dismissLoading()
            })
            .then(result => {
                if (result.data) {
                    if (isExport) {
                        const type = result.headers['content-type']
                        const blob = new Blob([result.data], { type: type })
                        const link = document.createElement('a')
                        link.href = window.URL.createObjectURL(blob)
                        const timeString = moment(new Date()).format(Constant.DateFileFormat);
                        link.download = `Back_Order_Export_${timeString}.xlsx`
                        link.click()
                    } else {
                        if (result.data) {
                            setPartOrderData(result.data.ObjectList);
                            let numberForPaging = CalcNumberOfItems_SimplePaginator(pageNumber, result.data.ObjectList.length);
                            setTotalItems(numberForPaging);
                        }
                    }

                }

            })
            .catch(error => CommonService.handleErrorResponse(error))
    }

    const onTableChange = (pagination, filters, sorter, extra) => {
        const searchData = form.getFieldsValue();
        setExpKeys([]);
        getRequestedPartOrders(pagination.current, pagination.pageSize, CommonService.getSortString(sorter), searchData, true)
        setGridConfigOptionsValue({
            pageNumber: pagination.current,
            pageSize: pagination.pageSize,
            sort: CommonService.getSortString(sorter)
        })
    }

    const onSearch = (data) => {
        setExpKeys([]);
        setVisibleExportReportButton(true);
        if (data) {
            getRequestedPartOrders(1, gridConfigOptions.pageSize, gridConfigOptions.sort, data, true)
        }
        else {
            let searchData = form.getFieldsValue();
            getRequestedPartOrders(1, gridConfigOptions.pageSize, gridConfigOptions.sort, searchData, true)
        }
    }

    const searchPart = (e)=>{
        e.preventDefault();
        var param = getSearchFields();
        setPartSearchData([])
        showLoading()
        BuyerService.getSearchStock(param.DebtorPartNumber, param.MakeKey)
            .finally(() => dismissLoading())
            .then(result => {
                if (!result.data || result.data.length == 0) {
                    CommonService.presentToast("warning", t("part_enquiry.part_not_found"))
                    resetFilter()
                    return
                }
                if (result.data.length == 1) {
                    handleSelectedPart(result.data[0])
                }
                else {//show part search
                    setPartSearchData(result.data)
                    showPartSearch()
                }
            })
            .catch(error => CommonService.handleErrorResponse(error))
    }

    const showPartSearch = () => {
        setVisibleModal(true)
    }
    const handleCancelPartSearch = (objectData) => {
        setVisibleModal(false)
        if (objectData) {
            handleSelectedPart(objectData)
        }
    };

    const getRequestedPartOrderLines = async (pickslipKey, isShowLoading = false) => {
        if (isShowLoading)
            showLoading();
        let data = [];
        try {
            let searchData = form.getFieldsValue();
            let makeId = null;
            if (searchData.MakeKey) {
                const make = makeList.find(f => f.MakeKey == searchData.MakeKey);
                makeId = make?.MakeId
            }

            const partlines = await OrderManagementService.getOrderPartLines(pickslipKey, makeId);
            if (partlines && partlines.data) {
                data = partlines.data;
            }
        } catch (error) {
            CommonService.handleErrorResponse(error);
            data = [];
        } finally {
            if (isShowLoading)
                dismissLoading();
        }
        return data;
    }

    const getSearchFields = () => {
        return form.getFieldsValue()
    }

    const handleSelectedPart = (objectData) => {
        const make = makeList.find(m=>m.MakeId == objectData.Make);
        form.setFieldsValue({
            "DebtorPartNumber": objectData.PartItemCodeCompressed,
             "MakeKey": make?make.MakeKey:null
        });
        onSearch(form.getFieldsValue());
    }

    const rowSelection = async (expanded, data) => {
        setExpKeys([]);
        if (!expanded) {
            return
        }
        for (let i = 0; i < partOrderData.length; i++) {
            if (partOrderData[i].PickslipKey === data.PickslipKey && !partOrderData[i].children) {
                const partLines = await getRequestedPartOrderLines(data.PickslipKey, true);
                partOrderData[i].children = partLines.filter(x => x.BackOrderMethod && x.TranStatusCode === 1)
                    .map(x => {
                        if (!x.BackOrderPONo) {
                            const backOrderPO = partLines.filter(x => x.BackOrderPONo);
                            if (backOrderPO && backOrderPO.length > 0) {
                                x.BackOrderPONo = backOrderPO[0].BackOrderPONo;
                            }
                        }
                        return x;
                    });
            }

            setPartOrderData(partOrderData);
            setExpKeys([data.PickslipKey]);
        }
    }


    const resetFilter = () => {
        form.resetFields();
        setGridConfigOptionsValue(
            {
                ...gridConfigOptions,
                pageNumber: 1
            }
        );
        setPartOrderData([]);
        setTotalItems(0)
    }
    const exportReport = () => {
        let searchData = form.getFieldsValue();
        getRequestedPartOrders(1, gridConfigOptions.pageSize, gridConfigOptions.sort, searchData, true, true);
    }


    const onChangeFilter = (e) => {
        setVisibleExportReportButton(false);
    }

    const onBlurOrderNumber = (e) => {
        if (e.target && e.target.value) {
            let formatOrderNumber = CommonService.trimSpacesAndUppercaseText(e.target.value, true, true)
            if (formatOrderNumber != e.target.value)
                form.setFieldsValue({
                    OrderNumber: formatOrderNumber
                })
        }
    }

    useEffect(() => {
        if (!orderTypes || orderTypes.length === 0) {
            BuyerService.getOrderTypes()
                .then(result => {
                    if (result.data) {
                        setorderTypes(result.data)
                    }
                })
                .catch(error => CommonService.handleErrorResponse(error))
                .finally(() => {
                })
        }
        if (!makeList || makeList.length == 0) {
            BuyerService.getMakeList()
                .then(result => {
                    if (result.data) {
                        setMakeList(result.data)
                    }
                })
                .catch(error => CommonService.handleErrorResponse(error))
        }
        if (!displayBackOrderEtaDates) {
            BuyerService.getDisplayBackOrderEtaDates().then(result => {
                setDisplayBackOrderEtaDates(result.data)
            })
                .catch(error => CommonService.handleErrorResponse(error))
                .finally(() => { })
        }
        // let searchData = form.getFieldsValue();
        // getRequestedPartOrders(gridConfigOptions.pageNumber, gridConfigOptions.pageSize, gridConfigOptions.sort, searchData, true)
        let timeoutFn = setTimeout(() => document.documentElement.style.setProperty(Constant.CssVariables.FixItemsContainerHeight, CommonService.calculateTableBodyMaxHeight()), 100);

        return () => {
            BuyerService.cancelRequest()
            clearTimeout(timeoutFn);
        }
    }, [])

    return (
        <div className="back-order-management">
            <Breadcrumb>
                <Breadcrumb.Item>{t("menu.back_order_management")}</Breadcrumb.Item>
            </Breadcrumb>
            <Row id={Constant.FixItemsContainerId}>
                <Col xs={{ span: 24 }}>
                    <Form
                        {...layout}
                        form={form}
                        name="backOrderFilterForm"
                        onFinish={onSearch}
                    >
                        <Row gutter={[Constant.SpaceConstant.HorizontalGutter,]}>
                            <Col xs={{ span: 6 }}>
                                <Form.Item
                                    label={t("common.date_from")}
                                    name="DateFrom" colon={false} labelAlign="left"
                                >
                                    <DatePicker format={Constant.DateFormat} onChange={onChangeFilter} />
                                </Form.Item>
                            </Col>
                            <Col xs={{ span: 6 }}>
                                <Form.Item
                                    label={t("common.date_to")}
                                    name="DateTo" colon={false} labelAlign="left"
                                >
                                    <DatePicker format={Constant.DateFormat} onChange={onChangeFilter} />
                                </Form.Item>
                            </Col>
                            <Col xs={{ span: 6 }}>
                                <Form.Item
                                    label={t("common.order_type")}
                                    name="OrderTypeCode" colon={false} labelAlign="left"
                                >
                                    <Select onChange={onChangeFilter} placeholder={t("common.order_type")} allowClear>
                                        <Option value="">{t("common.all")}</Option>
                                        {
                                            orderTypes.map((n, index) => <Option key={index} value={n.Code}>{n.Description}</Option>)
                                        }
                                    </Select>
                                </Form.Item>
                            </Col>
                            <Col xs={{ span: 6 }}>
                                <Form.Item
                                    label={t("common.pickslip_no")}
                                    name="PickslipNo" colon={false} labelAlign="left"
                                >
                                    <NumericInput allowClear={true} placeholder={t("common.pickslip_no")}
                                        onChange={onChangeFilter} maxLength={Constant.LimitLength.LengthInt}></NumericInput>
                                </Form.Item>
                            </Col>
                            <Col xs={{ span: 6 }}>
                                <Form.Item
                                    label={t("common.order_number")}
                                    name="OrderNumber" colon={false} labelAlign="left"
                                >
                                    <Input className='uppercase-text' placeholder={t("common.order_number")} onChange={onChangeFilter} onBlur={onBlurOrderNumber} allowClear />
                                </Form.Item>
                            </Col>
                            <Col xs={{ span: 6 }}>
                                <Form.Item
                                    label={t("back_order_management.make")}
                                    name="MakeKey" colon={false} labelAlign="left"
                                >
                                    <Select onChange={onChangeFilter}
                                        showSearch
                                        allowClear={true}
                                        optionFilterProp="children"
                                        placeholder={t("back_order_management.make")}
                                        filterOption={(input, option) =>
                                            option?.children.toUpperCase().indexOf(input.toUpperCase()) >= 0
                                        } >
                                        {
                                            makeList.map((n, index) => <Option key={index} value={n.MakeKey}>{n.MakeId}</Option>)
                                        }
                                    </Select>

                                </Form.Item>
                            </Col>
                            <Col xs={{ span: 6 }}>
                                <Form.Item
                                    label={t("common.part_number")}
                                    name="DebtorPartNumber" colon={false} labelAlign="left"
                                >
                                    <SearchInput allowClear objectData={getSearchFields()} getSearchFields={getSearchFields}
                                        makeList={makeList} handleSelectedItem={handleSelectedPart}
                                        onPressEnter={(value, e)=> {searchPart(e)}}
                                        uppercase={true} placeholder={t("common.part_number")}></SearchInput>
                                </Form.Item >
                            </Col>
                            <Col xxl={{ span: 6 }} xs={{ span: 24 }} className="text-right m-t-10 flex-items right-content">
                                <Tooltip placement="bottom" title={t("common.common_export_tooltip")}>
                                    <Button size="large" disabled={!visibleExportReportButton} onClick={exportReport} icon={<DownloadIcon />} className="included-icon">{t("common.common_export")}</Button>
                                </Tooltip>
                                <Button size="large" onClick={resetFilter} className="m-l-10">{t("common.reset_filter")}</Button>
                                <Button size="large" className="m-l-10 secondary-btn" htmlType="submit" >{t("common.retrieve")}</Button>
                            </Col>
                        </Row>
                    </Form>
                </Col>
            </Row>

            <Row className="m-t-10">
                <Col xs={{ span: 24 }}>
                    <Table
                        {...GetTableConfigs_SimplePaginator(columns, partOrderData, totalItems, "PickslipKey", onTableChange, gridConfigOptions.pageNumber)}
                        expandable={{ expandedRowRender, expandedRowKeys: expKeys }}
                        expandRowByClick={true}
                        onExpand={(b, r) => {
                            rowSelection(b, r)
                        }}
                    />
                </Col>
            </Row>
            {
                visibleModal && <PartSearch objectData={form.getFieldsValue()} makeList={makeList} handleCancel={handleCancelPartSearch} partList={partSearchData}></PartSearch>
            }
        </div>
    )

}

export default BackOrderManagement;