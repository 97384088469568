import axios from 'axios';
import { axiosPortal } from './authService'
import moment from 'moment';

const source = [];
let baseURL = "/Supplier"

export const SupplierBuyerService = {
    getSupplierBuyers: function (pageNumber, pageSize, sort, buyerContactEmail, statusCode, requestedDateFrom, requestedDateTo) {
        let cancelToken = axios.CancelToken.source();
        source.push(cancelToken);
        let url = `${baseURL}/GetRequestedBuyerList?PageNumber=${pageNumber}&PageSize=${pageSize}`
        if (sort) {
            url += `&Sort=${sort}`
        }
        if (buyerContactEmail) {
            url += `&BuyerContactEmail=${buyerContactEmail}`
        }
        if (statusCode) {
            url += `&StatusCode=${statusCode}`
        }
        if (requestedDateFrom) {
            let dateFormat = moment(requestedDateFrom).format("YYYY-MM-DD")
            url += `&RequestedDateFrom=${dateFormat}`
        }
        if (requestedDateTo) {
            let dateFormat = moment(requestedDateTo).format("YYYY-MM-DD")
            url += `&RequestedDateTo=${dateFormat}`
        }

        return axiosPortal.get(url, {
            cancelToken: cancelToken.token
        }).finally(() => {
            let index = source.indexOf(cancelToken)
            if (index > -1) {
                source.splice(index, 1)
            }
        })
    },
    updateSupplierBuyer: function (data) {
        let cancelToken = axios.CancelToken.source()
        source.push(cancelToken)
        return axiosPortal.put(`${baseURL}/UpdateBuyerForSupplier/${data.BuyerId}`, data, {
            cancelToken: cancelToken.token
        }).finally(() => {
            let index = source.indexOf(cancelToken)
            if (index > -1) {
                source.splice(index, 1)
            }
        })
    },
    getAppFunctions: function () {
        let cancelToken = axios.CancelToken.source();
        source.push(cancelToken);

        return axiosPortal.get(`${baseURL}/GetAppFunctions`, {
            cancelToken: cancelToken.token
        }).finally(() => {
            let index = source.indexOf(cancelToken)
            if (index > -1) {
                source.splice(index, 1)
            }
        })
    },
    getSupplierBuyerFunctions: function (buyerId, supplierId) {
        let cancelToken = axios.CancelToken.source();
        source.push(cancelToken);
        let url = `${baseURL}/GetSupplierBuyerFunctions?BuyerId=${buyerId}&SupplierId=${supplierId}`
        return axiosPortal.get(url, {
            cancelToken: cancelToken.token
        }).finally(() => {
            let index = source.indexOf(cancelToken)
            if (index > -1) {
                source.splice(index, 1)
            }
        })
    },
    getAndValidateDmsDebtorId: function (buyerId, supplierId, dmsDebtorId, isGetDmsDebtorOnly) {
        let cancelToken = axios.CancelToken.source();
        source.push(cancelToken);
        let url = `${baseURL}/GetAndValidateDmsDebtorId?BuyerId=${buyerId}&SupplierId=${supplierId}&DmsDebtorId=${dmsDebtorId}&IsGetDmsDebtorOnly=${isGetDmsDebtorOnly ? true : false}`
        return axiosPortal.get(url, {
            cancelToken: cancelToken.token
        }).finally(() => {
            let index = source.indexOf(cancelToken)
            if (index > -1) {
                source.splice(index, 1)
            }
        })
    },
    checkExistsSubmittedOrder: (buyerId, supplierId, dmsDebtorId) => {
        let cancelToken = axios.CancelToken.source();
        source.push(cancelToken);
        let url = `${baseURL}/CheckExistsSubmittedOrder?SupplierId=${supplierId}&BuyerId=${buyerId}`
        return axiosPortal.get(url, {
            cancelToken: cancelToken.token
        }).finally(() => {
            let index = source.indexOf(cancelToken)
            if (index > -1) {
                source.splice(index, 1)
            }
        });
    },
    cancelRequest: function () {
        if (source && source.length > 0) {
            for (var i = 0; i < source.length; i++) {
                source[i].cancel('Operation canceled.')
            }
            source.splice(0, source.length)
        }
    }
}