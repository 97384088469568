import React, { useState } from 'react';
import './menuItems.scss';
import { Menu } from 'antd';
import { useTranslation } from 'react-i18next';
import { Link } from "react-router-dom";

const MenuItems = (props) => {
    return (
        <Menu className="menu-items" mode="inline" defaultSelectedKeys={[props.location.pathname]}>
            {props.items.map((item, i) => {
                return (item.HasAccessPage &&
                    <Menu.Item key={item.PathName}>
                        <span className="icon">{item.Icon}</span>
                        <span className="text">{item.Name}</span>
                        <Link to={item.PathName} />
                    </Menu.Item>)
            })}
        </Menu>
    )
}

export default MenuItems