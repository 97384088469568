import React, { useState, useEffect } from 'react';
import { Form, Input, Modal, Select, Col, Row, Button, Table, Checkbox } from 'antd';
import { useTranslation } from 'react-i18next';
import LoadingContext from './../../../components/utils/loading/loadingContext'
import { BuyerService, Constant, CommonService, StorageService } from '../../../services/services';
import { GetTableConfigs, EditableCell, ActionButtonsCell } from './../../../components/utils/gridUtils/gridUtils'
import { TrashIcon, PlusIcon } from './../../../components/icons/icons';
import { ExclamationCircleOutlined } from '@ant-design/icons';
import { useGlobalState } from '../../../utilities/globalState'
import './importPartOrder.scss'

const { Option } = Select;
const layout = {
    labelCol: { span: 12 },
    wrapperCol: { span: 12 }
};
const tailLayout = {
    wrapperCol: { span: 24 },
};

const ImportPartOrder = (props) => {
    const { t } = useTranslation();
    const { showLoading, dismissLoading } = React.useContext(LoadingContext)
    const [form] = Form.useForm();
    const [columns, setColumns] = useState([])
    const [sourceData, setSourceData] = useState([])
    const [displayData, setDisplayData] = useState([])
    const [mappingData, setMappingData] = useState([])
    const [displayDestColumnList, setDisplayDestColumnList] = useState([])
    const [makeList, setMakeList] = useGlobalState('makeList');
    const [makeForGrid, setMakeForGrid] = useState([]);
    const [makeForImport, setMakeForImport] = useState("");
    const modalWidth = document.body.clientWidth - 300;
    const authData = StorageService.getAuthData()

    const _destColumnList = [
        {
            Code: Constant.ImportColumnName.PartNumber,
            Description: t('part_enquiry.part_number')
        },
        {
            Code: Constant.ImportColumnName.Make,
            Description: t('part_enquiry.make')
        },
        {
            Code: Constant.ImportColumnName.OrderedQty,
            Description: t('part_order.qty_order_inline')
        },
        {
            Code: Constant.ImportColumnName.BinLocation,
            Description: t('part_order.bin_location'),
            Hidden: CommonService.dmsVersionGreaterOrEqual(Constant.DmsVersion.version_1_36_6_0) ? false : true
        }
    ];
    let DestColumnList = JSON.parse(JSON.stringify(_destColumnList.filter(c => !c.Hidden)));
    if (makeForImport) {
        // DestColumnList = DestColumnList.filter(x => x.Code !== Constant.ImportColumnName.Make);
        DestColumnList.forEach(p => {
            if (p.Code === Constant.ImportColumnName.Make) {
                p.Disabled = true;
            }
        })
    }

    useEffect(() => {
        document.documentElement.style.setProperty(Constant.CssVariables.ModalFixItemsContainerHeight, CommonService.calculateScrollMaxHeightModal())
        let timeoutFn = setTimeout(() => document.documentElement.style.setProperty(Constant.CssVariables.FixItemsContainerHeight, CommonService.calculateTableBodyMaxHeight("previewGridId", 30, 0)), 100);
        if (!makeList || makeList.length === 0) {
            BuyerService.getMakeList()
                .then(result => {
                    if (result.data) {
                        setMakeList(result.data);
                        let _makeForGrid = result.data.map(p => {
                            return {
                                Code: p.MakeId,
                                Description: p.MakeId
                            }
                        });
                        setMakeForGrid(_makeForGrid);
                    }
                })
                .catch(error => CommonService.handleErrorResponse(error))
        } else {
            let _makeForGrid = makeList.map(p => {
                return {
                    Code: p.MakeId,
                    Description: p.MakeId
                }
            });
            setMakeForGrid(_makeForGrid);
        }
        form.setFieldsValue({
            ["IsFirstRowHasColumnHeading"]: true
        });

        setDisplayDestColumnList(DestColumnList)

        if (props.data && props.data.length > 0) {
            let maxLengthIndex = 0
            let maxLength = 0
            for (let i = 0; i < props.data.length; i++) {
                if (props.data[i] && props.data[i].length > 0) {
                    if (props.data[i].length > maxLength) {
                        maxLength = props.data[i].length
                        maxLengthIndex = i
                    }
                }
            }

            if (props.data[maxLengthIndex] && props.data[maxLengthIndex].length > 0) {
                let row = props.data[maxLengthIndex];
                let tempColumns = [];
                let mappingDataTemp = [];

                for (let i = 0; i < row.length; i++) {
                    let title = t('part_order.column_header') + (i + 1);

                    tempColumns.push({
                        title: title,
                        dataIndex: i,
                        key: i,
                        cellType: Constant.CellType.Input,
                        editable: true,
                        width: 200,
                        enableTabKeyEvent: true
                    });
                    mappingDataTemp.push({
                        Key: i,
                        SourceColumn: title,
                        TargetColumn: null
                    });
                }
                setColumns(tempColumns);
                setMappingData(mappingDataTemp);

                let displayDataTemp = [];
                for (let i = 0; i < props.data.length; i++) {
                    let obj = {};
                    for (let j = 0; j < tempColumns.length; j++) {
                        obj[j.toString()] = props.data[i][j];
                        obj["Key"] = i;
                    }
                    displayDataTemp.push(obj);
                }

                setSourceData(displayDataTemp);
                setDisplayData(displayDataTemp);
            }
        }



    }, [])

    useEffect(() => {
        let isFirstRowHeading = form.getFieldValue("IsFirstRowHasColumnHeading")
        if (sourceData && sourceData.length > 0 && isFirstRowHeading) {
            onColumnHeadingCheckChange(true)
        }
    }, [sourceData])

    const closeForm = () => {
        props.handleCancel()
    }

    const addNewPreviewLine = () => {
        let temp = displayData.map(x => Object.assign({}, x));
        let newLine = {
            Key: temp.length
        };
        let formData = form.getFieldsValue();
        if (makeForImport && formData.IsFirstRowHasColumnHeading) {
            let makeColumn = _destColumnList.find(p => p.Code === Constant.ImportColumnName.Make);
            if (makeColumn) {
                mappingData.some((v, i) => {
                    if (v.SourceColumn.toLowerCase() === makeColumn.Description.toLowerCase()) {
                        newLine[i.toString()] = makeForImport;
                        return true;
                    }
                });
            }
        }
        temp.push(newLine);
        setDisplayData(temp);
    }

    const onSubmit = () => {
        if (displayData && displayData.length > 0 && mappingData && mappingData.length > 0) {
            let partNumberKey = -1
            let makeKey = -1
            let qtyKey = -1
            let binLocationKey = -1

            let partNumbers = mappingData.filter(m => m.TargetColumn === Constant.ImportColumnName.PartNumber);
            if (partNumbers && partNumbers.length > 0) {
                partNumberKey = partNumbers[0].Key
            }

            let makes = mappingData.filter(m => m.TargetColumn === Constant.ImportColumnName.Make);
            if (makes && makes.length > 0) {
                makeKey = makes[0].Key
            }

            let orderedQtys = mappingData.filter(m => m.TargetColumn === Constant.ImportColumnName.OrderedQty);
            if (orderedQtys && orderedQtys.length > 0) {
                qtyKey = orderedQtys[0].Key
            }

            let binLocations = mappingData.filter(m => m.TargetColumn === Constant.ImportColumnName.BinLocation);
            if (binLocations && binLocations.length > 0) {
                binLocationKey = binLocations[0].Key
            }

            if (partNumberKey < 0 || (makeKey < 0 && !makeForImport) || qtyKey < 0) {
                return;
            }

            let isInvalidLine = false
            let tempRow = displayData.map(x => Object.assign({}, x));
            tempRow.forEach(line => {
                line.ErrorMessage = null

                if(!makeForImport){
                    let isValidMake = !!(makeList.find(p => p.MakeId === line[makeKey]));
                    if (!isValidMake) {
                        line.ErrorMessage = t("part_order.make_is_invalid")
                        isInvalidLine = true
                    }
    
                }
                
                if(!line[qtyKey] || isNaN(line[qtyKey])){
                    line.ErrorMessage = t("part_order.invalid_part_qtys")
                    isInvalidLine = true
                } else if(line[qtyKey] >= 100000) {
                    line.ErrorMessage = t("part_order.maximum_qty_error")
                    isInvalidLine = true
                }
                
            })

            if(isInvalidLine){
                setDisplayData(tempRow)
                return;
            }

            let request = displayData.map(d => {
                return {
                    PartNumber: d[partNumberKey].toString(),
                    DmsMakeId: makeForImport ? makeForImport : d[makeKey],
                    OrderedQty: d[qtyKey],
                    BinLocation: binLocationKey != -1 ? d[binLocationKey].toString() : null,
                    PickslipKey: props.Pickslip?.PickslipKey,
                    OrderNumber: props.orderNumber ? props.orderNumber : props.Pickslip?.OrderNumber,
                    OrderTypeCode: props.orderTypeCode ? props.orderTypeCode : props.Pickslip?.OrderTypeCode,
                    Sequence: d.Key,
                    UnitPriceTaxExclusive: 1,
                    UnitPriceTaxInclusive: 1
                }
            });

            showLoading()
            BuyerService.AddUpdatePartOrderLine(request)
                .finally(() => dismissLoading())
                .then(result => {
                    if (result.data && result.data.PickslipNo) {
                        props.handleCancel(result.data.PickslipNo)
                    } else {
                        if (result.data && result.data.PartOrderRequestLines && result.data.PartOrderRequestLines.length > 0) {
                            let errorLines = result.data.PartOrderRequestLines.filter(l => l.ErrorMessage)
                            if (errorLines && errorLines.length > 0) {
                                let temp = displayData.map(x => Object.assign({}, x));
                                temp.forEach(line => {
                                    line.ErrorMessage = null
                                })
                                errorLines.forEach(errorLine => {
                                    let line = temp.filter(t => t.Key == errorLine.Sequence)
                                    if (line && line.length > 0) {
                                        line[0].ErrorMessage = errorLine.ErrorMessage
                                    }
                                })
                                CommonService.presentToast("error", t("part_order.import_error"))
                                setDisplayData(temp)
                            }
                        }
                    }
                })
                .catch(error => CommonService.handleErrorResponse(error));
        }
    };

    const validateCellValue = (mappingList, displayList, key, columnName) => {
        if (columnName === Constant.ImportColumnName.PartNumber) {
            let invalidPartNumber = displayList.filter(t => !t[key])
            if (invalidPartNumber && invalidPartNumber.length > 0) {
                mappingList[key].CellError = t('part_order.invalid_part_numbers')
            } else {
                mappingList[key].CellError = null
            }
        } else if (columnName === Constant.ImportColumnName.Make) {
            let invalidMake = displayList.filter(t => !t[key])
            if (invalidMake && invalidMake.length > 0) {
                mappingList[key].CellError = t('part_order.invalid_part_makes')
            } else {
                mappingList[key].CellError = null
            }
        } else if (columnName === Constant.ImportColumnName.OrderedQty) {
            let invalidQty = displayList.filter(t => !t[key] || isNaN(t[key]))
            if (invalidQty && invalidQty.length > 0) {
                mappingList[key].CellError = t('part_order.invalid_part_qtys')
            } else {
                mappingList[key].CellError = null
            }
        } else {
            mappingList[key].CellError = null
        }
    }

    const onTargetSelected = (value, record) => {
        if (record) {
            let temp = mappingData.map(x => Object.assign({}, x));
            if (temp[record.Key]) {
                temp[record.Key].TargetColumn = value
                validateCellValue(temp, displayData, record.Key, value)
                setMappingData(temp)
            }
            let tempDestColumn = DestColumnList.map(x => Object.assign({}, x));
            // temp.forEach(t => {
            //     tempDestColumn = tempDestColumn.filter(c => c.Code != t.TargetColumn)
            // })
            tempDestColumn.forEach(x => {
                if (temp.some(t => t.TargetColumn === x.Code)) {
                    x.Hidden = true
                }
            })
            setDisplayDestColumnList(tempDestColumn)
        }
    }

    const onPreviewEdit = (value, record, dataIndex, triggeredOnTab) => {
        if (record) {
            let temp = displayData.map(x => Object.assign({}, x));
            if (temp[record.Key]) {
                temp[record.Key][dataIndex] = value;
                if (triggeredOnTab) {
                    temp[record.Key].CurrentEditFieldKey = dataIndex + 1
                } else {
                    temp[record.Key].CurrentEditFieldKey = null
                }
                setDisplayData(temp);
            }
        }
    }

    const fieldMappingColumns = [
        // {
        //     title: t('part_order.mandatory'),
        //     dataIndex: 'IsMandatory',
        //     key: 'IsMandatory',
        //     width: 100,
        //     editable: true,
        //     cellType: Constant.CellType.Checkbox
        // },
        {
            title: t('part_order.source_column'),
            dataIndex: 'SourceColumn',
            key: 'SourceColumn',
        },
        {
            title: t('part_order.target_column'),
            dataIndex: 'TargetColumn',
            key: 'TargetColumn',
            cellType: Constant.CellType.Selection,
            extraData: displayDestColumnList,
            editable: true,
            handleFormSubmit: onTargetSelected
        },
    ]

    const columnsMapping = fieldMappingColumns.map((col) => {
        if (!col.editable) {
            return col;
        }

        return {
            ...col,
            onCell: (record) => ({
                record,
                editable: col.editable,
                dataIndex: col.dataIndex,
                title: col.title,
                cellType: col.cellType,
                extraData: col.extraData,
                handleFormSubmit: col.handleFormSubmit
            }),
        };
    });

    const onInputChange = (value, record, dataIndex) => {
        if (record) {
            let temp = displayData.map(x => Object.assign({}, x));
            if (temp[record.Key]) {
                temp[record.Key][dataIndex] = value?.toString().toUpperCase();
                setDisplayData(temp);
            }
        }
    }

    const columnDisplay = [{
        title: t('common.action'),
        key: 'Id',
        render: (value, record) => (
            <ActionButtonsCell btnList={getActionButtonsInfo(record)} data={record} />
        ),
        align: 'center',
        width: 100
    }, ...columns].map((col) => {
        if (!col.editable || mappingData.some(p => p.Key === col.dataIndex && !p.TargetColumn)) {
            return col;
        }
        if (mappingData.some(p => p.Key === col.dataIndex && p.TargetColumn === Constant.ImportColumnName.Make)) {
            return {
                handleFormSubmit: onPreviewEdit,
                className: "highlight-cell",
                ...col,
                onCell: (record) => ({
                    record,
                    editable: col.editable,
                    dataIndex: col.dataIndex,
                    title: col.title,
                    cellType: Constant.CellType.Selection,
                    extraData: makeForGrid,
                    handleFormSubmit: onPreviewEdit
                }),
            };
        }
        if (mappingData.some(p => p.Key === col.dataIndex && p.TargetColumn === Constant.ImportColumnName.OrderedQty)) {
            return {
                handleFormSubmit: onPreviewEdit,
                className: "highlight-cell",
                ...col,
                onCell: (record) => {
                    return ({
                        record,
                        editable: col.editable,
                        dataIndex: col.dataIndex,
                        title: col.title,
                        cellType: Constant.CellType.NumberInput,
                        extraData: col.extraData,
                        handleFormSubmit: onPreviewEdit,
                        enableTabKeyEvent: col.enableTabKeyEvent
                    });
                },
            };
        }
        return {
            handleFormSubmit: onPreviewEdit,
            className: "highlight-cell",
            ...col,
            onCell: (record) => ({
                record,
                editable: col.editable,
                dataIndex: col.dataIndex,
                title: col.title,
                cellType: col.cellType,
                extraData: col.extraData,
                handleFormSubmit: onPreviewEdit,
                disableToggleInput: col.disableToggleInput,
                enableTabKeyEvent: col.enableTabKeyEvent,
                handleOnChange: onInputChange
            }),
        };
    });

    const components = {
        body: {
            cell: EditableCell,
        },
    };

    const onColumnHeadingCheckChange = (value) => {
        let temp = sourceData.map(x => Object.assign({}, x));
        let mappingDataTemp = mappingData.map(x => Object.assign({}, x));
        if (temp.length > 0) {
            let columnsTemp = columns.map(x => Object.assign({}, x));
            for (let i = 0; i < columnsTemp.length; i++) {
                let title = t('part_order.column_header') + (i + 1)
                if (value) {
                    if (temp[0][i]) {
                        columnsTemp[i].title = temp[0][i];
                    }
                }
                else {
                    columnsTemp[i].title = title;
                }
            }
            if (value) {
                temp.splice(0, 1)
            }
            for (let i = 0; i < temp.length; i++) {
                temp[i].Key = i
            }
            setDisplayData([]);
            setTimeout(() => {
                setDisplayData(temp);
            }, 300);
            let tempDestColumn = DestColumnList.map(x => Object.assign({}, x));
            for (let i = 0; i < mappingDataTemp.length; i++) {
                if (columnsTemp[i]) {
                    mappingDataTemp[i].SourceColumn = columnsTemp[i].title;
                    if (!mappingDataTemp[i].TargetColumn) {
                        let matchedColumns = DestColumnList.filter(x => x.Description && columnsTemp[i].title && x.Description.toString().toLowerCase() === columnsTemp[i].title.toString().toLowerCase());
                        if (matchedColumns.length > 0) {
                            mappingDataTemp[i].TargetColumn = matchedColumns[0].Code;
                            let destColumns = tempDestColumn.filter(x => x.Code === mappingDataTemp[i].TargetColumn);
                            if (destColumns.length > 0) {
                                destColumns[0].Hidden = true;
                            }
                        }
                    }
                }
                validateCellValue(mappingDataTemp, temp, i, mappingDataTemp[i].TargetColumn)
            }
            let destColumns = tempDestColumn.filter(x => mappingDataTemp.some(p => p.TargetColumn === x.Code));
            destColumns.forEach(p => {
                p.Hidden = true;
            });
            setColumns(columnsTemp);
            setMappingData(mappingDataTemp);
            setDisplayDestColumnList(tempDestColumn);
        }
    }

    const canSubmit = () => {
        if (displayData && displayData.length > 0 && mappingData && mappingData.length > 0) {
            let partNumberKey = -1
            let makeKey = -1
            let qtyKey = -1

            let partNumbers = mappingData.filter(m => m.TargetColumn === Constant.ImportColumnName.PartNumber);
            if (partNumbers && partNumbers.length > 0) {
                partNumberKey = partNumbers[0].Key
            }

            let makes = mappingData.filter(m => m.TargetColumn === Constant.ImportColumnName.Make);
            if (makes && makes.length > 0) {
                makeKey = makes[0].Key
            }

            let orderedQtys = mappingData.filter(m => m.TargetColumn === Constant.ImportColumnName.OrderedQty);
            if (orderedQtys && orderedQtys.length > 0) {
                qtyKey = orderedQtys[0].Key
            }

            if (partNumberKey < 0 || (makeKey < 0 && !makeForImport) || qtyKey < 0) {
                return false;
            }
        }

        let errors = mappingData.find(m => m.CellError);
        let visibleDisplay = mappingData.find(m => m.TargetColumn);
        return (!visibleDisplay || errors) ? false : true;
    }

    const deletePreviewLine = (data) => {
        let tempDisplayData = displayData.map(x => Object.assign({}, x));
        tempDisplayData = tempDisplayData.filter(d => d.Key != data.Key)
        for (let i = 0; i < tempDisplayData.length; i++) {
            tempDisplayData[i].Key = i
        }
        setDisplayData(tempDisplayData)
    }

    const getActionButtonsInfo = (record) => {
        return [
            {
                Description: t("common.delete"),
                Icon: <TrashIcon />,
                ColorClass: Constant.ColorClass.LightOrange,
                Hide: false,
                handleAction: deletePreviewLine
            },
            {
                Description: record.ErrorMessage,
                Icon: <ExclamationCircleOutlined />,
                ColorClass: Constant.ColorClass.LightOrange + " no-background",
                Hide: !record.ErrorMessage
            }
        ]
    }

    const onChangeMakeForImport = (val) => {
        let _mappingData = mappingData.map(x => Object.assign({}, x));
        if (val) {
            setMakeForImport(val);
            // DestColumnList = DestColumnList.filter(x => x.Code !== Constant.ImportColumnName.Make);
            DestColumnList.forEach(p => {
                if (p.Code === Constant.ImportColumnName.Make) {
                    p.Disabled = true;
                }
            });

            _mappingData.forEach(p => {
                if (p.TargetColumn === Constant.ImportColumnName.Make) {
                    p.TargetColumn = null;
                }
            })
            setMappingData(_mappingData);
        } else {
            setMakeForImport("");
            DestColumnList = JSON.parse(JSON.stringify(_destColumnList));
        }
        let tempDestColumn = DestColumnList.map(x => Object.assign({}, x));
        tempDestColumn = tempDestColumn.filter(x => (!_mappingData.find(t => t.TargetColumn === x.Code)));
        setDisplayDestColumnList(tempDestColumn);
    }

    return (
        <div>
            <Modal
                title={t("part_order.import_csv")}
                visible={true}
                onOk={onSubmit}
                confirmLoading={false}
                onCancel={closeForm}
                maskClosable={false}
                width={modalWidth}
                okText={t("common.import")}
                okButtonProps={{ disabled: !canSubmit() }}
                className="import-part-order"
                centered
            >
                <Form
                    {...layout}
                    form={form}
                    onFinish={onSubmit}
                    id="previewGridId"
                >
                    <Row align="middle" gutter={[Constant.SpaceConstant.HorizontalGutter]} className="header-form">
                        <Col className="inline-col" xs={{ span: 10 }}>
                            <Form.Item name="IsFirstRowHasColumnHeading" colon={false} labelAlign="left" valuePropName="checked" {...tailLayout} >
                                <Checkbox defaultChecked={true} onChange={value => onColumnHeadingCheckChange(value.target.checked)}>{t("part_order.first_row_has_header")}</Checkbox>
                            </Form.Item>
                        </Col>
                        <Col className="inline-col" xs={{ span: 10 }}>
                            <Form.Item className="make-select" name="MakeForImport" label={t("part_order.make_for_import")} colon={false} labelAlign="right" {...tailLayout}>
                                <Select allowClear onChange={(value) => { onChangeMakeForImport(value) }}>
                                    {makeList.map((n, index) => <Option key={index} value={n.MakeId}>{n.MakeId}</Option>)}
                                </Select>
                            </Form.Item>
                        </Col>
                        <Col xs={{ span: 4 }} className="text-right inline-col">
                            <Button type="primary" className="included-icon" icon={<PlusIcon />} onClick={addNewPreviewLine}>
                                {t("common.add")}
                            </Button>
                        </Col>
                    </Row>
                </Form>
                <Row>
                    <Col xs={{ span: 24 }}>
                        <Table
                            {...GetTableConfigs(columnDisplay, displayData, 10, "Key", null, 1, "preview-table", false)}
                            components={components}
                        />
                    </Col>
                </Row>
                <Row className="m-t-20" align="middle" gutter={[Constant.SpaceConstant.HorizontalGutter]}>
                    <Col xs={{ span: 24 }}>
                        <label className="text-field">{t("part_order.field_mapping")}</label>
                    </Col>
                    <Col xs={{ span: 24 }}>
                        <Table
                            {...GetTableConfigs(columnsMapping, mappingData, 0, "Key", null, 1, "", false)}
                            components={components}
                        />
                    </Col>
                </Row>
            </Modal>
        </div>
    )

}

export default ImportPartOrder