import React, { useState, useEffect, useContext } from 'react';
import './outstandingCreditNote.scss';
import { Button, Row, Col, Form, Input, Select, Table, Breadcrumb, DatePicker, Checkbox, Modal, Tooltip, Typography } from 'antd';
import { useTranslation } from 'react-i18next';
import { CalcNumberOfItems_SimplePaginator, GetTableConfigs_SimplePaginator, DateTimeCell, ActionButtonsCell, StatusCell } from '../../../components/utils/gridUtils/gridUtils';
import { Constant, OutstandingCreditNoteService, CommonService, StorageService, MediaService, BuyerService } from '../../../services/services';
import LoadingContext from '../../../components/utils/loading/loadingContext';
import { EditIcon, DownloadIcon } from './../../../components/icons/icons'
import { toast } from 'react-toastify';
import { Link, useLocation, useHistory } from "react-router-dom";
import moment from 'moment';
import NumericInput from './../../../components/numericInput/numericInput'

const { Option } = Select;
const { Text } = Typography;
const layout = {
    labelCol: { span: 24 },
    wrapperCol: { span: 24 }
};

export default function OutstandingCreditNote(props) {
    const { t } = useTranslation();
    const [formFilter] = Form.useForm();
    const { showLoading, dismissLoading } = useContext(LoadingContext);
    const location = useLocation();
    const history = useHistory();
    const [sortString, setSortString] = useState("");
    const [visibleDownloadDocumentModal, setVisibleDownloadDocumentModal] = useState(false);
    const [downloadDocuments, setDownloadDocuments] = useState([]);

    let stateObj = {};

    const getFormFilterEmpty = () => {
        return {
            RequestDateFrom: null,
            RequestDateTo: null,
            InvoiceNo: null,
            RequisitionStatus: 0,
            CreditRequestId: null,
            CreditRequestNo: null,
            OrderNo: null,
            ShowOutstandingOnly: true
        };
    }

    if (history.action === "REPLACE") {
        stateObj = {
            totalItems: location.state && location.state.totalItems ? location.state.totalItems : 0,
            listOfItems: location.state && location.state.listOfItems ? location.state.listOfItems : [],
            gridConfigOptions: location.state && location.state.gridConfigOptions ? location.state.gridConfigOptions : {
                pageNumber: 1,
                pageSize: Constant.PageSize,
                sort: ""
            },
            formFilterData: {
                RequestDateFrom: location.state.formFilterData.RequestDateFrom ? moment(new Date(location.state.formFilterData.RequestDateFrom)) : null,
                RequestDateTo: location.state.formFilterData.RequestDateTo ? moment(new Date(location.state.formFilterData.RequestDateTo)) : null,
                InvoiceNo: location.state.formFilterData.InvoiceNo,
                RequisitionStatus: location.state.formFilterData.RequisitionStatus,
                CreditRequestId: location.state.formFilterData.CreditRequestId,
                CreditRequestNo: location.state.formFilterData.CreditRequestNo,
                OrderNo: location.state.formFilterData.OrderNo,
                ShowOutstandingOnly: location.state.formFilterData.ShowOutstandingOnly
            },
            encryptKey: location.state.encryptKey,
            mediaServiceHostName: location.state.mediaServiceHostName
        };
    } else {
        stateObj = {
            totalItems: 0,
            listOfItems: [],
            gridConfigOptions: {
                pageNumber: 1,
                pageSize: Constant.PageSize,
                sort: ""
            },
            formFilterData: getFormFilterEmpty(),
            encryptKey: "",
            mediaServiceHostName: ""
        };
    }

    const [retrieveDisabled, setRetrieveDisabled] = useState(true);
    const [totalItems, setTotalItems] = useState(0);
    const [listOfItems, setListOfItems] = useState([]);
    const [gridConfigOptions, setGridConfigOptions] = useState(stateObj.gridConfigOptions);
    const [encryptKey, setEncryptKey] = useState(stateObj.encryptKey);
    const [mediaServiceHostName, setMediaServiceHostName] = useState(stateObj.mediaServiceHostName);

    let taxIncluded = true;
    let authData = StorageService.getAuthData();
    if (authData) {
        taxIncluded = authData.isShowPriceTaxIncluded;
    }

    const getStateFilterData = () => {
        let data = formFilter.getFieldsValue();
        return {
            RequestDateFrom: data.RequestDateFrom ? data.RequestDateFrom.format("YYYY/MM/DD") : null,
            RequestDateTo: data.RequestDateTo ? data.RequestDateTo.format("YYYY/MM/DD") : null,
            InvoiceNo: data.InvoiceNo,
            RequisitionStatus: data.RequisitionStatus,
            CreditRequestId: data.CreditRequestId,
            CreditRequestNo: data.CreditRequestNo,
            OrderNo: data.OrderNo,
            ShowOutstandingOnly: data.ShowOutstandingOnly
        };
    }

    const openDownloadDocument = (record) => {
        let _documentItems = CommonService.orderByDownloadDocuments(record);
        setDownloadDocuments(_documentItems);
        setVisibleDownloadDocumentModal(true);
    }

    const cancelDownloadDocumentModal = () => {
        setDownloadDocuments([]);
        setVisibleDownloadDocumentModal(false);
    }

    const getDocumentDetails = (record) => {
        return {
            downloadDocuments: CommonService.orderByDownloadDocuments(record),
            mediaServiceHostName: mediaServiceHostName,
            encryptKey: encryptKey
        }
    }

    const getRequestStatusColorClass = (item) => {
        if (item) {
            if (item.CreditRequisitionStatus == Constant.CreditRequisitionStatusValue.Approved)
                return Constant.ColorClass.LightGreen
            else if (item.CreditRequisitionStatus == Constant.CreditRequisitionStatusValue.Pending)
                return Constant.ColorClass.LightYellow
            else if (item.CreditRequisitionStatus == Constant.CreditRequisitionStatusValue.Rejected)
                return Constant.ColorClass.LightOrange
            else if (item.CreditRequisitionStatus == Constant.CreditRequisitionStatusValue.Arrived)
                return Constant.ColorClass.LightBlue
        }
        else {
            return Constant.ColorClass.LightBlue
        }
    }

    const getRequestStatusDescription = (item) => {
        if (item) {
            let sot = Constant.CreditRequisitionStatus.find(p => p.Code === item.CreditRequisitionStatus)
            if (sot)
                return sot.Description
        }
        return ''
    }

    const columns = [
        {
            title: t("outstanding_credit_note.action"),
            width: 70,
            align: "center",
            render: (value, record) => (
                <Link className="pickslip-url" to={{
                    pathname: Constant.Pages.OutstandingCreditNotesEnquiry_Details,
                    state: {
                        ...record, prevState: {
                            totalItems: totalItems,
                            listOfItems: listOfItems,
                            gridConfigOptions: gridConfigOptions,
                            formFilterData: getStateFilterData()
                        },
                        ...getDocumentDetails(record)
                    }
                }}><Button icon={<EditIcon />} className={`m-l-5 m-r-5 action-btn`} /></Link>
            ),
        },
        {
            title: t("outstanding_credit_note.credit_request_id"),
            dataIndex: 'CreditRequisitionHeaderKey',
            key: 'CreditRequisitionHeaderKey',
            width: 100,
            sorter: true,
            render: (value, record) => (
                <div className='flex-items align-space-between'>
                    <span>{record.CreditRequisitionHeaderKey}</span>
                    {
                        (record.DocumentItems && record.DocumentItems.length > 0 && CommonService.dmsVersionGreaterOrEqual(Constant.DmsVersion.version_1_36_18_0))
                        &&
                        // <Tooltip placement="bottom" title={t("common.download_document")}>
                        //         <Button icon={<DownloadIcon />}
                        //         style={{ float: "right" }} className={`m-l-5 action-btn`} onClick={() => { openDownloadDocument(record); }} />
                        // </Tooltip>

                        <ActionButtonsCell data={record} btnList={[
                            {
                                Description: t("common.download_document"),
                                Icon: <DownloadIcon />,
                                Hide: false,
                                ColorClass: Constant.ColorClass.LightBlue,
                                NoMargin: true,
                                handleAction: openDownloadDocument
                            }
                        ]} />
                    }
                </div>
            )
        },
        {
            title: t("outstanding_credit_note.credit_request_no"),
            dataIndex: 'CreditReference',
            key: 'CreditReference',
            width: 100,
            sorter: true
        },
        {
            title: t("outstanding_credit_note.credit_request_date"),
            dataIndex: 'RequestDate',
            key: 'RequestDate',
            width: 120,
            render: (value, record) => (
                <DateTimeCell value={record.RequestDate} />
            ),
            sorter: true
        },
        {
            title: t("outstanding_credit_note.invoice_no"),
            dataIndex: 'InvoiceNo',
            key: 'InvoiceNo',
            width: 100,
            sorter: true
        },
        {
            title: t("outstanding_credit_note.invoice_date"),
            dataIndex: 'InvoiceDate',
            key: 'InvoiceDate',
            width: 120,
            render: (value, record) => (
                <DateTimeCell value={record.InvoiceDate} />
            ),
            sorter: true
        },
        {
            title: t("outstanding_credit_note.order_no"),
            dataIndex: 'DebtorOrderNo',
            key: 'DebtorOrderNo',
            width: 100,
            sorter: true
        },
        {
            title: t("outstanding_credit_note.status"),
            dataIndex: 'CreditRequisitionStatus',
            key: 'CreditRequisitionStatus',
            width: 120,
            sorter: true,
            render: (value, record) =>
                // {
                //     let sot = Constant.CreditRequisitionStatus.find(p => p.Code === record.CreditRequisitionStatus);
                //     return sot ? <>{sot.Description}</> : <></>;
                // }
                <StatusCell data={record}
                    colorClass={getRequestStatusColorClass(record)}
                    description={getRequestStatusDescription(record)} />
        },
        {
            title: t("outstanding_credit_note.credit_approved_date"),
            dataIndex: 'ApproveDate',
            key: 'ApproveDate',
            width: 120,
            render: (value, record) => (
                <DateTimeCell value={record.ApproveDate} />
            ),
            sorter: true
        },
        {
            title: t("outstanding_credit_note.credit_note"),
            dataIndex: 'CreditNo',
            key: 'CreditNo',
            width: 150,
            sorter: true
        },
        {
            title: t("outstanding_credit_note.credit_note_date"),
            dataIndex: 'CreditNoteDate',
            key: 'CreditNoteDate',
            width: 120,
            sorter: true,
            render: (value, record) => (
                record.CreditNo ? <DateTimeCell value={record.CreditNoteDate} /> : <></>
            )
        },
        {
            title: t("outstanding_credit_note.credit_request_line"),
            dataIndex: 'CreditRequestLine',
            key: 'CreditRequestLine',
            width: 80,
            sorter: false
        },
        {
            title: <div className="text-center" dangerouslySetInnerHTML={{ __html: taxIncluded ? t('outstanding_credit_note.amount_requested_inc') : t('outstanding_credit_note.amount_requested_ex') }}></div>,
            dataIndex: taxIncluded ? 'RequestAmtTaxInclusive' : 'RequestAmtTaxExclusive',
            key: taxIncluded ? 'RequestAmtTaxInclusive' : 'RequestAmtTaxExclusive',
            width: 150,
            align: "center",
            sorter: true,
            render: (value, record) => (
                <>{taxIncluded
                    ? (record.RequestAmtTaxInclusive ? record.RequestAmtTaxInclusive.toFixed(Constant.NumberOfDecimalPlace) : null)
                    : (record.RequestAmtTaxExclusive ? record.RequestAmtTaxExclusive.toFixed(Constant.NumberOfDecimalPlace) : null)
                }</>
            )
        },
        {
            title: <div className="text-center" dangerouslySetInnerHTML={{ __html: taxIncluded ? t('outstanding_credit_note.amount_approved_inc') : t('outstanding_credit_note.amount_approved_ex') }}></div>,
            dataIndex: taxIncluded ? 'ApproveAmtTaxInclusive' : 'ApproveAmtTaxExclusive',
            key: taxIncluded ? 'ApproveAmtTaxInclusive' : 'ApproveAmtTaxExclusive',
            width: 150,
            align: "center",
            sorter: true,
            render: (value, record) => (
                <>{taxIncluded
                    ? (record.ApproveAmtTaxInclusive ? record.ApproveAmtTaxInclusive.toFixed(Constant.NumberOfDecimalPlace) : null)
                    : (record.ApproveAmtTaxExclusive ? record.ApproveAmtTaxExclusive.toFixed(Constant.NumberOfDecimalPlace) : null)
                }</>
            )
        },
        {
            title: <div className="text-center" dangerouslySetInnerHTML={{ __html: taxIncluded ? t('outstanding_credit_note.amount_rejected_inc') : t('outstanding_credit_note.amount_rejected_ex') }}></div>,
            dataIndex: taxIncluded ? 'RejectAmtTaxInclusive' : 'RejectAmtTaxExclusive',
            key: taxIncluded ? 'RejectAmtTaxInclusive' : 'RejectAmtTaxExclusive',
            width: 150,
            align: "center",
            sorter: true,
            render: (value, record) => (
                <>{taxIncluded
                    ? (record.RejectAmtTaxInclusive ? record.RejectAmtTaxInclusive.toFixed(Constant.NumberOfDecimalPlace) : null)
                    : (record.RejectAmtTaxExclusive ? record.RejectAmtTaxExclusive.toFixed(Constant.NumberOfDecimalPlace) : null)
                }</>
            )
        }
    ];

    const onTableChange = (pagination, filters, sorter, extra) => {
        let _sortString = CommonService.getSortString(sorter);
        let _currentPage = pagination.current;
        if (sortString !== _sortString) {
            setSortString(_sortString);
            _currentPage = 1;
        }

        let data = formFilter.getFieldsValue();
        setGridConfigOptions({
            pageNumber: _currentPage,
            pageSize: pagination.pageSize,
            sort: _sortString
        });

        _getCreditRequisition(_currentPage, {
            RequestDateFrom: data.RequestDateFrom,
            RequestDateTo: data.RequestDateTo,
            InvoiceNo: data.InvoiceNo,
            RequisitionStatus: data.RequisitionStatus,
            CreditRequestId: data.CreditRequestId,
            CreditRequestNo: data.CreditRequestNo,
            OrderNo: data.OrderNo,
            ShowOutstandingOnly: data.ShowOutstandingOnly,
            Sort: _sortString
        });
    }

    const _getCreditRequisition = (pageNumber, dataQuery) => {
        let validateRequestDate = true;
        if (dataQuery.RequestDateFrom && dataQuery.RequestDateTo
        ) {
            if (
                dataQuery.RequestDateFrom.year() > dataQuery.RequestDateTo.year()
                ||
                (
                    dataQuery.RequestDateFrom.year() === dataQuery.RequestDateTo.year()
                    &&
                    dataQuery.RequestDateFrom.month() > dataQuery.RequestDateTo.month()
                )
                ||
                (
                    dataQuery.RequestDateFrom.year() === dataQuery.RequestDateTo.year()
                    &&
                    dataQuery.RequestDateFrom.month() === dataQuery.RequestDateTo.month()
                    &&
                    dataQuery.RequestDateFrom.date() > dataQuery.RequestDateTo.date()
                )
            ) {
                validateRequestDate = false;
            }
        }
        if (validateRequestDate) {
            showLoading();
            OutstandingCreditNoteService.GetCreditRequisition(pageNumber, Constant.PageSize, dataQuery)
                .then(r => {
                    if (r.data) {
                        setEncryptKey(r.data.EncryptKey);
                        setMediaServiceHostName(r.data.MediaServiceHostName);
                        r.data.ObjectList.forEach((p, i) => { p["$index"] = i; });
                        let _objectList = r.data.ObjectList;
                        _objectList.forEach(obj => {
                            let _documentItems = [];
                            if (obj.InvoiceNo) {
                                _documentItems.push(CommonService.getDownloadDocumentItem(Constant.DownloadPdfDocumentType.Invoice, obj.InvoiceNo, obj.InvoiceDate, obj.DebtorTranKey));
                            }
                            if (obj.CreditRequisitionHeaderKey) {
                                _documentItems.push(CommonService.getDownloadDocumentItem(Constant.DownloadPdfDocumentType.CreditRequest, obj.CreditRequisitionHeaderKey, obj.RequestDate, obj.CreditRequisitionHeaderKey));
                            }
                            if (obj.CreditNo) {
                                _documentItems.push(CommonService.getDownloadDocumentItem(Constant.DownloadPdfDocumentType.CreditNote, obj.CreditNo, obj.CreditNoteDate, obj.CreditDebtorTranKey));
                            }
                            _documentItems.sort((p1, p2) => (p1.DocumentTypeCode - p2.DocumentTypeCode));
                            obj.DocumentItems = _documentItems;
                        });
                        setListOfItems(_objectList);
                        let numberForPaging = CalcNumberOfItems_SimplePaginator(pageNumber, r.data.ObjectList.length);
                        setTotalItems(numberForPaging);
                    }
                }).finally(() => {
                    dismissLoading();
                });
        } else {
            CommonService.presentToast(toast.TYPE.ERROR, t("outstanding_credit_note.error_validation_request_date"));
        }
    }

    useEffect(() => {
        let timeoutFn = setTimeout(() => document.documentElement.style.setProperty(Constant.CssVariables.FixItemsContainerHeight, CommonService.calculateTableBodyMaxHeight()), 100);
        if (history.action === "REPLACE") {
            let _retrieveDisabled = Object.keys(stateObj.formFilterData).filter(p => stateObj.formFilterData[p] && p !== "ShowOutstandingOnly").length === 0;
            setRetrieveDisabled(_retrieveDisabled);
            let data = formFilter.getFieldsValue();
            _getCreditRequisition(gridConfigOptions.pageNumber, {
                RequestDateFrom: data.RequestDateFrom,
                RequestDateTo: data.RequestDateTo,
                InvoiceNo: data.InvoiceNo,
                RequisitionStatus: data.RequisitionStatus,
                CreditRequestId: data.CreditRequestId,
                CreditRequestNo: data.CreditRequestNo,
                OrderNo: data.OrderNo,
                ShowOutstandingOnly: data.ShowOutstandingOnly,
                Sort: gridConfigOptions.sort
            });

        }
        return () => {
            BuyerService.cancelRequest();
            clearTimeout(timeoutFn);
        }
    }, []);

    const resetFilter = () => {
        stateObj.formFilterData = getFormFilterEmpty();
        formFilter.resetFields()
        setRetrieveDisabled(true);
        setGridConfigOptions({
            pageNumber: 1,
            pageSize: Constant.PageSize
        });
        setListOfItems([]);
        setTotalItems(0);

    }

    const onRetrieve = (data) => {
        setGridConfigOptions({
            pageNumber: 1,
            pageSize: Constant.PageSize
        });
        _getCreditRequisition(1, {
            RequestDateFrom: data.RequestDateFrom,
            RequestDateTo: data.RequestDateTo,
            InvoiceNo: data.InvoiceNo,
            RequisitionStatus: data.RequisitionStatus,
            CreditRequestId: data.CreditRequestId,
            CreditRequestNo: data.CreditRequestNo,
            OrderNo: data.OrderNo,
            ShowOutstandingOnly: data.ShowOutstandingOnly
        });
    }

    const onFieldsChange = (fields) => {
        let disabled = (fields.filter(obj => obj.value && obj.name.length > 0 && obj.name[0] !== "ShowOutstandingOnly").length === 0);
        setRetrieveDisabled(disabled);
    };

    const onBlurOrderNumber = (e) => {
        if (e.target && e.target.value) {
            let formatOrderNumber = CommonService.trimSpacesAndUppercaseText(e.target.value, true, true)
            if (formatOrderNumber != e.target.value)
                formFilter.setFieldsValue({
                    OrderNo: formatOrderNumber
                })
        }
    }

    return (
        <div className="orders-management-page">
            <Breadcrumb>
                <Breadcrumb.Item>{t("menu.credit_request_query")}</Breadcrumb.Item>
            </Breadcrumb>
            <Row id={Constant.FixItemsContainerId}>
                <Col xs={{ span: 24 }}>
                    <Form {...layout} form={formFilter} onFinish={onRetrieve} onFieldsChange={(_, allFields) => { onFieldsChange(allFields); }}
                        initialValues={stateObj.formFilterData}>
                        <Row align='middle' gutter={[Constant.SpaceConstant.HorizontalGutter,]}>
                            <Col xs={{ span: 6 }} xxl={6}>
                                <Form.Item
                                    label={t('outstanding_credit_note.credit_request_id')}
                                    colon={false} labelAlign="left" name="CreditRequestId"
                                >
                                    <NumericInput allowClear={true} placeholder={t('outstanding_credit_note.credit_request_id')}
                                        maxLength={Constant.LimitLength.LengthInt}></NumericInput>
                                </Form.Item>
                            </Col>
                            <Col xs={{ span: 6 }} xxl={6}>
                                <Form.Item
                                    label={t('outstanding_credit_note.credit_request_no')}
                                    colon={false} labelAlign="left" name="CreditRequestNo"
                                >
                                    <Input placeholder={t('outstanding_credit_note.credit_request_no')} allowClear />
                                </Form.Item>
                            </Col>
                            <Col xs={{ span: 6 }} xxl={6}>
                                <Form.Item
                                    label={t('outstanding_credit_note.invoice_no')}
                                    colon={false} labelAlign="left" name="InvoiceNo"
                                >
                                    <NumericInput allowClear={true} placeholder={t('outstanding_credit_note.invoice_no')} maxLength={Constant.LimitLength.LengthInt}></NumericInput>
                                </Form.Item>
                            </Col>
                            <Col xs={{ span: 6 }} xxl={6}>
                                <Form.Item
                                    label={t('outstanding_credit_note.order_no')}
                                    colon={false} labelAlign="left" name="OrderNo"
                                >
                                    <Input placeholder={t('outstanding_credit_note.order_no')} allowClear className='uppercase-text'
                                        onBlur={onBlurOrderNumber} />
                                </Form.Item>
                            </Col>
                            <Col xs={{ span: 6 }} xxl={3}>
                                <Form.Item
                                    label={t('outstanding_credit_note.request_date_from')}
                                    colon={false} labelAlign="left" name="RequestDateFrom"
                                >
                                    <DatePicker placeholder={t('common.select_date')} format={Constant.DateFormat} />
                                </Form.Item>
                            </Col>
                            <Col xs={{ span: 6 }} xxl={3}>
                                <Form.Item
                                    label={t('outstanding_credit_note.request_date_to')}
                                    colon={false} labelAlign="left" name="RequestDateTo"
                                >
                                    <DatePicker placeholder={t('common.select_date')} format={Constant.DateFormat} />
                                </Form.Item>
                            </Col>
                            <Col xs={{ span: 6 }} xxl={6}>
                                <Form.Item
                                    label={t('outstanding_credit_note.status')}
                                    colon={false} labelAlign="left" name="RequisitionStatus"
                                >
                                    <Select placeholder={t('outstanding_credit_note.status')} allowClear>
                                        {
                                            Constant.CreditRequisitionStatus.map((n, index) => <Option key={index} value={n.Code}>{n.Description}</Option>)
                                        }
                                    </Select>
                                </Form.Item>
                            </Col>
                            <Col xs={{ span: 6 }} xxl={6}>
                                <Form.Item label=" " colon={false} labelAlign="left" name="ShowOutstandingOnly" valuePropName="checked">
                                    <Checkbox>{t('outstanding_credit_note.show_outstanding_only')}</Checkbox>
                                </Form.Item>
                            </Col>
                            <Col xs={{ span: 24 }} xxl={6} className="text-right m-t--btn-group">
                                <Button size="large" onClick={resetFilter}>{t("common.reset_filter")}</Button>
                                <Button size="large" className="m-l-10 secondary-btn" htmlType="submit" disabled={retrieveDisabled}>{t("common.retrieve")}</Button>
                            </Col>
                        </Row>
                    </Form>
                </Col>
            </Row>

            <Row className="m-t--table">
                <Col xs={{ span: 24 }}>
                    <Table {...GetTableConfigs_SimplePaginator(columns, listOfItems, totalItems, "$index", onTableChange, gridConfigOptions.pageNumber)} />
                </Col>
            </Row>

            {visibleDownloadDocumentModal &&
                <Modal
                    className="pickslip-details-page"
                    title={t("common.download_document")}
                    visible={true}
                    confirmLoading={false}
                    onCancel={cancelDownloadDocumentModal}
                    maskClosable={false}
                    footer={null}
                    width={768}
                    centered
                >
                    <Row>
                        <Col xs={{ span: 24 }}>
                            <Table className="custom-table" columns={CommonService.getDownloadDocumentColumns(mediaServiceHostName, encryptKey, showLoading, dismissLoading)}
                                dataSource={downloadDocuments}
                                rowKey="$id"
                                pagination={false} scroll={{ y: 300 }} />
                        </Col>
                    </Row>
                </Modal>
            }
        </div>
    );
}