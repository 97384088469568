import React, { useEffect, useState } from "react";
import "./notification.scss";
import { Menu, Badge, List, Button, Popover, Empty, Col, Row } from 'antd';
import InfiniteScroll from 'react-infinite-scroller';
import { useGlobalState } from '../../utilities/globalState'
import { NotificationService, Constant } from '../../services/services'
import { BellIcon } from '../../components/icons/icons'
import { useTranslation } from 'react-i18next';
import { Link } from "react-router-dom";
import moment from 'moment'

export default function Notification(props) {
    const { t, i18n } = useTranslation();
    const [notificationMessages, setNotificationMessages] = useGlobalState('notificationMessages')
    const [notificationOption, setNotificationOption] = useGlobalState('notificationOption')

    // useEffect(() => {
    //     setNotificationOption(Constant.initNotificationOption)
    // }, [])

    const handleInfiniteOnLoad = (pageNumber) => {
        if (notificationMessages.length >= notificationOption.total) {
            setNotificationOption({
                ...notificationOption,
                isLoadMore: false,
            });
            return;
        }

        NotificationService.getNotifications(pageNumber, notificationOption.pageSize, notificationOption.sort)
            .then(result => {
                if (result.data) {
                    let list = notificationMessages.map(x => Object.assign({}, x))
                    list = list.concat(result.data.ObjectList)
                    setNotificationMessages(list)
                    setNotificationOption({
                        ...notificationOption,
                        pageNumber: pageNumber,
                        total: result.data.TotalItems,
                        totalUnread: result.data.TotalUnread
                    })
                }
            })
    }

    const getElapseTime = (data) => {
        let creationTimestamp = data.CreationTimestamp + 'Z'
        const diff = moment.duration(Date.now() - new Date(creationTimestamp))
        let seconds = diff.asSeconds()
        if(seconds < 60){
            return parseInt(seconds) + " secs ago"
        }
        let minutes = diff.asMinutes()
        if(minutes < 60){
            let text = parseInt(minutes) == 1 ? " min ago" : " mins ago"
            return parseInt(minutes) + text
        }
        let hours = diff.asHours()
        if(hours < 24){
            let text = parseInt(hours) == 1 ? " hour ago" : " hours ago"
            return parseInt(hours) + text
        }
        let days = diff.asDays()
        if(days < 365){
            let text = parseInt(days) == 1 ? " day ago" : " days ago"
            return parseInt(days) + text
        }
        let years = diff.asYears()
        let text = parseInt(years) == 1 ? " year ago" : " years ago"
        return parseInt(years) + text
    }

    const getPath = (pageCode) => {
        if(pageCode == Constant.AppFunction.BuyerManagement){
            return Constant.Pages.BuyerManagement
        } else if(pageCode == Constant.AppFunction.BuyerManagementEdit){
            return Constant.Pages.BuyerManagement_EditBuyer
        } else if(pageCode == Constant.AppFunction.SupplierSetting){
            return Constant.Pages.SupplierSetting
        } else if(pageCode == Constant.AppFunction.SupplierManagement){
            return Constant.Pages.SupplierManagement
        } else {
            return null
        }
    }

    const displayMessage = (data) => {
        if(data.NotificationTypeCode == Constant.NotificationType.Normal || getPath() == null){
            return (
                <Row className="row-noti">
                    <Col span={18} className="text-noti">{data.Message}</Col>  
                    <Col span={6} className="text-time">{getElapseTime(data)}</Col>                      
                </Row>
            )
        } else{
            return (
                <Row className="row-noti">
                    <Col span={18}><Link className="text-noti" to={getPath(data.NavigationPageCode)}>{data.Message}</Link></Col>  
                    <Col span={6} className="text-time">{getElapseTime(data)}</Col>                      
                </Row>
            )
        }
    }

    const onPopupDisplay = (visible) => {
        if(visible){
            NotificationService.markAsRead()
            .then(() => {
                let temp = {...notificationOption}
                temp.totalUnread = 0
                setNotificationOption(temp)
            })
        }      
    }

    const content = (
        <div key={notificationOption ? notificationOption.total : 0} className="infinite-scroll-container">
            <InfiniteScroll
                pageStart={1}
                initialLoad={false}
                loadMore={handleInfiniteOnLoad}
                hasMore={notificationOption.isLoadMore}
                useWindow={false}
            >
                <List
                    dataSource={notificationMessages}
                    locale={{ emptyText: <Empty description={t("common.no_data")} /> }}
                    renderItem={(item, i) => (
                        <List.Item>
                            {
                                displayMessage(item)
                            }
                        </List.Item>
                    )}
                />
            </InfiniteScroll>
        </div>
    )

    return (
        <Badge className={"notification " + props.className} count={notificationOption ? notificationOption.totalUnread : 0}>
            <Popover placement="bottomLeft" content={content} trigger="click" onVisibleChange={onPopupDisplay}>
                <Button className="icon-btn" shape="circle" icon={<BellIcon />} size="middle" />
            </Popover>
        </Badge>
    )
}